import { Injectable } from '@angular/core';
import { navItems } from './nav.items';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor() { }

  // returns data on the navItems
  getNavItemsBlueprintData(){
    return navItems.navItemBlueprint;
  }
  
}
