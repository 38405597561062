import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantModel } from '../models/consultant.model';
import { Store } from '@ngrx/store';
import { ConsultantDetailsService } from './consultant-details.service';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { UserModel } from '../models/user.model';
import { setUser } from 'app/core/store/currentUser/user.actions';
import { ImpersonateComponent } from '../../actuals/impersonate/impersonate.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})

export class UserService {
  currentUser: ConsultantModel | null;
  loggedInUser: UserModel;
  currentUserPhotoURL :string;
  private _announceImpersonate = new Subject<void>();
  announceImpersonate$ = this._announceImpersonate.asObservable();

  constructor(
    private _http: HttpClient,
    private _store: Store,
    private _dialog: MatDialog,
    private consultantDetailsService: ConsultantDetailsService
  ) { 
    this._store.select(getLoggedInUser).subscribe((data) => {
      this.loggedInUser = data;
    });
    this._store.select(getCurrentUser).subscribe((data)=>{
      this.currentUser = data;
    });

  }

  //Set Current user store value
  setCurrentUser(user: ConsultantModel) {
    this._store.dispatch(setUser({ user }));
  }

  //Get the user profile picture
  getUserPhoto(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('usermail', email);
    return this._http.get(URLPaths.URL_USER_PHOTO, {headers: ApiheaderService.getHeaders(), params: params, responseType: 'blob'});

  }

  //Opens the Impersonate Dialog box
  openImpersonateDialog() : void {
    this._dialog.open(ImpersonateComponent, {
      maxWidth: 'clamp(340px, 85vw, 800px)',
      width: '100%', 
      height: '65%',
      autoFocus: false, 
      hasBackdrop: true      
    });
  }

  //Get the current user Profile photo
  getUserProfilePhoto(userEmail: string): Observable<string> {
    return new Observable<string>(observer => {
      if(userEmail){
        this.getUserPhoto(userEmail).subscribe(
          value1 => {
            const userProfilePicture = URL.createObjectURL(value1);
            observer.next(userProfilePicture);
            observer.complete();
          },
          error => {
            if (error.status === 404) {
              observer.next(null);
              observer.complete();
            } else {
              observer.error(error);
            }
          }
        );
      } else {
          observer.next(null);
          observer.complete();
      }
      
    });
  }

  //Impersonate the user and update the store values
  impersonateUser(consultantName: string): void {
    this.consultantDetailsService.getConsultantDetails().subscribe(values => {
        this.currentUser = values.filter(consultant => {
            return consultant.Employee.toLocaleLowerCase() === consultantName.toLocaleLowerCase();
        })[0];
        this.setCurrentUser(this.currentUser);
        this.announceImpersonation();
    });
  }

  //Emit when impersonation is done
  announceImpersonation(): void {
    this._announceImpersonate.next();
  }

}
