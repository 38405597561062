import { commonFunctions } from '../../common/classes/common.class';
import { Component, HostListener, Injector, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConstantFormStates, Constants } from '../../common/utilities/constants.config';
import { BehaviorSubject } from 'rxjs';
import { DateUtility } from '../../common/utilities/date.utility';
import { Store } from '@ngrx/store';
import { getprojectDetailsPONumber } from 'app/core/store/projectDetailsPONumber/projectDetailsPONumber.selector';
import { GridOptions } from 'ag-grid-community';
import { getprojectDetailsInvoiceDetails } from 'app/core/store/projectDetailsInvoiceDetails/projectDetailsInvoiceDetails.selector';
import { setprojectDetailsInvoiceDetails } from 'app/core/store/projectDetailsInvoiceDetails/projectDetailsInvoiceDetails.action';
import { FormLayoutService } from './form-layout.service';

@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.scss']
})
export class FormLayoutComponent implements OnInit{
  @Input() formBlueprint: any
  @Input() fieldDetails: any[]
  @Input() service: any
  @Input() mode: string
  @Input() fieldValuesOfAllForms: BehaviorSubject<any>;
  @Input() enableSave: BehaviorSubject<boolean>;
  @Input() selectedIndex: number;
  @Input() rowData: any = [];
  @Input() columnDefs: any = [];
  @Input() gridOptions: GridOptions = {};
  @Input() rowSelection: string;
  @Input() projectDetailsContext: any;
  @Input() disabled: boolean;
  @Input() gridType: string;
  @Input() rowDataPD: any[];
  @Input() columnDefsPD:any[];

  @ViewChild('dropdown', { static:true }) dropdown: TemplateRef<any>;
  @ViewChild('input', { static: true }) input: TemplateRef<any>;
  @ViewChild('textarea', { static: true }) textarea: TemplateRef<any>;

  @ViewChild('date', { static: true }) date: TemplateRef<any>;

  @ViewChild('checkbox', { static: true }) checkbox: TemplateRef<any>;
  @ViewChild('radio', { static: true }) radio: TemplateRef<any>;
  @ViewChild('button', { static: true }) button: TemplateRef<any>;
  sectionFields: Map<string, string[]> = new Map<string, string[]>();
  fieldValues: Map<string, any> = new Map<string, any>();
  blueprintProps: any;
  formDataAfterSubmission: { [key: string]: any };
  checkboxMap: Map<string, boolean> = new Map<string, boolean>();

  islgScreenFlag: boolean;
  ismdScreenFlag: boolean;
  isLargeScreenFlag: boolean;
  issmScreenFlag: boolean;
  index: number = 0;
  subscribedToButtons: boolean;
  buttonClickHandler: string;
  isValidationRequired: boolean;
  buttonEnabled: boolean = true;
  activeButtons: string[];
  isRowSelectedforDeletion: boolean;
  invoiceDetails: any;
  public form: FormGroup = this.fb.group({});

  private scrollToBottomFlag: boolean = false;
  
  constructor(
    private fb: FormBuilder,
    private injector: Injector,
    private _store: Store,
    private formLayoutService: FormLayoutService
  ) {
    this.checkScreenSize();
  }

  async ngOnInit() {
    this.blueprintProps = this.formBlueprint.props;

    // you can make this method synchronous by prepopulating the values for the root fields like Client and directly accessing them synchronously, thus making the helper methods synchronous. As of now, async ngOnInit didn't give any issues. If any issues arise, maybe switching to synchronous methods might help
    await this.formInitializer();
    this.createForm();

    // Truncating PR-CODE to only first 4 characters are set using form-control
    this.service.setProjectCodeValidation(this.form)
    
    for (const sectionName in this.formBlueprint) {
      
      if (sectionName != Constants.PROPS_OBJECT_NAME) {
        
        const sectionFieldDetails = this.formBlueprint[sectionName]["formTemplateData"][0]["fieldDetails"];
        
        if(sectionFieldDetails === undefined) break;

        const fieldCodes = sectionFieldDetails.map((fieldDetail: { fieldCode: any; }) => fieldDetail.fieldCode);
        this.sectionFields.set(sectionName
          , fieldCodes);    // eg., projectDetails: ['CL', 'PR-CODE', ...]
      }
    }
    this.fieldValuesOfAllForms.subscribe((formValues) => {
      
      this.formDataAfterSubmission = formValues;
      
    })

    this._store.select(getprojectDetailsPONumber).subscribe((data: any)=> {
      this.service.setPOFormValue(this.form, data);
    })

     // Subscribe to valueChanges to detect changes
    //  this.form.valueChanges.subscribe(() => {
    //   if (this.form.dirty) {
    //     this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(true); 
    //   }
    // });

    //Subscribing to store so whenever it updates we do apply the changes for cascading
    this._store.select(getprojectDetailsInvoiceDetails).subscribe((data: any) => {
      this.invoiceDetails = data;
      if (this.invoiceDetails && (this.blueprintProps.formName == 'projectDetailsEditModeInvoiceDetails' || this.blueprintProps.formName == 'projectDetailsAddModeInvoiceDetails'))
        this.settingFieldsForInvoice(this.invoiceDetails);
    })
  }

  ngOnDestroy(): void
  {
    this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(false);    
  }

  async formInitializer() {    
    this.service = this.injector.get<any>(this.service);
    this.fieldDetails = await this.service.initialMetaDataConstructor(this.fieldDetails, this.mode, this.fieldValues);
    if(this.blueprintProps.formName=="projectDetailsEditModeInvoiceDetails" || this.blueprintProps.formName=="projectDetailsAddModeInvoiceDetails") {
      this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
        selectionFields : [],
        fieldsToEnable : [],
        fieldsToDisable: [],
        fieldsToSet: [],
        fieldsToReset: [],
        fieldDetails: this.fieldDetails,
        fieldValues: [],
        projectFieldValues: this.fieldValues
    }}))
    }
  }

  getFormData() {
    const formData: any = {};
    const fieldValuesOfAllForms = this.fieldValuesOfAllForms.value;
    for (let fieldDetail of this.fieldDetails) {
      const fieldName = fieldDetail.fieldName;
      const fieldCode = fieldDetail.fieldCode;
      const fieldType = fieldDetail.fieldType;
      const defaultValue = fieldDetail.fieldDefaultValue;
      if (fieldType === Constants.CHECKBOX_FIELD_NAME) {
          formData[fieldName] = this.fieldValues.get(fieldCode);
          // this.checkboxMap.set(fieldCode, defaultValue);
      }
      else {
        if(this.form.controls[fieldCode].value === null)
          formData[fieldName] = "";
        else
          formData[fieldName] = this.form.controls[fieldCode].value;
      }
      fieldValuesOfAllForms[fieldName] = formData[fieldName];
    }
    this.fieldValuesOfAllForms.next(fieldValuesOfAllForms);
    
    return this.formDataAfterSubmission;
  }

  //Asterisk needed when required and enabled
  shouldDisplayAsterisk(fieldDetail: any): boolean {
    // Add your condition here
    return fieldDetail.required && !this.form.get(fieldDetail.fieldCode).disabled; // Example condition
  }

  getIconName(buttonName: string): string {
    return this.service.getIcon(buttonName)
  }

  checkSubmit(buttonName, buttonType, buttonPage){
    if (this.form.dirty) {
      this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(true); 
    }
    return this.service.submitHandler(buttonName, buttonType, this.form.valid, this.getFormData());
  }


  restrictPRCodeLength(event: KeyboardEvent, fieldDetail: any): void {
    if (fieldDetail.fieldCode === 'PR-CODE') {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.value.length >= 4 && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();  // Prevent further input after reaching 4 characters
      }
    }
  }

  async handleButtonClick(buttonClickHandler: string, isValidationRequired: boolean, buttonName: string, buttonType: string, buttonPage: string) {
    if (!this.form.valid && isValidationRequired) {
      alert("Please fill all the Details")
      return;
    }
    //Clear button
    if(this.service.buttonSelector(buttonClickHandler)){
      // this.service.settingFields([this.getFormData()]);
      // this.settingFieldsForInvoice(this.cascadingMetaDetaForInvoice)
      let cascadingStateMetadata;
      
      if(this.blueprintProps.formName == 'projectDetailsEditModeInvoiceDetails' || this.blueprintProps.formName == 'projectDetailsAddModeInvoiceDetails'){
        cascadingStateMetadata = this.service.clearProjectDetails(this.invoiceDetails.fieldDetails);
      }
      else{
        cascadingStateMetadata = this.service.clearProjectDetails(this.fieldDetails);
        this.service.setProjectSelectedBoolean(true);
        this.service.setAddProjectOtherFormsDisabled(true);
        this.service.resetFieldsFunctionClear(cascadingStateMetadata, this.form, this.fieldDetails);
      }
      this.resetFields(cascadingStateMetadata);
      
      // if(buttonType === Constants.PROJECT_DETAILS_EDIT_MODE)
      this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(false);
      this.projectDetailsContext.isSubmitClicked = true;
    }
    else{
      const formData = this.getFormData();
      const params = [formData];
      const response = await commonFunctions.callFunction.bind(this.service)(buttonClickHandler, params); //Calling the function mentioned in blueprint
      if(buttonName == Constants.SUBMIT_BUTTON_NAME && response == "success"){
        // this.service.settingFields(params);
        this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(false);
        this.projectDetailsContext.isSubmitClicked = false;// Set the flag to true
        this.scrollToBottomFlag = true;
      }
      if(buttonName == Constants.SAVE_BUTTON_NAME && (response == "success" || response == "failure")){
        this.service.savebuttonsDisable(buttonType);
        this.projectDetailsContext.isSubmitClicked = false;
      }
      if(buttonName == Constants.DELETE_BUTTON_NAME && response == "success")
        this.service.deletebuttonsDisable(buttonType);
      if(buttonName == Constants.NEXT_BUTTON_NAME){
        this.service.setSelectedIndex(this.selectedIndex+1);
      }
      if(buttonName == Constants.VIEW_BUTTON_NAME){
        this.service.viewThemeAllocation(this.rowData, this.columnDefs, this.gridOptions, this.rowSelection, this.projectDetailsContext, this.gridType, this.mode, this.rowDataPD, this.columnDefsPD, this)
      }
    }
  }

  // scrollToBottom(): void {
  //   this.renderer.selectRootElement('#bottom').scrollIntoView({ behavior: 'smooth' });
  // }

  validateWholeNo(control: AbstractControl): ValidationErrors | null {
    let wholeNoRegex = new RegExp('^(0|[1-9][0-9]*)(\\.[0-9]+)?$');
    const isWholeNo = wholeNoRegex.test(control.value as string);
    if (isWholeNo) return null;
    return { errorMessage: 'Please enter a valid number!' };
    
  }

  validateNaturalNo(control: AbstractControl): ValidationErrors | null {
    let naturalNoRegex = new RegExp('^[1-9][0-9]*$');
    const isNaturalNo = naturalNoRegex.test(control.value as string);
    if (isNaturalNo) return null;
    return { errorMessage: 'Please enter a valid number!' };
  }

  validateProjectCodeLength(control: AbstractControl): ValidationErrors | null {
    if(control.value && control.value.label.length > 4)
      return { errorMessage: 'Please enter a valid PR-CODE!' };
    return null;
  }

  createForm() {
    // assumption for 'required' validator: whenever a field gets enabled, a required validator is added (only if the required property is true in the blueprint). When it gets `disabled`, the required validator gets removed
    for (const control of this.fieldDetails) {
      this.form.addControl(control.fieldCode, new FormControl({ value: control.defaultValue, disabled: (control.enabledFor === ConstantFormStates.enabledAlways) ? false : true }, { nonNullable: true }));
      const validators = [];
      if (control.enabledFor === ConstantFormStates.enabledAlways && control.required) {
        validators.push(Validators.required);
      }
      if (control.inputType === Constants.NUMBER_FIELD_NAME) {
        validators.push(this.validateWholeNo);
      }
      if (control.inputType === Constants.DROPDOWN_FIELD_NAME && control.fieldCode === "PR-CODE"){
        validators.push(this.validateProjectCodeLength);
      }

      this.form.controls[control.fieldCode].setValidators(validators);
      this.form.controls[control.fieldCode].updateValueAndValidity();
      this.fieldValues.set(control.fieldCode, control.defaultValue);
    }
  }

  enableControl(fieldCode: string) {
    let formControl = this.form.controls[fieldCode];
    if (formControl.enabled) return;
    formControl.enable();

    for (let index in this.fieldDetails) {
      let field = this.fieldDetails[index];
      if (field.fieldCode === fieldCode && field.required) {
        formControl.addValidators(Validators.required);
        formControl.updateValueAndValidity();
        break;
      }
    }
  }

  disableControl(fieldCode: string) {
    let formControl = this.form.controls[fieldCode];
    if (formControl.disabled) return;
    formControl.disable();
    
    for (let index in this.fieldDetails) {
      let field = this.fieldDetails[index];
      if (field.fieldCode === fieldCode) {
        formControl.removeValidators(Validators.required);
        formControl.updateValueAndValidity();
        break;
      }
    }
  }

  resetFields(fieldsToReset: string[]) {
    for (const index in this.fieldDetails) {
      const fieldCode = this.fieldDetails[index].fieldCode;
      const defaultValue = this.fieldDetails[index].defaultValue;
      const inputType = this.fieldDetails[index].inputType;
      if (fieldsToReset.includes(fieldCode)) {
        this.form.controls[fieldCode].reset(defaultValue);
        this.fieldValues.set(fieldCode, defaultValue);

        // Reset date field
        if (inputType === Constants.DATE_FIELD_NAME) {
          

          const dateInputs = document.querySelectorAll('[id^="mat-input-"]');
          dateInputs.forEach((inputElement: HTMLInputElement) => {
            if (inputElement) {
              (inputElement as HTMLInputElement).value = defaultValue;
              const event = new Event('input', { bubbles: true });
              inputElement.dispatchEvent(event);
            } else {
              console.error('No matching date input elements found.');
            }
          });

        }

        if (inputType === Constants.CHECKBOX_FIELD_NAME) {
          this.checkboxMap.set(fieldCode, defaultValue);
        }
      
        // Set default value of purchaseOrder No to be null whern only clent is selected again
        this.service.resetPurchaseOrder(fieldCode, this.fieldDetails, fieldsToReset, this.form);
        this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(false);
        if(inputType === Constants.RADIO_FIELD_NAME) {
          var radios = document.querySelectorAll(`input[type="radio"][ng-reflect-name=${fieldCode}]`);
          radios.forEach((radio: any) => {
            radio.checked = false;
          });
          this.form.controls[fieldCode].disable();
        }
       }
    }
    this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(false);  
  }

  clearDefaultValue(fieldsToClear: string[]) {
    for (const index in this.fieldDetails) {
      const fieldCode = this.fieldDetails[index].fieldCode;
      const defaultValue = "";
      if (fieldsToClear.includes(fieldCode)) {
        this.form.controls[fieldCode].reset(defaultValue);
        this.fieldDetails[index].defaultValue = defaultValue;
      }
    }
  }

  settingFieldsForInvoice(cascadingStateMetadata) {
    for (const fieldCode of cascadingStateMetadata.fieldsToEnable) {
      this.enableControl(fieldCode);
    }

    for (const fieldCode of cascadingStateMetadata.fieldsToDisable) {
      this.disableControl(fieldCode);
    }

    for (const fieldToSet of cascadingStateMetadata.fieldsToSet) {
      this.form.controls[fieldToSet[0]].setValue(fieldToSet[1]);
      this.fieldValues.set(fieldToSet[0], fieldToSet[1]);
      if (fieldToSet[0] === "NO-VAT" || fieldToSet[0] === "CHG" || fieldToSet[0] === "SHB") {
        this.checkboxMap.set(fieldToSet[0], fieldToSet[1]);
      }
    }

    if (this.invoiceDetails.fieldValues.length == 0) {
      this._store.dispatch(setprojectDetailsInvoiceDetails({
        projectDetailsInvoiceDetails: {
          selectionFields: this.invoiceDetails.selectionFields,
          fieldsToEnable: this.invoiceDetails.fieldsToEnable,
          fieldsToDisable: this.invoiceDetails.fieldsToDisable,
          fieldsToSet: this.invoiceDetails.fieldsToSet,
          fieldsToReset: this.invoiceDetails.fieldsToReset,
          fieldDetails: this.invoiceDetails.fieldDetails,
          fieldValues: this.fieldValues,
          projectFieldValues: this.invoiceDetails.projectFieldValues
        }
      }))
    }
   
    this.resetFields(cascadingStateMetadata.fieldsToReset);
    
    for (const fieldCode of cascadingStateMetadata.fieldsToEnable) {
      this.enableControl(fieldCode);
    }
  }

  async onSelectionChange(event: any) {
    let selectedFieldCode: string = event.selectedFieldCode;
    let selectedValue = event.selectedValue;
    
    this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(true); 
    
    if(event.fieldType == Constants.RADIO_FIELD_NAME)
    {
      let selectedValueIndex = event.fieldValues.indexOf(selectedValue);
      selectedFieldCode = event.fieldKeys[selectedValueIndex];
      this.form.controls[event.selectedFieldCode].setValue(selectedFieldCode); 
    }

    if (selectedValue === Constants.CHECKBOX_TOGGLE_EVENT_NAME) {
      if(typeof this.fieldValues.get(selectedFieldCode) === "string" || typeof this.fieldValues.get(selectedFieldCode) === undefined) {
        this.fieldValues.set(selectedFieldCode,commonFunctions.Stringtoboolean(this.fieldValues.get(selectedFieldCode)));
      }
      this.fieldValues.set(selectedFieldCode, !this.fieldValues.get(selectedFieldCode));
      selectedValue = this.fieldValues.get(selectedFieldCode);
      this.checkboxMap.set(selectedFieldCode, !this.checkboxMap.get(selectedFieldCode));
    } 
 
    else {
      this.fieldValues.set(selectedFieldCode, selectedValue);
    }
    let invoiceFeildDetailsForSelection = this.invoiceDetails.fieldDetails;
    let cascadingStateMetadata = await this.service.onDropdownSelection(this.fieldDetails, this.formBlueprint.props, selectedFieldCode, selectedValue, this.mode, this.fieldValues, invoiceFeildDetailsForSelection);
    
    if (cascadingStateMetadata.hasOwnProperty('fieldsToEnable')) {
      for (const fieldCode of cascadingStateMetadata.fieldsToEnable) {
        this.enableControl(fieldCode);
      }
    }
    
    if (cascadingStateMetadata.hasOwnProperty('fieldsToDisable')) {
      for (const fieldCode of cascadingStateMetadata.fieldsToDisable) {
        this.disableControl(fieldCode);
      }
    }
    
    // setting the fields
    if (cascadingStateMetadata.hasOwnProperty('fieldsToSet')) {
      
      for (const fieldToSet of cascadingStateMetadata.fieldsToSet) {
        this.form.controls[fieldToSet[0]].setValue(fieldToSet[1]);
        this.fieldValues.set(fieldToSet[0], fieldToSet[1]);
  
        if (this.checkboxMap.has(fieldToSet[0])) {
          fieldToSet[1] = commonFunctions.Stringtoboolean(fieldToSet[1])
          this.checkboxMap.set(fieldToSet[0], fieldToSet[1]);
        }
        this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
          selectionFields : this.invoiceDetails.selectionFields,
          fieldsToEnable : this.invoiceDetails.fieldsToEnable,
          fieldsToDisable: this.invoiceDetails.fieldsToDisable,
          fieldsToSet: this.invoiceDetails.fieldsToSet,
          fieldsToReset: this.invoiceDetails.fieldsToReset,
          fieldDetails: this.invoiceDetails.fieldDetails,
          fieldValues: this.invoiceDetails.fieldValues,
          projectFieldValues: this.fieldValues
          }}))
      }


    }
    
    if (cascadingStateMetadata.hasOwnProperty('fieldsToReset')) {
      this.resetFields(cascadingStateMetadata.fieldsToReset);
      if(this.service.checkSelection(selectedFieldCode, this.mode)){
        for (const fieldCode of cascadingStateMetadata.fieldsToEnable) {
          this.enableControl(fieldCode);
        }
      }
    }
    this.service.disableState(this.form, this.mode); // State enabled only when Country dropdown is enabled and country is USA in edit mode 
  }

  onSelectionClear(selectedFieldCode: string) {
    const cascadingStateMetadata = this.service.onDropdownClear(this.fieldDetails, this.formBlueprint.props, selectedFieldCode, this.mode, this.fieldValues);
    
    this.fieldDetails = cascadingStateMetadata.fieldDetails;
    this.fieldValues.delete(selectedFieldCode);
    this.formLayoutService.setprojectEntryIsUnsavedChangesPresent(true); 
    if (cascadingStateMetadata.hasOwnProperty('fieldsToDisable')) {
      for (const fieldCode of cascadingStateMetadata.fieldsToDisable) {
        this.disableControl(fieldCode);
      }
    }

    if (cascadingStateMetadata.hasOwnProperty('fieldsToReset')) {
      this.resetFields(cascadingStateMetadata.fieldsToReset);
    }

    if (cascadingStateMetadata.hasOwnProperty('fieldsToClear')) {
      this.clearDefaultValue(cascadingStateMetadata.fieldsToClear);
    }
  }

  isObject(val: any) {
    return commonFunctions.isObject(val);
  }

  handleDateSelection(selectedFieldCode: string, event: any) {
    // handles only dates selected using Angular Material date-picker
    this.form.controls[selectedFieldCode].setValue(DateUtility.createDateTypefromDate(DateUtility.formatStringfromDate(event.value._d).substr(0, 10)));
  }    

  getCheckboxToggleEventName() { return Constants.CHECKBOX_TOGGLE_EVENT_NAME; }

  getFieldName(fieldDetail: any) {
    const functionToFillFieldName = fieldDetail.fillFieldNameFunction;
    const fieldNameFunctionParams = fieldDetail.fillFieldNameFunctionParams;
    let actualParams = [];
    if (functionToFillFieldName === ''){
      return (fieldDetail.fieldDisplayName);
    }

    for (const param of fieldNameFunctionParams) {
      // param will be a fieldCode
      // if(this.invoiceDetails.projectFieldValues.length != 0)
        actualParams.push(this.invoiceDetails.projectFieldValues.get(param));
      // else
        // actualParams.push(this.fieldValues.get(param))
    } 
    return functionToFillFieldName(...actualParams);
  }

  handleSubmit() {
   
  }

  AddGridStyle(sectionName: string | number) {
    let section = this.formBlueprint[sectionName];
    let cssStyles = {
      'display': 'grid',
      'align-items': 'center',
      'grid-template-areas': section.formTemplateData[0].templateArea[this.index],
    }
    return cssStyles;
  }

  AddOuterGridStyle() {
     let cssStyles = {
      'display': 'grid',
      'grid-template-areas': this.formBlueprint.props.gridAreaForm[this.index],
    }
    return cssStyles;
  }

  getFieldDetails(fieldCode: string) {
    if (this.blueprintProps.formName=="projectDetailsEditModeInvoiceDetails" || this.blueprintProps.formName=="projectDetailsModeInvoiceDetails")
      return this.invoiceDetails.fieldDetails.find(f => f.fieldCode === fieldCode);
    else
      return this.fieldDetails.find(f => f.fieldCode === fieldCode);
  }

  
  getSectionDisplayName(sectionName: string): string {
    if (this.formBlueprint[sectionName]) {
      return this.formBlueprint[sectionName].sectionDisplayName;
    }
    return '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isLargeScreenFlag = this.issmScreenFlag = this.ismdScreenFlag = this.islgScreenFlag = false;
    if (window.innerWidth < 600) {
      this.issmScreenFlag = true;
      this.index = 3;
    } else if (window.innerWidth < 960) {
      this.ismdScreenFlag = true;
      this.index = 2;
    } else if (window.innerWidth < 1190) {
      this.islgScreenFlag = true;
      this.index = 1;
    } else {
      this.isLargeScreenFlag = true;
      this.index = 0;
    }
  }
}





