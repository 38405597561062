import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { Shortcut } from 'app/core/types/nav-shortcuts.types';
import { shortcuts } from 'app/core/nav-items/nav-shortcuts/nav-shortcuts.items';
import { CookieService } from 'ngx-cookie-service';
import { SnackbarService } from 'app/core/mips/common/services/snackbar.service';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
    selector: 'app-nav-shortcuts',
    templateUrl: './nav-shortcuts.component.html',
    styleUrls: ['./nav-shortcuts.component.scss'],
})
export class NavShortcutsComponent implements OnInit, OnDestroy {
    @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
    @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    mode: 'view' | 'add' = 'view';
    shortcutForm: UntypedFormGroup;
    navigationItems: Shortcut[];
    isOverlayActive: boolean = false;
    currentShortcutSelection;
    shortcutItems: Shortcut[];

    /**
     * Constructor
     */
    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _cookieService: CookieService,
        private _snackbarService: SnackbarService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the shortcuts
        if ((this._cookieService.check(Constants.NAV_SHORTCUTS_COOKIE))) {
            this.navigationItems = JSON.parse(
                this._cookieService.get(Constants.NAV_SHORTCUTS_COOKIE)
            );
        } else {
            this.navigationItems = shortcuts;
            this._cookieService.set(
                Constants.NAV_SHORTCUTS_COOKIE,
                JSON.stringify(shortcuts),
                undefined,
                '/'
            );
        }
        this.shortcutItems = this.navigationItems.filter(option => option['isBookmark']);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the shortcuts panel
     */
    openPanel(): void {
        this.isOverlayActive = !this.isOverlayActive;

        // Return if the shortcuts panel or its origin is not defined
        if (!this._shortcutsPanel || !this._shortcutsOrigin) {
            return;
        }

        // Make sure to start in 'view' mode
        this.mode = 'view';

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._shortcutsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the shortcuts panel
     */
    closePanel(): void {
        this.isOverlayActive = !this.isOverlayActive;
        this._overlayRef.detach();
    }

    /**
     * Change the mode
     */
    changeMode(mode: 'view' | 'add'): void {
        // Change the mode
        this.mode = mode;
    }

    /**
     * Prepare for a new shortcut
     */
    newShortcut(): void {
        // Enter the add mode
        this.mode = 'add';
    }


    /**
     * Save shortcut
     */
    save(): void {
        // Get the data from the form
        let element = this.navigationItems.filter((e) => {
            return e.id == this.currentShortcutSelection.id;
        });
        element[0].isBookmark == true
            ? this._snackbarService.showSnackBar(
                  Constants.SNACKBAR_MSG_SHORTCUT_ALREADY_EXISTS,
                  'close',
                  'failure'
              )
            : this.updateShortcutStatus();
               
            ;
        // Go back to view mode
        this.mode = 'view';
    }

    updateShortcutStatus(){
    this.statusUpdator(true, this.currentShortcutSelection.id);
    this._snackbarService.showSnackBar(
        Constants.SNACKBAR_MSG_SHORTCUT_ADDED,
        'close',
        'info'
    );
    }

    statusUpdator(value : boolean, filterOn: String) {
        let element = this.navigationItems.filter((e) => {
            return e.id == filterOn;
        });
        element[0].isBookmark = value;
        this._cookieService.set(
            Constants.NAV_SHORTCUTS_COOKIE,
            JSON.stringify(this.navigationItems),
            undefined,
            '/'
        );
        this.shortcutItems = this.navigationItems.filter(option => option['isBookmark']);
    }

    /**
     * Delete shortcut
     */
    delete(shortcut: string): void {
        this.statusUpdator(false, shortcut);
        this._snackbarService.showSnackBar(
            Constants.SNACKBAR_MSG_SHORTCUT_REMOVED,
            'close',
            'failure'
        );
        // Go back to view mode
        this.mode = 'view';
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._shortcutsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this.isOverlayActive = !this.isOverlayActive;
            this._overlayRef.detach();
        });
    }
}
