import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '../../../common/services/snackbar.service';
import { Constants } from '../../../common/utilities/constants.config';
import { FlagEntryService } from '../../../common/services/flag-entry.service';
import { GridApi, GridOptions } from 'ag-grid-community';
import { DateUtility } from '../../../common/utilities/date.utility';
import { commonFunctions } from '../../../common/classes/common.class';
import { Store } from '@ngrx/store';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { LogoutService } from '../../../common/services/logout.service';

@Component({
    selector: 'app-flag-summary',
    templateUrl: './flag-summary.component.html',
    styleUrls: ['./flag-summary.component.scss'],
})
export class FlagSummaryComponent implements OnInit {
    @Input() matOptionsValue: any;
    displayWeekData: boolean;
    displayDayLevelData = false;
    getTimeStartDate: any;
    getTimeEndDate: any;
    gridOptions: GridOptions = {
        groupDefaultExpanded: 0,
        suppressAggFuncInHeader: true,
        columnDefs: [],
        autoGroupColumnDef: {},
        headerHeight: 45,
    };
    agGridAPI: GridApi;
    defaultColDef: any;
    agGridColumnAPI: any;
    checkFlagDeadline = null;
    rowData: any;
    columnDefs: any;
    autoGroupColumnDef: any;
    headerHeight: any;
    groupDefaultExpanded: any;
    suppressAggFuncInHeader: any;
    currentUser: ConsultantModel;
    beginLogoutAction: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _snackBarService: SnackbarService,
        private _flagEntryService: FlagEntryService,
        private _store: Store,
        private _logoutService: LogoutService
    ) {
        this.gridOptions.groupDefaultExpanded = 0;
        this.gridOptions.suppressAggFuncInHeader = true;
        this.setGridColumnDef();
        this.gridOptions.columnDefs = this.columnDefs;

        this._flagEntryService.flagSummaryData.subscribe((data) => {
            this.rowData = data;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit() {
        this._logoutService.beginLogoutAction.subscribe((data) => {
            this.beginLogoutAction = data;
        });
        this._flagEntryService
        .getIsWeekMode()
        .subscribe((data) =>{
            (this.displayWeekData = data);
        });
        this._flagEntryService.getSummaryDateRange().subscribe((data) => {
            if(this.displayWeekData){
                (this.getTimeStartDate = data.weekStartDate),
                (this.getTimeEndDate = data.weekEndDate);
            }
            else{
                (this.getTimeStartDate = data.monthStartDate),
                (this.getTimeEndDate = data.monthEndDate);
            }

        });

        this._store.select(getCurrentUser).subscribe((data) => {
            if (!this.beginLogoutAction) {
                this.currentUser = data;
                this._flagEntryService.getFlagTimeEntries(
                    this.getTimeStartDate,
                    this.getTimeEndDate,
                    this.currentUser.Employee
                );
            }
        });
    }

    //   Change between Week/Month Mode to update Summary data {Start and End Date}
    dataModeChange(params): void {
        this.displayWeekData = this._flagEntryService.setIsWeekMode();
        this._snackBarService.showSnackBar(
            Constants.SNACKBAR_MSG_FLAG_SUMMARY_GRID_REFRESH,
            null,
            'info'
        );
        if (this.displayWeekData) {
            this._flagEntryService.getSummaryDateRange().subscribe((data) => {
                (this.getTimeStartDate = data.weekStartDate),
                    (this.getTimeEndDate = data.weekEndDate);
            });
        } else {
            this._flagEntryService.getSummaryDateRange().subscribe((data) => {
                (this.getTimeStartDate = data.monthStartDate),
                    (this.getTimeEndDate = data.monthEndDate);
            });
        }
        this._flagEntryService.getFlagTimeEntries(
            this.getTimeStartDate,
            this.getTimeEndDate,
            this.currentUser.Employee
        );
    }

    //   Change between View By options (Day, Project) and update summary data
    viewChange(params): void {
        this.displayDayLevelData = !this.displayDayLevelData;

        this.columnDefs = [];
        this.gridOptions.columnDefs = [];
        this.setGridColumnDef();
        this.gridOptions.columnDefs = this.columnDefs;

        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.agGridAPI.setColumnDefs(this.columnDefs);
    }

    //   Configure how the AG grid would look like depending on the View By option chosen
    setGridColumnDef(): void {
        // View By Day
        if (this.displayDayLevelData) {
            this.columnDefs = [
                {
                    headerName: 'Entry Date',
                    field: 'TDate',
                    filter: 'agDateColumnFilter',
                    rowGroup: true,
                    hide: true,
                    valueGetter: (params) => {
                        if (params.node.group) {
                            return params.node.aggData;
                        }
                        return params.data.TDate;
                    },
                    valueFormatter: (params) => {
                        if (params.node.group) {
                            return DateUtility.formatDateWithDayNamefromString(
                                params.value.substr(0, 10)
                            );
                        }
                        return DateUtility.formatDatefromString(params.value.substr(0, 10));
                    },
                    showRowGroup: true,
                },
                {
                    headerName: 'Project',
                    field: 'ProjectName',
                    width: 400,
                    rowGroup: true,
                    hide: true,
                },
                {
                    headerName: 'Task Description',
                    width: 200,
                    field: 'Task_Description',
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
                {
                    headerName: 'PLAN',
                    field: 'Hours',
                    filter: 'agNumberColumnFilter',
                    aggFunc: 'sum',
                    valueGetter: (params) => {
                        if (!params.node.group && params.data.Version === 'PLAN') {
                            return parseFloat(params.data.Hours);
                        }
                        return 0;
                    },
                    valueFormatter: (params) => {
                        if (!params.node.group) {
                            return (+params.data.Hours).toFixed(2);
                        }
                        return (+params.value).toFixed(2);
                    },
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
                {
                    headerName: 'ACTUAL',
                    field: 'Hours',
                    filter: 'agNumberColumnFilter',
                    aggFunc: 'sum',
                    valueGetter: (params) => {
                        if (!params.node.group && params.data.Version === 'ACTUAL') {
                            return parseFloat(params.data.Hours);
                        }
                        return 0;
                    },
                    valueFormatter: (params) => {
                        if (!params.node.group) {
                            return (+params.data.Hours).toFixed(2);
                        }
                        return (+params.value).toFixed(2);
                    },

                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
            ];
            this.gridOptions.autoGroupColumnDef = {
                headerName: 'Group',
                width: 400,
            };
        }
        // View by Project
        else if (!this.displayDayLevelData) {
            this.columnDefs = [
                {
                    headerName: 'Charge',
                    field: 'Charge',
                    valueGetter: (params) => {
                        if (params.node.group) {
                            return params.node.aggData;
                        }
                        if (params.data.Charge === 'NO') {
                            return 'NO';
                        } else {
                            return 'YES';
                        }
                    },
                    valueFormatter: (params) => {
                        if (!commonFunctions.isNullOrUndefined(params.value)) {
                            if (params.value.toUpperCase() === 'NO') {
                                return 'Non-Chargeable Entries';
                            } else {
                                return 'Chargeable Entries';
                            }
                        }
                    },
                    rowGroup: true,
                    hide: true,
                    showRowGroup: true,
                },
                {
                    headerName: 'Project',
                    field: 'ProjectName',
                    width: 400,
                    rowGroup: true,
                    hide: true,
                },
                {
                    headerName: 'Entry Date',
                    field: 'TDate',
                    filter: 'agDateColumnFilter',
                    rowGroup: true,
                    hide: true,
                    valueGetter: (params) => {
                        if (params.node.group) {
                            return params.node.aggData;
                        }
                        return params.data.TDate;
                    },
                    valueFormatter: (params) => {
                        if (params.node.group) {
                            return DateUtility.formatDateWithDayNamefromString(
                                params.value.substr(0, 10)
                            );
                        }
                        return DateUtility.formatDatefromString(params.value.substr(0, 10));
                    },
                },
                {
                    headerName: 'Task Description',
                    width: 200,
                    field: 'Task_Description',
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
                {
                    headerName: 'PLAN',
                    field: 'Hours',
                    filter: 'agNumberColumnFilter',
                    aggFunc: 'sum',
                    valueGetter: (params) => {
                        if (!params.node.group && params.data.Version === 'PLAN') {
                            return parseFloat(params.data.Hours);
                        }
                        return 0;
                    },
                    valueFormatter: (params) => {
                        if (!params.node.group) {
                            return (+params.data.Hours).toFixed(2);
                        }
                        return (+params.value).toFixed(2);
                    },
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
                {
                    headerName: 'ACTUAL',
                    field: 'Hours',
                    filter: 'agNumberColumnFilter',
                    aggFunc: 'sum',
                    valueGetter: (params) => {
                        if (!params.node.group && params.data.Version === 'ACTUAL') {
                            return parseFloat(params.data.Hours);
                        }
                        return 0;
                    },
                    valueFormatter: (params) => {
                        if (!params.node.group) {
                            return (+params.data.Hours).toFixed(2);
                        }
                        return (+params.value).toFixed(2);
                    },
                    cellStyle: (param) => {
                        if (
                            param.node.group &&
                            (param.node.field === 'Charge' ||
                                param.node.field === 'ProjectName')
                        ) {
                            if (param.node.aggData.Hours > param.node.aggData.Hours_1) {
                                return { background: '#FFCDCD' };
                            } else {
                                return { background: 'white' };
                            }
                        }
                    },
                    enablePivot: true,
                    enableRowGroup: true,
                    enableValue: true,
                },
            ];
            this.gridOptions.autoGroupColumnDef = {
                headerName: 'Group',
                width: 400,
            };
        }
        this.gridOptions.headerHeight = 45;
        this.defaultColDef = {
            filter: true,
            sortable: true,
            resizable: true,
        };
    }

    //   AG Grid Config
    onGridReady(params): void {
        this.agGridAPI = params.api;

        this.agGridColumnAPI = params.columnApi;
        this._flagEntryService.getWeeksInYear();
        this._flagEntryService.getSelectedWeek(
            DateUtility.getCurrentWeekDates()['startDate']
        );
        this._flagEntryService.getMonthsInYear();
    }
}
