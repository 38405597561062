import { Component, OnDestroy, OnInit,HostListener } from '@angular/core';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { NavbarService } from 'app/core/nav-items/navbar.service';
import { getFlagReminderMsg } from 'app/core/store/flagReminderMsg/flagReminderMsg.selectors';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { Store } from '@ngrx/store';
import { URLPaths } from '../common/utilities/urlPaths.config';
import { CronJob } from 'cron';
import { UserModel } from '../common/models/user.model';
import { UserService } from '../common/services/user.service';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantModel } from '../common/models/consultant.model';
import { setUser } from 'app/core/store/currentUser/user.actions';
import { setLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.action';
import { LocalStorageService } from '../common/services/local-storage.service';
import { WriteSilenceService } from '../common/services/write-silence.service';
import { LogoutService } from '../common/services/logout.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { mobileHeaderItems } from 'app/shared/settings/settings.items';
import { FlagEntryService } from '../common/services/flag-entry.service';
import { ImmigrationDetailsService } from '../common/services/immigration-details.service';
import { includes } from 'lodash';

@Component({
    selector: 'app-baselayout',
    templateUrl: './baselayout.component.html',
    styleUrls: ['./baselayout.component.scss']
})
export class BaselayoutComponent implements OnInit, OnDestroy
{
    flagReminderMsg: string | null;
    user: UserModel | null;
    currentUser: ConsultantModel;
    isScreenSmall: boolean;
    navigation: any[];
    isLoaded: boolean = true;
    cronJob: CronJob;
    isWriteSilence: boolean;
    beginLogoutAction: boolean = false;
    currentURL: string = '';
    mobileHeaderItems: {};
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    loggedInUserImage: string;
    showMobileSettings = false;
    currentUrl: string;

    /**
     * Constructor
     */

    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _navBarItemService : NavbarService,
        private _store: Store,
        private _userService: UserService,
        private _writeSilenceService: WriteSilenceService,
        private _logoutService: LogoutService,
        private _router: Router,
        private _flagEntryService: FlagEntryService,
        private _immigrationDetailsService: ImmigrationDetailsService
    )
    {
        this.getURL();
        this.mobileHeaderItems = mobileHeaderItems.shortcutItems;
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentURL = event["url"];
            }
        });
        this._store.select(getLoggedInUser).subscribe((data) => {
            if(data != null){
                this.user = data;
                this.loggedInUserImage = `https://ui-avatars.com/api/?name=${this.user.first_name}+${this.user.last_name}&background=random`;
                this._userService.getUserProfilePhoto(this.user.email).toPromise().then(
                  (data)=>{
                    if(data != null){
                        this.loggedInUserImage =  data;
                    }
                  }
                );
            }
          });
          this._store.select(getCurrentUser).subscribe((data)=>{
            if(data != null){
                this.currentUser = data;
                this._immigrationDetailsService.setCountryCulture();
            }
        });
        this._writeSilenceService.writeSilence.subscribe((data)=>{
            this.isWriteSilence = data;
        })
        this._flagEntryService.getFlagEntry().subscribe();
        this._store.select(getFlagReminderMsg).subscribe((data) => {
            this.flagReminderMsg = data;
        });
        
        // intialising the web worker
        if (typeof Worker !== 'undefined') {
            // Create a new
            let postData = {
                    workerURL : URLPaths.URL_WRITE_SILENCE,
                    authorisation: 'Token ' + LocalStorageService.getLocalStorageValueBasedOnKeys('API_KEY')
            }
            const worker = new Worker(new URL('../worker/write-listener.worker', import.meta.url));
            this.cronJob = new CronJob('*/10 * * * * *', async () => {
                try {
                    worker.postMessage(postData);
                      
                } catch (e) {
                  console.error(e);
            }});
                // Start job
            if (!this.cronJob.running) {
                    this.cronJob.start();
            }     
            worker.onmessage = ({ data }) => {
              if(data.detail){
                alert("Your session has expired. Please Login again")
                window.location.href = "/#" + URLPaths.ROUTE_LOGIN;
                this.cronJob.stop();
              }
              this._writeSilenceService.writeSilence.next(data["status"]);
            };
            this._logoutService.beginLogoutAction.subscribe((data)=>{
                if(data){
                    this.cronJob.stop();
                }
            })
          } else {
            // Web workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
          }
    }

    async getURL(){
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.urlAfterRedirects; // Get the final URL after redirects
            this.navigation = this._navBarItemService.getNavItemsBlueprintData();
            console.log(this.navigation)
            this.navigation[4].children[0].active = this.currentUrl.includes(URLPaths.ROUTE_PROJECT_DETAILS);
        });
    }

    // Listen for window resize events
    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
      this.checkScreenSize();
    }
  
    // Function to check the screen size and toggle the showMobileSettings flag
    checkScreenSize(): void {
      this.showMobileSettings = window.innerWidth <= 768; // Adjust the breakpoint as needed
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to media changes
        
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.checkScreenSize();
    }

    setCurrentUser(user: ConsultantModel) {
        this._store.dispatch(setUser({ user }));
    }
    setLoggedInUser(user: UserModel ) {
        this._store.dispatch(setLoggedInUser({ user }));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
