<!-- Check if the Device is of a smaller or larger screen -->
<div
  [ngClass]="{
    'header-small-screen': isSmallScreen(),
    'header-large-screen': isLargeScreen()
  }"
>
 
  <div class="flex flex-wrap flex-col tb:flex-row gap-x-4 gap-y-2 w-full px-2 py-2 tb:items-center tb:justify-center">

     <!-- Dropdown to select Projects -->
    <!-- <div>
      <ng-multiselect-dropdown
        [data]="projectList"
        fxFill
        placeholder="Select Projects"
        [(ngModel)]="selectedProjects"
        [allowSearchFilter]="true"
        [settings]="dropdownSettings"
      >
      </ng-multiselect-dropdown>
    </div> -->

    <!-- <div>
      <ng-select
        [items]="projectList"
        bindLabel="Display_String"
        [multiple]="true"
        placeholder="Select Projects"
        (change) = "OnChange($event)"
      >
      </ng-select>
    </div> -->

    <div>
      <ng-select
        [items]="projectList"
        bindLabel="Display_String"
        [virtualScroll]="true"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        placeholder="Select Projects"
        [(ngModel)]="selectedProjects"
        groupBy="IsPlannedorFrequent"
        [selectableGroup]="true"
        [searchFn]="searchFunctionDropdown"
        (search)="onSearch($event)"
        class="p-0 border-t-0"
        [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '13px'} }"
      >
      </ng-select>
    </div>

    <!-- Dropdown to select Consultants -->
    <div>
      <ng-select
        [items]="consultantList"
        bindLabel="Employee"
        [virtualScroll]="true"
        [multiple]="true"
        [closeOnSelect]="false"
        [searchable]="true"
        [(ngModel)]="selectedConsultants"
        placeholder="Select Consultants"
        class="consultant p-0 border-t-0"
        [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '13px'} }"
      >
      </ng-select>
    </div>

    <!-- Calendar/Date-Picker -->
    <div class="flex">
      <mat-form-field class="date-picker shadow">
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
          <input
            matStartDate
            placeholder="Start date"
            formControlName="start"
            readonly
            (click)="picker.open()"
    
          />
          <input
            matEndDate
            placeholder="End date"
            formControlName="end"
            readonly
            (click)="picker.open()"
    
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          (closed)="setDateRangeSelection()"
        ></mat-date-range-picker>
      </mat-form-field>
    </div>

    <!-- Apply Button (To Apply selected configuration in Time Dicer table) -->
    <button
      mat-raised-button
      (click)="applyButtonClicked()"
      class="flex items-center time-dicer-button min-h-0"
      [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
    >
      <div class="flex items-center" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Apply</div>
    </button>

    <!-- Bookmarks -->
    <button
      mat-raised-button
      (click)="manageConfigbuttonClicked()"
      class="flex items-center time-dicer-button min-h-0"
      [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
    >
      <div class="flex items-center" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Bookmarks</div>
    </button>

  </div>

