import { Component, Input, OnInit, HostListener, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TimeEntryModel } from '../../../common/models/TimeEntry.model';
import { FormControl, FormGroup } from '@angular/forms';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { Store } from '@ngrx/store';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { DateUtility } from '../../../common/utilities/date.utility';
import { EntryDialogComponent } from '../entry-dialog/entry-dialog.component';
import { GridApi } from 'ag-grid-community';
import { Constants } from '../../../common/utilities/constants.config';
import { getTimeDateRange } from 'app/core/store/timeDateRangeSelection/timeDateRangeSelection.selectors';
import { getExpenseDateRange } from 'app/core/store/expenseDateRangeSelection/expenseDateRangeSelection.selectors';
import { commonFunctions } from '../../../common/classes/common.class';
import { TimeEntryService } from '../../../common/services/time-entry.service';
import { PlanActualComparisionDialogComponent } from 'app/core/mips/actuals/time-entry/plan-actual-comparision-dialog/plan-actual-comparision-dialog.component';
import { SnackbarService } from '../../../common/services/snackbar.service';
import { EntryTableService } from '../../../common/services/entry-table.service';
import { FlagEntryService } from '../../../common/services/flag-entry.service';
import { setTimeDateRangeSelection } from 'app/core/store/timeDateRangeSelection/timeDateRangeSelection.actions';
import { DateRangeModel } from '../../../common/models/date-range.model';
import { DatePipe } from '@angular/common';
import { getTimeEntryGridConfig } from 'app/core/store/timeEntryGridConfig/timeEntryGridConfig.selector';
import { setTimeEntryGridConfig } from 'app/core/store/timeEntryGridConfig/timeEntryGridConfig.actions';
import { setExpenseDateRangeSelection } from 'app/core/store/expenseDateRangeSelection/expenseDateRangeSelection.actions';
import { ExpenseEntryService } from '../../../common/services/expense-entry.service';
import { ExpenseEntryModel } from '../../../common/models/ExpenseEntry.model';
import { setExpenseEntryGridConfig } from 'app/core/store/expenseEntryGridConfig/expenseEntryGridConfig.actions';
import { getExpenseEntryGridConfig } from 'app/core/store/expenseEntryGridConfig/expenseEntryGridConfig.selector';
import { WriteSilenceService } from '../../../common/services/write-silence.service';

@Component({
  selector: 'app-entry-header',
  templateUrl: './entry-header.component.html',
  styleUrls: ['./entry-header.component.scss'],
})
export class EntryHeaderComponent implements OnInit, OnChanges {
  @Input() entryPageType: string;
  isSmallScreenFlag: boolean;
  isLargeScreenFlag: boolean;
  isUnsavedChangesPresent: boolean;
  isRowSelectedforDeletion: boolean;
  currentUser: ConsultantModel;
  entryTDate: any;
  weekStart: string = '';
  weekEnd: string = '';
  agGridAPI: GridApi;
  agGridColumnAPI: any;
  getDateRange: any;
  setDateRange: any;
  entryCountHashMap: any;
  dateEntryCountHashMap: any;
  actualsAndChangedEntries: TimeEntryModel[];
  plannedTimeEntries: TimeEntryModel[];
  deletedEntries = [];
  entryGridConfig: any;
  entryService: any;
  rowDragMode: boolean;
  setEntryGridConfiguration: any;
  getEntryGridConfiguration: any;
  entryModel: any;
  isWriteSilence: boolean;

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  /**
   * Constructor
   */

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _timeEntryService: TimeEntryService,
    private _expenseEntryService: ExpenseEntryService,
    private _snackBarService: SnackbarService,
    private _entryTableService: EntryTableService,
    private _flagEntryService: FlagEntryService,
    private _datePipe: DatePipe,
    private _writeSilenceService: WriteSilenceService,
    private _renderer: Renderer2
  ) {
    this.isSmallScreenFlag = false;
    this.isLargeScreenFlag = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.checkScreenSize();
    switch (this.entryPageType) {
      case Constants.NAV_TIME_ENTRY_ACTUALS:
        this.getDateRange = getTimeDateRange;
        this.entryCountHashMap = this._timeEntryService.entryCountHashMap;
        this.setDateRange = setTimeDateRangeSelection;
        this.entryService = this._timeEntryService;
        this.setEntryGridConfiguration = setTimeEntryGridConfig;
        this.getEntryGridConfiguration = getTimeEntryGridConfig;
        this.entryModel = TimeEntryModel;
        break;
      case Constants.NAV_EXPENSE_ENTRY:
        this.getDateRange = getExpenseDateRange;
        this.setDateRange = setExpenseDateRangeSelection;
        this.entryService = this._expenseEntryService;
        this.entryCountHashMap = this._expenseEntryService.entryCountHashMap;
        this.setEntryGridConfiguration = setExpenseEntryGridConfig;
        this.getEntryGridConfiguration = getExpenseEntryGridConfig;
        this.entryModel = ExpenseEntryModel;
        break;
    }
    this._writeSilenceService.writeSilence.subscribe((data) => {
      this.isWriteSilence = data;
    });
    this._store.select(this.getDateRange).subscribe((data) => {
      if (data) {
        this.weekStart = data['startDate'];
        this.weekEnd = data['endDate'];
      }
    });
    this.entryService.rowDragMode.subscribe((data) => {
      this.rowDragMode = data;
    });
    this.entryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.isUnsavedChangesPresent = data;
    });
    this.entryService.entryisRowSelectedForDeletion.subscribe((data) => {
      this.isRowSelectedforDeletion = data;
    });

    this.entryService.entryCountHashMap.subscribe((data) => {
      this.dateEntryCountHashMap = data;
    });
    this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this.entryService.entryActualsAndChanged.subscribe((data) => {
      this.actualsAndChangedEntries = data;
    });
    this.entryService.entryPlanned.subscribe((data) => {
      this.plannedTimeEntries = data;
    });
    this._store.select(this.getEntryGridConfiguration).subscribe((data) => {
      this.entryGridConfig = data;
    });
    this.dateRange.get('start').setValue(new Date(this.weekStart));
    this.dateRange.get('end').setValue(new Date(this.weekEnd));

    const element = document.querySelector(".mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper");
    if(element){
      this._renderer.setStyle(element, 'display', 'none', 1);
    }
  }

  /**
   * On changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.checkScreenSize();
    switch (this.entryPageType) {
      case Constants.NAV_TIME_ENTRY_ACTUALS:
        this.getDateRange = getTimeDateRange;
        this.entryCountHashMap = this._timeEntryService.entryCountHashMap;
        this.setDateRange = setTimeDateRangeSelection;
        this.entryService = this._timeEntryService;
        this.setEntryGridConfiguration = setTimeEntryGridConfig;
        this.getEntryGridConfiguration = getTimeEntryGridConfig;
        this.entryModel = TimeEntryModel;
        break;
      case Constants.NAV_EXPENSE_ENTRY:
        this.getDateRange = getExpenseDateRange;
        this.setDateRange = setExpenseDateRangeSelection;
        this.entryService = this._expenseEntryService;
        this.entryCountHashMap = this._expenseEntryService.entryCountHashMap;
        this.setEntryGridConfiguration = setExpenseEntryGridConfig;
        this.getEntryGridConfiguration = getExpenseEntryGridConfig;
        this.entryModel = ExpenseEntryModel;
        break;
    }
    this._writeSilenceService.writeSilence.subscribe((data) => {
      this.isWriteSilence = data;
    });
    this._store.select(this.getDateRange).subscribe((data) => {
      if (data) {
        this.weekStart = data['startDate'];
        this.weekEnd = data['endDate'];
      }
    });
    this.entryService.rowDragMode.subscribe((data) => {
      this.rowDragMode = data;
    });
    this.entryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.isUnsavedChangesPresent = data;
    });
    this.entryService.entryisRowSelectedForDeletion.subscribe((data) => {
      this.isRowSelectedforDeletion = data;
    });

    this.entryService.entryCountHashMap.subscribe((data) => {
      this.dateEntryCountHashMap = data;
    });
    this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this.entryService.entryActualsAndChanged.subscribe((data) => {
      this.actualsAndChangedEntries = data;
    });
    this.entryService.entryPlanned.subscribe((data) => {
      this.plannedTimeEntries = data;
    });
    this._store.select(this.getEntryGridConfiguration).subscribe((data) => {
      this.entryGridConfig = data;
    });
    this.dateRange.get('start').setValue(new Date(this.weekStart));
    this.dateRange.get('end').setValue(new Date(this.weekEnd));

    const element = document.querySelector(".mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper");
    if(element){
      this._renderer.setStyle(element, 'display', 'none', 1);
    }
  }

  // Set selected date range
  setDateRangeSelection() {
    let confirmRedirect = false;
    if (this.isUnsavedChangesPresent) {
        confirmRedirect = window.confirm(Constants.MSG_CONFIRM_DISCARD_CHANGES)
    }
    if (!this.isUnsavedChangesPresent || (this.isUnsavedChangesPresent && confirmRedirect)) {
      let week = new DateRangeModel();
      week['startDate'] = this._datePipe.transform(
        this.dateRange.value.start,
        'dd MMM yyyy'
      );
      week['endDate'] = this._datePipe.transform(
        this.dateRange.value.end,
        'dd MMM yyyy'
      );
      if (
        this.weekStart != week['startDate'] ||
        this.weekEnd != week['endDate']
      ) {
        if(week['endDate'] == null){
          week['endDate'] = week['startDate']
          this.dateRange.get('end').setValue(new Date(week['endDate']));
        }
        this._store.dispatch(this.setDateRange({ week: week }));
      }
    }
    else {
      this.dateRange.get('start')?.setValue(new Date(this.weekStart));
      this.dateRange.get('end')?.setValue(new Date(this.weekEnd));
    }
  }

  //Set Date Entry Count Hashmap to store
  setDateEntryCountHashMap(dateEntryCountHashMap) {
    this.entryService.entryCountHashMap.next(dateEntryCountHashMap);
  }

  //Set boolean whether if any unsaved changes are present
  setEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
    this.entryService.entryIsUnsavedChangesPresent.next(
      isUnsavedChangesPresent
    );
  }

  // Set the AG Grid Config
  setEntryGridConfig(entryGridConfig) {
    this._store.dispatch(this.setEntryGridConfiguration({ entryGridConfig }));
  }

  // Check for the Screen Size
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  // Check the screen size
  checkScreenSize() {
    this.isSmallScreenFlag = window.innerWidth < 768; // Example: small screens have width less than 768px
    this.isLargeScreenFlag = !this.isSmallScreenFlag;
  }

  isSmallScreen(): boolean {
    return this.isSmallScreenFlag;
  }

  isLargeScreen(): boolean {
    return this.isLargeScreenFlag;
  }

  // New Entry Dialog Box
  onNewButtonClicked(): void {
    this.entryTDate = this.entryService.getEntryTDate();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = new this.entryModel();
    dialogConfig.maxWidth = 'clamp(340px, 85vw, 800px)';
    dialogConfig.width = '100%';
    dialogConfig.height = 'auto';
    dialogConfig.data.Employee = this.currentUser.Employee;
    dialogConfig.data.TDate = this.entryTDate;
    if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      dialogConfig.data.Invoice_Date = this.entryTDate;
    } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      dialogConfig.data.Invoice_date = this.entryTDate;
    }
    dialogConfig.data['startDate'] = this.weekStart;
    dialogConfig.data['endDate'] = this.weekEnd;
    dialogConfig.data.Mode = 'create';
    dialogConfig.data.entryPageType = this.entryPageType;
    const dialogRef = this._dialog.open(EntryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result != typeof true && result != undefined) {
        this.setEntryIsUnsavedChangesPresent(true);
        result['status'] = 'create';
        this.agGridAPI = this.entryService.getGridApi();
        this.agGridAPI.applyTransaction({ add: [result] });
        if (
          this.dateEntryCountHashMap.get(result['TDate'].substr(0, 10)) === -1
        ) {
          this.agGridAPI.forEachNode((node) => {
            if (
              !commonFunctions.isNullOrUndefined(node.data) &&
              node.data.TDate === result['TDate'] &&
              node.data.status === 'dummy'
            ) {
              this.agGridAPI.applyTransaction({ remove: [node.data] });
            }
          });
          this.dateEntryCountHashMap.set(result['TDate'].substr(0, 10), 1);
        } else {
          this.dateEntryCountHashMap.set(
            result['TDate'].substr(0, 10),
            this.dateEntryCountHashMap.get(result['TDate'].substr(0, 10)) + 1
          );
        }
        this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
        this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
      }
    });
    this.entryService.setEntryTDate(DateUtility.formatStringfromDate(DateUtility.createDateType()));
  }

  // Plan Vs Actual Dialog Box
  planActualComparisionButtonClicked() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = 'clamp(340px, 92vw, 900px)';
    dialogConfig.width = '100%';
    dialogConfig.height = 'auto';
    // Check if there are actuals entered
    if (!commonFunctions.isNullOrUndefined(this.actualsAndChangedEntries)) {
      dialogConfig.data = this.plannedTimeEntries.concat(
        this.actualsAndChangedEntries
      );
    } else {
      dialogConfig.data = this.plannedTimeEntries;
    }
    dialogConfig.data.push(this.dateRange);
    const compareDialogRef = this._dialog.open(
      PlanActualComparisionDialogComponent,
      dialogConfig
    );
  }

  // Save Row Entries
  saveChangesButtonClicked(): void {
    this.agGridAPI = this.entryService.getGridApi();
    this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
    this.setEntryIsUnsavedChangesPresent(false);
    let updateCreateEntries = this.actualsAndChangedEntries.filter((values) => {
      return (
        values.status !== 'clean' &&
        values.status !== 'dummy' &&
        values.status !== 'bad' &&
        values.status !== 'multiplerate'
      );
    });
    if (
      !commonFunctions.isNullOrUndefined(updateCreateEntries) &&
      updateCreateEntries.length > 0
    ) {
      this._snackBarService.showSnackBar(
        Constants.SNACKBAR_MSG_SAVING_CHANGES,
        null,
        'info'
      );
      this.entryService.saveEntries(updateCreateEntries).subscribe(
        (response) => {
          if (!response['result']) {
            this._snackBarService.showSnackBar(
              response['Message'],
              'close',
              'failure'
            );
          } else {
            this._snackBarService.showSnackBar(
              response['Message'],
              '',
              'success'
            );
          }
          this._entryTableService.getEntryTableEntries(
            this.agGridAPI,
            this.entryPageType
          );
          this._snackBarService.showSnackBar(
            response['Message'],
            '',
            'success'
          );
          for (const values of this.actualsAndChangedEntries) {
            if (
              !commonFunctions.isNullOrUndefined(values) &&
              (values.status === 'bad' || values.status === 'multiplerate')
            ) {
              if (!commonFunctions.isNullOrUndefined(values.TDate)) {
                this.entryService.addRecordsToGrid(this.agGridAPI, values);
              }
            }
          }
        },
        (error) => {
          this._snackBarService.showSnackBar(
            Constants.SNACKBAR_MSG_ENTRY_SAVE_FALIED,
            'close',
            'failure'
          );
        }
      );
    }
  }

  // Delete Selected Rows
  deleteButtonClicked(): void {
    const confirmDeletion = window.confirm(Constants.MSG_CONFIRM_DELETE_RECORDS);
    if (confirmDeletion) {
      this.agGridAPI = this.entryService.getGridApi();
      const selectedRows = this.agGridAPI.getSelectedRows();
      selectedRows.filter((value) => {
        if (value.status !== 'dummy') {
          this.agGridAPI.applyTransaction({ remove: [value] });
          if (this.dateEntryCountHashMap.get(value.TDate.substr(0, 10)) === 1) {
            this._entryTableService.addEmptyRowForEntryDate(
              value.TDate.substr(0, 10),
              this.agGridAPI
            );
          } else {
            this.dateEntryCountHashMap.set(
              value.TDate.substr(0, 10),
              this.dateEntryCountHashMap.get(value.TDate.substr(0, 10)) - 1
            );
            this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
          }

          if (
            (!commonFunctions.isNullOrUndefined(value.Time_ID) &&
              value.Time_ID !== 0) ||
            (!commonFunctions.isNullOrUndefined(value.ExpenseID) &&
              value.ExpenseID !== 0)
          ) {
            this.deletedEntries.push(value);
          }
        }
      });
      if (
        !commonFunctions.isNullOrUndefined(this.deletedEntries) &&
        this.deletedEntries.length > 0
      ) {
        this._snackBarService.showSnackBar(
          Constants.SNACKBAR_MSG_SAVING_CHANGES,
          null,
          'info'
        );
        const deleteTSEXEntry = this.deletedEntries.filter((values) => {
          return (
            values.Client === 'AD' &&
            (values.Project === 'TS' || values.Project === 'EX')
          );
        });
        this.entryService.deleteEntries(this.deletedEntries).subscribe(
          (response) => {
            if (!response['result']) {
              this._snackBarService.showSnackBar(
                response['Message'],
                'close',
                'failure'
              );
              this._entryTableService.getEntryTableEntries(
                this.agGridAPI,
                this.entryPageType
              );
            } else {
              this.deletedEntries = [];
              this._snackBarService.showSnackBar(
                response['Message'],
                '',
                'success'
              );
              if (
                !commonFunctions.isNullOrUndefined(deleteTSEXEntry) &&
                deleteTSEXEntry.length > 0 &&
                this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS
              ) {
                this._flagEntryService.getFlagEntry().subscribe();
              }
            }
          },
          (error) => {
            this._snackBarService.showSnackBar(
              Constants.SNACKBAR_MSG_ENTRY_DELETE_FALIED,
              'close',
              'failure'
            );
          }
        );
      }
      this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
      const checkForDirtyRows = this.actualsAndChangedEntries.filter(
        (entry) => entry.status !== 'clean' && entry.status !== 'dummy'
      );
      if (
        commonFunctions.isNullOrUndefined(checkForDirtyRows) ||
        checkForDirtyRows.length === 0
      ) {
        this.setEntryIsUnsavedChangesPresent(false);
      }
    }
  }

  // Reset Data
  resetChangesButtonClicked(): void {
    const confirmReset = window.confirm(
      Constants.MSG_CONFIRM_RESET_ENTRY
    );
    if (confirmReset) {
      this._entryTableService.getEntryTableEntries(
        this.entryService.getGridApi(),
        this.entryPageType
      );
    }
  }

  // Reset Columns
  resetColumnsButtonClicked(): void {
    let tempEntryGridConfig = { ...this.entryGridConfig };
    this.agGridColumnAPI = this.entryService.getGridColumnApi();
    if (!commonFunctions.isNullOrUndefined(this.agGridColumnAPI)) {
      this.agGridColumnAPI.resetColumnState();
      if (
        !commonFunctions.isNullOrUndefined(tempEntryGridConfig) &&
        tempEntryGridConfig.id !== 0
      ) {
        tempEntryGridConfig.Configuration = JSON.stringify(
          this.agGridColumnAPI.getColumnState()
        );
        this.setEntryGridConfig(tempEntryGridConfig);
      }
    }
    this.entryService.setGridColumnApi(this.agGridColumnAPI);
  }

  // Toggle between Move and Copy
  toggleValueChanged() {
    this.entryService.rowDragMode.next(!this.rowDragMode);
  }
}
