import { Component, Input, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { RowDragEndEvent } from 'ag-grid-community';
import { commonFunctions } from '../../../common/classes/common.class';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { getTimeDateRange } from 'app/core/store/timeDateRangeSelection/timeDateRangeSelection.selectors';
import { getExpenseDateRange } from 'app/core/store/expenseDateRangeSelection/expenseDateRangeSelection.selectors';
import { Constants } from '../../../common/utilities/constants.config';
import { EntryDialogComponent } from '../entry-dialog/entry-dialog.component';
import { GridApi, GridOptions } from 'ag-grid-community';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { DateRangeModel } from '../../../common/models/date-range.model';
import { EntryGridConfigService } from '../../../common/services/entry-grid-config.service';
import { DateUtility } from '../../../common/utilities/date.utility';
import { URLPaths } from '../../../common/utilities/urlPaths.config';
import { UserService } from '../../../common/services/user.service';
import { TimeEntryService } from '../../../common/services/time-entry.service';
import { setTimeEntryGridConfig } from 'app/core/store/timeEntryGridConfig/timeEntryGridConfig.actions';
import { TimeEntryGridConfigModel } from '../../../common/models/time-entry-grid-config.model';
import { SnackbarService } from '../../../common/services/snackbar.service';
import { TimeEntryModel } from '../../../common/models/TimeEntry.model';
import { ProjectsService } from '../../../common/services/projects.service';
import { ProjectModel } from '../../../common/models/project.model';
import { ProjectTaskModel } from '../../../common/models/project-task.model';
import { ProjectTaskService } from '../../../common/services/project-task.service';
import { ConsultantProjectRateParameterModel } from '../../../common/models/consultant-rate.model';
import { ConsultantRateModel } from '../../../common/models/consultant-rate.model';
import { ConsultantRateService } from '../../../common/services/consultant-rate.service';
import { getTimeEntryGridConfig } from 'app/core/store/timeEntryGridConfig/timeEntryGridConfig.selector';
import { EntryTableService } from '../../../common/services/entry-table.service';
import { ExpenseEntryService } from '../../../common/services/expense-entry.service';
import { setExpenseEntryGridConfig } from 'app/core/store/expenseEntryGridConfig/expenseEntryGridConfig.actions';
import { ExpenseEntryGridConfigModel } from '../../../common/models/expense-entry-grid-config.model';
import { getExpenseEntryGridConfig } from 'app/core/store/expenseEntryGridConfig/expenseEntryGridConfig.selector';
import { ExpenseEntryModel } from '../../../common/models/ExpenseEntry.model';
import { LogoutService } from '../../../common/services/logout.service';

@Component({
    selector: 'app-entry-table',
    templateUrl: './entry-table.component.html',
    styleUrls: ['./entry-table.component.scss'],
})
export class EntryTableComponent implements OnInit, AfterViewInit {
    @Input() entryPageType: string;
    weekStart: string;
    weekEnd: string;
    getDateRange: any;
    setEntryGridConfiguration: any;
    entryCountHashMap: any;
    dateEntryCountHashMap: any;
    public agGridAPI: GridApi;
    agGridColumnAPI: any;
    gridOptions: GridOptions = {};
    currentUser: ConsultantModel;
    week: DateRangeModel[];
    consultant = '';
    currentWeekDates: any;
    subscription: Subscription;
    entryGridConfig: any;
    rowDragModeIsCopy = true;
    rowSelection: string;
    consultantRateEntryDate: string;
    defaultColDef: any;
    selectedProject: ProjectModel;
    selectedProjectTask: ProjectTaskModel;
    entryService: any;
    entryGridConfigURLPath: string;
    getEntryGridConfiguration: any;
    domLayout: any = 'autoHeight';
    entryModel: any;
    beginLogoutAction: boolean = false;
    isUnsavedChangesPresent: boolean = false;
    inlineEditCols = [];
    nodeStatus = [];
    agGridCopy: any;

    /**
     * Constructor
     */
    constructor(
        public datePipe: DatePipe,
        private _store: Store,
        private _dialog: MatDialog,
        private _userService: UserService,
        private _timeEntryService: TimeEntryService,
        private _snackBarService: SnackbarService,
        private _projectsService: ProjectsService,
        private _projectTaskService: ProjectTaskService,
        private _consultantRateService: ConsultantRateService,
        private _entryTableService: EntryTableService,
        private _entryGridConfigService: EntryGridConfigService,
        private _expenseEntryService: ExpenseEntryService,
        private _logoutService: LogoutService
    ) {
        this.defaultColDef = {
            width: 100,
            filter: true,
            resizable: true,
            headerCheckboxSelection: (params) => {
                const diaplayedColumns = params.columnApi.getAllDisplayedColumns();
                return diaplayedColumns[0] === params.column;
            },
        };
        this.gridOptions.groupDefaultExpanded = 1;
        this.gridOptions.groupSelectsChildren = true;
        this.gridOptions.getRowStyle = (param) => {            
            if (param.node.group) {
                return {
                    background: '#6ac738',
                    'font-weight': 'bold'

                };
            } else {
                if (param.data.status !== 'clean' && param.data.status !== 'dummy') {
                    if (
                        param.data.status === 'bad' ||
                        param.data.status === 'multiplerate'
                    ) {
                        return { background: '#ffc7ce' };
                    } else {
                        return { background: '#ffc107' };
                    }
                }
            }
        };
        this.gridOptions.suppressAggFuncInHeader = true;
        this.rowSelection = 'multiple';
    }
    ngAfterViewInit(): void {
        this._store.select(this.getDateRange).subscribe((data) => {
            if (data) {
                this.weekStart = data['startDate'];
                this.weekEnd = data['endDate'];
                if ((this.agGridCopy && !this.agGridCopy.destroyCalled) && !this.beginLogoutAction) {
                    this._entryTableService.getEntryTableEntries(
                        this.agGridAPI,
                        this.entryPageType
                    );
                }
            }
        });

        this._store.select(getCurrentUser).subscribe((data) => {
            this.currentUser = data;
            if ((this.agGridCopy && !this.agGridCopy.destroyCalled) && !this.beginLogoutAction) {
                this._entryTableService.getEntryTableEntries(
                    this.agGridAPI,
                    this.entryPageType
                );
            }
        })

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit() {
        switch (this.entryPageType) {
            case Constants.NAV_TIME_ENTRY_ACTUALS:
                this.getDateRange = getTimeDateRange;
                this.entryService = this._timeEntryService;
                this.entryGridConfigURLPath = URLPaths.URL_TIME_ENTRY_GRID_CONFIG;
                this.setEntryGridConfiguration = setTimeEntryGridConfig;
                this.getEntryGridConfiguration = getTimeEntryGridConfig;
                this.entryModel = TimeEntryModel;
                this.inlineEditCols = ['ag-Grid-AutoColumn', 'Hours'];
                this.nodeStatus = ['dummy', 'bad', 'multiplerate'];
                break;
            case Constants.NAV_EXPENSE_ENTRY:
                this.getDateRange = getExpenseDateRange;
                this.entryService = this._expenseEntryService;
                this.entryGridConfigURLPath = URLPaths.URL_EXPENSE_ENTRY_GRID_CONFIG;
                this.setEntryGridConfiguration = setExpenseEntryGridConfig;
                this.getEntryGridConfiguration = getExpenseEntryGridConfig;
                this.entryModel = ExpenseEntryModel;
                this.inlineEditCols = [
                    'ag-Grid-AutoColumn',
                    'AmountSpent',
                    'Currency_From',
                ];
                this.nodeStatus = ['dummy'];
                break;
        }
        this._logoutService.beginLogoutAction.subscribe((data) => {
            this.beginLogoutAction = data;
        });
        // this._store.select(this.getDateRange).subscribe((data) => {
        //     if (data) {
        //         this.weekStart = data['startDate'];
        //         this.weekEnd = data['endDate'];
        //         if (this.agGridAPI !== undefined && !this.beginLogoutAction) {
        //             this._entryTableService.getEntryTableEntries(
        //                 this.agGridAPI,
        //                 this.entryPageType
        //             );
        //         }
        //     }
        // });
        this.entryService.entryIsUnsavedChangesPresent.subscribe((data) => {
            this.isUnsavedChangesPresent = data;
        });

        this.entryService.rowDragMode.subscribe((data) => {
            this.rowDragModeIsCopy = data;
        });
        this.entryService.entryCountHashMap.subscribe((data) => {
            this.dateEntryCountHashMap = data;
        });
        this._store.select(getCurrentUser).subscribe((data) => {
            this.currentUser = data;
            // this.onGridReady()
        })
        this._store.select(this.getEntryGridConfiguration).subscribe((data) => {
            this.entryGridConfig = data;
        });
        this.gridOptions.columnDefs = this.entryService.columnDefs;
        this.gridOptions.autoGroupColumnDef = this.entryService.autoGroupColumnDefs;
        this.gridOptions.stopEditingWhenCellsLoseFocus = true;
        this.entryService.setEntryTDate(DateUtility.formatStringfromDate(DateUtility.createDateType()));
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander($event) {
        if (this.isUnsavedChangesPresent) {
            return ($event.returnValue =
                Constants.MSG_CONFIRM_DISCARD_CHANGES);
        }
        return true;
    }

    @HostListener('window:pagehide', ['$event'])
    pageHidedHanderforIos($event) {
        if (this.isUnsavedChangesPresent) {
            return ($event.returnValue =
                Constants.MSG_CONFIRM_DISCARD_CHANGES);
        }
        return true;
    }

    //   AG Grid Config
    setEntryGridConfig(entryGridConfig) {
        this._store.dispatch(this.setEntryGridConfiguration({ entryGridConfig }));
    }

    //Set Date Entry Count Hashmap to store
    setDateEntryCountHashMap(dateEntryCountHashMap) {
        this.entryService.entryCountHashMap.next(dateEntryCountHashMap);
    }

    //Set boolean whether if any unsaved changes are present
    setEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
        this.entryService.entryIsUnsavedChangesPresent.next(
            isUnsavedChangesPresent
        );
    }

    //   Get the consultant's rate for Project
    getConsultantProjectRate(values): void {
        const consultantClientProjectRate =
            new ConsultantProjectRateParameterModel();
        let selectedConsultantRate = new ConsultantRateModel();
        let entryDate: any;
        if (
            !commonFunctions.isNullOrUndefined(this.consultantRateEntryDate) &&
            this.consultantRateEntryDate !== ''
        ) {
            entryDate = DateUtility.createDateTypefromDate(
                this.consultantRateEntryDate
            );
            consultantClientProjectRate.entrydate =
                String(entryDate.getFullYear()) +
                String(
                    (Number(entryDate.getMonth()) + 1 < 10 ? '0' : '') +
                    (Number(entryDate.getMonth()) + 1)
                );
        } else {
            consultantClientProjectRate.entrydate = '';
        }
        consultantClientProjectRate.employee = values.Employee;
        consultantClientProjectRate.client = values.Client;
        consultantClientProjectRate.project = values.Project;
        this._consultantRateService
            .getConsultantByProject(consultantClientProjectRate)
            .subscribe((consultantRate) => {
                this._snackBarService.dismissSnackBar();
                if (
                    !commonFunctions.isNullOrUndefined(consultantRate) &&
                    consultantRate.length > 0
                ) {
                    if (values.Rate === -1) {
                        if (consultantRate.length === 1) {
                            selectedConsultantRate = consultantRate.filter((rate) => {
                                return (
                                    rate.Client.toLowerCase() === values.Client.toLowerCase() &&
                                    rate.Project.toLowerCase() === values.Project.toLowerCase()
                                );
                            })[0];
                        } else {
                            selectedConsultantRate.Rate = '0';
                            selectedConsultantRate.Converted_Currency_Code = '';
                            selectedConsultantRate.Converted_Currency_Symbol = '';
                            if (values.status !== 'bad') {
                                values.status = 'multiplerate';
                            }
                        }
                        if (commonFunctions.isNullOrUndefined(selectedConsultantRate)) {
                            selectedConsultantRate = consultantRate.filter(
                                (rates) => rates.Is_Default_Rate === 1
                            )[0];
                        }
                        if (!commonFunctions.isNullOrUndefined(selectedConsultantRate)) {
                            values.Rate = selectedConsultantRate.Converted_Rate;
                            values.Currency_Code =
                                selectedConsultantRate.Converted_Currency_Code;
                            values.Currency_symbol_short =
                                selectedConsultantRate.Converted_Currency_Symbol;
                        }
                    } else {
                        let minrateDifference = -1;
                        let rateDifference = 0;
                        for (const rate of consultantRate) {
                            rateDifference =
                                parseFloat(values.Rate) - parseFloat(rate.Converted_Rate);
                            rateDifference =
                                rateDifference < 0 ? -rateDifference : rateDifference;
                            if (
                                rateDifference < minrateDifference ||
                                minrateDifference === -1
                            ) {
                                minrateDifference = rateDifference;
                                selectedConsultantRate = rate;
                            }
                        }
                        values.Rate = selectedConsultantRate.Converted_Rate;
                        values.Currency_Code =
                            selectedConsultantRate.Converted_Currency_Code;
                        values.Currency_symbol_short =
                            selectedConsultantRate.Converted_Currency_Symbol;
                    }
                } else {
                    values.Rate = 0;
                }
                if (
                    !commonFunctions.isNullOrUndefined(values) &&
                    !commonFunctions.isNullOrUndefined(values.TDate)
                ) {
                    this.entryService.addRecordsToGrid(this.agGridAPI, values);
                    if (values.status === 'bad') {
                        this._snackBarService.showSnackBar(
                            Constants.SNACKBAR_MSG_ENTRY_BAD_ENTRIES,
                            null,
                            'warning'
                        );
                    } else {
                        if (values.status === 'multiplerate') {
                            this._snackBarService.showSnackBar(
                                Constants.SNACKBAR_MSG_ENTRY_MULTIPLE_RATES,
                                'close',
                                'warning'
                            );
                        }
                    }
                }
                this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
            });
    }

    //   Get the project task
    getProjectTask(values): void {
        this._projectTaskService
            .getProjectTasks(values.Client, values.Project)
            .subscribe((projectTasks) => {
                this.selectedProjectTask = projectTasks.filter((task) => {
                    return (
                        task.Task_name.toLowerCase() === values.Task_Code.toLowerCase()
                    );
                })[0];
                if (!commonFunctions.isNullOrUndefined(this.selectedProjectTask)) {
                    values.Task_Code = this.selectedProjectTask.Task_name;
                    values.Task_Name = this.selectedProjectTask.Task_description;
                    values.Task_id = this.selectedProjectTask.Task_id;
                } else {
                    values.status = 'bad';
                }
                this.getConsultantProjectRate(values);
            });
    }

    // Get the Projects for the consultant
    getProjects(values): void {
        this._projectsService
            .getProjects(
                false,
                this.currentUser['Employee'],
                this.weekStart,
                this.weekEnd
            )
            .subscribe((projectList) => {
                if (
                    !commonFunctions.isNullOrUndefined(values.Project) &&
                    !commonFunctions.isNullOrUndefined(values.Client)
                ) {
                    this.selectedProject = projectList.filter((projects) => {
                        return (
                            projects.Project.toLowerCase() === values.Project.toLowerCase() &&
                            projects.Client.toLowerCase() === values.Client.toLowerCase()
                        );
                    })[0];
                    if (!commonFunctions.isNullOrUndefined(this.selectedProject)) {
                        values.Client = this.selectedProject.Client;
                        values.Project = this.selectedProject.Project;
                        values.ClientName = this.selectedProject.ClientName;
                        values.ProjectName = this.selectedProject.Description;
                        if (
                            commonFunctions.isNullOrUndefined(values.Charge) ||
                            values.Charge === '' ||
                            this.selectedProject.Charge.toLowerCase() === 'no'
                        ) {
                            values.Charge = this.selectedProject.Charge;
                        }
                        this.getProjectTask(values);
                    } else {
                        values.status = 'bad';
                        this.getConsultantProjectRate(values);
                    }
                }
            });
    }

    // Assign Row Values
    assignValues(params): void {
        this._snackBarService.showSnackBar(
            Constants.SNACKBAR_MSG_WAITING_FOR_API_RESPONSE,
            null,
            'info'
        );
        if (
            params[0][0] === 'EntryDate' &&
            params[0][1] === 'Client' &&
            params[0][2] === 'Project' &&
            params[0][3] === 'TaskName' &&
            params[0][4] === 'TaskDescription' &&
            params[0][5] === 'Hours'
        ) {
            params.shift();
        }

        let count = 0;
        count = params.length - 1;
        if (params[count].length === 1 && params[count][0] === '') {
            params.pop();
        }

        for (const values of params) {
            const selectedCopyClipboard = new this.entryModel();
            selectedCopyClipboard.Rate = -1;
            selectedCopyClipboard.TDate = '';
            if (values.length > 5) {
                if (
                    values[0] === '' &&
                    values[1] === '' &&
                    values[2] === '' &&
                    values[3] === '' &&
                    values[4] === '' &&
                    values[5] === ''
                ) {
                    this._snackBarService.showSnackBar(
                        Constants.SNACKBAR_MSG_ENTRY_BLANK_ROW,
                        '',
                        'warning'
                    );
                } else if (
                    values[0] === '' ||
                    values[1] === '' ||
                    values[2] === '' ||
                    values[3] === '' ||
                    values[4] === '' ||
                    values[5] === '' ||
                    commonFunctions.isNullOrUndefined(values[0]) ||
                    commonFunctions.isNullOrUndefined(values[1]) ||
                    commonFunctions.isNullOrUndefined(values[2]) ||
                    commonFunctions.isNullOrUndefined(values[3]) ||
                    commonFunctions.isNullOrUndefined(values[4]) ||
                    commonFunctions.isNullOrUndefined(values[5])
                ) {
                    if (
                        values[0] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[0])
                    ) {
                        this.consultantRateEntryDate = values[0];
                        selectedCopyClipboard.TDate = DateUtility.formatStringfromDate(
                            DateUtility.createDateTypefromDate(values[0])
                        );
                        if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                            selectedCopyClipboard.Invoice_Date = selectedCopyClipboard.TDate;
                        } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                            selectedCopyClipboard.Invoice_date = selectedCopyClipboard.TDate;
                        }
                    } else {
                        this.consultantRateEntryDate = '';
                    }
                    if (
                        values[1] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[1])
                    ) {
                        selectedCopyClipboard.Client = values[1];
                    }
                    if (
                        values[2] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[2])
                    ) {
                        selectedCopyClipboard.Project = values[2];
                    }
                    if (
                        values[3] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[3])
                    ) {
                        selectedCopyClipboard.Task_Code = values[3];
                    }
                    if (
                        values[4] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[4])
                    ) {
                        selectedCopyClipboard.Task_Description = values[4];
                    }
                    if (
                        values[5] !== '' &&
                        !commonFunctions.isNullOrUndefined(values[5]) &&
                        parseFloat(values[5]) > 0 &&
                        parseFloat(values[5]) < 25
                    ) {
                        selectedCopyClipboard.Hours = values[5];
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[6]) &&
                        values[6] !== ''
                    ) {
                        selectedCopyClipboard.Rate = values[6];
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[7]) &&
                        values[7] !== ''
                    ) {
                        if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                            selectedCopyClipboard.Invoice_Date =
                                DateUtility.formatStringfromDate(
                                    DateUtility.createDateTypefromDate(values[7])
                                );
                        } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                            selectedCopyClipboard.Invoice_date =
                                DateUtility.formatStringfromDate(
                                    DateUtility.createDateTypefromDate(values[7])
                                );
                        }
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[8]) &&
                        values[8] !== ''
                    ) {
                        selectedCopyClipboard.Charge = values[8];
                    }
                    selectedCopyClipboard.status = 'bad';
                    selectedCopyClipboard.Employee = this.currentUser['Employee'];
                    selectedCopyClipboard['startDate'] = this.weekStart;
                    selectedCopyClipboard['endDate'] = this.weekEnd;
                    this.getProjects(selectedCopyClipboard);
                } else {
                    this.consultantRateEntryDate = values[0];
                    selectedCopyClipboard.TDate = DateUtility.formatStringfromDate(
                        DateUtility.createDateTypefromDate(values[0])
                    );
                    if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                        selectedCopyClipboard.Invoice_Date = selectedCopyClipboard.TDate;
                    } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                        selectedCopyClipboard.Invoice_date = selectedCopyClipboard.TDate;
                    }
                    selectedCopyClipboard.Client = values[1];
                    selectedCopyClipboard.Project = values[2];
                    selectedCopyClipboard.Task_Code = values[3];
                    selectedCopyClipboard.Task_Description = values[4];
                    if (parseFloat(values[5]) > 0 && parseFloat(values[5]) < 25) {
                        selectedCopyClipboard.Hours = values[5];
                    } else {
                        selectedCopyClipboard.status = 'bad';
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[6]) &&
                        values[6] !== ''
                    ) {
                        selectedCopyClipboard.Rate = values[6];
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[7]) &&
                        values[7] !== ''
                    ) {
                        if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                            selectedCopyClipboard.Invoice_Date =
                                DateUtility.formatStringfromDate(
                                    DateUtility.createDateTypefromDate(values[7])
                                );
                        } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                            selectedCopyClipboard.Invoice_date =
                                DateUtility.formatStringfromDate(
                                    DateUtility.createDateTypefromDate(values[7])
                                );
                        }
                    }
                    if (
                        !commonFunctions.isNullOrUndefined(values[8]) &&
                        values[8] !== ''
                    ) {
                        selectedCopyClipboard.Charge = values[8];
                    }

                    selectedCopyClipboard.Employee = this.currentUser['Employee'];
                    selectedCopyClipboard['startDate'] = this.weekStart;
                    selectedCopyClipboard['endDate'] = this.weekEnd;
                    this.getProjects(selectedCopyClipboard);
                }
            } else {
                if (values.length === 0) {
                    this._snackBarService.showSnackBar(
                        Constants.SNACKBAR_MSG_ENTRY_BLANK_ROW,
                        '',
                        'warning'
                    );
                } else {
                    this._snackBarService.showSnackBar(
                        Constants.SNACKBAR_MSG_ENTRY_MISSING_COLUMNS,
                        '',
                        'warning'
                    );
                }
            }
        }
    }

    // Auto Size required columns : Apart from checkbox
    autoSizeAllColumns(): void {
        const allColumnIds = [];
        this.agGridColumnAPI.getAllColumns().forEach((column) => {
            if (column.colId !== 'checkbox') {
                allColumnIds.push(column.colId);
            }
        });
        this.agGridColumnAPI.autoSizeColumns(allColumnIds);
        this.entryService.setGridColumnApi(this.agGridColumnAPI);
    }

    // Remove the dummy row when there is a entry in a date
    removeDummyChildrenFromParent(params, result) {
        const dummyLeafChild = params.node.parent.parent.allLeafChildren.filter(
            (leafChild) => {
                return (
                    leafChild.data.status === 'dummy' &&
                    leafChild.data.TDate === result['TDate']
                );
            }
        )[0];
        if (!commonFunctions.isNullOrUndefined(dummyLeafChild)) {
            this.agGridAPI.applyTransaction({ remove: [dummyLeafChild.data] });
        }
    }

    //   When the AG Grid Cell is double Clicked
    onCellDoubleClicked(params): void {
        if (
            !params.node.group &&
            (commonFunctions.isNullOrUndefined(params.data.Invoice_id) ||
                params.data.Invoice_id === 0) &&
            !commonFunctions.isNullOrUndefined(params.data) &&
            !(
                params.data.Client === 'AD' &&
                (params.data.Project === 'TS' || params.data.Project === 'EX')
            )
        ) {
            if (
                !this.inlineEditCols.includes(params.column.colId) ||
                this.nodeStatus.includes(params.node.data.status)
            ) {
                this.entryService.setEntryTDate(params.data.TDate);
                // Dialog Box opens for the selected cells' row
                const dialogConfig = new MatDialogConfig();
                dialogConfig.maxWidth = 'clamp(340px, 92vw, 900px)';
                dialogConfig.width = '100%';
                dialogConfig.height = 'auto';
                dialogConfig.disableClose = true;
                dialogConfig.data = params.data;
                dialogConfig.data['startDate'] = this.weekStart;
                dialogConfig.data['endDate'] = this.weekEnd;
                dialogConfig.data.entryPageType = this.entryPageType;
                if (params.data.status !== 'dummy') {
                    dialogConfig.data['Mode'] = 'edit';
                } else {
                    dialogConfig.data['Mode'] = 'create';
                }
                if (
                    this.dateEntryCountHashMap.get(params.data.TDate.substr(0, 10)) !== -1
                ) {
                    this.dateEntryCountHashMap.set(
                        params.data.TDate.substr(0, 10),
                        this.dateEntryCountHashMap.get(params.data.TDate.substr(0, 10)) - 1
                    );
                } else {
                    this.dateEntryCountHashMap.set(params.data.TDate.substr(0, 10), 0);
                }
                this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                const dialogRef = this._dialog.open(EntryDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe((result) => {
                    if (typeof result != typeof true) {
                        this.setEntryIsUnsavedChangesPresent(true);
                        if (!result['Copy']) {
                            if (result['TDate'] !== params.data.TDate) {
                                if (
                                    this.dateEntryCountHashMap.get(
                                        params.data.TDate.substr(0, 10)
                                    ) > 0
                                ) {
                                    this.dateEntryCountHashMap.set(
                                        params.data.TDate.substr(0, 10),
                                        this.dateEntryCountHashMap.get(
                                            params.data.TDate.substr(0, 10)
                                        ) - 1
                                    );
                                } else {
                                    this.dateEntryCountHashMap.set(
                                        params.data.TDate.substr(0, 10),
                                        0
                                    );
                                }
                                this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                            }

                            for (const property in result) {
                                params.data[property] = result[property];
                            }

                            if (
                                !commonFunctions.isNullOrUndefined(params.data.Time_ID) &&
                                params.data.Time_ID !== 0
                            ) {
                                params.data.status = 'update';
                            } else {
                                params.data.status = 'create';
                            }

                            this.agGridAPI.applyTransaction({ update: [params.data] });

                            if (
                                this.dateEntryCountHashMap.get(
                                    result['TDate'].substr(0, 10)
                                ) === -1
                            ) {
                                this.removeDummyChildrenFromParent(params, result);
                                this.dateEntryCountHashMap.set(
                                    result['TDate'].substr(0, 10),
                                    1
                                );
                            } else {
                                this.dateEntryCountHashMap.set(
                                    result['TDate'].substr(0, 10),
                                    this.dateEntryCountHashMap.get(
                                        result['TDate'].substr(0, 10)
                                    ) + 1
                                );
                            }
                        } else {
                            result['status'] = 'create';
                            this.agGridAPI.applyTransaction({ add: [result] });
                            if (
                                this.dateEntryCountHashMap.get(
                                    result['TDate'].substr(0, 10)
                                ) === -1
                            ) {
                                this.removeDummyChildrenFromParent(params, result);
                                this.dateEntryCountHashMap.set(
                                    result['TDate'].substr(0, 10),
                                    1
                                );
                            } else {
                                this.dateEntryCountHashMap.set(
                                    result['TDate'].substr(0, 10),
                                    this.dateEntryCountHashMap.get(
                                        result['TDate'].substr(0, 10)
                                    ) + 1
                                );
                            }
                            this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                            if (
                                this.dateEntryCountHashMap.get(
                                    params.data.TDate.substr(0, 10)
                                ) !== -1
                            ) {
                                this.dateEntryCountHashMap.set(
                                    params.data.TDate.substr(0, 10),
                                    this.dateEntryCountHashMap.get(
                                        params.data.TDate.substr(0, 10)
                                    ) + 1
                                );
                            } else {
                                this.dateEntryCountHashMap.set(
                                    params.data.TDate.substr(0, 10),
                                    0
                                );
                            }
                            this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                        }
                    } else {
                        if (
                            this.dateEntryCountHashMap.get(
                                params.data.TDate.substr(0, 10)
                            ) !== -1 &&
                            params.data.status !== 'dummy'
                        ) {
                            this.dateEntryCountHashMap.set(
                                params.data.TDate.substr(0, 10),
                                this.dateEntryCountHashMap.get(
                                    params.data.TDate.substr(0, 10)
                                ) + 1
                            );
                        } else {
                            this.dateEntryCountHashMap.set(
                                params.data.TDate.substr(0, 10),
                                -1
                            );
                        }
                        this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                    }
                    this.entryService.setEntryTDate(DateUtility.formatStringfromDate(DateUtility.createDateType()));
                    this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
                    this._entryTableService.addEmptyRowsforMissingTDates(this.agGridAPI);
                    this.agGridAPI.setColumnDefs(this.gridOptions.columnDefs);
                });
            }
        }
    }

    // When Cell in AG grid is clicked
    onCellClicked(params): void {
        if (!params.node.group) {
            this.entryService.setEntryTDate(params.data.TDate);
        } else {
            this.entryService.setEntryTDate(params.node.key);
        }
    }

    // Once AG Grid is ready, config
    onGridReady(params): void {
        this._entryGridConfigService
            .getSavedEntryGridConfig(this.entryPageType)
            .subscribe((response) => {
                this.agGridAPI = params.api;
                this.agGridColumnAPI = params.columnApi;
                this.entryService.setGridApi(this.agGridAPI);
                this.entryService.setGridColumnApi(this.agGridColumnAPI);
                this.setEntryGridConfig(response);
                if (!commonFunctions.isNullOrUndefined(response)) {
                    this.agGridColumnAPI.applyColumnState(
                        JSON.parse(response.Configuration)
                    );
                }
                this.agGridCopy = this.agGridAPI;
                    this._entryTableService.getEntryTableEntries(
                        this.agGridAPI,
                        this.entryPageType
                    );
            });

        this.subscription = this._userService.announceImpersonate$.subscribe(
            (response) => { }
        );
        this.gridOptions.columnApi.getColumn('TDate').setSort('asc');
    }

    //   Move/Copy rows
    onRowDragEnd(params: RowDragEndEvent): void {
        if (
            !(
                params.node.data.Client == 'AD' &&
                (params.node.data.Project == 'TS' || params.node.data.Project == 'EX')
            )
        ) {
            if (this.rowDragModeIsCopy) {
                this.setEntryIsUnsavedChangesPresent(true);
                const newEntry = {};
                for (const property in params.node.data) {
                    newEntry[property] = params.node.data[property];
                }
                if (!commonFunctions.isNullOrUndefined(params.overNode)) {
                    if (params.overNode.group) {
                        newEntry['TDate'] = params.overNode.key;
                    } else {
                        newEntry['TDate'] = params.overNode.data.TDate;
                    }
                    if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                        if (params.node.data.Charge.toLowerCase() !== 'no') {
                            newEntry['Charge'] = 'yes';
                            newEntry['Invoice_id'] = 0;
                        }
                        newEntry['Time_ID'] = null;
                        newEntry['Invoice_Date'] = newEntry['TDate'];
                    } else {
                        newEntry['Invoice_id'] = 0;
                        newEntry['ExpenseID'] = null;
                        newEntry['Invoice_date'] = newEntry['TDate'];
                    }
                    if (params.node.data.status !== 'dummy') {
                        newEntry['status'] = 'create';
                        this.setEntryIsUnsavedChangesPresent(true);
                    } else {
                        newEntry['status'] = 'dummy';
                    }
                    this.agGridAPI.applyTransaction({ add: [newEntry] });
                }
            } else {
                if (
                    (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS &&
                        (params.node.data.Charge.toLowerCase() === 'yes' ||
                            params.node.data.Charge.toLowerCase() === 'no')) ||
                    (this.entryPageType == Constants.NAV_EXPENSE_ENTRY &&
                        (params.node.data.Invoice_id === 0 ||
                            params.node.data.ExpenseType === 'Non-Charge'))
                ) {
                    this.setEntryIsUnsavedChangesPresent(true);
                    this.dateEntryCountHashMap.set(
                        params.node.data.TDate.substr(0, 10),
                        this.dateEntryCountHashMap.get(
                            params.node.data.TDate.substr(0, 10)
                        ) - 1
                    );
                    this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                    if (
                        this.dateEntryCountHashMap.get(
                            params.node.data.TDate.substr(0, 10)
                        ) === 0
                    ) {
                        this._entryTableService.addEmptyRowForEntryDate(
                            params.node.data.TDate.substr(0, 10),
                            this.agGridAPI
                        );
                    }
                    const newRowNodeData = params.node.data;
                    if (!commonFunctions.isNullOrUndefined(params.overNode)) {
                        if (params.overNode.group) {
                            newRowNodeData.TDate = params.overNode.key;
                            if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                                newRowNodeData.Invoice_Date = params.overNode.key;
                            } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                                newRowNodeData.Invoice_date = params.overNode.key;
                            }
                        } else {
                            newRowNodeData.TDate = params.overNode.data.TDate;
                            if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                                newRowNodeData.Invoice_Date = params.overNode.data.TDate;
                            } else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
                                newRowNodeData.Invoice_date = params.overNode.data.TDate;
                            }
                        }
                    }
                    newRowNodeData.status = 'update';
                    this.agGridAPI.applyTransaction({ update: [newRowNodeData] });
                }
            }
            if (
                (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS &&
                    (this.rowDragModeIsCopy ||
                        (!this.rowDragModeIsCopy &&
                            (params.node.data.Charge.toLowerCase() === 'yes' ||
                                params.node.data.Charge.toLowerCase() === 'no')))) ||
                (this.entryPageType == Constants.NAV_EXPENSE_ENTRY &&
                    (this.rowDragModeIsCopy ||
                        (!this.rowDragModeIsCopy && params.node.data.Invoice_id === 0)))
            ) {
                if (!commonFunctions.isNullOrUndefined(params.overNode)) {
                    if (params.overNode.group) {
                        if (
                            this.dateEntryCountHashMap.get(
                                params.overNode.key.substr(0, 10)
                            ) === -1
                        ) {
                            const dummyLeafChild = params.overNode.allLeafChildren.filter(
                                (leafChild) => {
                                    return leafChild.data.status === 'dummy';
                                }
                            )[0];
                            this.agGridAPI.applyTransaction({
                                remove: [dummyLeafChild.data],
                            });
                            this.dateEntryCountHashMap.set(
                                params.overNode.key.substr(0, 10),
                                1
                            );
                        } else {
                            this.dateEntryCountHashMap.set(
                                params.overNode.key.substr(0, 10),
                                this.dateEntryCountHashMap.get(
                                    params.overNode.key.substr(0, 10)
                                ) + 1
                            );
                        }
                        this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                    } else {
                        if (
                            this.dateEntryCountHashMap.get(
                                params.overNode.data.TDate.substr(0, 10)
                            ) === -1
                        ) {
                            const dummyLeafChild =
                                params.overNode.parent.allLeafChildren.filter((leafChild) => {
                                    return leafChild.data.status === 'dummy';
                                })[0];
                            this.agGridAPI.applyTransaction({
                                remove: [dummyLeafChild.data],
                            });
                            this.dateEntryCountHashMap.set(
                                params.overNode.data.TDate.substr(0, 10),
                                1
                            );
                        } else {
                            this.dateEntryCountHashMap.set(
                                params.overNode.data.TDate.substr(0, 10),
                                this.dateEntryCountHashMap.get(
                                    params.overNode.data.TDate.substr(0, 10)
                                ) + 1
                            );
                        }
                        this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
                    }
                    this.entryService.updateActualsAndChangedEntries(this.agGridAPI);
                }
            }
        }
    }

    // Assign values from the clipboard
    processDataFromClipboard = (params) => {
        this.assignValues(params.data);
    };

    // After dragging required row
    onDragStopped(): void {
        let tempEntryGridConfig = { ...this.entryGridConfig };
        if (!commonFunctions.isNullOrUndefined(this.agGridColumnAPI)) {
            if (
                !commonFunctions.isNullOrUndefined(tempEntryGridConfig) &&
                tempEntryGridConfig.id !== 0
            ) {
                tempEntryGridConfig.Configuration = JSON.stringify(
                    this.agGridColumnAPI.getColumnState()
                );
            } else {
                if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
                    tempEntryGridConfig = new TimeEntryGridConfigModel();
                } else {
                    tempEntryGridConfig = new ExpenseEntryGridConfigModel();
                }
                tempEntryGridConfig.Configuration = JSON.stringify(
                    this.agGridColumnAPI.getColumnState()
                );
            }
            this.entryService.setGridColumnApi(this.agGridColumnAPI);
            this.setEntryGridConfig(tempEntryGridConfig);
        }
    }

    //   On selecting Rows
    onRowSelection(param: any): void {
        const selectedRows = this.agGridAPI.getSelectedRows();
        if (selectedRows.length > 0) {
            this.entryService.entryisRowSelectedForDeletion.next(true);
        } else {
            this.entryService.entryisRowSelectedForDeletion.next(false);
        }
    }
}
