import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { Observable } from 'rxjs';
import { SharedStorage } from 'ngx-store';
import { ConsultantModel } from '../models/consultant.model';
import { map } from 'rxjs/operators';
import { DateUtility } from '../utilities/date.utility';
import { ImmigrationDetailsModel } from '../models/immigration-details.model';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { setImmigrationDetails } from 'app/core/store/immigrationDetails/immigrationDetails.action';
import { LocalStorageService } from './local-storage.service';


@Injectable({
    providedIn: 'root'
})

export class ImmigrationDetailsService {

    currentUser: ConsultantModel;
    immigrationDetails: ImmigrationDetailsModel[] = [];
    consultantWorkCountry = null;
    consultantWorkHour = null;
    countryCultureMap = {
        'India': 'en-GB', 'United Kingdom': 'en-GB',
        'United States of America': 'en-US', 'Brazil': 'en-GB',
        'Singapore': 'en-GB'
    };
    

    constructor(private http: HttpClient, private _store: Store) {
        this._store.select(getCurrentUser).subscribe((data) => {
            this.currentUser = data;
        })
    }

    setCountryCulture() {
        const currentDate = DateUtility.createDateType();
        let culture_info: any;
        this.getConsultantImmigrationDetails(currentDate).subscribe((res) => {
            this._store.dispatch(setImmigrationDetails({immigrationDetails: res}));
            culture_info = this.countryCultureMap[res.consultantWorkCountry]
            LocalStorageService.setLocalStorageValueBasedOnKey("country_culture", culture_info)
        })

    }

    //Get the Consultant Immigration Details
    getConsultantImmigrationDetails(currentDate): Observable<any> {
        let params = new HttpParams();
        params = params.append('employee', this.currentUser.Employee);
        return this.http.get<any>(URLPaths.URL_IMMIGRATION_DETAILS, { headers: ApiheaderService.getHeaders(), params: params })
            .pipe(
                map(res => {
                    this.immigrationDetails = res;
                    this.consultantWorkCountry = this.currentUser.Country_Description;
                    this.consultantWorkHour = Number(this.currentUser.DayHours);
                    for (const immigration of this.immigrationDetails) {
                        const startDate = DateUtility.createDateTypefromDate(immigration.ImmigrationEntryDate.substr(0, 10));
                        const returnDate = DateUtility.createDateTypefromDate(immigration.ImmigrationReturnDate.substr(0, 10));
                        if ((startDate <= currentDate) && (currentDate <= returnDate)) {
                            this.consultantWorkCountry = immigration.CountryName;
                            this.consultantWorkHour = Number(immigration.DayHours);
                            break;
                        }
                    }
                    return {
                        consultantWorkCountry: this.consultantWorkCountry,
                        consultantWorkHour: this.consultantWorkHour
                    };
                }
                )
            );
    }

}

