// Types
export type Screens = { [key: string]: string };

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig
{
    screens: Screens;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 */
export const appConfig: AppConfig = {
    screens: {
        sm: '600px',
        tb: '768px',
        md: '960px',
        lg: '1280px',
        xl: '1440px'
    }
};
