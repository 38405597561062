import { Component, OnInit } from '@angular/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { UserModel } from '../../common/models/user.model';
import { ConsultantDetailsService } from '../../common/services/consultant-details.service';
import { ConsultantModel } from '../../common/models/consultant.model';
import { DateUtility } from '../../common/utilities/date.utility';
import { isNull } from 'lodash';
import { Subject } from 'rxjs';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { UserService } from '../../common/services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ExpenseEntryService } from '../../common/services/expense-entry.service';
import { TimeEntryService } from '../../common/services/time-entry.service';
import { Constants } from '../../common/utilities/constants.config';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateComponent implements OnInit {
  currentUser: ConsultantModel;
  loggedInUser: UserModel;
  rowData: ConsultantModel[];
  gridOptions: GridOptions = {};
  agGridAPI: GridApi;
  agGridColumnAPI: ColumnApi;
  rowSelection: any;
  searchText = '';
  selectedRow: any;
  subject = new Subject<any>();
  defaultColDef: any;
  private announceImpersonate = new Subject<string>();
  announceImpersonate$ = this.announceImpersonate.asObservable();
  timeEntryIsUnsavedChangesPresent: boolean;
  expenseEntryIsUnsavedChangesPresent: boolean;

  /**
   * Constructor
   */

  constructor(
    private _store: Store<any>,
    private _consultantDetailsService: ConsultantDetailsService,
    private _userService: UserService,
    public dialogRef: MatDialogRef<ImpersonateComponent>,
    private _timeEntryService: TimeEntryService,
    private _expenseEntryService: ExpenseEntryService
  ) {
    this.getConsultantDetails();

    // Columns for the AG Grid
    this.gridOptions.columnDefs = [
      {
        headerName: 'Consultant Details',
        children: [
          { headerName: 'Employee', field: 'Employee' },
          { headerName: 'First Name', field: 'FirstName' },
          { headerName: 'Last Name', field: 'LastName' },
          { headerName: 'Email', field: 'Email' }, //autoSize: true },
          { headerName: 'Base Rate', field: 'BaseRate' },
          { headerName: 'Manager', field: 'Manager' },
          {
            headerName: 'Date of Joining',
            field: 'DateOfJoining',
            filter: 'agDateColumnFilter',
            valueGetter: (params) => {
              if (params.data.DateOfJoining !== null) {
                return DateUtility.createDateTypefromDate(
                  params.data.DateOfJoining.substr(0, 10)
                );
              }
              return null;
            },
            valueFormatter: (params) => {
              if (!isNull(params.data.DateOfJoining)) {
                return DateUtility.formatDatefromString(
                  params.data.DateOfJoining.toString().substr(0, 10)
                );
              }
            },
          },
          { headerName: 'Company Name', field: 'Company_Name' },
          {
            headerName: 'Availability',
            field: 'Plansheet',
            valueGetter: (params) => {
              if (!isNull(params.data)) {
                if (params.data['Plansheet'] === 1) {
                  return 'Yes';
                } else {
                  return 'No';
                }
              } else {
                return '';
              }
            },
          },
        ],
      },
      {
        headerName: 'Working Hours',
        children: [
          { headerName: 'Mon', field: 'Mon', width: 80 },
          { headerName: 'Tue', field: 'Tue', width: 80 },
          { headerName: 'Wed', field: 'Wed', width: 80 },
          { headerName: 'Thu', field: 'Thu', width: 80 },
          { headerName: 'Fri', field: 'Fri', width: 80 },
          { headerName: 'Sat', field: 'Sat', width: 80 },
          { headerName: 'Sun', field: 'Sun', width: 80 },
        ],
      },
    ];
    this._store.select(getLoggedInUser).subscribe((data) => {
      this.loggedInUser = data;
    });
    this._store.select(getCurrentUser).subscribe(async (data) => {
      this.currentUser = data;
    });
    this._timeEntryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.timeEntryIsUnsavedChangesPresent = data;
    })
    this._expenseEntryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.expenseEntryIsUnsavedChangesPresent = data;
    })

    this.rowSelection = 'single';
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit() { }

  // Get all the consultants details
  getConsultantDetails() {
    this._consultantDetailsService.getConsultantDetails().subscribe(
      (data: ConsultantModel[]) => {
        this.rowData = data;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  //  Set the AG Grid
  onGridReady(params): void {
    this.agGridAPI = params.api;
    this.agGridColumnAPI = params.columnApi;
    this.agGridAPI.setRowData(this.rowData);
    this.autoSizeAllColumns();
  }

  // Resize Columns
  autoSizeAllColumns(): void {
    const allColumnIds = [];
    this.agGridColumnAPI.getColumns().forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.agGridColumnAPI.autoSizeColumns(allColumnIds);
  }

  // On row selected
  onRowSelection(params): void {
    this.selectedRow = this.agGridAPI.getSelectedRows();
  }

  // Apply Impersonation on clicking Apply button
  impersonateOnClick(): void {
    if (this.checkUnsavedEntries()) {
      const selectedRows = this.agGridAPI.getSelectedRows();
      this._userService.impersonateUser(selectedRows[0].Employee);
    }

  }

  // Apply Impersonation on cell double clicked
  onCellDoubleClicked(params): void {
    if (this.checkUnsavedEntries()) {
      this._userService.impersonateUser(params.data.Employee);
      this.dialogRef.close();
    }
  }

  checkUnsavedEntries(){
    if(this.timeEntryIsUnsavedChangesPresent || this.expenseEntryIsUnsavedChangesPresent){
      const confirmRedirect = window.confirm(Constants.MSG_CONFIRM_DISCARD_CHANGES)
      return confirmRedirect;
    }
    return true;
  }

  // Announce Impersonation observable
  announceImpersonation(): void {
    this.announceImpersonate.next('Impersonation announcement');
  }
}
