
<div *ngIf="isPageInitialized" class="h-full w-full">
  <div *ngIf="mode === 'add'" class="h-full w-full" >
    <mat-tab-group
      [(selectedIndex)]="selectedIndex"
      (selectedTabChange)="tabChanged($event)" class="h-full w-full"
    >
      <mat-tab label="Add Project Details/Theme Allocation" >
        <div class="relative border-gray-400 flex flex-col tb:flex-row gap-5 h-fit pt-2 pb-2">
          <div class="flex flex-row gap-2.5">
            <button
            mat-raised-button
            class="project-details-button"
            (click)="onModeChange('add')"
            disabled="true"
            >Add
            </button>
            <button
            mat-raised-button
            class="project-details-button"
            (click)="onModeChange('edit')">Edit
            </button>
          </div>
        </div>
        <app-form-baselayout
          [formBlueprint]="projectDetailsBlueprintData"
          [mode]="mode"
          [service]="service"
          [fieldDetails]="projectDetailsFieldDetails"
          class="h-full"
          [fieldValuesOfAllForms]="fieldValuesOfAllForms"
          [enableSave]="enableSave"
          [selectedIndex]="selectedIndex"
          [projectDetailsContext]="this"
        ></app-form-baselayout>

          <app-form-baselayout
            *ngIf="!addOtherDetailsDisabled"
            [formBlueprint]="themeALlocationBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="themeAllocationFieldDetails"
            [enableSave]="enableSave"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [selectedIndex]="selectedIndex"
            [rowData]="themeAllocationRowData"
            [columnDefs]="themeAllocationColumnDefs"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [projectDetailsContext] = "this"
            gridType = "themeAllocation"
          ></app-form-baselayout>


      </mat-tab>
      <!-- <div *ngIf="addOtherDetailsDisabled"> -->
      <mat-tab label="Consultant Project Rates" [disabled]="addOtherDetailsDisabled"
        class="h-full w-full"
          >
          <div class="flex flex-col gap-5 h-full">
            <app-form-baselayout
              [formBlueprint]="consultantProjectRatesBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="consultantProjectRatesFieldDetails"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [enableSave]="enableSave"
              [selectedIndex]="selectedIndex"
              [projectDetailsContext] = "this"
            ></app-form-baselayout
          >
          <ag-grid-angular
              #agGrid
              class="ag-theme-balham"
              style="width: 100%; height: 100%; padding-right: 12px"
              [rowData]="consultantProjectRatesRowData"
              [columnDefs]="consultantProjectRatesColumnDefs"
              (gridReady)="OnGridReady($event)"
              [gridOptions]="gridOptions"
              [rowSelection]="rowSelection"
              [suppressRowClickSelection]="true"
              (selectionChanged)="onSelectionChanged($event)"
              (cellValueChanged)="onCellValueChanged($event, label)"
            ></ag-grid-angular>
          </div>
      </mat-tab>
      <mat-tab label="Project Tasks" [disabled]="addOtherDetailsDisabled"
          >
        <div class="flex flex-col gap-5 h-full">
          <app-form-baselayout
            [formBlueprint]="projectTasksBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="projectTasksFieldDetails"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [enableSave]="enableSave"
            [selectedIndex]="selectedIndex"
            [projectDetailsContext] = "this"
          ></app-form-baselayout>
        <ag-grid-angular
        #agGrid
        class="ag-theme-balham"
        style="width: 100%; height: 100%; padding-right: 12px"
        [rowData]="projectTasksRowData"
        [columnDefs]="projectTasksColumnDefs"
        (gridReady)="OnGridReady($event)"
        [gridOptions]="gridOptions"
        [rowSelection]="rowSelection"
        [components]="components"
        [suppressRowClickSelection]="true"
        (cellValueChanged)="onCellValueChanged($event)"
        (selectionChanged)="onSelectionChanged($event)"
      ></ag-grid-angular>
        </div>
      </mat-tab>
        <mat-tab label="Invoice/Prepayment Details" [disabled]="addOtherDetailsDisabled"
          >
            <app-form-baselayout
              [formBlueprint]="inoviceDetailsBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="invoiceDetailsFieldDetails"
              [enableSave]="enableSave"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [selectedIndex]="selectedIndex"
              [projectDetailsContext] = "this"
            ></app-form-baselayout>
            <app-form-baselayout
              [formBlueprint]="prepaymentDetailsBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="prepaymentDetailsFieldDetails"
              [enableSave]="enableSave"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [selectedIndex]="selectedIndex"
              [rowData]="prepaymentDetailsRowData"
              [columnDefs]="prepaymentDetailsColumnDefs"
              [gridOptions]="gridOptions"
              [rowSelection]="rowSelection"
              [projectDetailsContext] = "this"
              gridType = "prepaymentDetails"
            ></app-form-baselayout>
          <!-- <ag-grid-angular
            class="ag-theme-balham"
            style="width: 100%; height: 100%;  padding-right: 12px"
            [rowData]="prepaymentDetailsRowData"
            [columnDefs]="prepaymentDetailsColumnDefs"
            (gridReady)="OnGridReady($event)"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [components]="components"
            [suppressRowClickSelection]="true"
            (cellValueChanged)="onCellValueChanged($event)"
            (selectionChanged)="onSelectionChanged($event)"
          ></ag-grid-angular> -->
      </mat-tab>
    </mat-tab-group>
    <!-- <button mat-raised-button (click)="nextStep()" type="button">
      Next Page
    </button> -->
  </div>

  <div *ngIf="mode === 'view'" class="h-full w-full">
    <mat-tab-group class="h-full w-full" [mat-stretch-tabs]="!viewOtherDetailsDisabled">
      <mat-tab label="View Project Details" class="h-full w-full">
        <app-form-baselayout
          [formBlueprint]="projectDetailsBlueprintData"
          [mode]="mode"
          [service]="service"
          [fieldDetails]="projectDetailsFieldDetails"
          class="h-full"
          [fieldValuesOfAllForms]="fieldValuesOfAllForms"
          [enableSave]="enableSave"
          [projectDetailsContext] = "this"
        ></app-form-baselayout>
      </mat-tab>
      <div *ngIf="!viewOtherDetailsDisabled">
        <mat-tab
          [label]="tab.tabDisplayName"
          *ngFor="let tab of tabGroup"
          [disabled]="viewOtherDetailsDisabled"
          class="h-full w-full"
        >
          <ag-grid-angular
          #agGrid
            class="ag-theme-balham"
            style="width: 100%; height: 100%;  padding-right: 12px; padding-top: 12px;"
            [rowData]="tab.tabRowData"
            [columnDefs]="tab.tabColumnDefs"
            (gridReady)="OnGridReady($event)"
            [checkboxSelection]="false"
          >
          </ag-grid-angular>
        </mat-tab>
      </div>
    </mat-tab-group>
  </div>

  <!-- Darshan 23-04-2024 Edit Mode is set -->
  <div *ngIf="mode === 'edit'" class="h-full w-full">

    <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="tabChanged($event)"
    class="h-full w-full"
    >
    <div *ngIf="mode === 'edit'" class="h-full w-full">
    <mat-tab label="Edit Project Details/Theme Allocation">
      <div #scrollableContainer class="scrollable-container h-full overflow-y-auto">
        <div class="relative border-gray-400 flex flex-col tb:flex-row gap-5 h-fit pt-2 pb-2">
          <div class="flex flex-row gap-2.5">
            <button
            mat-raised-button
            class="project-detail-button"
            (click)="onModeChange('add')"
            >Add
            </button>
            <button
            mat-raised-button
            class="project-details-button"
            (click)="onModeChange('edit')"
            disabled="true"
            >Edit
            </button>
          </div>
        </div>
        <app-form-baselayout
          [formBlueprint]="projectDetailsBlueprintData"
          [mode]="mode"
          [service]="service"
          [fieldDetails]="projectDetailsFieldDetails"
          [fieldValuesOfAllForms]="fieldValuesOfAllForms"
          class="h-full"
          [enableSave]="enableSave"
          [selectedIndex]="selectedIndex"
          [projectDetailsContext]="this"
          ></app-form-baselayout>

          <app-form-baselayout
            *ngIf="!viewOtherDetailsDisabled"
            #formBaseLayout
            [formBlueprint]="themeALlocationBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="themeAllocationFieldDetails"
            [enableSave]="enableSave"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [selectedIndex]="selectedIndex"
            [rowData]="themeAllocationRowData"
            [columnDefs]="themeAllocationColumnDefs"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [projectDetailsContext] = "this"
            [rowDataPD]="prepaymentDetailsRowData"
            [columnDefsPD]="prepaymentDetailsColumnDefs"
            gridType = "themeAllocation"
          ></app-form-baselayout>
      </div>
    </mat-tab>
  </div>
  <div *ngIf="mode === 'add'" class="h-full w-full">
    <mat-tab label="Add Project Details/Theme Allocation" >
      <div class="relative border-gray-400 flex flex-col tb:flex-row gap-5 h-fit pt-6">
        <div class="flex flex-row gap-1">
          <input
            type="radio"
            id="addProject"
            name="projectAction"
            value="addProject"
            [formControlName]="'projectActionControl'"
            (change)="onSelectionChange({ selectedFieldCode: 'projectActionControl', selectedValue: 'addProject' })"
            style="opacity: 1 !important;"
          >
          <label for="addProject" class="self-center">Add Project</label>

          <input
            type="radio"
            id="editProject"
            name="projectAction"
            value="editProject"
            [formControlName]="'projectActionControl'"
            (change)="onSelectionChange({ selectedFieldCode: 'projectActionControl', selectedValue: 'editProject' })"
            style="opacity: 1 !important;"
          >
          <label for="editProject" class="self-center">Edit Project</label>
        </div>
        </div>
          <app-form-baselayout
            [formBlueprint]="projectDetailsBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="projectDetailsFieldDetails"
            class="h-full"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [enableSave]="enableSave"
            [selectedIndex]="selectedIndex"
            [projectDetailsContext]="this"
          ></app-form-baselayout>

          <app-form-baselayout
            *ngIf="!addOtherDetailsDisabled"
            [formBlueprint]="themeALlocationBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="themeAllocationFieldDetails"
            [enableSave]="enableSave"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [selectedIndex]="selectedIndex"
            [rowData]="themeAllocationRowData"
            [columnDefs]="themeAllocationColumnDefs"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [projectDetailsContext] = "this"
            gridType = "themeAllocation"
          ></app-form-baselayout>
    </mat-tab>
  </div>

      <!-- adding ngClass to test color on tab selection -->
      <!-- flat base and rounded tops -->
       <mat-tab label="Consultant Project Rates" [disabled]="viewOtherDetailsDisabled"
        class="h-full w-full">

      <div class="row-container border border-gray-400 gap-5 rounded-md pr-3 mb-2 mt-2 mr-3 pt-2 pd-1 pl-4 bg-slate-200" style="display: flex; align-items: center;">

        <!-- Selected Client Field -->
        <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; flex-basis: 7%; display: flex; align-items: center;">
          <input
            type="text"
            [formControl]="selectedClientControl"
            class="input-field absolute left-0 top-0 w-full h-full bg-transparent"
            [value]="selectedClient"
            readonly
          />
          <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
            Selected Client
          </span>
        </div>

        <!-- Selected Project Field -->
        <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; display: flex; align-items: center;">
          <input
            type="text"
            [formControl]="selectedProjectControl"
            class="input-field absolute left-0 top-0 w-full h-full bg-transparent"
            [value]="selectedProject"
            readonly
          />
          <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
            Selected Project
          </span>
        </div>

      </div>
        <div class="flex flex-col gap-5 h-full">
          <app-form-baselayout
              [formBlueprint]="consultantProjectRatesBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="consultantProjectRatesFieldDetails"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [enableSave]="enableSave"
              [selectedIndex]="selectedIndex"
              [projectDetailsContext] = "this"
            ></app-form-baselayout
          >
            <ag-grid-angular
            #agGrid
                class="ag-theme-balham"
                style="width: 100%; height: 100%; padding-right: 12px"
                [rowData]="consultantProjectRatesRowData"
                [columnDefs]="consultantProjectRatesColumnDefs"
                (gridReady)="OnGridReady($event)"
                [gridOptions]="gridOptions"
                [rowSelection]="rowSelection"
                [suppressRowClickSelection]="true"
                (cellValueChanged)="onCellValueChanged($event)"
                (cellDoubleClicked)="onCellDoubleClicked($event)"
            ></ag-grid-angular>
        </div>
      </mat-tab>
      <mat-tab label="Project Tasks" [disabled]="viewOtherDetailsDisabled"
       class="h-full w-full">

         <!-- Selected Client and Selected Project Fields -->
        <div class="row-container border border-gray-400 gap-5 rounded-md pr-3 mb-2 mt-2 mr-3 pt-2 pd-1 pl-4 bg-slate-200" style="display: flex; align-items: center;">

          <!-- Selected Client Field -->
          <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; flex-basis: 7%; display: flex; align-items: center;">
            <input
              type="text"
              [formControl]="selectedClientControl"
              class="input-field absolute left-0 top-0 w-full h-full bg-transparent"
              [value]="selectedClient"
              readonly
            />
            <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
              Selected Client
            </span>
          </div>

          <!-- Selected Project Field -->
          <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; display: flex; align-items: center;">
            <input
              type="text"
              [formControl]="selectedProjectControl"
              class="input-field absolute left-0 top-0 w-full h-full bg-transparent"
              [value]="selectedProject"
              readonly
            />
            <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
              Selected Project
            </span>
          </div>

        </div>
        <div class="flex flex-col gap-5 h-full">
          <app-form-baselayout
              [formBlueprint]="projectTasksBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="projectTasksFieldDetails"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [enableSave]="enableSave"
              [selectedIndex]="selectedIndex"
              [projectDetailsContext] = "this"
            ></app-form-baselayout>
          <ag-grid-angular
          #agGrid
            class="ag-theme-balham"
            style="width: 100%; height: 100%; padding-right: 12px"
            [rowData]="projectTasksRowData"
            [columnDefs]="projectTasksColumnDefs"
            (gridReady)="OnGridReady($event)"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [components]="components"
            [suppressRowClickSelection]="true"
            (cellValueChanged)="onCellValueChanged($event)"
            (cellDoubleClicked)="onCellDoubleClicked($event)"
            (cellEditingStarted)="onCellEditingStarted($event)"
            (cellEditingStopped)="onCellEditingStopped($event)"
        ></ag-grid-angular>
      </div>
      </mat-tab>
      <!-- <mat-tab label="Theme Allocation" [disabled]="viewOtherDetailsDisabled"
        >
        <div class="flex flex-col gap-5 h-full">
        <app-form-baselayout
            [formBlueprint]="themeALlocationBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="themeAllocationFieldDetails"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [forms]="true"
            [buttons]="true"
            [selectedIndex]="selectedIndex"
            [projectDetailsContext] = "this"
          ></app-form-baselayout>
          <ag-grid-angular
            class="ag-theme-balham"
            style="width: 100%; height: 100%"
            [rowData]="themeAllocationRowData"
            [columnDefs]="themeAllocationColumnDefs"
            (gridReady)="OnGridReady($event)"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [suppressRowClickSelection]="true"
            (cellValueChanged)="onCellValueChanged($event)"

        ></ag-grid-angular>
      </div>
      </mat-tab> -->
        <mat-tab label="Invoice/Prepayment Details" [disabled]="viewOtherDetailsDisabled"
          >
          <div class="row-container border border-gray-400 gap-5 rounded-md pr-3 mb-2 mt-2 mr-3 pt-2 pd-1 pl-4 bg-slate-200" style="display: flex; align-items: center;">

            <!-- Selected Client Field -->
            <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; flex-basis: 7%; display: flex; align-items: center;">
              <input
                [type]="text"
                [formControlName]="selectedClientField"
                [className]="'input-field'"
                [name]="selectedClientField"
                placeholder=" "
                class="absolute left-0 top-0 w-full h-full bg-transparent"
                [value]="selectedClient"
                style="cursor: default;"
                readonly
              />
              <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
                Selected Client
              </span>
            </div>

            <!-- Selected Project Field -->
            <div class="relative border-b border-gray-400" style="height: 30px; flex-grow: 1; display: flex; align-items: center;">
              <input
                [type]="text"
                [formControlName]="selectedProjectField"
                [className]="'input-field'"
                [name]="selectedProjectField"
                placeholder=" "
                class="absolute left-0 top-0 w-full h-full bg-transparent"
                [value]="selectedProject"
                style="cursor: default;"
                readonly
              />
              <span class="floating-label absolute left-0 pointer-events-none" style="font-size: 11px;">
                Selected Project
              </span>
            </div>

          </div>

          <app-form-baselayout
              [formBlueprint]="inoviceDetailsBlueprintData"
              [mode]="mode"
              [service]="service"
              [fieldDetails]="invoiceDetailsFieldDetails"
              [enableSave]="enableSave"
              [fieldValuesOfAllForms]="fieldValuesOfAllForms"
              [selectedIndex]="selectedIndex"
              [projectDetailsContext] = "this"
            ></app-form-baselayout>
          <app-form-baselayout
            [formBlueprint]="prepaymentDetailsBlueprintData"
            [mode]="mode"
            [service]="service"
            [fieldDetails]="prepaymentDetailsFieldDetails"
            [fieldValuesOfAllForms]="fieldValuesOfAllForms"
            [forms]="true"
            [buttons]="true"
            [selectedIndex]="selectedIndex"
            [projectDetailsContext] = "this"
            [rowData]="prepaymentDetailsRowData"
            [columnDefs]="prepaymentDetailsColumnDefs"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [projectDetailsContext] = "this"
            [rowDataPD]="prepaymentDetailsRowData"
            [columnDefsPD]="prepaymentDetailsColumnDefs"
            gridType = "prepaymentDetails"
          ></app-form-baselayout>
         <!-- <ag-grid-angular
            class="ag-theme-balham"
            style="width: 100%; height: 100%"
            [rowData]="prepaymentDetailsRowData"
            [columnDefs]="prepaymentDetailsColumnDefs"
            (gridReady)="OnGridReady($event)"
            [gridOptions]="gridOptions"
            [rowSelection]="rowSelection"
            [components]="components"
            [suppressRowClickSelection]="true"
            (cellValueChanged)="onCellValueChanged($event)"
         > </ag-grid-angular> -->
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
