import { Component, Input } from '@angular/core';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
    selector     : 'app-settings',
    templateUrl  : './settings.component.html',
    styleUrls    : ['./settings.component.scss'],
})
export class SettingsComponent {
    @Input() pageType: any;
    navTimeEntryActuals: string;
    navExpenseEntry: string;
    navTimeDicer: string;

    /**
     * Constructor
     */
    constructor() {
        this.navTimeEntryActuals = Constants.NAV_TIME_ENTRY_ACTUALS;
        this.navExpenseEntry = Constants.NAV_EXPENSE_ENTRY;
        this.navTimeDicer = Constants.NAV_TIME_DICER;
    }

}
