import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimeDicerConfigService } from '../../../common/services/time-dicer-config.service';
import { commonFunctions } from '../../../../mips/common/classes/common.class';
import { Store } from '@ngrx/store';
import { setTimeDicerDateRangeSelection } from 'app/core/store/timeDicerDateRangeSelection/timeDicerDateRangeSelection.actions';
import { FormGroup, FormControl } from '@angular/forms';
import { DateRangeModel } from '../../../common/models/date-range.model';
import { DatePipe } from '@angular/common';
import { getTimeDicerDateRange } from 'app/core/store/timeDicerDateRangeSelection/timeDicerDateRangeSelection.selector';
import { GridApi } from 'ag-grid-community';
import { ProjectModel } from '../../../common/models/project.model';
import { ProjectService } from '../../../common/services/project.service';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantDetailsService } from '../../../common/services/consultant-details.service';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-time-dicer-header',
  templateUrl: './time-dicer-header.component.html',
  styleUrls: ['./time-dicer-header.component.scss'],
})
export class TimeDicerHeaderComponent implements OnInit {
  isSmallScreenFlag: boolean;
  isLargeScreenFlag: boolean;
  weekStart: string = '';
  weekEnd: string = '';
  clientProjectNames: string;
  consultantNames: string;
  agGridAPI: GridApi;
  currentUser: ConsultantModel;
  loadConfigSubscription: Subscription;
  selected: any;
  rowData: any;
  projectList: ProjectModel[] = [];
  selectedProjects: any = null;
  manageConfigsubscription: Subscription;
  consultantList: ConsultantModel[];
  selectedConsultants: any = null;
  
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  
  /**
   * Constructor
   */
  constructor(
    private _timeDicerConfigService: TimeDicerConfigService,
    private _store: Store,
    private _datePipe: DatePipe,
    private _consultantDetailsService: ConsultantDetailsService,
    private _projectService: ProjectService,
    private _renderer: Renderer2
  ) {
    // Retrieve current User Details from the Store
    this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this.isSmallScreenFlag = false;
    this.isLargeScreenFlag = false;
    this._timeDicerConfigService.loadConfigButtonClicked$.subscribe((res) => {
      this.selectedConsultants = [];
      this.selectedProjects = [];
      if (!commonFunctions.isNullOrUndefined(res['ConsultantsSelected'])) {
        this.selectedConsultants = JSON.parse(res['ConsultantsSelected']);
      }
      if (!commonFunctions.isNullOrUndefined(res['ProjectsSelected'])) {
        this.selectedProjects = JSON.parse(res['ProjectsSelected']);
      }
    });
    this.getProject();
    this.rowData = [];
    this.manageConfigsubscription =
      this._timeDicerConfigService.manageConfigButtonClicked$.subscribe(
        (res) => {
          if (!commonFunctions.isNullOrUndefined(res)) {
            this.selectedProjects = JSON.parse(res)['selectedProjects']
              ? JSON.parse(res)['selectedProjects']
              : null;
            this.selectedConsultants = JSON.parse(res)['selectedConsultants']
              ? JSON.parse(res)['selectedConsultants']
              : null;
          }
        }
      );
    this.getConsultantDetails();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  dropdownSettings: IDropdownSettings = {};
  ngOnInit(): void {
    this._store.select(getTimeDicerDateRange).subscribe((data) => {
      if (data) {
        this.weekStart = data['startDate'];
        this.weekEnd = data['endDate'];
      }
    });
    this.checkScreenSize();
    this.dateRange.get('start').setValue(new Date(this.weekStart));
    this.dateRange.get('end').setValue(new Date(this.weekEnd));

    const element = document.querySelector(".mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper");
    if(element){
      this._renderer.setStyle(element, 'display', 'none', 1);
    }

    this.dropdownSettings = {
      singleSelection: false,
      textField: 'Description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  onSearch(term: string): void {
    console.log('Search term:', term);
  }
  
  // Get the Date Range
  setDateRangeSelection() {
    let week = new DateRangeModel();
    week['startDate'] = this._datePipe.transform(
      this.dateRange.value.start,
      'dd MMM yyyy'
    );
    week['endDate'] = this._datePipe.transform(
      this.dateRange.value.end,
      'dd MMM yyyy'
    );
    this._store.dispatch(setTimeDicerDateRangeSelection({ week: week }));
  }

  // Check the size of the screen
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  // Check the Size of the Screen
  checkScreenSize() {
    this.isSmallScreenFlag = window.innerWidth < 768; // Example: small screens have width less than 768px
    this.isLargeScreenFlag = !this.isSmallScreenFlag;
  }

  isSmallScreen(): boolean {
    return this.isSmallScreenFlag;
  }

  isLargeScreen(): boolean {
    return this.isLargeScreenFlag;
  }

  // Get details of consultant 
  getConsultantDetails(): void {
    this._consultantDetailsService.getConsultantDetails().subscribe((values) => {
      this.consultantList = values;
    });
  }

  // Function to implement dropdown search in "Select Projects" element
  searchFunctionDropdown(term: string, item): any {
    const clientName = item['ClientName'].toLowerCase();
    const description = item['Description'].toLowerCase();
    const client = item['Client'].toLowerCase();
    const project = item['Project'].toLowerCase();
    const searchTerm = term.toLowerCase().split(' ');
    let found = 0;
    for (const key of searchTerm) {
      if (
        description.includes(key) ||
        clientName.includes(key) ||
        project.includes(key) ||
        client.includes(key.toLowerCase())
      ) {
        found = 1;
      } else {
        found = 0;
        break;
      }
    }
    return found;
  }

  // Calls apply function when apply button is clicked
  applyButtonClicked(): void {
    this._timeDicerConfigService.applyChanges(this.selectedConsultants, this.selectedProjects);
  }

  // Applies selected Bookmark configuration 
  manageConfigbuttonClicked(): void {
    this._timeDicerConfigService.announceManageButtonConfigClicked(
      this.selectedConsultants,
      this.selectedProjects
    );
    this._timeDicerConfigService.manageConfig(this.selectedProjects, this.selectedConsultants);
  }

  // Get Project Details 
  getProject(): void {
    this._projectService
      .getProjects(
        false,
        this.currentUser.Employee,
        this.weekStart,
        this.weekEnd
      )
      .subscribe((projectList) => {
        this.projectList = projectList;
      });
  }

  OnChange(event: any) {
    // Manually refocus the input field
    setTimeout(() => {
      this.ngSelectComponent.focus();
    }, 0);
    console.log(this.projectList)
  }
}
