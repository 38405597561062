<!--mat-tab-group dynamicHeight>
    <mat-tab label="Add Client" class="h-full"> 
<div class="title-container text-center py-3">
  <h2 class="text-2xl font-bold page-title">Add Client</h2>
</div> -->
<form [formGroup]="clientForm" id="clientForm" class="formSection pt-3 mb-3 flex flex-col gap-4" >
    <div class="form-row flex flex-col md:flex-row gap-3">
        <div
            class="relative w-full border-b border-gray-400"
            style="height: 30px"
            >
                <input
                    id="clientCode"
                    matInput
                    maxlength="4"
                    formControlName="clientCode"
                    type="text"
                    class="dialogInput w-full"
                    [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '14px'} }"
                    required
                />
                <span
                    class="floating-label absolute left-0 pointer-events-none"
                    [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '16px'} }"
                    >Client Code</span
                >
        </div>

        <div
            class="relative w-full border-b border-gray-400"
            style="height: 30px"
            >
                <input
                    id="clientName"
                    matInput
                    formControlName="clientName"
                    type="text"
                    class="dialogInput w-100"
                    [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '14px'} }"
                    required
                />
                <span
                    class="floating-label absolute left-0 pointer-events-none"
                    [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '16px'} }"
                    >Client Name</span
                >
        </div>
        <div class="form-field w-full flex items-center">
            <label for="internal" class="form-label">Internal</label>
            
                <input id="internal" type="checkbox" formControlName="internal" class="input-field relative border-b border-gray-400 flex flex-row gap-3 items-center pb-0" />
            
        </div>
    </div>
</form>

<div class="flex flex-row gap-5 justify-end pr-3">
    <!-- Button Container -->
    <div class="button-container flex flex-row gap-5">
        <!-- Add Client Button -->
        <button mat-raised-button type="submit" form="clientForm" (click)="onSubmit()" [disabled]="checkDisability()" 
            [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
            class="submit-button flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200 min-h-0 mb-1 w-full">
            <div class="flex items-center justify-center gap-2 w-full h-full">
                <mat-icon class="" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">add</mat-icon>
                <div class="font-sans flex items-center"  [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Add Client</div>
            </div>
        </button>

        <!-- Clear Button -->
        <button mat-raised-button type="button" form="clientForm" (click)="onClear()" 
            [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
            class="clear-button flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200 min-h-0 mb-1 w-full">
            <div class="flex items-center justify-center gap-2 w-full h-full">
                <mat-icon class="" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">clear</mat-icon>
                <div class="font-sans flex items-center"  [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Clear</div>
            </div>
        </button>
    </div>
</div>

<div class="search-bar-container mb-2 flex items-center justify-between gap-5">
    <!-- Search Input -->
    <input
      type="text"
      placeholder="Search Name or Code..."
      (input)="onSearch($event)"
      class="search-bar-input p-2 border border-gray-300 rounded flex-grow"
    />
  
    <!-- Save Button -->
    <button mat-raised-button type="button" form="clientForm" (click)="onSave()" [disabled]="!isSaveButtonEnabled"
      [appDynamicStyles]="{
        'height': { zoomout: '40px', zoomin: '30px' },
        'padding': { zoomout: '8px 12px 8px 8px', zoomin: '4px 8px 4px 4px' }
      }"
      class="save-button flex items-center justify-center disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer font-semibold rounded-md hover-effect hover:shadow-md transition-shadow duration-200 min-h-0 mb-1"
      style="margin-top: 2px;"
    >
      <div class="flex items-center justify-center gap-2 w-full h-full">
        <mat-icon [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}" >save</mat-icon>
        <div class="font-sans flex items-center" [appDynamicStyles]="{'font-size': { zoomout: '16px', zoomin: '14.5px' }, 'height': { zoomout: '28px', zoomin: '14.5px' }}">Save</div>
      </div>
    </button>
  </div>

<div class="flex-col flex-grow gap-5 h-full mb-2">
    
    <ag-grid-angular
      #agGrid
      class="ag-theme-balham"
      style="width: 100%; height: 370px;"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      rowSelection="multiple"
      (cellValueChanged)="onCellValueChanged($event)"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
    >
    </ag-grid-angular>
</div>
<!-- </mat-tab>
</mat-tab-group> -->