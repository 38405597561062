import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { ProjectDetailsService } from '../../project-details.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridDatePickerComponent } from 'app/core/mips/common/custom-components/ag-grid-datepicker-component';
import { ProjectDetailsGridConfigModel } from 'app/core/mips/common/models/project-details-grid-config.model';

@Component({
  selector: 'app-theme-allocation-dailog',
  templateUrl: './theme-allocation-dailog.component.html',
  styleUrls: ['./theme-allocation-dailog.component.scss']
})
export class ThemeAllocationDailogComponent implements OnInit{
  componentContext: any;
  formContext: any;
  themeAllocationRowData: any[];
  themeAllocationColumnDefs: any[];
  prepaymentDetailasRowData: any[];
  prepaymentDetailasColumnDefs: any[];
  gridOptions: GridOptions;
  rowSelection: string;
  gridIndex: number = 0;
  agGridColumnAPI: ColumnApi;
  agGridAPI: GridApi
  gridType: string;
  mode: any;
  projectDetailsRowData: any
  private isEditing = false;
  components = {
    'GridDatePickerComponent': GridDatePickerComponent
  };
  constructor(
    public dialogRef: MatDialogRef<ThemeAllocationDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, //here data is the data we pass when we call the dialog
    private cdr: ChangeDetectorRef,
    private service: ProjectDetailsService
  ) {
    this.gridOptions = data.gridOptions;
    this.rowSelection = data.rowSelection;
    this.componentContext = data.projectDetailsContext; //Context for projectDetails Component.ts to utilise its functions and properties
    this.formContext = data.formlayoutContext; //Context for formLayout Component.ts to utilise its functions and properties
    this.gridType = data.gridType;
    this.mode = data.mode;

    let projectDetailsGridConfig = new ProjectDetailsGridConfigModel();
    this.service.projectDetailTableRowData.subscribe((data) => {
      this.projectDetailsRowData = data;
      if (this.mode == 'add' || this.mode == 'edit') {
        this.themeAllocationColumnDefs = this.componentContext.createGridColumnDefs(
          projectDetailsGridConfig.themeAllocationColumns
        );
        this.prepaymentDetailasColumnDefs = this.componentContext.createGridColumnDefs(
          projectDetailsGridConfig.prepaymentDetailsColumns
        );
        this.themeAllocationRowData = this.projectDetailsRowData.projectThemeAllocation;
        this.prepaymentDetailasRowData = this.projectDetailsRowData.projectPrepaymentDetails;
      }
    });
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  onCellEditingStarted(event: any) {
    this.isEditing = true;
  }

  onCellEditingStopped(event: any) {
    this.isEditing = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.isEditing && !this.isClickInsideGrid(event)) {
      this.agGridAPI.stopEditing();
    }
  }

  private isClickInsideGrid(event: MouseEvent): boolean {
    const gridElement = document.querySelector('.ag-theme-alpine');
    return gridElement?.contains(event.target as Node) ?? false;
  }

  // Bind the functions to the component context
  onCellValueChanged(event: any) {
    event.data.isModified = true;
    event.node.data["dirty"] = true;
    event.api.applyTransaction({
      update: [event.node.data],
    })
    event.api.redrawRows({ rowNodes: [event.node] });
    const { data, colDef, rowIndex, oldValue } = event;
    let cellKey = '';

    if(this.gridType == "themeAllocation") {
      cellKey = `${data.Theme}_${data.Allocation_Percent}_${colDef.field}`;
    }

    this.service.originalRowData[cellKey] = oldValue;
  }

  onSelectionChanged(event: any) {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedRows = selectedNodes.map(node => node.data);
    if (selectedNodes.length > 0) {
      this.service.deletebuttonsEnable(event.api.getValue('GridName', event.api.getRowNode('0').toString()))
    } else {
      this.service.deletebuttonsDisable(event.api.getValue('GridName', event.api.getRowNode('0').toString()))
    }
  }

  onGridReady(event: any) {
    this.agGridAPI = event.api;
    this.agGridColumnAPI = event.columnApi;
    if(this.gridType == "themeAllocation")
      this.service.agGrids["themeAllocation"] = event.api;
    else if(this.gridType == "prepaymentDetails")
      this.service.agGrids["prepaymentDetails"] = event.api;
    this.componentContext.gridIndex += 1;
    this.autoSizeAllColumns();
  }

  autoSizeAllColumns(): void {
    const allColumnIds: string[] = [];
    this.agGridColumnAPI.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
  }

  onSaveClickTA(){
    this.service.updateThemeAllocation().then(() => {
      this.service.savebuttonsDisable('TA');
    });
  }

  onDeleteClick(){
    this.service.deleteThemeAllocation().then(() => {
      this.service.deletebuttonsDisable('TA');
    });
  }

  onSaveClickPD(){
    this.service.updatePrepaymentDetails().then(() => {
      this.service.savebuttonsDisable('PD');
    })
  }

  checkSubmit(buttonName, buttonType, buttonPage){
    return this.service.submitHandler(buttonName, buttonType, this.formContext.form.valid, this.formContext.getFormData())
  }
}