import { Constants } from "app/core/mips/common/utilities/constants.config"
import { URLPaths } from "app/core/mips/common/utilities/urlPaths.config"
import { Shortcut } from "app/core/types/nav-shortcuts.types"

export const shortcuts: Array<Shortcut> = [
    {
        id          : 'flag',
        label       : Constants.NAV_FLAG,
        link        : URLPaths.ROUTE_FLAG_ACTUALS,
        icon        : 'flag',
        isBookmark  : false
    },
    {
        id          : 'timeEntry',
        label       : Constants.NAV_TIME_ENTRY_ACTUALS,
        link        : URLPaths.ROUTE_TIME_ENTRY_ACTUALS,
        icon        : 'access_time',
        isBookmark  : false
    },
    {
        id          : 'expenseEntry',
        label       : Constants.NAV_EXPENSE_ENTRY,
        link        : URLPaths.ROUTE_EXPENSE_ENTRY_ACTUALS,
        icon        : 'attach_money',
        isBookmark  : false
    },
    {
        id          : 'timeDicer',
        label       : Constants.NAV_TIME_DICER,
        link        : URLPaths.ROUTE_TIME_DICER_ACTUALS,
        icon        : 'pie_chart',
        isBookmark  : false
    }
]