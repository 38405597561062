<!-- Maintenance Page -->
<div class="flex flex-col justify-center items-center h-screen bg-neutral-100">
    <div class="pb-7">
        <img src="../../../assets/images/logo/mips-logo-simple.png" class="w-32">
    </div>
    <div class="font-sans text-lg font-normal text-center pb-3">
        {{ bigMessage }}
    </div>
    <div class="font-sans text-base font-light font-color text-center">
        {{ smallMessage1 }}<br>{{ smallMessage2 }} 
    </div>
</div>