export class FlagEntryModel {
    flagPeriod: string;
    flagType: string;
    entryDate: string;
    startDate: string;
    employee: string;
    employeeMail: string;
    loginName: string;
    CurrCode: string;
}
