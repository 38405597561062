import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { URLPaths } from '../utilities/urlPaths.config';
import {ProjectModel} from '../models/project.model';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SnackbarService} from './snackbar.service';
import {ErrorHandlerService} from './error-handler.service';
import {ExpenseProjectModel} from '../models/expense-project.model';
import { ApiheaderService } from './apiheader.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projects: ProjectModel[] = null;
  private expenseProjects: ExpenseProjectModel[] = null;
  private sortedProjectList: any = null;
  private employeeProjMap: Map<string, ProjectModel[]> = new Map<string, ProjectModel[]>();
  private employeeExpProjMap: Map<string, ExpenseProjectModel[]> = new Map<string, ExpenseProjectModel[]>();

  constructor(
        private http: HttpClient,
        private snackbarService: SnackbarService,
        private errorHandlerService: ErrorHandlerService
  ) { }

  //Get specific project details
  getProjects(forceReload: boolean = false, employee: string, startDate: string, endDate: string): Observable<ProjectModel[]> {
    let params = new HttpParams();
    params = params.append('employee', employee);
    params = params.append('startdate', startDate);
    params = params.append('enddate', endDate);
    if (forceReload || !this.employeeProjMap.has(employee)) {
        return this.http.get(URLPaths.URL_PROJECT, { headers: ApiheaderService.getHeaders(), params: params })
            .pipe(
                map(res => {
                    this.projects = res as ProjectModel[];
                    this.sortedProjectList = this.projects.sort((a, b) => {
                        // if (a.Charge === 'yes' && b.Charge === 'no') {
                        //     return -1;
                        // } else {6
                        if (Number(a.Frequency) > Number(b.Frequency)) {
                            return -1;
                        } else if (Number(a.Frequency) < Number(b.Frequency)) {
                            return 1;
                        }
                        else {
                            if (a.Charge.toLowerCase() === 'yes' && b.Charge.toLowerCase() === 'no') {
                                return -1;
                            } else if (a.Charge.toLowerCase() === 'no' && b.Charge.toLowerCase() === 'yes') {
                                return 1;
                            } else {
                                if (a.Description.toLowerCase() < b.Description.toLowerCase()) {
                                    return -1;
                                } else if (a.Description.toLowerCase() > b.Description.toLowerCase()) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    });
                    for (const proj of this.sortedProjectList) {
                        proj.Display_String = proj.Description + ' (' + proj.Project + ') - ' + proj.ClientName + ' (' + proj.Client + ')';
                        if (Number(proj.IsPlannedorFrequent) === 1) {
                            proj.IsPlannedorFrequent = 'Planned/ Frequently Used Projects';
                            continue;
                        }
                        proj.IsPlannedorFrequent = 'Other Projects';
                    }
                    this.employeeProjMap.set(employee, this.sortedProjectList as ProjectModel[]);
                    return this.employeeProjMap.get(employee);
                    // return this.sortedProjectList;
                }),
                catchError(this.errorHandlerService.handleError.bind(this))
            );
    } else {
        return of(this.employeeProjMap.get(employee));
    }
}

  //Get specific project details
  getExpenseProject(forceReload: boolean = false, employee: string, startDate: string, endDate: string): Observable<ExpenseProjectModel[]> {
      let params = new HttpParams();
      params = params.append('employee', employee);
      params = params.append('startdate', startDate);
      params = params.append('enddate', endDate);

      if (forceReload || !this.employeeProjMap.has(employee)) {
          return this.http.get(URLPaths.URL_EXPENSE_PROJECT, { headers: ApiheaderService.getHeaders(), params: params})
              .pipe(
                  map(res => {
                      this.expenseProjects = res as ExpenseProjectModel[];
                      // this.sortedProjectList = this.expenseProjects.sort((a, b) => {
                      this.expenseProjects.sort((a, b) => {
                          if (Number(a.ExpenseFrequency) > Number(b.ExpenseFrequency)) {
                              return -1;
                          }
                          else if (Number(a.ExpenseFrequency) < Number(b.ExpenseFrequency)) {
                              return 1;
                          }
                          else {
                              if (Number(a.TimeFrequency) > Number(b.TimeFrequency)) {
                                  return -1;
                              }
                              else if (Number(a.TimeFrequency) < Number(b.TimeFrequency))
                              {
                                  return 1;
                              }
                              else {
                                  if (a.Charge.toLowerCase() === 'yes' && b.Charge.toLowerCase() === 'no') {
                                      return -1;
                                  } else if (a.Charge.toLowerCase() === 'no' && b.Charge.toLowerCase() === 'yes') {
                                      return 1;
                                  } else {
                                      if (a.Description.toLowerCase() < b.Description.toLowerCase()) {
                                          return -1;
                                      } else if (a.Description.toLowerCase() > b.Description.toLowerCase()) {
                                          return 1;
                                      } else {
                                          return 0;
                                      }
                              }
                          }
                          }
                      });
                      for (const proj of this.expenseProjects) {
                          proj.Display_String = proj.Description + ' (' + proj.Project + ') - ' + proj.ClientName + ' (' + proj.Client + ')';
                          if (Number(proj.IsFrequent) === 1){
                              proj.IsPlannedorFrequent = 'Frequently Used Projects';
                              continue;
                          }
                          proj.IsPlannedorFrequent = 'Other Projects';
                      }
                      this.employeeExpProjMap.set(employee, this.expenseProjects as ExpenseProjectModel[]);

                      return this.employeeExpProjMap.get(employee);

                  }),
                  catchError(this.errorHandlerService.handleError.bind(this))
              );
      } else {
          return of(this.employeeExpProjMap.get(employee));
      }
  }

  
}
