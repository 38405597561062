import { Component, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { FuseAlertType } from '@fuse/components/alert';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AuthenticationService } from './authentication.service';
import { SnackbarService } from 'app/core/mips/common/services/snackbar.service';
import { commonFunctions } from 'app/core/mips/common/classes/common.class';
import { UserModel } from 'app/core/mips/common/models/user.model';
import { ConsultantDetailsService } from 'app/core/mips/common/services/consultant-details.service';
import { ConsultantModel } from 'app/core/mips/common/models/consultant.model';
import { FlagEntryService } from 'app/core/mips/common/services/flag-entry.service';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';
import { Router } from '@angular/router';
import { UserService } from 'app/core/mips/common/services/user.service';
import { Store } from '@ngrx/store';
import { setLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.action';
import { setUser } from 'app/core/store/currentUser/user.actions';
import { LocalStorageService } from 'app/core/mips/common/services/local-storage.service';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit
  {
    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    signInForm: UntypedFormGroup;
    showAlert: boolean = false;
    userName: string;
    password: string;
    redirectUrl: string;

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _cdRef: ChangeDetectorRef,
        private _snackbarService: SnackbarService,
        private _authenticationService : AuthenticationService,
        private _router: Router,
        private _store: Store,
        private _renderer: Renderer2
    )
    {  
      const element = document.querySelector(".mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper");
        if(element){
          this._renderer.setStyle(element, 'display', 'none', 1);
        }
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Create the form
        this.signInForm = this._formBuilder.group({
            username   : ['', [Validators.required, this.validateStringWithDot]],
            password   : ['', Validators.required],
        });
    }

    checkAutofill(): void {
      this.signInForm.patchValue({
        username: this.signInForm.get('username').value,
        password: this.signInForm.get('password').value,
      });
    }

    setCurrentUser(user: ConsultantModel) {
      this._store.dispatch(setUser({ user }));
    }
    
    setLoggedInUser(user: UserModel ) {
      this._store.dispatch(setLoggedInUser({ user }));
    }
      
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    validateStringWithDot(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        const regex = /^[a-zA-Z]+\.[a-zA-Z]+$/; // regular expression to match two strings separated by a dot
        return regex.test(value) ? null : { invalidString: true };
    }
      
    /**
     * Sign in
     */
    
    // signIn = () => {
    //     const signInButton = document.getElementById('signInButton');
    //     signInButton.setAttribute('disabled', 'true');
    //     let currentUser : ConsultantModel;
    //     let authenticateduserProfilePhoto : any; 
    //     this._cdRef.detectChanges();
    //     if (this.userName && this.password && this.userName !== '' && this.password !== '') {
    //         this._snackbarService.showSnackBar(Constants.SNACKBAR_MSG_LOGIN_CONTACTING_CONTROLLER, null, 'info', true);
    //         this._authenticationService.login(this.userName, this.password).subscribe((apiKey)=>{
    //           this._snackbarService.showSnackBar(Constants.SNACKBAR_MSG_LOGIN_VERIFYING_CREDENTIALS, null, 'info', true);
    //           LocalStorageService.setLocalStorageValueBasedOnKey('API_KEY', apiKey);
    //             let authenticatedUserDetails  : any= {};
    //              this._authenticationService.getUser().subscribe((loggedInUserDetails)=>{
    //                 this._snackbarService.showSnackBar(Constants.SNACKBAR_MSG_LOGIN_RETRIEVING_DETAILS, null, 'info', true);
    //                 authenticatedUserDetails = loggedInUserDetails;
    //                 const updatedAuthenticatedUserDetails = {
    //                   ...authenticatedUserDetails,
    //               };
    //               authenticatedUserDetails = updatedAuthenticatedUserDetails;
    //               this.setLoggedInUser(authenticatedUserDetails);
    //               this._consultantDetailsService.getConsultantDetails().subscribe((consultantDetails) => {
    //                   currentUser = consultantDetails.filter((consultant) => {
    //                       return consultant.LoginName.toLowerCase() === authenticatedUserDetails.username.toLowerCase();
    //                   })[0];
    //                   const updatedCurrentUserDetails = {
    //                       ...currentUser,
    //                   };
    //                   this.setCurrentUser(updatedCurrentUserDetails);
    //                   if (commonFunctions.isNullOrUndefined(this.redirectUrl)) {
    //                       this.redirectUrl = URLPaths.ROUTE_TIME_ENTRY_ACTUALS;
    //                   }
    //                   this._snackbarService.dismissSnackBar();
    //                   this._router.navigate([this.redirectUrl]);
    //               }, (error) => {
    //                   this._snackbarService.showSnackBar(error.error['detail'], 'close', 'failure');
    //                   signInButton.removeAttribute('disabled');
    //               });
    //             },
    //             (error) => {
    //                 this._snackbarService.showSnackBar(error.error['detail'], 'close', 'failure');
    //                 signInButton.removeAttribute('disabled');
    //             });
    //         },
    //         (error) => {
    //             if(error.error['detail']){
    //               this._snackbarService.showSnackBar(error.error['detail'], 'close', 'failure');
    //               signInButton.removeAttribute('disabled');
    //             }
                
    //         }
            
    //         )
    //     }
    // }

    signIn = () => {

      const signInButton = document.getElementById('signInButton');
        signInButton.setAttribute('disabled', 'true');
        let currentUser : ConsultantModel;
        this._cdRef.detectChanges();
        if (this.userName && this.password && this.userName !== '' && this.password !== '') {
            this._snackbarService.showSnackBar(Constants.SNACKBAR_MSG_LOGIN_CONTACTING_CONTROLLER, null, 'info', true);
            this._authenticationService.login_optimized(this.userName, this.password).subscribe((login_data)=>{
              LocalStorageService.setLocalStorageValueBasedOnKey('API_KEY', login_data.api_key);
              let authenticatedUserDetails  : any= {};
              authenticatedUserDetails = login_data.user_details;
              const updatedAuthenticatedUserDetails = {
                  ...authenticatedUserDetails,
              };
              authenticatedUserDetails = updatedAuthenticatedUserDetails;
              this.setLoggedInUser(authenticatedUserDetails);
              currentUser = login_data.consultant_details;
              const updatedCurrentUserDetails = {
                              ...currentUser,
              };
              this.setCurrentUser(updatedCurrentUserDetails);
              if (commonFunctions.isNullOrUndefined(this.redirectUrl)) {
                  this.redirectUrl = URLPaths.ROUTE_TIME_ENTRY_ACTUALS;
              }
              this._snackbarService.dismissSnackBar();
              this._router.navigate([this.redirectUrl]);
          
            }, (error) => {
              this._snackbarService.showSnackBar(error.error['detail'], 'close', 'failure');
              signInButton.removeAttribute('disabled');
          });
       }
    }
  }
