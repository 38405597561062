import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LandingComponent } from './landing/landing.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { pageRoutes } from './page.routing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    LandingComponent,
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(pageRoutes)
  ]
})
export class PagesModule { }
