<div *ngIf="!isLoaded" class="fuse-splash-screen">
    <img
        src="assets/images/logo/mips-logo-simple.png"
        alt="">
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>

<div
    *ngIf="isLoaded"
    class="flex flex-row h-full">

    <!-- Loading bar -->
    <fuse-loading-bar class="loader"></fuse-loading-bar>

    <!-- Navigation -->
    <fuse-vertical-navigation
        class="bg-gray-800 text-white print:hidden"
        
        [mode]="isScreenSmall ? 'over' : 'side'"
        [name]="'mainNavigation'"
        [navigation]="navigation"
        [opened]="!isScreenSmall">

        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationHeader>
            <div class="flex items-center w-full p-4 bg-gray-900 justify-between">

                <!-- MIPS Logo -->
                <div class="flex items-center justify-center gap-3">
                    <img
                        class="w-6 h-6"
                        src="assets/images/logo/mips-logo-simple.png"
                        alt="Logo image">
                    <span class="text-center h-5 text-lg font-light tracking-wide leading-6 text-white text-opacity-70 font-sans">
                        MIPS 2.0
                    </span>
                </div>

                <!-- Navigation toggle button -->
                <div class="flex items-center justify-center">
                    <button
                        class="sm:inline md:hidden flex items-center justify-center"
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
                    </button>
                </div>
            </div>

            <!-- User -->
            <div *ngIf="user" class="flex flex-row bg-gray-900 relative gap-2 pb-4">
                <div class="pl-5 rounded-full z-10">
                    <img
                        *ngIf="loggedInUserImage"
                        class="w-13 h-13 rounded-full"
                        [src]="loggedInUserImage | urlSanitizer"
                        alt="User avatar">
                </div>
                <div class="flex flex-col place-content-center">
                    <div class="font-normal text-white font-sans textleading-6 z-10" [appDynamicStyles]="{ 'font-size' : {zoomout: '14px', zoomin: '13px'} }">
                        {{ user.first_name }}
                    </div>
                    <div class="font-normal text-white text-opacity-50 font-sans textleading-6 z-10" [appDynamicStyles]="{ 'font-size' : {zoomout: '11px', zoomin: '10px'} }">
                        Data & AI Consultant
                    </div>
                </div>
            </div>
        </ng-container>
    </fuse-vertical-navigation>

    <!-- Wrapper -->
    <div class="flex flex-col flex-auto w-full min-w-0 bg-neutral-100" style="height: 100vh !important;">
        <!-- Header -->
        <div
            class="sticky top-0 flex flex-0 items-center w-full px-2 md:px-3 z-99 shadow dark:shadow-none dark:border-b print:hidden bg-neutral-100" style="height: 8%;">

            <!-- Navigation toggle button -->
            <div class="mt-2 mr-2 ">
                <button
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:menu'" class="w-5 h-5 min-h-5 min-w-5"></mat-icon>
                </button>
            </div>

            <!-- Shortcuts Component -->
            <app-nav-shortcuts></app-nav-shortcuts>

            <!-- Flag Reminder Message -->
            <div *ngIf="flagReminderMsg !== null" class="flag-missing-font ml-4">
                <b>Flag(s) missing for Week/Month!</b>
            </div>

            <!-- User and Country Components -->
            <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 h-full">
                <app-nav-user></app-nav-user>
                <div class="toolbar-separator"></div>
                <app-nav-user-country></app-nav-user-country>
            </div>
        </div>

        <!-- Content -->
        <div
            class="w-full flex flex-col box-border gap-1 content-main"
            style="height: calc(100% - 70px);"
            [appDynamicStyles]="{ 'padding': { zoomout: '12px', zoomin: '8px' }}">

            <!-- Read Only Mode Component - to be called only during planning meeting -->
            <div *ngIf = "isWriteSilence">
                <app-read-only-mode></app-read-only-mode>
            </div>

            <!-- Quick Mobile Actions -->
            <div class="block tb:hidden">
                <div *ngIf="showMobileSettings && mobileHeaderItems[currentURL]">
                    <app-settings [pageType]="mobileHeaderItems[currentURL]"></app-settings>
                </div>
            </div>

            <!-- Other MIPS Components -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>
</div>