<div class="flex flex-col gap-3 h-full">

  <!-- Display Week Flag, Month Flag and Notifications -->
  <div class="flex flex-wrap tb:flex-nowrap tb:flex-row gap-4 justify-center">
    <div
      *ngFor="let item of cards"
      class="card-dimensions"
      [appDynamicStyles]="{ 'height': {zoomout: '210px', zoomin: '180px'} }"
      [ngStyle]="{ order: item.displayOrder }"
    >
      <app-flag-container
        [card]="item"
        [matOptionsValue]="matOptionsValue"
        [checkFlagDeadline]="checkFlagDeadline"
      ></app-flag-container>
    </div>
  </div>

  <!-- Summary Grid -->
  <div class="h-full">
    <app-flag-summary [matOptionsValue]="matOptionsValue"></app-flag-summary>
  </div>
  
</div>
