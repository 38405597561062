import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';
import { Store } from '@ngrx/store';
import { TimeEntryService } from './time-entry.service';
import { Constants } from '../utilities/constants.config';
import { DateUtility } from '../utilities/date.utility';
import { DatePipe } from '@angular/common';
import { TimeEntryModel } from '../models/TimeEntry.model';
import { ConsultantModel } from '../models/consultant.model';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { GridApi } from 'ag-grid-community';
import { getTimeDateRange } from 'app/core/store/timeDateRangeSelection/timeDateRangeSelection.selectors';
import { ExpenseEntryService } from './expense-entry.service';
import { ExpenseEntryModel } from '../models/ExpenseEntry.model';
import { getExpenseDateRange } from 'app/core/store/expenseDateRangeSelection/expenseDateRangeSelection.selectors';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class EntryTableService {

  weekStart: string;
  weekEnd: string;
  dateEntryCountHashMap = new Map<string, number>();
  currentUser: ConsultantModel;
  agGridAPI: GridApi;
  consultant: string;
  entryService: any;
  deletedExpenseEntries: ExpenseEntryModel[] = [] as ExpenseEntryModel[];
  entryModel: any;
  getDateRange: any;
  entryPageType: string;
  beginLogoutAction: boolean = false;

  constructor(
    private _snackBarService: SnackbarService,
    private _store: Store,
    private _timeEntryService: TimeEntryService,
    private _expenseEntryService: ExpenseEntryService,
    public datePipe: DatePipe,
    private _logoutService: LogoutService
  ) {
    this._logoutService.beginLogoutAction.subscribe((data) => {
      this.beginLogoutAction = data;
    })
    this._store.select(getCurrentUser).subscribe((data) => {
      if (!this.beginLogoutAction) {
        this.currentUser = data;
        this.consultant = data['Employee'];
      }
    })
  }

  //Set entry page type to Time or Expense
  setEntryPageType(entryPageType) {
    this.entryPageType = entryPageType;
    switch (entryPageType) {
      case Constants.NAV_TIME_ENTRY_ACTUALS:
        this.getDateRange = getTimeDateRange;
        this.entryService = this._timeEntryService;
        this.entryModel = TimeEntryModel;

        break;
      case Constants.NAV_EXPENSE_ENTRY:
        this.entryService = this._expenseEntryService;
        this.entryModel = ExpenseEntryModel;
        this.getDateRange = getExpenseDateRange;
        break;
    }
    this._store.select(this.getDateRange).subscribe((data) => {
      if (data) {
        this.weekStart = data["startDate"];
        this.weekEnd = data["endDate"];
      }
    })
  }

  //Set the Entry Count Hashmap store
  setDateEntryCountHashMap(dateEntryCountHashMap) {
    this.entryService.entryCountHashMap.next(dateEntryCountHashMap);
  }

  //Set boolean whether if any unsaved changes are present
  setEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
    this.entryService.entryIsUnsavedChangesPresent.next(isUnsavedChangesPresent);
  }

  //Set the Actual time entries
  setTimeEntryActualsAndChanged(actualsAndChangedTimeEntries) {
    this._timeEntryService.entryActualsAndChanged.next(actualsAndChangedTimeEntries);
  }

  //Set the planned time entries
  setTimeEntryPlanned(plannedTimeEntries) {
    this._timeEntryService.entryPlanned.next(plannedTimeEntries);
  }

  //Retrieve the Table entries for Time and Expense
  getEntryTableEntries(agGridAPI: GridApi, entryPageType: string): void {
    this.setEntryPageType(entryPageType);
    this._snackBarService.showSnackBar(Constants.SNACKBAR_MSG_WAITING_FOR_API_RESPONSE, null, 'info');
    this.entryService.entryCountHashMap.next(null);
    this.dateEntryCountHashMap.clear();
    this.setEntryIsUnsavedChangesPresent(false);
    if (entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.entryService.getTimeEntries(this.weekStart, this.weekEnd, this.consultant).subscribe(values => {
        for (let day = DateUtility.createDateTypefromDate(this.datePipe.transform(this.weekStart, 'yyyy-MM-dd'));
          day <= DateUtility.createDateTypefromDate(this.datePipe.transform(this.weekEnd, 'yyyy-MM-dd'));
          day.setDate(day.getDate() + 1)) {
          this.dateEntryCountHashMap.set(DateUtility.formatStringfromDate(day).substr(0, 10), 0);
          this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
        }
        agGridAPI.setRowData(null);
        for (const value of values) {
          value.status = 'clean';
          if (value.Version === 'ACTUAL') {
            const count = this.dateEntryCountHashMap.get(value.TDate.substr(0, 10));
            this.dateEntryCountHashMap.set(value.TDate.substr(0, 10), (count + 1));
            this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
            agGridAPI.applyTransaction({ add: [value] });
          }
        }
        this.setTimeEntryPlanned(values.filter((obj) => {
          return obj.Version === 'PLAN';
        }));
        this.setTimeEntryActualsAndChanged(values.filter((obj) => {
          return obj.Version !== 'PLAN';
        }));
        this.addEmptyRowsforMissingTDates(agGridAPI);
      });
    }
    else if (entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      this.entryService.getExpensesEntries(this.weekStart, this.weekEnd, this.consultant).subscribe(values => {
        this.deletedExpenseEntries = [] as ExpenseEntryModel[];
        for (let day = DateUtility.createDateTypefromDate(this.datePipe.transform(this.weekStart, 'yyyy-MM-dd'));
          day <= DateUtility.createDateTypefromDate(this.datePipe.transform(this.weekEnd, 'yyyy-MM-dd'));
          day.setDate(day.getDate() + 1)) {
          this.dateEntryCountHashMap.set(DateUtility.formatStringfromDate(day).substr(0, 10), 0);
          this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
        }
        agGridAPI.setRowData(null);
        for (const value of values) {
          value.status = 'clean';
          const count = this.dateEntryCountHashMap.get(value.TDate.substr(0, 10));
          this.dateEntryCountHashMap.set(value.TDate.substr(0, 10), (count + 1));
          this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
          agGridAPI.applyTransaction({ add: [value] });
        }
        this.addEmptyRowsforMissingTDates(agGridAPI);
      });
    }
  }

  //Add empty rows where there are no actual entries
  addEmptyRowsforMissingTDates(agGridAPI: GridApi): void {
    this.dateEntryCountHashMap.forEach((value: number, key: string) => {
      if (value === 0) {
        this.addEmptyRowForEntryDate(key, agGridAPI);
      }
    });
  }

  //Add empty rows where there are no actual entries
  addEmptyRowForEntryDate(entryDate: string, agGridAPI: GridApi): void {
    this.dateEntryCountHashMap.set(entryDate, -1);
    this.setDateEntryCountHashMap(this.dateEntryCountHashMap);
    const dummyRow = new this.entryModel();
    dummyRow['TDate'] = DateUtility.formatStringfromDate(DateUtility.createDateTypefromDate(entryDate));
    if (this.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      dummyRow['Invoice_Date'] = DateUtility.formatStringfromDate(DateUtility.createDateTypefromDate(entryDate));
    }
    else if (this.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      dummyRow['Invoice_date'] = DateUtility.formatStringfromDate(DateUtility.createDateTypefromDate(entryDate));
    }
    dummyRow['Employee'] = this.currentUser['Employee'];
    dummyRow['status'] = 'dummy';
    agGridAPI.applyTransaction({ add: [dummyRow] });
  }
}
