import { Component, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form-baselayout',
  templateUrl: './form-baselayout.component.html',
  styleUrls: ['./form-baselayout.component.scss']
})
export class FormBaselayoutComponent {
  @Input() formBlueprint: any;
  @Input() fieldDetails: any;
  @Input() mode: string;
  @Input() service: any;
  @Input() projectDetailsStyle: any;
  @Input() fieldValuesOfAllForms: BehaviorSubject<any>;
  @Input() selectedIndex: number;
  @Input() rowData: any = [];
  @Input() columnDefs: any = [];
  @Input() gridOptions: GridOptions = {};
  @Input() rowSelection: string;
  @Input() projectDetailsContext: any;
  @Input() disabled: boolean;
  @Input() gridType: string;
  @Input() rowDataPD: any[];
  @Input() columnDefsPD:any[];

  ngOnInit() {
  }
}
