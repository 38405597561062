import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'urlSanitizer'
})
export class URLSanitizerPipe implements PipeTransform  {

    constructor(private sanitizer: DomSanitizer) {}

    //Check if the URL used is valid or not
    transform(url: string): SafeUrl {
        if(!url) return null;
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

}
