<!-- AG Grid for Time Entry Page -->
<mat-card-content
  *ngIf="entryPageType == 'Time'"
  class="flex flex-row entry-table h-full"
>
  <ag-grid-angular
    style="max-width: 2256px; width: 100%; "
    class="ag-theme-balham w-full"
    (cellDoubleClicked)="onCellDoubleClicked($event)"
    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    [animateRows]="true"
    [gridOptions]="gridOptions"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="true"
    [processDataFromClipboard]="processDataFromClipboard"
    [defaultColDef]="defaultColDef"
    (dragStopped)="onDragStopped()"
    (rowSelected)="onRowSelection($event)"
  >
  </ag-grid-angular>
</mat-card-content>

<!-- AG Grid for Expense Entry Page -->
<mat-card-content
  *ngIf="entryPageType == 'Expense'"
  class="flex flex-row entry-table h-full"
>
  <ag-grid-angular
    style="max-width: 2256px; width: 100%;"
    class="ag-theme-balham w-full"
    (cellDoubleClicked)="onCellDoubleClicked($event)"
    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    [animateRows]="true"
    [gridOptions]="gridOptions"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="true"
    (dragStopped)="onDragStopped()"
    [defaultColDef]="defaultColDef"
    (rowSelected)="onRowSelection($event)"
    [suppressDragLeaveHidesColumns]="true"
  >
  </ag-grid-angular>
</mat-card-content>
