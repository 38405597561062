import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ExpenseEntryModel } from '../models/ExpenseEntry.model';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { TitleCasePipe } from '@angular/common';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { commonFunctions } from '../classes/common.class';
import { GridApi } from 'ag-grid-community';
import { DateUtility } from '../utilities/date.utility';
import { CurrencyModel } from '../models/currency.model';
import { TimeEntryModel } from '../models/TimeEntry.model';
import { CurrencyService } from './currency.service';

@Injectable({
    providedIn: 'root'
})
export class ExpenseEntryService {
    agGridAPI: GridApi;
    agGridColumnAPI: any;
    dateEntryCountHashMap: any;
    columnDefs = [];
    autoGroupColumnDefs = {};
    currencyList: CurrencyModel[];
    currentCurrencySymbol: any;
    entryActualsAndChanged: BehaviorSubject<TimeEntryModel[]> = new BehaviorSubject<TimeEntryModel[]>([]);
    entryCountHashMap: BehaviorSubject<Map<string, number>> = new BehaviorSubject<Map<string, number>>(null);
    entryIsUnsavedChangesPresent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    rowDragMode: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
    entryisRowSelectedForDeletion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    entryPlanned: BehaviorSubject<TimeEntryModel[]> = new BehaviorSubject<TimeEntryModel[]>([]);
    entryTDate = DateUtility.formatStringfromDate(DateUtility.createDateType());

    constructor(
        private _http: HttpClient,
        private _titleCasePipe: TitleCasePipe,
        private _errorHandlerService: ErrorHandlerService,
        private _currencyService: CurrencyService) {
        this._currencyService.getCurrencyDetails().subscribe(currencies => {
            this.currencyList = currencies;
        });
        this.entryCountHashMap.subscribe((data) => {
            this.dateEntryCountHashMap = data;
        })
        //Column definitions for AG Grid
        this.columnDefs = [
            {
                headerName: '',
                field: 'Task_Description',
                width: 100,
                valueGetter: (params) => {
                    return '';
                },
                checkboxSelection: params => {
                    if ((params.node.group) || (params.node.data && (params.node.data.status !== 'dummy' && params.node.data.Invoice_id === 0))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                rowDrag: params => !params.node.group && params.node.data.status !== 'dummy',
                pinned: 'left'
            },
            {
                headerName: 'Entry Date',
                field: 'TDate',
                filter: 'agDateColumnFilter',
                rowGroup: true,
                hide: true,
                valueGetter: (params) => {
                    if (params.node.group) {
                        return (params.node.aggData);
                    }
                    return (params.data.TDate);
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return DateUtility.formatDateWithDayNamefromString(params.value.substr(0, 10));
                    }
                    return DateUtility.formatDatefromString(params.value.substr(0, 10));
                },
                showRowGroup: true
            },
            {
                headerName: 'Client',
                field: 'Client',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return params.data.Client + ' - ' + params.data.ClientName;

                    }
                    return null;
                }
            },
            {
                headerName: 'Project',
                field: 'Project',
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return params.data.Project + ' - ' + params.data.Project_Description;
                    }
                    return null;
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Task Name',
                field: 'Task_Name',
                valueGetter: (params) => {
                    return params.node.data ? (params.node.data.Task_Description + ' - ' + params.node.data.Task_Name) : '';
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Type',
                field: 'ExpenseType',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: ' Type Description',
                field: 'Description',
                width: 100,
                // valueGetter: (params) => {
                //     return '';
                // },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true

            },
            {
                headerName: 'Currency',
                field: 'Currency_From',
                editable: params => {
                    if (!commonFunctions.isNullOrUndefined(params.node.data) && params.node.data.status !== 'dummy' &&
                        !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0) &&
                        (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: (params) => {
                    return {
                        values: this.currencyList,
                        formatValue: (value) => {
                            if (commonFunctions.isNullOrUndefined(value.currency_code)) {
                                return value;
                            }
                            return value.currency_code;
                        }
                    };
                },
                valueSetter: (params) => {
                    if (params.oldValue !== params.newValue) {
                        (params.data.Currency_symbol_short) = params.newValue.currency_symbol_short;
                        params.data.Currency_From = params.newValue.currency_code;
                        params.data.status = 'update';

                        /* the following snippet is to make sure currency symbol is selected properly in Amount column.
                        Please feel free to replace the logic with proper ag-grid API if you come across one! */
                        // Start Snippet
                        const temporaryAmountSpent = params.data.AmountSpent;
                        params.data.AmountSpent = 1;
                        this.agGridAPI.refreshCells();
                        params.data.AmountSpent = temporaryAmountSpent;
                        this.agGridAPI.refreshCells();
                        // End Snippet

                        this.currentCurrencySymbol = params.newValue.currency_symbol_short;
                        this.setExpenseEntryIsUnsavedChangesPresent(true);
                        return true;
                    } else {
                        return false;
                    }
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Amount spent',
                field: 'AmountSpent',
                // width: 100,
                editable: params => {
                    if (!commonFunctions.isNullOrUndefined(params.node.data) && params.node.data.status !== 'dummy' &&
                        !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0) &&
                        (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return parseFloat(params.data.AmountSpent);
                    }
                    return null;
                },
                valueSetter: (params) => {
                    if (params.oldValue !== params.newValue) {
                        params.data.AmountSpent = params.newValue;
                        params.data.status = 'update';
                        this.setExpenseEntryIsUnsavedChangesPresent(true);
                        return true;
                    } else {
                        return false;
                    }
                },
                valueParser: (params) => {
                    const returnValue = +Number(params.newValue).toFixed(4);
                    if (!isNaN(returnValue)) {
                        if (returnValue > 0) {
                            return returnValue;
                        }
                    }
                    return params.oldValue;
                },
                valueFormatter: (params) => {
                    if (!params.node.group) {
                        return params.node.data.Currency_symbol_short + ' ' + params.value;
                    }
                    return params.value;

                },
                enablePivot: true,
                enableRowGroup: true

            },
            {
                headerName: 'Payment Method',
                field: 'PaymentMethod',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Amount Constant',
                field: 'Amount_Constant',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Amount Native',
                field: 'Amount_Native',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Company id',
                field: 'Company_id',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Currency From',
                field: 'Currency_From',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Currency To',
                field: 'Currency_To',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Currency Symbol',
                field: 'Currency_symbol',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Currency Symbol Short To',
                field: 'Currency_symbol_short_to',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Expense ID',
                field: 'ExpenseID',
                hide: true,
            },
            {
                headerName: 'Invoice ID',
                field: 'Invoice_id',
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return params.data.Invoice_id;
                    }
                    return null;
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Invoice Date',
                field: 'Invoice_date',
                filter: 'agDateColumnFilter',
                valueGetter: (params) => {

                    if (params.node.group) {
                        return null;
                    }
                    return (params.data.Invoice_date);
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return DateUtility.formatDatefromString(params.value.toString().substr(0, 10));
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Mileage',
                field: 'Mileage',
                editable: params => {
                    if (!commonFunctions.isNullOrUndefined(params.node.data) && params.node.data.status !== 'dummy' &&
                        !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0) && (params.data.Description === 'Mileage') &&
                        (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                valueGetter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    else {
                        if (params.data.Mileage) {
                            return (params.data.Mileage);
                        }
                        else {
                            return 0;
                        }

                    }
                },
                valueParser: (params) => {
                    if (params.node.group) {
                        return null;
                    } else {
                        const returnValue = +Number(params.newValue).toFixed(6);
                        if (!isNaN(returnValue)) {
                            return returnValue;
                        }
                        return params.oldValue;

                    }

                },
                valueSetter: (params) => {
                    if (Number(params.oldValue) !== params.newValue) {
                        params.data.Mileage = params.newValue;
                        params.data.status = 'update';
                        this.setExpenseEntryIsUnsavedChangesPresent(true);
                        return true;
                    } else {
                        return false;
                    }
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'MileageRate',
                field: 'MileageRate',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Employee',
                field: 'Employee',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Entry Owner',
                field: 'Entry_Owner_LoginName',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Period Month',
                field: 'Period_Month',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Project Sales Tax',
                field: 'Project_Sales_Tax',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'State Name',
                field: 'State_Name',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'State Code',
                field: 'State_Code',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'State ID',
                field: 'State_id',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Task ID',
                field: 'Task_id',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Version ID',
                field: 'Version_id',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },


        ];

        //Column definitions for AG Grid
        this.autoGroupColumnDefs = {
            headerName: 'Expense Description',
            width: 400,
            field: 'Details',
            editable: params => {
                if (params.node.data.status !== 'dummy' && !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0)
                    && (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                    return true;
                } else {
                    return false;
                }
            },
            valueSetter: (params) => {
                if (params.oldValue !== params.newValue) {
                    params.data.Details = params.newValue;
                    params.data.status = 'update';
                    this.setExpenseEntryIsUnsavedChangesPresent(true);
                    return true;
                } else {
                    return false;
                }
            },
        };
    }

    //Set boolean whether if any unsaved changes are present in expense page
    setExpenseEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
        this.entryIsUnsavedChangesPresent.next(isUnsavedChangesPresent);
    }

    //Set Actual time entries in Expense Page
    setExpenseEntryActualsAndChanged(actualsAndChangedTimeEntries) {
        this.entryActualsAndChanged.next(actualsAndChangedTimeEntries);
    }

    //Set Expense Entry count hashmap
    setDateExpenseEntryCountHashMap(dateEntryCountHashMap) {
        this.entryCountHashMap.next(dateEntryCountHashMap);
    }

    //Get Expense Entries
    public getExpensesEntries(startDate, endDate, employee = '', clientProjects = ''): Observable<ExpenseEntryModel[]> {

        let params = new HttpParams();
        let formattedEmployee = '';
        if (employee !== '') {
            formattedEmployee = employee.split(',').map(value => {
                return ('\'' + value + '\'');
            }).join(',');
        } else {
            formattedEmployee = '';
        }

        params = params.append('startdate', startDate);
        params = params.append('enddate', endDate);
        params = params.append('employee', formattedEmployee);
        params = params.append('clientProjects', clientProjects);

        return this._http.get<ExpenseEntryModel[]>(URLPaths.URL_EXPENSE_ENTRY, { headers: ApiheaderService.getHeaders(), params: params }).pipe(
            map(values => {

                for (const value of values) {
                    value.Employee = this._titleCasePipe.transform(value.Employee);
                    value.Client = commonFunctions.convertCase(value.Client, 1);
                    value.Project = commonFunctions.convertCase(value.Project, 1);
                }
                return values;
            }
            ),
            catchError(this._errorHandlerService.handleError.bind(this))
        );
    }

    //Save Expense Entries
    public saveEntries(expenseEntryData: ExpenseEntryModel[]): Observable<any> {

        expenseEntryData = expenseEntryData.filter(values => {
            return values.status !== 'clean' && values.status !== 'remove';
        });

        return this._http.post(URLPaths.URL_EXPENSE_ENTRY, { 'save_expense_entries': expenseEntryData }, { headers: ApiheaderService.getHeaders() })
            .pipe(
                catchError(this._errorHandlerService.handleError.bind(this)));
    }

    //Delete expense entries
    public deleteEntries(expenseEntryData: ExpenseEntryModel[]): Observable<any> {
        let params = new HttpParams();
        for (const expenseEntry of expenseEntryData) {
            params = params.append('deleteExpenseIDArray', String(expenseEntry.ExpenseID));
        }
        return this._http.delete(URLPaths.URL_EXPENSE_ENTRY + '1/', { headers: ApiheaderService.getHeaders(), params: params }).pipe(
            catchError(this._errorHandlerService.handleError.bind(this)));
    }

    //Set Entry TDate
    setEntryTDate(TDate) {
        this.entryTDate = TDate;
    }

    //Get Entry TDate
    getEntryTDate(){
        return this.entryTDate;
    }


    //Set Grid API value
    setGridApi(agGridAPI: GridApi) {
        this.agGridAPI = agGridAPI;
    }

    //Get Grid API value
    getGridApi() {
        return this.agGridAPI;
    }

    //Set Grid Column API value
    setGridColumnApi(agGridColumnAPI: any) {
        this.agGridColumnAPI = agGridColumnAPI;
    }

    //Get Grid Column API value
    getGridColumnApi() {
        return this.agGridColumnAPI;
    }

    //Update the actuals entry in AG Grid
    updateActualsAndChangedEntries(agGridAPI: GridApi): void {
        const rowData: ExpenseEntryModel[] = [];
        this.entryActualsAndChanged.next([]);
        agGridAPI.forEachNode(node => {
            if (!commonFunctions.isNullOrUndefined(node.data) && node.data.status !== 'dummy') {
                if (commonFunctions.isNullOrUndefined(node.data.ExpenseID)) {
                    node.data.ExpenseID = 0;
                }
                rowData.push(node.data);
            }
        });
        this.setExpenseEntryActualsAndChanged(rowData);
    }

    //Add Records in the AG Grid
    addRecordsToGrid(agGridAPI: GridApi, values): void {
        if (values.status !== 'bad' && values.status !== 'multiplerate') {
            values.status = 'create';
        }
        this.setExpenseEntryIsUnsavedChangesPresent(true);
        if (this.dateEntryCountHashMap.get(values.TDate.substr(0, 10)) === -1) {
            this.dateEntryCountHashMap.set(values.TDate.substr(0, 10), 1);
            agGridAPI.forEachNode(node => {
                if (!commonFunctions.isNullOrUndefined(node.data) && node.data.TDate === values.TDate && node.data.status === 'dummy') {
                    agGridAPI.applyTransaction({ remove: [node.data] });
                }
            });
        } else {
            this.dateEntryCountHashMap.set(values.TDate.substr(0, 10),
                this.dateEntryCountHashMap.get(values.TDate.substr(0, 10)) + 1);
        }
        this.setDateExpenseEntryCountHashMap(this.dateEntryCountHashMap);
        agGridAPI.applyTransaction({ add: [values] });
    }
}
