import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { URLPaths } from '../../core/mips/common/utilities/urlPaths.config';
import { AuthenticationService } from '../../../app/login/login-page/authentication.service';
import { ConsultantDetailsService } from '../../core/mips/common/services/consultant-details.service';
import { FlagEntryService } from '../../core/mips/common/services/flag-entry.service';
import { SnackbarService } from '../../core/mips/common/services/snackbar.service';
import { LocalStorageService } from '../../core/mips/common/services/local-storage.service';
import { commonFunctions } from '../../core/mips/common/classes/common.class';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { setUser } from 'app/core/store/currentUser/user.actions';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { setLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.action';
import { ConsultantModel } from '../../core/mips/common/models/consultant.model';
import { UserModel } from '../../core/mips/common/models/user.model';
import { UserService } from '../../core/mips/common/services/user.service';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  gifImagePath = URLPaths.PATH_USERSEARCH_GIF;
  bigMessage = Constants.PAGES_LANDING_BIG_TEXT;
  smallMessage = Constants.PAGES_LANDING_SMALL_TEXT_REMEMBER;
  currentUser: ConsultantModel;
  loggedInUser: UserModel;
  API_KEY: string = null;

  /*
   * Constructor
   */

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _consultantDetailsService: ConsultantDetailsService,
    private _flagEntryService: FlagEntryService,
    private _snackbarService: SnackbarService,
    private _store: Store,
    private _userService: UserService
  ) {
    this.API_KEY =
      LocalStorageService.getLocalStorageValueBasedOnKeys('API_KEY');
    this.beginAuthorisation();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this._store.select(getLoggedInUser).subscribe((data) => {
      this.loggedInUser = data;
    });
  }

  setLoggedInUser(user: UserModel) {
    this._store.dispatch(setLoggedInUser({ user }));
  }

  setCurrentUser(user: ConsultantModel) {
    this._store.dispatch(setUser({ user }));
  }

  // Authorisation of the user
  beginAuthorisation() {
    if (
      !commonFunctions.isNullOrUndefined(this.API_KEY) &&
      this.API_KEY !== ''
    ) {
      this.smallMessage = Constants.PAGES_LANDING_SMALL_TEXT_FOUND;
      this.validateToken();
    } else {
      let url = URLPaths.ROUTE_LOGIN;
      this._router.navigate([url]);
    }
  }

  // Validate the API Key
  validateToken() {
    let authenticatedUserPhoto: any;
    this._authenticationService.validateToken().subscribe(
      (value) => {
        this.smallMessage = Constants.PAGES_LANDING_SMALL_TEXT_VERIFIED;
        this.gifImagePath = URLPaths.PATH_USERFETCHINFO_GIF;
        let authenticatedUserDetails: any = {};
        this._authenticationService.getUser().subscribe((userDetails) => {
          authenticatedUserDetails = userDetails;
          this.setLoggedInUser(authenticatedUserDetails);
          this._consultantDetailsService
          .getConsultantDetails()
          .subscribe((values) => {
            this.setCurrentUser(
              values.filter((consultant) => {
                return (
                  consultant.LoginName.toLowerCase() ===
                  this.loggedInUser.username.toLowerCase()
                );
              })[0]
            );
            this.setCurrentUser(this.currentUser);
                    let url = URLPaths.ROUTE_TIME_ENTRY_ACTUALS;
            this._router.navigate([url]);
          },
          (error1) => {
            // error toaster code will come here
            this._router.navigate([URLPaths.ROUTE_LOGIN]);
            this._snackbarService.showSnackBar(
              error1.error['detail'],
              'close',
              'failure'
            );
          }
        );
        
        });
      },
      (error) => {
        // error toaster code will come here
        if (error.status == 401) {
          this.API_KEY = '';
        }
        this._router.navigate([URLPaths.ROUTE_LOGIN]);
        this._snackbarService.showSnackBar(
          error.error['detail'],
          'close',
          'failure'
        );
      }
    );
  }
}
