import { Component } from '@angular/core';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  bigMessage = Constants.PAGES_MAINTENANCE_BIG_TEXT;
  smallMessage1 = Constants.PAGES_MAINTENANCE_SMALL_TEXT_1;
  smallMessage2 = Constants.PAGES_MAINTENANCE_SMALL_TEXT_2;
}
