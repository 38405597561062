import { Component, Input, TemplateRef, ViewChild, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ReportEmbedService } from '../report-embed.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { SnackbarService } from '../../common/services/snackbar.service';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnChanges {

  @Input() headerData: any;
  selectedValue: string;
  filterDetails = [];
  @ViewChild('date', {static: true}) date: TemplateRef<any>;
  @ViewChild('dropdown', {static: true}) dropdown: TemplateRef<any>;
  reportForm: FormGroup;
  isSmallScreenFlag: boolean;
  isLargeScreenFlag: boolean;
  isssmScreenFlag: boolean;
  issmScreenFlag: boolean;
  istbScreenFlag: boolean;
  ismdScreenFlag: boolean;
  islgScreenFlag: boolean;
  isxlScreenFlag: boolean;
  index: number;
  @Output() showReportEnabled = new EventEmitter<boolean>();
  showReport: boolean;
  
  constructor(private _reportEmbedService: ReportEmbedService,
    private _formBuilder: FormBuilder, private _snackbarService: SnackbarService) {
    this.reportForm = this._formBuilder.group({
    })
    this.isSmallScreenFlag = false;
    this.isLargeScreenFlag = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    // Always show Report when changing report page
    this.valueChanged(true);
    //Enable button when selecting a new page
    document.getElementById('submitButton').removeAttribute('disabled'); 
    //Dismiss snackbar when selecting a new page
    this._snackbarService.dismissSnackBar(); 
    this.filterDetails = this._reportEmbedService.reportMetaDataConstructor(this.headerData.filterDetails);
    this.setFilterEmitterFactoryData(this.filterDetails);
    this.checkScreenSize();
  }

  setFilterEmitterFactoryData(filterDetails) {
    let filterData: Map<string, any> = new Map<string, any>();
    for (let index in filterDetails) {
      filterData.set(filterDetails[index].filterCode, filterDetails[index].defaultValue);
    }
    this.buildForm(filterData);
    this._reportEmbedService.filterDataEmitterFactory.next(filterData);

  }

  buildForm(filterData) {
    this.reportForm = this._formBuilder.group({})
    for (let [key, value] of filterData.entries()) {
      this.reportForm.addControl(key, new FormControl(value))
    }
    for (let index in this.filterDetails) {
      if (this.filterDetails[index].filterType == 'date') {
        this.reportForm.get(this.filterDetails[index].filterCode).setValue(moment(this.filterDetails[index].defaultValue));
      }

    }

  }

  dateCheck() {
    this.valueChanged(false);
    const submitButton = document.getElementById('submitButton');
    if(this.reportForm.get('SD').value > this.reportForm.get('ED').value){
      submitButton.setAttribute('disabled', 'true');
      this._snackbarService.showSnackBar('Please select a valid start date!', null, 'failure', true)
    }
    else{
      this._snackbarService.dismissSnackBar();
      submitButton.removeAttribute('disabled');
    }
  }
  
  valueChanged(value: boolean) {
    this.showReport = value;
    this.showReportEnabled.emit(this.showReport);
  }

  reportSubmit() {
    this.valueChanged(true);
    for (let index in this.filterDetails) {
      if (this.filterDetails[index].filterType == 'date') {
        this.reportForm.value[this.filterDetails[index].filterCode] = this.reportForm.value[this.filterDetails[index].filterCode].format("MM-DD-YYYY")
      }

    }
    this._reportEmbedService.filterDataEmitterFactory.next(new Map(Object.entries(this.reportForm.value)));
  }



  AddReportFilterStyles() {
    let cssStyles = {
      'display': 'grid',
      'grid-template-areas': this.headerData.childAreaTemplate[this.index],
      // 'gap': '20px'
    };
    return cssStyles;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  // Check the Size of the Screen
  checkScreenSize() {
    this.isssmScreenFlag = window.innerWidth < 360;
    this.issmScreenFlag = window.innerWidth < 600; 
    this.istbScreenFlag = window.innerWidth < 768; 
    this.ismdScreenFlag = window.innerWidth < 960; 
    this.islgScreenFlag = window.innerWidth < 1280; 
    this.isxlScreenFlag = window.innerWidth < 1440;
    this.isLargeScreenFlag = window.innerWidth >=1440;
    if(this.isLargeScreenFlag){
      this.index = 0;
    }
    if(this.isxlScreenFlag){
      this.index = 1;
    }
    if(this.islgScreenFlag){
      this.index = 2;
    }
    if(this.ismdScreenFlag){
      this.index = 3;
    }
    if(this.istbScreenFlag){
      this.index = 4;
    }
    if(this.issmScreenFlag){
      this.index = 5;
    }
    if(this.isssmScreenFlag){
      this.index = 6;
    }
  }

}
