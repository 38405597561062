<!-- Bookmark Dialog Box -->
<div class="flex flex-col">
  <!-- Header for the Dialog Box -->
  <div class="flex flex-wrap flex-row justify-between items-center">
    <h2 mat-dialog-title class="text-xl">Bookmark</h2>
    <button mat-dialog-title [mat-dialog-close]="true" mat-flat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- Body for the Dialog Box -->
  <form flex="0 0 100%" novalidate fxLayout="column" class="timeDicerConfig">
    <mat-dialog-content
      class="flex flex-col tb:flex-nowrap tb:flex-row gap-2"
      style="box-sizing: border-box; overflow-y: hidden"
    >
      <!-- List of Saved Bookmark Configurations -->
      <div class="w-full tb:w-1/3">
        <!-- For Large Screens -->
        <div
          *ngIf="isLargeScreen()"
          name="configsList"
          class="basic-container"
          fxFlex="1 0 100%"
          style="width: 100%"
        >
          <mat-nav-list *ngIf="!showLoader">
            <mat-list-item class="configBookmarks"
              *ngFor="let config of timeDicerConfigList"
              (click)="configuration(config)"
            >
              <div mat-line>
                {{ config.NameOfConfig }}
              </div>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <!-- For Small Screens -->
        <div *ngIf="isSmallScreen()">
          <ng-select
            [(ngModel)]="currentConfig"
            name="lit"
            placeholder="Select a Bookmark"
            [virtualScroll]="true"
            [items]="timeDicerConfigList"
            bindLabel="NameOfConfig"
          >
          </ng-select>
        </div>
      </div>
      <!-- Vertical Line dividing Saved Bookmarks and Bookmark Details Element -->
      <div
        class="inline-block min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"
      ></div>

      <!-- Bookmark Details Element -->
      <div style="width: 100%" class="w-full mb-2 gap-10 tb:w-2/3 tb:px-3">
        <div class="mr-0 flex flex-col pb-3">
          <div class="pb-5">
            <mat-label>
              Bookmark ID:
              {{
                currentConfig && currentConfig.User === user.id
                  ? currentConfig.id
                  : ""
              }}
            </mat-label>
          </div>
          <div class="relative w-full pb-5">
            <input
              matInput
              [(ngModel)]="currentConfig.NameOfConfig"
              type="text"
              matInput
              name="name"
              [value]="currentConfig ? currentConfig.NameOfConfig : ''"
              class="dialogInput w-full border-b border-gray-400"
              required
            />
            <span class="floating-label absolute left-0 pointer-events-none"
              >Bookmark Name</span
            >
          </div>
        </div>
        <div class="relative w-full pb-5">
          <textarea
            rows="3"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            [(ngModel)]="currentConfig.ConfigDescription"
            matInput
            name="desc"
            [value]="currentConfig ? currentConfig.ConfigDescription : ''"
            class="dialogInput w-full border-b border-gray-400"
            placeholder=" "
          >
          </textarea>
          <span class="floating-label absolute left-0 pointer-events-none"
            >Bookmark Description</span
          >
        </div>
        <mat-dialog-actions style="justify-content: center;">
          <!-- New, Load, Save and Delete Buttons for the Bookmarks -->
          <div class="flex flex-wrap flex-row gap-2.5">
            <button
              class="flex-1 entry_button--design p-3 button-popup-css"
              mat-raised-button
              (click)="addNewConfigButtonClicked()"
            >
              New
            </button>
            <button
              class="flex-1 entry_button--design p-3 button-popup-css"
              mat-raised-button
              (click)="loadConfigButtonClicked()"
            >
              Load
            </button>
            <button
              class="flex-1 entry_button--design p-3 button-popup-css"
              mat-raised-button
              [mat-dialog-close]="true"
              (click)="saveConfigButtonClicked()"
            >
              Save
            </button>
            <button
              class="flex-1 entry_button--design p-3 button-popup-css"
              mat-raised-button
              [mat-dialog-close]="true"
              (click)="deleteConfigButtonClicked()"
            >
              Delete
            </button>
          </div>
        </mat-dialog-actions>
      </div>
    </mat-dialog-content>
  </form>
</div>
