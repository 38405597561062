<div class="flex flex-col" style="height: 100% !important"[appDynamicStyles]="{ 'row-gap': {zoomout: '15px', zoomin: '8px'} }" >
  <!-- Header for Impersonate Dialog -->
  <div class="flex justify-between items-center">
    <div class="flex items-center">
      <h1 mat-dialog-title [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '14.5px'}}">Impersonate</h1>
    </div>

    <div class="flex items-center">
      <button mat-dialog-title [mat-dialog-close]="true" mat-flat-button [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '14.5px'} }">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="flex flex-row items-center gap-10 relative" [appDynamicStyles]="{ 'gap': {zoomout: '12px', zoomin: '8px'} }">
    <!-- Filter consultant -->
    <div
      class="relative border-b border-gray-400"
      style="height: 30px"
      [appDynamicStyles]="{ 'gap': {zoomout: '5px', zoomin: '0px'} }"
    >
      <input
        matInput
        [(ngModel)]="searchText"
        type="text"
        class="dialogInput w-full"
        [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }"
        required
      />
      <span
        class="floating-label absolute left-0 pointer-events-none"
        [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }"
        >Filter Consultant</span
      >
    </div>

    <!-- Apply impersonation -->
    <button
      mat-raised-button
      (click)="impersonateOnClick()"
      [disabled]="!selectedRow"
      [mat-dialog-close]="true"
      class="apply_button--design"
      [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }"
    >
      Apply
    </button>
  </div>

  <!-- AG Grid of all consultants -->
  <mat-dialog-content class="impersonateDialog dialog-content"
  [appDynamicStyles]="{ 'paading': {zoomout: '8px', zoomin: '4px'} }">
    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham"
      [(gridOptions)]="gridOptions"
      (gridReady)="onGridReady($event)"
      [(rowData)]="rowData"
      [quickFilterText]="searchText"
      [animateRows]="true"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      (cellDoubleClicked)="onCellDoubleClicked($event)"
      (rowSelected)="onRowSelection($event)"
    >
    </ag-grid-angular>
  </mat-dialog-content>
</div>
