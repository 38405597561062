<!-- If Page Not Found -->
<div class="flex flex-col justify-center items-center h-screen bg-neutral-100">
    <div class="pb-7">
        <img src="../../../assets/images/logo/mips-logo-simple.png" class="w-32">
    </div>
    <div class="font-sans text-lg font-normal text-center pb-2">
        {{ bigMessage }}
    </div>
    <div class="font-sans text-base font-light font-color text-center pb-7">
        {{ smallMessage }}
    </div>
    <div class="p-4 button-design text-lg font-medium font-sans">
        <a [routerLink]="homeRoute">GO HOME</a>
    </div>
</div>
