<!-- Button -->
<div class="relative">
    <!-- Current User Details and Image -->
  <button
    class="flex items-center justify-center space-x-2 px-4 py-2 rounded-md"
    [matMenuTriggerFor]="userActions"
  >
    <img
      *ngIf="currentUserImageData"
      class="w-8 h-8 rounded-full"
      [src]="currentUserImageData | urlSanitizer"
    />
    <span *ngIf="currentUser" class="font-normal hidden md:flex" [appDynamicStyles]="{'font-size' : { zoomout: '14px', zoomin: '13px' }}"
      >{{ currentUser.FirstName }} {{ currentUser.LastName }}</span
    >
    <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
      <path d="M6 8l4 4 4-4"></path>
    </svg>
  </button>
  <!-- Impersonate, Logout options (Cancel Impersonation when Impersonated) -->
  <mat-menu
    class="bg-white shadow-2xl rounded"
    [xPosition]="'after'"
    #userActions="matMenu"
  >
    <button
      *ngIf="shouldDisplay()"
      mat-menu-item
      (click)="cancelImpersonateButtonClicked()"
      class="mat_option--Itemstyle"
    >
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>Cancel Impersonation</span>
    </button>
    <button
      mat-menu-item
      (click)="impersonateButtonClicked()"
      class="mat_option--Itemstyle"
    >
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>Impersonate</span>
    </button>
    <button mat-menu-item (click)="signOut()" class="mat_option--Itemstyle">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</div>
