import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NavShortcutsComponent } from './nav-shortcuts/nav-shortcuts.component';
import { NavUserComponent } from './nav-user/nav-user.component';
import { NavUserCountryComponent } from './nav-user-country/nav-user-country.component';
import { SharedModule } from 'app/shared/shared.module';
import { PipesModule } from '../mips/common/pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from '../mips/common/directives/directives.module';

@NgModule({
  declarations: [
    NavShortcutsComponent,
    NavUserComponent,
    NavUserCountryComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    PortalModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatAutocompleteModule,
    PipesModule,
    MatSelectModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  exports: [
    NavShortcutsComponent,
    NavUserComponent,
    NavUserCountryComponent
  ]
})
export class NavItemsModule { }