import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  // Set Local Storage Value
  public static setLocalStorageValueBasedOnKey(keyName: string, value: string) {
    try {
        localStorage.setItem(keyName,value);
    } catch(error) {
      console.error(error);
    }
    
  }

  // Get Local Storage Value
  public static getLocalStorageValueBasedOnKeys(keyName: string) {
    // the return value is string, not object!
    try {
      const value = localStorage.getItem(keyName);
      if (value) {
        return value;
      }
    } catch(error){
      console.error(error);
    }
    return null;
  }

  // Remove Local Storage Value
  public static removeLocalStorageValueBasedOnKeys(keyName: string) {
    try {
      return localStorage.removeItem(keyName);
    } catch(error) {
      console.error(error);
      return null;
    }
  }
}
