import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TimeDicerComponent } from './time-dicer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AgGridModule } from 'ag-grid-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TimeDicerBookmarkConfigPanelComponent } from './time-dicer-bookmark-config-panel/time-dicer-bookmark-config-panel.component';
import { NgSelectModule } from '@ng-select/ng-select'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TimeDicerHeaderComponent } from './time-dicer-header/time-dicer-header.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DirectivesModule } from '../../common/directives/directives.module';

@NgModule({
    declarations: [
      TimeDicerComponent,
      TimeDicerBookmarkConfigPanelComponent,
      TimeDicerHeaderComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      MatFormFieldModule, 
      MatSelectModule, 
      MatInputModule, 
      MatCardModule, 
      AgGridModule,
      MatDatepickerModule,
      MatIconModule,
      MatDialogModule,
      MatListModule,
      MatCheckboxModule,
      MatButtonModule,
      NgSelectModule,
      MatProgressSpinnerModule,
      ReactiveFormsModule,
      NgMultiSelectDropDownModule.forRoot(),
      DirectivesModule
    ], 
    providers: [
      DatePipe
    ],
    exports: [
      TimeDicerComponent,
      TimeDicerHeaderComponent,
      TimeDicerBookmarkConfigPanelComponent
    ],
    schemas: [
      NO_ERRORS_SCHEMA,
    ]

  })
  export class TimeDicerModule { }