import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ReportEmbedService } from '../report-embed.service';
import { URLPaths } from '../../common/utilities/urlPaths.config';

@Component({
  selector: 'app-report-area',
  templateUrl: './report-area.component.html',
  styleUrls: ['./report-area.component.scss']
})
export class ReportAreaComponent implements OnChanges, OnInit {

  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;

  @Input() reportData: any;
  filterData: any;

  reportServer: string = URLPaths.ROUTE_REPORT_SERVER;
  reportUrl: string;
  reportParams: any;
  parameters: any;
  language: string = "en-us";
  width: number = 100;
  height: number = 100;
  toolbar: string = "true";

  constructor(private _reportEmbedService: ReportEmbedService) { }
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.reportUrl = this.reportData.reportMetaData.reporturl;
    this.reportParams = this.reportData.reportMetaData.parameters;
    this._reportEmbedService.filterDataEmitterFactory.subscribe((data) => {
      this.parameters = this._reportEmbedService.reportParamsMetaDataConstructor(data, this.reportParams);
      if (this.outletRef) {
        this.outletRef.clear();
        this.outletRef.createEmbeddedView(this.contentRef);
      }
    })
  }

}
