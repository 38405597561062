<!-- Settings Component: Used for accessing the header component for smaller screens -->
<div
    class="fixed flex items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-red-600 bg-opacity-90 print:hidden"
    style="top: 275px"
    (click)="settingsDrawer.toggle()">
    <mat-icon class="icon-size-5 text-white">album</mat-icon>
</div>

<fuse-drawer
    class="sm:w-100 sm:min-w-100 bg-neutral-100"
    fixed
    [mode]="'over'"
    [name]="'settingsDrawer'"
    [position]="'right'"
    #settingsDrawer>

    <div class="flex flex-col w-full overflow-hidden bg-neutral-100 gap-3">
        <div class="flex flex-row items-center p-6 h-15 min-h-15">
            <button
                mat-icon-button
                (click)="settingsDrawer.close()">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <!-- Header for Time Entry, Expense Entry & Time Dicer Pages -->
        <div class="flex flex-col px-6">
            <div *ngIf="pageType==navTimeEntryActuals || pageType==navExpenseEntry">
                <app-entry-header [entryPageType]="pageType"></app-entry-header>
            </div>
            <div *ngIf="pageType==navTimeDicer">
                <app-time-dicer-header></app-time-dicer-header>
            </div>
        </div>
    </div>
</fuse-drawer>
