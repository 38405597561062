<div class="flex flex-col">
    <div class="flex flex-row">
        <!-- Plan vs Actual Header -->
        <div
            class="flex flex-wrap tb:flex-nowrap tb:flex-row justify-between items-cente basis-11/12"
        >
            <h2 mat-dialog-title class="dialog-title" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'} }">
                Plan vs Actual
            </h2>
            <span mat-dialog-title class="dialog-title"  [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'} }">{{
                this.weekStart + " - " + this.weekEnd
            }}</span>
        </div>
        <div class="flex items-center absolute right-0 basis-1/12">
            <button mat-dialog-title [mat-dialog-close]="true" mat-flat-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <!-- Plan vs Actual chart -->

    <mat-dialog-content class="overflow-y-hidden pb-0">
        <div
            *ngIf="plannedAndActualsCombined.length > 0"
            class="comparisionDialog"
            #chartArea
        >
            <ngx-charts-bar-vertical-2d
                [animations]="true"
                [gradient]="false"
                [legend]="true"
                [results]="chartData"
                [scheme]="colorScheme"
                [showDataLabel]="true"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Projects'"
                [xAxis]="true"
                [yAxisLabel]="'Hours'"
                [yAxis]="true"
                [groupPadding]="10"
                [view]="relativeView"
                [barPadding]="10"
            >
            </ngx-charts-bar-vertical-2d>
        </div>
        <div
            *ngIf="plannedAndActualsCombined.length === 0"
            class="message-box warning"
            class="flex flex-row items-center"
        >
            No data to compare
        </div>
    </mat-dialog-content>
</div>
