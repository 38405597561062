<!-- NOT DYNAMIC PLEASE TRY TO IMPROVE IF YOU CAN -->

<div class="flex flex-col" style="height: 100% !important" [appDynamicStyles]="{ 'row-gap': {zoomout: '12px', zoomin: '8px'} }">    

  <div class="flex justify-between items-center">
    <div class="flex items-center" *ngIf="gridType === 'themeAllocation'">
      <h1 mat-dialog-title class="p-0" [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '16px'}}">Theme Allocation</h1>
    </div>
    <div class="flex items-center" *ngIf="gridType === 'prepaymentDetails'">
      <h1 mat-dialog-title class="p-0" [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '16px'}}">Prepayment Details</h1>
    </div>
    <div class="flex items-end">
      <button mat-dialog-title [mat-dialog-close]="true" mat-flat-button class="flex min-h-0 justify-end p-0" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="flex flex-row" [appDynamicStyles]="{ 'gap': {zoomout: '20px', zoomin: '28px'} }">
    <button
      *ngIf="gridType === 'themeAllocation'"
      [disabled]="checkSubmit('Save', 'TA', mode)"
      mat-raised-button
      type="button"
      class="flex items-center min-h-0 justify-center font-semibold rounded-md save-button disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer"
      [appDynamicStyles]="{ 'height': { zoomout: '30px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 4px 8px 4px'}}"
      (click)="onSaveClickTA()"
    >
      <div class="flex items-center justify-center gap-2 w-full h-full">
        <mat-icon class="" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">save</mat-icon>
        <div class="font-sans flex items-center" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Save</div>
      </div>
    </button>
    <button
      *ngIf="gridType === 'themeAllocation'"
      [disabled]="checkSubmit('Delete', 'TA', mode)"
      mat-raised-button
      type="button"
      class="flex items-center min-h-0 justify-center font-semibold rounded-md delete-button disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer"
      [appDynamicStyles]="{ 'height': { zoomout: '30px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 4px 8px 4px'}}"
      (click)="onDeleteClick('deleteThemeAllocation', false, 'Delete', 'TA', mode)"
    >
      <div class="flex items-center justify-center gap-2 w-full h-full">
        <mat-icon class="" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">delete</mat-icon>
        <div class="font-sans flex items-center" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Delete</div>
      </div>
    </button>
    <button
      *ngIf="gridType === 'prepaymentDetails'"
      [disabled]="checkSubmit('Save', 'PD', mode)"
      mat-raised-button
      type="button"
      class="flex items-center min-h-0 justify-center font-semibold rounded-md save-button disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer"
      [appDynamicStyles]="{ 'height': { zoomout: '30px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 4px 8px 4px'}}"
      (click)="onSaveClickPD()"
    >
      <div class="flex items-center justify-center gap-2 w-full h-full">
        <mat-icon class="" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">save</mat-icon>
        <div class="font-sans flex items-center" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Save</div>
      </div>
    </button>
  </div>
  <mat-dialog-content class="p-0"> 
    <ag-grid-angular
      *ngIf="gridType === 'themeAllocation'"
      class="ag-theme-balham"
      style="width: 100%; height: 100%;"
      [rowData]="themeAllocationRowData"
      [columnDefs]="themeAllocationColumnDefs"
      [gridOptions]="gridOptions"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      (cellValueChanged)="onCellValueChanged($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
    <ag-grid-angular
      *ngIf="gridType === 'prepaymentDetails'"
      class="ag-theme-balham"
      style="width: 100%; height: 100%;"
      [rowData]="prepaymentDetailasRowData"
      [columnDefs]="prepaymentDetailasColumnDefs"
      [gridOptions]="gridOptions"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      (cellValueChanged)="onCellValueChanged($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (gridReady)="onGridReady($event)"
      [components]="components"
      (cellEditingStarted)="onCellEditingStarted($event)"
      (cellEditingStopped)="onCellEditingStopped($event)"
    ></ag-grid-angular>
  </mat-dialog-content>
</div>

