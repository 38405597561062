import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable, OnDestroy} from '@angular/core';
import { ConsultantModel } from '../../common/models/consultant.model';
import { OnInit } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { URLPaths } from '../utilities/urlPaths.config';

@Injectable()
export class MipsRouteGuard implements CanActivate, CanActivateChild, OnDestroy, OnInit {

    currentUser: ConsultantModel;

    constructor(
        private router: Router,
    ) 
    {    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkLogin(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        // await this.delay(100);
        const loggedInUser = 'loggedInUser'
        if (LocalStorageService.getLocalStorageValueBasedOnKeys(loggedInUser) && !(url == '/mips/core' || url == '/mips')) {
            return true;
        }
        
        // Navigate to the login page with extras
        this.router.navigate([URLPaths.ROUTE_LANDING_PAGE]);
        return false;
    }
}
