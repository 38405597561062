<div *ngIf="isPageInitialized" class="flex flex-col gap-2.5 h-full">
  <div>
    <app-report-header (showReportEnabled)="showReportFn($event)" [headerData]="headerData"></app-report-header>
  </div>
  <div 
    *ngIf="reportRender" 
    style="overflow-y: auto;">
    <app-report-area [reportData]="reportData"></app-report-area>
  </div>
  <div *ngIf="!reportRender">
    <p>To view the report, please enter the parameter values and click on 'Submit'</p>
  </div>
</div>
