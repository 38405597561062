import { Injectable } from "@angular/core";
import { CommonService } from "../common/services/common.service";

@Injectable({
    providedIn: "root",
})
export class ProjectDetailsInitializerService {
    constructor(private _commonService: CommonService) { }

    initializeProjectDetailsData = async () => {
        await Promise.all([
            this._commonService.getAllClients('projectDetails'),
            this._commonService.getClientswithInetrnalCode('projectDetails')
        ]);
        
    }

    
}
