import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EntryHeaderComponent } from './entry-header/entry-header.component';
import { EntryTableComponent } from './entry-table/entry-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { EntryDialogComponent } from './entry-dialog/entry-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import 'ag-grid-enterprise';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { DateUtility } from '../../common/utilities/date.utility';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgSelectModule } from '@ng-select/ng-select';
import { PagesRouteGuard } from '../../common/utilities/pages.routeguard';
import { DirectivesModule } from '../../common/directives/directives.module';


@NgModule({
  declarations: [
    EntryHeaderComponent,
    EntryTableComponent,
    EntryDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    AgGridModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDividerModule,
    MatNativeDateModule,
    MatMenuModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  exports: [
    EntryHeaderComponent,
    EntryTableComponent
  ],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateUtility.CALENDER_DATE_FORMAT },
    PagesRouteGuard
  ]
})
export class EntryModule { }
