<div
  class="header-screen"
>
<form [formGroup]="reportForm"
      class="flex flex-wrap flex-col justify-center tb:flex-row w-full px-2"
      [appDynamicStyles]="{ 'column-gap': { zoomout: '32px', zoomin: '28px' },  'row-gap': { zoomout: '16px', zoomin: '8px' }}">
  <div class="flex flex-wrap flex-col tb:flex-row">
      <div class="reportHeader justify-start" [style]="AddReportFilterStyles()" [appDynamicStyles]="{ 'column-gap': { zoomout: '32px', zoomin: '20px' },  'row-gap': { zoomout: '16px', zoomin: '8px' }}">
        <div
          *ngFor="let filterDetail of filterDetails"
          class="{{ filterDetail.filterName }}"
          [style.grid-area]="filterDetail.filterName"
        >
          <ng-container
            [ngTemplateOutlet]="this[filterDetail.filterType]"
            [ngTemplateOutletContext]="{ data: filterDetail }"
          ></ng-container>
        </div>
      </div>
      
      <ng-template #date let-filterDetail="data">
        <div class="relative flex flex-col justify-items-center dateSelect pb-4">
          <label>{{ filterDetail.filterDisplayName }}</label>
          <div
            class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
            style="padding-bottom: 3.3px"
          >
            <input
              matInput
              [matDatepicker]="picker"
              (dateChange)="dateCheck()"
              readonly
              (click)="picker.open()"
              [formControlName]="filterDetail.filterCode"
              class="text-base"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
              style="position: absolute; left: 88%; padding-bottom: 8px"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </ng-template>

      <ng-template #dropdown let-filterDetail="data">
        <div class="dropdown" >
          <ng-select
            (change)="valueChanged(false)"
            [items]="filterDetail.filterValues"
            [virtualScroll]="true"
            [placeholder]="filterDetail.filterDisplayName"
            [formControlName]="filterDetail.filterCode"
          ></ng-select>
        </div>
      </ng-template>
    </div>
    <div class="flex justify-start items-center pb-4">
      <button
        id="submitButton"
        type="submit"
        class="submitButton right-0"
        (click)="reportSubmit()"
        [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 8px 8px 8px' , zoomin: '4px 4px 4px 4px'}}"
      >
        <div class="flex items-center font-sans"
        [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }"
        >Submit</div>
      </button>
    </div>
    </form>
</div>