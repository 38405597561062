<mat-card class="flex flex-col shadow-xl border-2 rounded bg-white h-full" [appDynamicStyles]="{ 'gap': {zoomout: '16px', zoomin: '10px'} }">
  <mat-card-header class="flex flex-col" [appDynamicStyles]="{'padding': {zoomout: '16px 16px 0px 16px', zoomin: '8px 12px 0px 12px'} }">
    <mat-card-title class="font-semibold" [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '14.5px'} }">Summary</mat-card-title>
    <div
      class="flex flex-wrap tb:flex-nowrap tb:flex-row shadow-xl items-center justify-center rounded gap-1 tb:gap-3"
    >
      <!-- Mode Week/Month for Summary Table -->
      <div class="flex header-div-dimensions items-center justify-start pl-5" [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">
        <mat-label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">Mode</mat-label>
        <mat-radio-group (change)="dataModeChange($event)">
          <mat-radio-button [checked]="displayWeekData" value="week">
            <label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">Week</label>
          </mat-radio-button>
          <mat-radio-button [checked]="!displayWeekData" value="month">
            <label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">Month</label>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- View By Day/Project for Summary Table -->
      <div
        class="flex header-div-dimensions items-center justify-start tb:justify-center pl-5 tb:pl-0"
        [appDynamicStyles]="{ 'font-size': {zoomout: '37.39px', zoomin: '32px'} }"
      >
        <mat-label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">View By</mat-label>
        <mat-radio-group (change)="viewChange($event)">
          <mat-radio-button [checked]="displayDayLevelData" value="day">
            <label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">Day</label>
          </mat-radio-button>
          <mat-radio-button [checked]="!displayDayLevelData" value="project">
            <label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">Project</label>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        class="flex header-div-dimensions items-center justify-start tb:justify-end pr-5 pl-5 tb:pl-0"
        [appDynamicStyles]="{ 'font-size': {zoomout: '37.39px', zoomin: '32px'} }"
      >
        <mat-label [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }">{{ getTimeStartDate }} - {{ getTimeEndDate }}</mat-label>
      </div>
    </div>
  </mat-card-header>

  <!-- AG-Grid for summary  -->
  <mat-card-content class="flex flex-row tb:h-3/4" style="height: 70vh">
    <ag-grid-angular
      *ngIf="displayDayLevelData"
      style="max-width: 2256px; width: 100%"
      class="ag-theme-balham w-full"
      (gridReady)="onGridReady($event)"
      [(rowData)]="rowData"
      [(gridOptions)]="gridOptions"
      [(columnDefs)]="columnDefs"
      [defaultColDef]="defaultColDef"
    >
    </ag-grid-angular>
    <ag-grid-angular
      *ngIf="!displayDayLevelData"
      style="max-width: 2256px; width: 100%"
      class="ag-theme-balham w-full"
      (gridReady)="onGridReady($event)"
      [(rowData)]="rowData"
      [(gridOptions)]="gridOptions"
      [(columnDefs)]="columnDefs"
      [defaultColDef]="defaultColDef"
    >
    </ag-grid-angular>
  </mat-card-content>
</mat-card>
