import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { URLPaths } from '../utilities/urlPaths.config';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private _router: Router,
    private _snackbarService: SnackbarService
  ) { }

  //Handle login exception and other API errors
  handleError(error: HttpErrorResponse): any {
    let errorMessage = error.statusText;
    if(error.error['Message']) {
        errorMessage += ' - ' + error.error['Message'];
    } else if (error.error['detail']) {
        errorMessage += ' - ' + error.error['detail'];
    }
    if (error.status == 401) {
      window.location.href = "/#" + URLPaths.ROUTE_LOGIN;
    }
    this._snackbarService.showSnackBar(errorMessage, 'close', 'failure');

    return error;
}

}
