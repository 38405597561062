<div class="h-full">
  <ng-container #outlet [ngTemplateOutlet]="content"> </ng-container>
</div>

<ng-template #content>
    <ssrs-reportviewer
      class="ssrsReport"
      [reportserver]="reportServer"
      [reporturl]="reportUrl"
      [parameters]="parameters"
      [language]="language"
      [width]="width"
      [height]="height"
      [toolbar]="toolbar"
    >
    </ssrs-reportviewer>
</ng-template>
