import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WriteSilenceService {
  //Contains the write silence API data
  writeSilence: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
