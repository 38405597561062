import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { commonFunctions } from '../classes/common.class';
import { ConsultantModel } from '../models/consultant.model';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultantDetailsService {
  private consultantDetailsList: ConsultantModel[];

  constructor(
    private _http: HttpClient,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  // Return all the consultant details
  getConsultantDetails(): Observable<any> {
    const params = new HttpParams();
    this.consultantDetailsList = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('Consultants_Detail'));
    if (commonFunctions.isNullOrUndefined(this.consultantDetailsList)) {
        return this._http.get(URLPaths.URL_CONSULTANT_DETAILS, {headers: ApiheaderService.getHeaders(), params: params})
            .pipe(
                map(res => {
                    this.consultantDetailsList = res as ConsultantModel[];
                    SessionStorageService.setSessionStorageValueBasedOnKey('Consultants_Detail', JSON.stringify(this.consultantDetailsList));
                    return res;
                }),
                catchError(this._errorHandlerService.handleError.bind(this)));
    } else {
        return of(this.consultantDetailsList);
    }
  }
}
