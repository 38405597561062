import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { UserService } from 'app/core/mips/common/services/user.service';
import { ConsultantModel } from 'app/core/mips/common/models/consultant.model';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { UserModel } from 'app/core/mips/common/models/user.model';
import { AuthenticationService } from 'app/login/login-page/authentication.service';
import { LocalStorageService } from 'app/core/mips/common/services/local-storage.service';
import { Logout } from 'app/core/store/logoutUser/logoutUser.action';
import { LogoutService } from 'app/core/mips/common/services/logout.service';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';
import { ExpenseEntryService } from 'app/core/mips/common/services/expense-entry.service';
import { TimeEntryService } from 'app/core/mips/common/services/time-entry.service';
import { Constants } from 'app/core/mips/common/utilities/constants.config';

@Component({
  selector: 'app-nav-user',
  templateUrl: './nav-user.component.html',
  styleUrls: ['./nav-user.component.scss'],
})
export class NavUserComponent implements OnInit
{
    currentUser : ConsultantModel = null;
    loggedInUser: UserModel;
    static ngAcceptInputType_showAvatar: BooleanInput;
    currentUserImageData: any;
    @Input() showAvatar: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    timeEntryIsUnsavedChangesPresent: boolean;
    expenseEntryIsUnsavedChangesPresent: boolean;
    

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _userService: UserService,
    private _Store: Store,
    private _authenticationService: AuthenticationService,
    private _logoutService: LogoutService,
    private _timeEntryService: TimeEntryService,
    private _expenseEntryService: ExpenseEntryService
  ) {
    this._Store.select(getLoggedInUser).subscribe((data) => {
      this.loggedInUser = data;
    });
    this._Store.select(getCurrentUser).subscribe((data) => {
      if(data != null){
        this.currentUser = data;
        this.currentUserImageData =  `https://ui-avatars.com/api/?name=${this.currentUser.FirstName}+${this.currentUser.LastName}&background=random`;
        this._userService.getUserProfilePhoto(this.currentUser.Email).toPromise().then(
          (data)=>{
            if(data != null){
              this.currentUserImageData =  data;
          }
          }
        );
      }

    });
    this._timeEntryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.timeEntryIsUnsavedChangesPresent = data;
    })
    this._expenseEntryService.entryIsUnsavedChangesPresent.subscribe((data) => {
      this.expenseEntryIsUnsavedChangesPresent = data;
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */

    /**
     * Sign out
     */
    signOut(): void
    {
        this._authenticationService.logout().subscribe(value =>{
            this._logoutService.beginLogoutAction.next(true);
            this._Store.dispatch(new Logout());
            // LocalStorageService.removeLocalStorageValueBasedOnKeys('API_KEY');
            // LocalStorageService.removeLocalStorageValueBasedOnKeys('Consultants_Detail');
            localStorage.clear();
            this._router.navigate([URLPaths.ROUTE_LOGIN]);
        })
    }

    /**
   * Open Material Dialog
   */
  impersonateButtonClicked(): void {
    this._userService.openImpersonateDialog();
  }

  shouldDisplay(): boolean {
    return (
      this.currentUser &&
      this.loggedInUser &&
      this.currentUser.LoginName.toLowerCase() !==
        this.loggedInUser.username.toLowerCase()
    );
  }

    cancelImpersonateButtonClicked(): void {
      if(this.checkUnsavedEntries()){
        this._userService.impersonateUser(this.loggedInUser.userProfile.employee);
      }
    }  

    checkUnsavedEntries(){
      if(this.timeEntryIsUnsavedChangesPresent || this.expenseEntryIsUnsavedChangesPresent){
        const confirmRedirect = window.confirm(Constants.MSG_CONFIRM_DISCARD_CHANGES)
        return confirmRedirect;
      }
      return true;
    }
}
