<!-- Shortcuts toggle -->
<div class="overlay" [class.active]="isOverlayActive"></div>
<button
    mat-icon-button
    (click)="openPanel()"
    #shortcutsOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:star'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-60 sm:w-60 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-2 pl-3 pr-1 shortcut_container--header text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="flex items-center text-lg font-medium leading-10">
                <span class="">Shortcuts</span>
                <ng-container *ngIf="mode === 'add'">
                    <span class="ml-1">- Add new</span>
                </ng-container>
            </div>

            <div class="ml-auto">

                <!-- View mode -->
                <ng-container *ngIf="mode === 'view'">
                    <!-- Enter 'add' mode -->
                    <button
                        mat-icon-button
                        (click)="newShortcut()">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    </button>
                </ng-container>

                <!-- Add mode -->
                <ng-container *ngIf="mode === 'add'">
                    <!-- Enter 'view' mode -->
                    <button
                        mat-icon-button
                        (click)="changeMode('view')">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>

        <!-- Body -->
        <div class="relative flex flex-col flex-auto sm:max-h-90 -mb-px overflow-y-auto bg-white">

            <!-- View mode -->
            <ng-container *ngIf="mode === 'view'">

                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row">
                    <!-- Shortcut -->
                    <ng-container *ngFor="let shortcut of shortcutItems; trackBy: trackByFn">
                        <div class="relative group flex flex-col overflow-hidden bg-card shortcuts-separator even:border-r-0 hover:bg-gray-100 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <div class="absolute top-0 right-0">
                                <button
                                    mat-icon-button
                                    (click)="delete(shortcut.id)">
                                    <mat-icon
                                        class="icon-size-5 text-current"
                                        [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                </button>
                            </div>
                            <a
                                class="flex flex-col items-center justify-center w-full h-full pt-7 pb-3 no-underline"
                                [routerLink]="shortcut.link"
                                (click)="closePanel()">
                                <div class="relative flex shrink-0 items-center justify-center w-8 h-8 rounded-full icons--background">
                                    <mat-icon
                                        class="icons--color"
                                        [svgIcon]="shortcut.icon"></mat-icon>
                                </div>
                                <div class="font-normal text-center pt-1">{{ shortcut.label }}</div>
                            </a>
                        </div>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!shortcutItems || !shortcutItems.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-8 px-8">
                        <div class="flex flex-0 items-center justify-center w-12 h-12 rounded-full icons--background">
                            <mat-icon
                                class="icons--color"
                                [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </div>
                        <div class="pt-2 text-xl font-medium tracking-tight">No shortcuts</div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Add mode -->
            <ng-container *ngIf="mode === 'add'">
                <div class="p-6">
                    <ng-select
                    [(ngModel)]="currentShortcutSelection"
                    [items]="navigationItems"
                    [virtualScroll]="true"
                    bindLabel="label"
                    placeholder="Search for a page"
                    appendTo="body"
                    dropdownPosition="bottom"
                    >
                    </ng-select>
                    <div class="flex items-center justify-center mt-4">
                        <button
                            class="shortcut_buttons--design"
                            mat-flat-button
                            type="button"
                            (click)="save()">
                            Add
                        </button>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</ng-template>
