<!-- Header component for Time and Expense -->
<div
  [ngClass]="{
    'entry-header-small-screen': isSmallScreen(),
    'entry-header-large-screen': isLargeScreen()
  }"
>
  <div class="flex flex-wrap flex-col tb:flex-row justify-between" [appDynamicStyles]="{ 'column-gap': { zoomout: '28px', zoomin: '14px' },  'row-gap': { zoomout: '12px', zoomin: '6px' }}">
    <!-- New Entry Button -->
    <div>
      <button
        mat-raised-button
        matTooltip="Add new time entry"
        matTooltipClass="sidenavTooltip"
        class="flex items-center entry-page-button"
        (click)="onNewButtonClicked()"
        [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
      >
        <div class="flex flex-row gap-1">
          <div class="flex items-center" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
            <mat-icon>add</mat-icon>
          </div>
          <div class="flex items-center font-sans" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">New</div>
        </div>
      </button>
    </div>
    <!-- Plan Vs Actual for Time -->
    <div *ngIf="entryPageType != 'Expense'">
      <button
        mat-raised-button
        class="flex items-center entry-page-button disabled:opacity-50"
        (click)="planActualComparisionButtonClicked()"
        [appDynamicStyles]="{ 'height': { zoomout: '40pxsa', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
        [disabled]="isUnsavedChangesPresent"
      >
        <div class="flex flex-row gap-1">
          <div class="flex items-center" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
            <mat-icon>bar_chart</mat-icon>
          </div>
          <div class="flex items-center font-sans" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Plan Vs Actual</div>
        </div>
      </button>
    </div>
    <!-- Save Entries Button -->
    <div>
      <button
        mat-raised-button
        class="flex items-center entry-page-button disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer"
        (click)="saveChangesButtonClicked()"
        [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
        [disabled]="isWriteSilence || !isUnsavedChangesPresent"
      >
        <div class="flex flex-row gap-1">
          <div class="flex items-center" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
            <mat-icon>save</mat-icon>
          </div>
          <div class="flex items-center font-sans" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Save</div>
        </div>
      </button>
    </div>
    <!-- Delete Entries Button -->
    <div>
      <button
        mat-raised-button
        class="flex items-center entry-page-button disabled:opacity-50 cursor-not-allowed enabled:cursor-pointer"
        (click)="deleteButtonClicked()"
        [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
        [disabled]="isWriteSilence || !isRowSelectedforDeletion"
      >
        <div class="flex flex-row gap-1">
          <div class="flex items-center" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
            <mat-icon>delete_outline</mat-icon>
          </div>
          <div class="flex items-center font-sans" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Delete</div>
        </div>
      </button>
    </div>
    <!-- Reset Button -->
    <div>
      <button
        [matMenuTriggerFor]="resetMenu"
        mat-raised-button
        [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px 12px 8px 8px' , zoomin: '4px 8px 4px 4px'}}"
        class="flex items-center entry-page-button"
      >
        <div class="flex flex-row gap-1">
          <div class="flex items-center" [appDynamicStyles]="{ 'height': { zoomout: '28px', zoomin: '14.5px' }}">
            <mat-icon>restore</mat-icon>
          </div>
          <div class="flex items-center font-sans" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '28px', zoomin: '14.5px' } }">Reset</div>
        </div>
      </button>
      <mat-menu
        #resetMenu="matMenu"
        [overlapTrigger]="false"
        class="bg-white shadow-2xl rounded"
        [xPosition]="'after'"
      >
        <!-- Reset Rows Data -->
        <button
          [disabled]="isWriteSilence || !isUnsavedChangesPresent"
          matTooltip="Reset time entry changes"
          class="enabled:cursor-pointer disabled:opacity-50 cursor-not-allowed mat_option--Itemstyle"
          mat-menu-item
          (click)="resetChangesButtonClicked()"
          [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px' , zoomin: '4px'}}"
        >
          <mat-icon class="h-4 w-4 min-h-4 min-w-4">bar_chart</mat-icon>
          <span>Reset Data</span>
        </button>
        <!-- <button mat-menu-item class="mat_option--Itemstyle" (click)="resetColumnsButtonClicked()">
                    <mat-icon>undo</mat-icon>
                    <span>Reset Columns</span>
                </button> -->
      </mat-menu>
    </div>
    <!-- Calendar to pick Date Range -->
    <div>
      <mat-form-field class="date-picker shadow flex justify-center" [appDynamicStyles]="{ 'height': { zoomout: '40px', zoomin: '30px' } }">
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start Date"
            readonly
            (click)="picker.open()"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End Date"
            readonly
            (click)="picker.open()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          (closed)="setDateRangeSelection()"
        ></mat-date-range-picker>
      </mat-form-field>
    </div>
    <!-- Move or Copy -->
    <div class="drag-mode flex font-sans justify-center gap-1" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14.5px'}, 'height': { zoomout: '40px', zoomin: '30px' }, 'padding': {zoomout: '8px' , zoomin: '4px'} }">
      <mat-label>Drag Mode : Move </mat-label>
      <mat-slide-toggle [checked]="rowDragMode" (change)="toggleValueChanged()">
      </mat-slide-toggle>
      <mat-label>Copy</mat-label>
    </div>
  </div>
</div>
