<mat-card class="flex flex-col shadow-xl border-2 rounded bg-white h-full" [appDynamicStyles]="{ 'gap': {zoomout: '12px', zoomin: '8px'} }">
    <!-- Card -->
    <mat-card-header [appDynamicStyles]="{'padding': {zoomout: '16px 16px 0px 16px', zoomin: '8px 12px 0px 12px'} }">
        <mat-card-title class="font-semibold" [appDynamicStyles]="{ 'font-size': {zoomout: '18px', zoomin: '14.5px'} }">
            {{card.title}}
        </mat-card-title>
    </mat-card-header>
    <mat-divider class="static divide-black opacity-12 ml-3 mr-3"></mat-divider>
    <ng-container 
        *ngTemplateOutlet="this[card.id]"></ng-container>
</mat-card>


<!-- Week Flag -->
<ng-template #weekFlag>
    <ng-container *ngTemplateOutlet="flag; context: {flagType: 'Week'}"></ng-container>
</ng-template>

<!-- Month Flag -->
<ng-template #monthFlag>
    <ng-container *ngTemplateOutlet="flag; context: {flagType: 'Month'}"></ng-container>
</ng-template>

<!-- Notifications -->
<ng-template #notifications>
    <div class="flex flex-col flex-auto items-center overflow-y-scroll">
        <!-- If there is no Flag Reminder -->
        <div *ngIf="this.flagReminderMsg == null" class="py-6 px-8 flex flex-col items-center justify-center">
            <div class="flex flex-col items-center justify-center w-12 h-12 rounded-full nonotifications--background">
                <mat-icon
                    class="nonotifications--color"
                    [svgIcon]="'heroicons_outline:bell'"></mat-icon>
            </div>
            <div class="font-semibold tracking-tight" [appDynamicStyles]="{ 'font-size': {zoomout: '16px', zoomin: '14px'} }">
                No notifications
            </div>
        </div>
        <!-- If there is Flag Reminder -->
        <div *ngIf="this.flagReminderMsg != null" class="font-sans notifications--font py-2 px-9" [appDynamicStyles]="{ 'font-size': {zoomout: '13.5px', zoomin: '11px'} }">
            <!-- Week Flag Reminder -->
            <ng-container *ngIf="this.checkFlagDeadline !== null && this.checkFlagDeadline['periodWeekFlagMap'] !== null ">
                <ul *ngFor="let flagPeriod of this.checkFlagDeadline['periodWeekFlagMap'] | keyvalue">
                    <li *ngIf=" (!flagPeriod.value['isTSFlagPresent'] || !flagPeriod.value['isEXFlagPresent']) && flagPeriod.value['notifications'].length > 0" class="list-disc">
                        {{ flagPeriod.value['notifications'][0] }}
                    </li>
                </ul>
            </ng-container>
            <!-- Month Flag Reminder -->
            <ng-container *ngIf="this.checkFlagDeadline !== null && this.checkFlagDeadline['periodMonthFlagMap'] !== null" >
                <ul *ngFor="let flagPeriod of this.checkFlagDeadline['periodMonthFlagMap'] | keyvalue">
                    <li *ngIf=" (!flagPeriod.value['isTSFlagPresent'] || !flagPeriod.value['isEXFlagPresent']) && flagPeriod.value['notifications'].length > 0" class="list-disc">
                        {{ flagPeriod.value['notifications'][0] }}
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #flag let-flagType="flagType">
    <mat-card-content>
        <!-- Select Date Range -->
        <ng-select
        (change)="onSelectChangeHandler()"
        [(ngModel)]="selected"
        [items]="options"
        [virtualScroll]="true"
        bindLabel="option"
        placeholder="Select {{ flagType }}"
        appendTo="body"
        dropdownPosition="bottom"
        [appDynamicStyles]="{ 'font-size': {zoomout: '14px', zoomin: '12px'} }"
        >
        </ng-select>
    </mat-card-content>
    <mat-card-actions class="flex flex-row justify-center gap-3 py-0">
        <ng-container *ngTemplateOutlet="button; context: {flagType: flagType, flagMetric: 'Time'}"></ng-container>
        <ng-container *ngTemplateOutlet="button; context: {flagType: flagType, flagMetric: 'Expense'}"></ng-container>       
    </mat-card-actions>
</ng-template>

<!-- Time and Expense Flag Buttons -->
<ng-template #button let-flagType="flagType" let-flagMetric="flagMetric">
    <button mat-raised-button matTooltip="{{ flagType }} {{ flagMetric }} Complete"
            id = "{{ flagType }} - {{ flagMetric }}"
            matTooltipClass="sidenavTooltip"
            class="flag_button--design w-full"
            [disabled]="flagDisabledHandler(flagMetric)"
            (click)="flagClickHandler(flagMetric)">
        <div class="flex flex-row gap-1 justify-center items-center">
            <div>
                {{ flagMetric }}
            </div>
            <div>
                <mat-icon [svgIcon]="'flag'"></mat-icon>
            </div>
        </div>
    </button>
</ng-template>
