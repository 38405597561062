import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportBlueprint } from '../common/blueprints/report-blueprint';
import { ReportEmbedService } from './report-embed.service';
import { ReportEmbedInitializerService } from './report-embed.initializer';


@Component({
  selector: 'app-report-embed',
  templateUrl: './report-embed.component.html',
  styleUrls: ['./report-embed.component.scss']
})
export class ReportEmbedComponent implements OnInit {

  routeParams: any;
  reportData: any;
  filterData: any;
  headerData: any;
  isPageInitialized: boolean = false;
  reportRender: boolean = true;
  
  constructor(private _route: ActivatedRoute, 
    private _reportEmbedService: ReportEmbedService,
    private _reportEmbedInitializer: ReportEmbedInitializerService) { }

  ngOnInit(): void {
    this._route.params.subscribe((params)=>{
      this.routeParams = params;
      this.reportInitializer();
      this._reportEmbedInitializer.initializeReportEmbed().then(data=>{
        this.isPageInitialized = true;
      })
    })
  }

  reportInitializer(){
    this.headerData = ReportBlueprint.getPageTemplateSection(this.routeParams.reporturl, "Header")
    this.reportData = ReportBlueprint.getPageTemplateSection(this.routeParams.reporturl, "ReportArea")
  }

  showReportFn(eventData: boolean) {
    this.reportRender = eventData;
  }
}
