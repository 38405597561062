import { Injectable } from "@angular/core";
import { CommonService } from "../common/services/common.service";

@Injectable({
    providedIn: "root",
})
export class ReportEmbedInitializerService {
    constructor(private _commonService: CommonService) { }

    initializeReportEmbed = async () => {
        await this._commonService.getAllClients('reports');
        await this._commonService.getAllCompanies('reports');
    }
}
