import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeEntryHeaderModule } from './time-entry-header/time-entry-header.module';
import { TimeEntryForPlanningComponent } from './time-entry-for-planning.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { DirectivesModule } from '../../common/directives/directives.module';



@NgModule({
  declarations: [TimeEntryForPlanningComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    AgGridModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDividerModule,
    MatNativeDateModule,
    MatMenuModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    TimeEntryHeaderModule
  ]
})
export class TimeEntryForPlanningModule { }
