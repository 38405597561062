<div class="flex flex-col sm:flex-row ssm:flex-row items-center md:items-start ssm:justify-center sm:justify-center md:justify-start flex-auto min-w-0 h-full login-page--background">
    <div class="md:flex md:justify-center w-full ssm:w-auto md:h-full py-8 px-4 sm:p-12 md:p-16 md:pt-24 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card bg-white ssm:rounded-2xl ssm:shadow flex-col relative container-mobile__setting">
      <div class="mips-login_footer flex flex-row gap-4 items-center absolute bottom-0 left-0 bg-slate-100 w-full justify-center">
        <div>
            <img src="../../../assets/images/logo/mips-logo-simple.png" alt="" class="mips-logo w-9">
        </div>
        <div class="font">
            For any queries reach out to <a href="mailto:MIPSDev@thorogood.com" class="underline">MIPS 2.0 Team</a>
        </div>
      </div>
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0 ssm:w-80 ssm:mx-0">
            <!-- Logo -->
            <div class="w-50">
                <img src="../../../assets/images/logo/Thorogood-main-logo.svg">
            </div>

            <!-- Title -->
            <div class="mt-8 text-2xl font uppercase">Sign In To MIPS 2.0</div>

            <!-- Sign in form -->
            <form
                class="mt-8"
                [formGroup]="signInForm"
                #signInNgForm="ngForm"
                (input)="checkAutofill()">

                <!-- Username field -->
                <mat-form-field class="w-full">
                    <mat-label class="font">Username</mat-label>
                    <input
                        id="username"
                        [(ngModel)]="userName"
                        matInput
                        [formControlName]="'username'" />
                    <mat-hint>
                        <span class="hint-error_text--design">
                            Firstname.Lastname
                        </span>
                    </mat-hint>
                    <mat-error class="hint-error_text--design" *ngIf="signInForm.get('username')?.hasError('required')">
                        Username is required
                    </mat-error>
                    <mat-error class="hint-error_text--design" *ngIf="!signInForm.get('username')?.hasError('required') && signInForm.get('username')?.hasError('invalidString')">
                        Enter a valid username - Firstname.Lastname
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full pt-4">
                    <mat-label class="font">Password</mat-label>
                    <input
                        id="password"
                        [(ngModel)]="password"
                        matInput
                        type="password"
                        [formControlName]="'password'"
                        #passwordField>
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon
                            class="icon-size-5"
                            *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error class="hint-error_text--design" *ngIf="signInForm.get('password')?.hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>


                <!-- Submit button -->
                <button
                    id="signInButton"
                    class="fuse-mat-button-large w-full mt-6 font login_button--design"
                    mat-flat-button
                    [disabled]="!signInForm.valid"
                    (click)="signIn()">
                    <span>
                        Sign In
                    </span>
                </button>

            </form>
        </div>
    </div>
    <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden dark:border-l">
    </div>
</div>
