import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntryModule } from '../entry/entry.module';
import { SharedModule } from 'app/shared/shared.module';
import { ExpenseEntryComponent } from './expense-entry.component';



@NgModule({
  declarations: [
    ExpenseEntryComponent
  ],
  imports: [
    CommonModule,
    EntryModule,
    SharedModule
  ]
})
export class ExpenseEntryModule { }
