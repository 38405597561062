import { NgModule } from "@angular/core";
import { FormBaselayoutComponent } from "./form-baselayout.component";
import { FormLayoutComponent } from "./form-layout/form-layout.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from "@angular/material/icon";
import { DirectivesModule } from "../common/directives/directives.module";

@NgModule({
    declarations: [FormBaselayoutComponent, FormLayoutComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        DirectivesModule
    ],
    exports: [FormBaselayoutComponent, FormLayoutComponent],
})
export class FormModule {

}