import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';;
import { BaselayoutComponent } from './baselayout/baselayout.component';
import { FlagComponent } from './actuals/flag/flag.component';
import { TimeEntryComponent } from './actuals/time-entry/time-entry.component';
import { ExpenseEntryComponent } from './actuals/expense-entry/expense-entry.component';
import { TimeDicerComponent } from './actuals/time-dicer/time-dicer.component';
import { MipsRouteGuard } from './common/utilities/mips.routeguard';
import { PagesRouteGuard } from './common/utilities/pages.routeguard';
import { ReportEmbedComponent } from './report-embed/report-embed.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AddProjectPageComponent } from './add-project-page/add-project-page.component';
import { TimeEntryForPlanningComponent } from './planning/time-entry/time-entry-for-planning.component';
import { AddClientComponent } from './add-client/add-client.component';

const mipsRoutes: Routes = [
    {
        path: 'core',
        component: BaselayoutComponent,
        canActivate: [MipsRouteGuard],
        canDeactivate: [PagesRouteGuard],
        canActivateChild: [MipsRouteGuard],
        children: [
            {
                path: 'actuals',
                children: [
                    {
                        path: '',
                        redirectTo: 'time',
                        pathMatch: 'full'
                    },
                    {
                        path: 'flag',
                        component: FlagComponent
                    },
                    {
                        path: 'time',
                        component: TimeEntryComponent,
                        canDeactivate: [PagesRouteGuard]
                    },
                    {
                        path: 'expense',
                        component: ExpenseEntryComponent,
                        canDeactivate: [PagesRouteGuard]
                    },
                    {
                        path: 'time-dicer',
                        component: TimeDicerComponent
                    },
                    // {
                    //     path: 'dummy',
                    //     component: DummyComponent
                    // }
                ]
            },
            {
                path: 'report-embed/:reporturl',
                component: ReportEmbedComponent
            },
            {
                path: 'project-details',
                children: [
                    // {
                    //     path: 'view',
                    //     component: ProjectDetailsComponent,
                    //     data: { mode: 'view' }
                    // },
                    {
                        path: 'add',
                        component: ProjectDetailsComponent,
                        canDeactivate: [PagesRouteGuard],
                        data: { mode: 'add' }
                    },
                    {
                        path: 'edit',
                        component: ProjectDetailsComponent,
                        canDeactivate: [PagesRouteGuard],
                        data: { mode: 'edit' }
                    }
                ]
                
            },
            {
                path: 'client',
                children: [
                    {
                        path: 'add-client',
                        component: AddClientComponent,
                    }
                ]
            },
            // {
            //     path: 'planning',
            //     children: [
            //         {
            //             path: 'time-entry',
            //             component: TimeEntryForPlanningComponent,
            //         }
            //     ]
            // }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(mipsRoutes)],
    exports: [RouterModule]
})
export class MipsRoutingModule { }