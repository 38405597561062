import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormModule } from "../form-baselayout/form.module";
import { AddProjectPageComponent } from "./add-project-page.component";

@NgModule({
    declarations: [AddProjectPageComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        FormModule
    ],
    exports: [AddProjectPageComponent],
})
export class AddProjectPageModule {

}