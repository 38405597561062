import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanActualComparisionDialogComponent } from './plan-actual-comparision-dialog/plan-actual-comparision-dialog.component';
import { TimeEntryComponent } from './time-entry.component';
import { EntryModule } from '../entry/entry.module';
import { SharedModule } from 'app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DirectivesModule } from '../../common/directives/directives.module';


@NgModule({
  declarations: [
    PlanActualComparisionDialogComponent,
    TimeEntryComponent
  ],
  imports: [
    CommonModule,
    EntryModule,
    SharedModule,
    MatIconModule,
    MatDialogModule,
    NgxChartsModule,
    DirectivesModule
  ]
})
export class TimeEntryModule { }
