import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ProjectDetailsBlueprint } from './project-details-blueprint';
import { ProjectDetailsInitializerService } from './project-details.initializer';
import { ProjectDetailsService } from './project-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { commonFunctions } from '../common/classes/common.class';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BehaviorSubject } from 'rxjs';
import { ProjectDetailsGridConfigModel } from '../common/models/project-details-grid-config.model';
import { GridDatePickerComponent } from '../common/custom-components/ag-grid-datepicker-component';
import { CommonService } from '../common/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { URLPaths } from '../common/utilities/urlPaths.config';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnDestroy, OnInit {
  @Input() generateDialog: boolean;
  components = {
    'GridDatePickerComponent': GridDatePickerComponent
  };
  blueprintData: any;
  mode: string;
  service: any;
  projectDetailsRowData: any;
  updateTable: any;
  examplearr: any;
  fieldDetails: any[];
  isPageInitialized: boolean = false;
  agGridColumnAPI: ColumnApi;
  selectedIndex: number = 0;
  addOtherDetailsDisabled: boolean = true;
  viewOtherDetailsDisabled: boolean = true;
  defaultActiveBooleanValues: string[] = ['YES', 'NO'];
  consultantList: string[];
  themeList: string[];
  projectDetailsContext: any
  gridTypeTA: string = "themeAllocation"
  gridTypePD: string = "prepaymentDetails"

  addAGColDefs: any;
  gridOptions: GridOptions = {};
  rowSelection: string;
  agGridAPI: GridApi;
  originalRow: any;
  gridIndex: number = 0;
  defaultColDef: any;

  selectedClient: any;
  selectedProject: any;

  consultantProjectRatesRowData: any = [];
  projectTasksRowData: any = [];
  themeAllocationRowData: any = [];
  prepaymentDetailsRowData: any = [];
  consultantProjectRatesColumns: any = [];
  consultantProjectRatesColumnDefs: any = [];
  projectTasksColumns: any = [];
  projectTasksColumnDefs: any = [];
  themeAllocationColumns: any = [];
  themeAllocationColumnDefs: any = [];
  prepaymentDetailsColumns: any = [];
  prepaymentDetailsColumnDefs: any = [];

  projectDetailsBlueprintData: any = {};
  projectDetailsFieldDetails: any = [];
  themeALlocationBlueprintData: any = {};
  inoviceDetailsBlueprintData: any = {};
  invoiceDetailsFieldDetails: any = {};
  themeAllocationFieldDetails: any = [];
  prepaymentDetailsBlueprintData: any = {};
  prepaymentDetailsFieldDetails: any = [];
  consultantProjectRatesBlueprintData: any = {};
  consultantProjectRatesFieldDetails: any = [];
  projectTasksBlueprintData: any = {};
  projectTasksFieldDetails: any = [];

  fieldValuesOfAllForms: BehaviorSubject<any> = new BehaviorSubject<any>({});
  viewOtherDetailsForEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cascadingMetaDetaForInvoice: any = {};
  // For View Mode

  private modeSubject = new BehaviorSubject<string>('edit'); // Default mode is 'add'
  mode$ = this.modeSubject.asObservable();

  tabGroup = [
    {
      tabDisplayName: 'Consultant Project Rates',
      tabName: 'consultantProjectRates',
      tabRowData: this.consultantProjectRatesRowData,
      tabColumnDefs: this.consultantProjectRatesColumnDefs,
    },
    {
      tabDisplayName: 'Project Tasks',
      tabName: 'projectTasks',
      tabRowData: this.projectTasksRowData,
      tabColumnDefs: this.projectTasksColumnDefs,

    },
    {
      tabDisplayName: 'Theme Allocation',
      tabName: 'themeAllocation',
      tabRowData: this.themeAllocationRowData,
      tabColumnDefs: this.themeAllocationColumnDefs,
    },
    {
      tabDisplayName: 'Prepayment Details',
      tabName: 'prepaymentDetails',
      tabRowData: this.prepaymentDetailsRowData,
      tabColumnDefs: this.prepaymentDetailsColumnDefs,
    },
  ];

  projectTasks: any;
  entryService: any;
  rowClassRules: any;

  private isEditing = false;
  @ViewChild('agGrid', { static: false }) agGrid!: ElementRef;

  constructor(
    private _projectDetailsInitializer: ProjectDetailsInitializerService,
    private _projectDetailsService: ProjectDetailsService,
    private _activatedRoute: ActivatedRoute,
    private _store: Store,
    private _commonService: CommonService,
    private _dialog: MatDialog,
    private renderer: Renderer2,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this._projectDetailsService.projectDetailTableRowData.subscribe((data) => {
      this.projectDetailsRowData = data;
    })

    this._projectDetailsService.projectDetailTableRowData.subscribe((data) => {
      this.projectDetailsRowData = data;
      if (this.mode == 'add' || this.mode == 'edit') {
        this.gridRows();
      }
    });

    this._projectDetailsService.selectedIndex$.subscribe(index => {
      this.selectedIndex = index;
    });

    this._projectDetailsService.selectedProject$.subscribe(project => {
      this.selectedProject = project;
    });

    this._projectDetailsService.selectedClient$.subscribe(client => {
      this.selectedClient = client;
    });

    this._projectDetailsService.projectSelectedBoolean.subscribe((data) => {
      this.viewOtherDetailsDisabled = data;
      if (!this.viewOtherDetailsDisabled) {
        this.gridColumns();
        this.gridRows();
      }
    });

    this._projectDetailsService.setAddProjectOtherFormsDisabled(true);

    this.consultantList = this._projectDetailsService.getEmployees();
    this.themeList = this._projectDetailsService.getThemeList();

    this._projectDetailsService.addProjectOtherFormsDisabled.subscribe((data) => {
      this.addOtherDetailsDisabled = data;
    })

    this.gridOptions = {
      onCellValueChanged: (event) => {
        this.handleCellValueChange(event);
      },
      onSelectionChanged: (event) => {
        this.onSelectionChanged(event);
      },
    };

    this.gridOptions.groupDefaultExpanded = 1;
    this.gridOptions.groupSelectsChildren = true;

    //This will get overrided by the style that we project in model.
    this.gridOptions.getRowStyle = (param) => {
      if (param.node.isSelected()) {
        return { background: 'lightblue' };
      }
      if (param.node.group) {
        return {
          background: '#6ac738',
          'font-weight': 'bold'

        };
      } else {
        if (param.node.data.dirty && param.node.data.dirty == true) {
          return { background: '#ffc107' };
        }
      }
    };
    this.gridOptions.suppressAggFuncInHeader = true;
    this.gridOptions.rowSelection = 'multiple';

    this.projectDetailsContext = this;
  }

  onCellEditingStarted(event: any) {
    this.isEditing = true;
  }

  onCellEditingStopped(event: any) {
    this.isEditing = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.isEditing && !this.isClickInsideGrid(event)) {
      this.agGridAPI.stopEditing();
    }
  }

  onModeChange(newMode: string): void {
    this.modeSubject.next(newMode); // Update the BehaviorSubject with the new mode

    // Navigate to a different route based on the mode
    if (newMode === 'add') {
      this.router.navigate([URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_ADD]); // Change to your desired route
    } else if (newMode === 'edit') {
      this.router.navigate([URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_EDIT]); // Change to your desired route
    }
    this.selectedIndex = 0;
  }

  private isClickInsideGrid(event: MouseEvent): boolean {
    const gridElement = document.querySelector('.ag-theme-alpine');
    return gridElement?.contains(event.target as Node) ?? false;
  }

  //Set boolean whether if any unsaved changes are present
  setEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
    this.entryService.entryIsUnsavedChangesPresent.next(
      isUnsavedChangesPresent
    );
  }

  //Delete Button disable/enable
  onSelectionChanged(event) {
    const selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length > 0) {
      this._projectDetailsService.deletebuttonsEnable(event.api.getValue('GridName', event.api.getRowNode('0').toString()))
    } else {
      this._projectDetailsService.deletebuttonsDisable(event.api.getValue('GridName', event.api.getRowNode('0').toString()))
    }
    event.api.redrawRows({ rowNodes: selectedNodes });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this._projectDetailsService.originalRowData.length = 0;
  }

  //Save button enabled (disabled when we click the button [in formLayout])
  handleCellValueChange(event) {
    event.data.isModified = true;
    this._projectDetailsService.savebuttonsEnable(event.api.getValue('GridName', event.api.getRowNode(event.rowIndex.toString())).toString())
  }

  onCellValueChanged(event: any) {

    event.data.isModified = true;
    event.node.data["dirty"] = true;
    event.api.applyTransaction({
      update: [event.node.data],
    })
    event.api.redrawRows({ rowNodes: [event.node] });
    const { data, colDef, rowIndex, oldValue } = event;
    let cellKey = '';

    if (this.selectedIndex == 1) {
      cellKey = `${data.Consultant}_${data.Month}_${colDef.field}`;
    }
    else if (this.selectedIndex == 0) {
      cellKey = `${data.Theme}_${data.Allocation_Percent}_${colDef.field}`;
    }

    this._projectDetailsService.originalRowData[cellKey] = oldValue;
  }

  nextStep() {
    this.addOtherDetailsDisabled = false;
    this.selectedIndex += 1;
  }

  projectTasksColumnTransformations(jsonObj) {
    jsonObj.forEach((element) => {
      element.Active = commonFunctions.booleanToString(element.Active);
      element.Default = commonFunctions.booleanToString(element.Default);
      if (element.Fixed_Cost_Start) {
        element.Fixed_Cost_Start = element.Fixed_Cost_Start.split('T')[0];
      }
      if (element.Fixed_Cost_End) {
        element.Fixed_Cost_End = element.Fixed_Cost_End.split('T')[0];
      }
    });
    return jsonObj;
  }

  gridColumns() {
    let projectDetailsGridConfig = new ProjectDetailsGridConfigModel();
    this.consultantProjectRatesColumnDefs = this.createGridColumnDefs(
      projectDetailsGridConfig.consultantProjectRatesColumns
    );

    this.projectTasksColumnDefs = this.createGridColumnDefs(
      projectDetailsGridConfig.projectTasksColumns
    );

    this.themeAllocationColumnDefs = this.createGridColumnDefs(
      projectDetailsGridConfig.themeAllocationColumns
    );
    this.prepaymentDetailsColumnDefs = this.createGridColumnDefs(
      projectDetailsGridConfig.prepaymentDetailsColumns
    );
    // this.tabGroup[0].tabColumnDefs = this.themeAllocationColumnDefs;
    this.tabGroup[0].tabColumnDefs = this.consultantProjectRatesColumnDefs;
    this.tabGroup[1].tabColumnDefs = this.projectTasksColumnDefs;
    this.tabGroup[2].tabColumnDefs = this.themeAllocationColumnDefs;
    this.tabGroup[3].tabColumnDefs = this.prepaymentDetailsColumnDefs;
  }

  gridRows() {
    this.consultantProjectRatesRowData =
      this.projectDetailsRowData.consultantProjectRates;
    this.projectTasksRowData = this.projectTasksColumnTransformations(
      this.projectDetailsRowData.projectTasks
    );
    this.themeAllocationRowData =
      this.projectDetailsRowData.projectThemeAllocation;
    this.prepaymentDetailsRowData =
      this.projectDetailsRowData.projectPrepaymentDetails;

    // this.tabGroup[0].tabRowData = this.themeAllocationRowData;
    this.tabGroup[0].tabRowData = this.consultantProjectRatesRowData;
    this.tabGroup[1].tabRowData = this.projectTasksRowData;
    this.tabGroup[2].tabRowData = this.themeAllocationRowData;
    this.tabGroup[3].tabRowData = this.prepaymentDetailsRowData;
  }

  createGridColumnDefs(gridColumns: any) {
    let gridColumnDefs = [];
    gridColumnDefs = gridColumns.map((item: any) => {

      return {
        headerName: item.headerName.split('_').join(' '),
        field: item.field,
        resizable: true,
        sortable: true,
        editable: this.mode == "view" ? false : item.editable,
        cellStyle: this.mode == "view" ? false : item.cellStyle,
        checkboxSelection: this.mode == "view" ? false : item.checkboxSelection,
        headerCheckboxSelection: this.mode == "view" ? false : item.headerCheckboxSelection,
        hide: item.hide ? true : false,
        cellEditor: item.cellEditor ? item.cellEditor : null,
        cellRenderer: item.cellRenderer ? item.cellRenderer : null, // adding cellRendrer for edit mode only
        valueGetter: item.valueGetter ? item.valueGetter : null,
        cellEditorParams: item.cellEditorParams ? item.cellEditorParams : null,
        valueFormatter: item.valueFormatter ? item.valueFormatter : null,
        flex: 1
      };
    });

    for (let index in gridColumnDefs) {
      if (gridColumnDefs[index].cellEditorParams) {
        gridColumnDefs[index].cellEditorParams.values = eval(gridColumnDefs[index].cellEditorParams.values)
      }
    }
    return gridColumnDefs;
  }

  OnGridReady(params): void {
    this.agGridAPI = params.api;
    this.agGridColumnAPI = params.columnApi;
    this._projectDetailsService.agGrids[this.tabGroup[this.gridIndex].tabName] = params.api;
    this.gridIndex += 1;
    this.autoSizeAllColumns();
  }

  autoSizeAllColumns(): void {
    const allColumnIds: string[] = [];
    this.agGridColumnAPI.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    // this.agGridColumnAPI.autoSizeColumns(allColumnIds, false);
  }

  ngOnInit() {
    this.mode = this._activatedRoute.snapshot.data.mode;
    this.modeSubject = new BehaviorSubject<string>(this.mode);
    this.mode$ = this.modeSubject.asObservable(); // Expose the Observab
    this.service = ProjectDetailsService;
    this.getBlueprintData();
    this._projectDetailsInitializer
      .initializeProjectDetailsData()
      .then((data) => {
        this.isPageInitialized = true;
      });

    this.service.selectedProject$.subscribe(project => {
      this.selectedProject = project;
    });

    this.service.selectedClient$.subscribe(client => {
      this.selectedClient = client;
    });

    this.selectedIndex = 0;
  }

  clearGridData() {
    this.service.setProjectDetailRows({});
  }

  ngOnDestroy(): void {
    // Disable all the tabs once component is destroyed
    this._projectDetailsService.setProjectSelectedBoolean(true);
  }

  getBlueprintData() {
    const blueprintDetails = ProjectDetailsBlueprint.getProjectBlueprintData(
      this.mode
    );

    this.projectDetailsBlueprintData = blueprintDetails.projectDetailsBlueprint
      ? blueprintDetails.projectDetailsBlueprint
      : {};
    this.projectDetailsFieldDetails =
      blueprintDetails.projectDetailsFieldDetails
        ? blueprintDetails.projectDetailsFieldDetails
        : [];

    this.inoviceDetailsBlueprintData = blueprintDetails.invoiceDetailsBlueprint
      ? blueprintDetails.invoiceDetailsBlueprint
      : {};
    this.invoiceDetailsFieldDetails =
      blueprintDetails.invoiceDetails
        ? blueprintDetails.invoiceDetails
        : [];

    if (this.mode == 'add' || this.mode === 'edit') {

      this.themeALlocationBlueprintData =
        blueprintDetails.themeAllocationBlueprint;
      this.themeAllocationFieldDetails =
        blueprintDetails.themeAllocationFieldDetails;

      this.prepaymentDetailsBlueprintData =
        blueprintDetails.prepaymentDetailsBlueprint;
      this.prepaymentDetailsFieldDetails =
        blueprintDetails.prepaymentDetailsFieldDetails;

      this.consultantProjectRatesBlueprintData =
        blueprintDetails.consultantProjectRatesBlueprint;
      this.consultantProjectRatesFieldDetails =
        blueprintDetails.consultantProjectRatesFieldDetails;

      this.projectTasksBlueprintData = blueprintDetails.projectTasksBlueprint;
      this.projectTasksFieldDetails = blueprintDetails.projectTasksFieldDetails;

      this.inoviceDetailsBlueprintData =
        blueprintDetails.invoiceDetailsBlueprint;
      this.invoiceDetailsFieldDetails = blueprintDetails.invoiceDetails;
      this.gridColumns();
    }
  }
}
