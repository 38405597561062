import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'app/core/mips/common/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiheaderService {

  private static API_KEY: string = null;
  private static apiHeader: HttpHeaders = (new HttpHeaders()).set('Content-Type', 'application/json');

  constructor(
  ) {
  }


  //Return the API Headers with the Auth token 
  public static getHeaders(): HttpHeaders {
    ApiheaderService.API_KEY = LocalStorageService.getLocalStorageValueBasedOnKeys('API_KEY');

    return ApiheaderService.apiHeader.set('Authorization', 'Token ' + ApiheaderService.API_KEY);
  }
}
