import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { TimeDicerConfigModel } from '../../../common/models/time-dicer-config.model';
import { commonFunctions } from '../../../common/classes/common.class';
import { UserModel } from '../../../common/models/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeDicerConfigService } from '../../../common/services/time-dicer-config.service';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';

@Component({
  selector: 'app-time-dicer-bookmark-config-panel',
  templateUrl: './time-dicer-bookmark-config-panel.component.html',
  styleUrls: ['./time-dicer-bookmark-config-panel.component.scss'],
})
export class TimeDicerBookmarkConfigPanelComponent implements OnInit {
  deviceInfo = null;
  timeDicerConfigList: TimeDicerConfigModel[] = [];
  currentConfig: TimeDicerConfigModel = new TimeDicerConfigModel();
  user: UserModel = new UserModel();
  showLoader: boolean = true;
  isSmallScreenFlag: boolean;
  isLargeScreenFlag: boolean;

  /**
   * Constructor
   */

  constructor(
    public dialogRef: MatDialogRef<TimeDicerBookmarkConfigPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: TimeDicerConfigModel,
    private _store: Store,
    private _timeDicerConfigService: TimeDicerConfigService
  ) {
    this.currentConfig = dialogData;
    this.getTimeDicerConfig();
    this.isSmallScreenFlag = false;
    this.isLargeScreenFlag = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.checkScreenSize();
    this._store.select(getLoggedInUser).subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
  }

  // New Button Clicked
  addNewConfigButtonClicked(): void {
    this.currentConfig = new TimeDicerConfigModel();
    this.currentConfig.id = null;
    this.currentConfig.status = 'create';
  }

  // Check for Screen Size
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  // Save Button Clicked
  saveConfigButtonClicked(): void {
    if (!commonFunctions.isNullOrUndefined(this.currentConfig.id)) {
      if (this.currentConfig.User === this.user.id) {
        this.currentConfig.status = 'update';
      } else {
        this.currentConfig.User = null;
        this.currentConfig.status = 'create';
      }
    }
    this.dialogRef.close(this.currentConfig);
  }

  // Delete Button Clicked
  deleteConfigButtonClicked(): void {
    this.currentConfig.status = 'delete';
    this.dialogRef.close(this.currentConfig);
  }

  // Load Button Clicked
  loadConfigButtonClicked(): void {
    this.currentConfig.status = 'load';
    this.dialogRef.close(this.currentConfig);
  }

  // Get Bookmarks' configuration
  getTimeDicerConfig(): void {
    this._timeDicerConfigService
      .getSavedTimeDicerConfig()
      .subscribe((values) => {
        this.timeDicerConfigList = values.filter((obj) => {
          return obj['User'] === this.user.id;
        });
        this.showLoader = false;
      });
  }

  // Set current configuration
  configuration(current): void {
    this.currentConfig = current;
  }

  // Function to check the Screen Size
  checkScreenSize() {
    this.isSmallScreenFlag = window.innerWidth < 768; // Example: small screens have width less than 768px
    this.isLargeScreenFlag = !this.isSmallScreenFlag;
  }

  isSmallScreen(): boolean {
    return this.isSmallScreenFlag;
  }

  isLargeScreen(): boolean {
    return this.isLargeScreenFlag;
  }
}
