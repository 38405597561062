import { Component } from '@angular/core';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { Store } from '@ngrx/store';
import { ConsultantModel } from 'app/core/mips/common/models/consultant.model';

@Component({
    selector    : 'app-nav-user-country',
    templateUrl : './nav-user-country.component.html',
    styleUrls   : ['./nav-user-country.component.scss']
})
export class NavUserCountryComponent {

    currentUser : ConsultantModel = null;

    constructor(
        private _Store: Store
    )
    {
        this._Store.select(getCurrentUser).subscribe((data)=>{
            this.currentUser = data;
        })
    }

}