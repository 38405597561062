<div class="header-screen">
    <form
      [formGroup]="entryForm"
      class="flex flex-wrap flex-col justify-center tb:flex-row w-full px-2"
      [appDynamicStyles]="{ 'column-gap': { zoomout: '32px', zoomin: '28px' },  'row-gap': { zoomout: '16px', zoomin: '8px' }}"
    >
      <div class="flex flex-col tb:flex-row w-full tb:space-x-4 space-y-2 tb:space-y-0">
        <!-- Dropdown 1 -->
        <ng-select
          class="w-full tb:w-1/3 mb-2 tb:mb-0"
          [items]="clientList"
          bindLabel="name"
          bindValue="id"
          placeholder="All Clients"
          formControlName="clientDropDown"
          multiple="true"
        >
        </ng-select>
  
        <!-- Dropdown 2 -->
        <ng-select
          class="w-full tb:w-1/3 mb-2 tb:mb-0"
          [items]="[]"
          bindLabel="name"
          bindValue="id"
          placeholder="Select Project"
          formControlName="projectDropDown"
        >
        </ng-select>
  
        <!-- Dropdown 3 -->
        <ng-select
          class="w-full tb:w-1/3"
          [items]="[]"
          bindLabel="name"
          bindValue="id"
          placeholder="Select Employee"
          formControlName="employeeDropDown"
        >
        </ng-select>

        <!-- Date Range Picker -->
        <div class="flex items-center w-full mb-2 tb:w-1/3 tb:mb-0 date-picker">
            <mat-form-field class="w-full">
              <mat-date-range-input [rangePicker]="picker" [formGroup]="entryForm">
                <input
                  matStartDate
                  formControlName="start"
                  [placeholder]="weekStart"
                  (click)="picker.open()"
                  readonly
                />
                <input
                  matEndDate
                  formControlName="end"
                  [placeholder]="weekEnd"
                  (click)="picker.open()"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker ></mat-date-range-picker>
            </mat-form-field>
        </div>
  
        <!-- Checkbox 1 -->
        <div class="flex items-center tb:w-1/6 mb-2 tb:mb-0">
          <input
            type="checkbox"
            id="checkbox1"
            formControlName="firstCheckbox"
            class="mr-2"
          />
          <label for="checkbox1" class="text-sm">Show Clashes</label>
        </div>
  
        <!-- Checkbox 2 -->
        <div class="flex items-center tb:w-1/6 mb-2 tb:mb-0">
          <input
            type="checkbox"
            id="checkbox2"
            formControlName="secondCheckbox"
            class="mr-2"
          />
          <label for="checkbox2" class="text-sm">Charged</label>
        </div>
  
      </div>
    </form>
  </div>