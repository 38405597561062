import _ from "lodash";

export class commonFunctions {
  
  //Check if the value is null or undefined and return a boolean
  static isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  static isNullOrUndefinedOrEmptyString(value: any) {
    return value === null || value === undefined || value === '';
  }

  //Convert the case of the incoming text from lower to upper case and vice versa
  static convertCase(value, toUpper): string{
    if (!commonFunctions.isNullOrUndefined(value)) {
        if (toUpper) {
            return value.toLocaleUpperCase();
        }
        else {
            return value.toLocaleLowerCase();
        }
    }
    return value;
  }

  static removeDuplicatesAndSort(arr: any[]): any[] {
    return _.uniq(arr).sort();
  }
  
  static isObject(val: any) {
    return typeof val === 'object';
  }

  static booleanToString(val: boolean) {
    // custom boolean to string converter
    if(typeof val == 'boolean') {
      return (val ? 'YES' : 'NO');
    }
    else {
      return val;
    }
  }

  static Stringtoboolean(val: string) : boolean {
    // custom boolean to string converter
    return (val == 'YES' ? true : false);
  }

  static booleanToInteger(val: boolean) {
    return (val ? 1 : 0);
  }

  static round(val: number) {
    return Math.round((val + Number.EPSILON) * 100) / 100;
  }

  static callFunction(functionName, ...params) {
    // put all the params in an array and call this function
    let values;
    const func = this[functionName];
    if (typeof func === "function") {
        values = func.apply(this, ...params);
    }        
    return values;
  }

  static extractNumericPart(value: string) {
    // Regular expression to extract numeric part
    const numericPart = value.match(/\d+/) ? parseInt(value.match(/\d+/)[0]) : 0;
    // If numeric part exists, parse it to integer, otherwise return 0
    return numericPart;
  }
}