import {Injectable, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CurrencyModel} from '../models/currency.model';
import {map} from 'rxjs/operators';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { commonFunctions } from '../classes/common.class';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService implements OnInit {
    currencyList: CurrencyModel[];

    constructor(private _http: HttpClient) {
    }

    //Fetch the currency details
    getCurrencyDetails(): Observable<any> {
        if (commonFunctions.isNullOrUndefined(this.currencyList)) {
            return this._http.get<CurrencyModel[]>(URLPaths.URL_CURRENCY, {headers: ApiheaderService.getHeaders()}).pipe(
                map(res => {
                    this.currencyList = res;
                    return res;
                }));
        }
        return of(this.currencyList);

    }

    ngOnInit(): void {
    }


}
