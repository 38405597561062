import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { ActionTypes } from "./logoutUser/logoutUser.action";


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['currentUser', 'loggedInUser',
    'timeEntryGridConfig' ], rehydrate: true })(reducer);
  }

  export function clearStoreData(reducer) {
    return function (state, action) {
  
      if (action.type === ActionTypes.LOGOUT) {
        state = undefined;
      }
  
      return reducer(state, action);
    };
  }