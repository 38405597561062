import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateUtility } from '../common/utilities/date.utility';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { LocalStorageService } from '../common/services/local-storage.service';
import { CommonService } from '../common/services/common.service';
import moment from 'moment';
import { ConsultantDetailsService } from '../common/services/consultant-details.service';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';

@Injectable({
    providedIn: 'root'
})
export class ReportEmbedService {

    filterDataEmitterFactory: BehaviorSubject<Map<any, any>> = new BehaviorSubject<Map<any, any>>(null);
    currentUser: string;
    loggedInUserEmployee: string;
    consultantDetails: any;
    defaultCompanyName: any = "";
    defaultClientName: any = "";
    isInitialFilterSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    companyDetails = [];
    clientDetails = [];
    defaultUser = 'All Employees';
    loggedInUserCountry: any = "";
    transformFilterData: Map<string, any> = new Map<string, any>();



    constructor(private _store: Store, private _commonService: CommonService, private _consultantDetailsService: ConsultantDetailsService) {
        this._store.select(getLoggedInUser).subscribe((data) => {
            this.loggedInUserEmployee = data.userProfile.employee;
        })
        this._store.select(getCurrentUser).subscribe((data) => {
            this.currentUser = data.Employee;
            // this.currentUserCountry = data.Company_Name;
        })
        // this.consultantDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('Consultants_Detail'));
        this._consultantDetailsService.getConsultantDetails().subscribe((values) => {
            this.consultantDetails = values;
        });
    }

    dates() {
        return DateUtility.createDateType();
    }

    getConsultantNames() {
        let consultantNames = [];
        this.consultantDetails.forEach((consultant) => {
            if (consultant["Employee"]) {
                consultantNames.push(consultant["Employee"]);
            }
        });
        consultantNames.push('All Employees')
        return consultantNames;
    }

    getCompany() {
        // For Leave Details Company filter, default value is loggedInUserCountry
        this.consultantDetails.forEach((consultant) => {
            if(this.loggedInUserEmployee == consultant.Employee){
                this.loggedInUserCountry = consultant.Company_Name;
            }
        })
        let companyNames = [];
        this.companyDetails.forEach((clients) => {
            companyNames.push(clients["Company_Name"]);
        })
        this.defaultCompanyName = companyNames[0];
        return companyNames;
    }

    getClients() {
        let clientNames = [];
        this.clientDetails.forEach((clients) => {
            clientNames.push(clients["ClientDescription"]);
        })
        this.defaultClientName = clientNames[0];
        return clientNames;
    }

    setCulture() {
        return LocalStorageService.getLocalStorageValueBasedOnKeys('country_culture')
    }

    setPlanVersion() {
        return 27
    }

    setStartMonth(displayValue) {
        return moment(displayValue, 'MM-DD-YYYY').startOf('month').format("MM-DD-YYYY")
    }

    setEndMonth(displayValue) {
        return moment(displayValue, 'MM-DD-YYYY').endOf('month').format("MM-DD-YYYY")
    }

    setCompanyName(displayValue) {
        return this.companyDetails.find(e => e.Company_Name == displayValue).Company_id;
    }

    setClientName(displayValue) {
        return this.clientDetails.find(e => e.ClientDescription == displayValue).Client;
    }

    setUserName(displayValue) {
        if(displayValue == 'All Employees'){
            return 9999;
        }
        return displayValue.toUpperCase();
    }

    callFunction(functionName, params) {
        let values;
        const func = this[functionName];
        if (typeof func === "function") {
            values = func.apply(this, [params]);
        }
        return values;
    }

    reportMetaDataConstructor(filterDetails) {
        if (!this.clientDetails.length || !this.companyDetails.length) {
            this.clientDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('clients_reports'));
            this.companyDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('companies_reports'));
        }
        let filterData = [];
        this.transformFilterData.clear();
        for (let index in filterDetails) {
            filterData.push({
                "filterCode": filterDetails[index].filterCode,
                "filterName": filterDetails[index].filterName,
                "filterValues": this.callFunction(filterDetails[index].filterValues, null),
                "filterType": "dropdown",
                "filterDisplayName": filterDetails[index].filterDisplayName,
                "defaultValue": eval(filterDetails[index].defaultValue),
            })
            if (filterDetails[index].isDateField) {
                filterData[index].filterType = "date"
                filterData[index].defaultValue = filterDetails[index].defaultValue.format("MM-DD-YYYY")
            }
            if (filterDetails[index].isTransformationRequired) {
                this.transformFilterData.set(filterDetails[index].filterCode, filterDetails[index].transformFunction)
            }
        }
        return filterData;
    }

    reportParamsMetaDataConstructor(filterData, parameters) {
        let filters = {};
        for (let param in parameters) {
            if (filterData.get(parameters[param]) == undefined) {
                filters[param] = this.callFunction(parameters[param], null);
            }
            else if (this.transformFilterData.has(parameters[param])) {
                filters[param] = this.callFunction(this.transformFilterData.get(parameters[param]), filterData.get(parameters[param]))
            }
            else {
                filters[param] = filterData.get(parameters[param])
            }
        }
        return filters;
    }
}