<div [class.disabled]="disabled">
    <app-form-layout 
    
    [formBlueprint]="formBlueprint"
    [mode]="mode"
    [service]="service"
    [fieldDetails]="fieldDetails"
    class="h-full w-full"
    [fieldValuesOfAllForms]="fieldValuesOfAllForms"
    [selectedIndex] = "selectedIndex"
    [projectDetailsContext] = "projectDetailsContext"
    [disabled] = "disabled"
    [gridType] = "gridType"
    [rowData] = "rowData"
    [columnDefs] = "columnDefs"
    [gridOptions] = "gridOptions"
    [rowSelection] = "rowSelection"
    [rowDataPD]="rowDataPD"
    [columnDefsPD]="columnDefsPD"

>
</app-form-layout>
</div>

