import { Component } from '@angular/core';
import { Constants } from '../../common/utilities/constants.config';

@Component({
  selector: 'app-expense-entry',
  templateUrl: './expense-entry.component.html',
  styleUrls: ['./expense-entry.component.scss']
})
export class ExpenseEntryComponent {
  entryPageType: any;
  ngOnInit(): void {
    //Set Entry Page Type to Time Entry Page
    this.entryPageType = Constants.NAV_EXPENSE_ENTRY;
  }
}
