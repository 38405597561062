import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { ApiheaderService } from './apiheader.service';
import { Constants } from '../utilities/constants.config';
import { GridConfig } from '../../actuals/gridconfig.blueprint';

@Injectable({
  providedIn: 'root'
})
export class EntryGridConfigService {
  user: UserModel;

  constructor(
    private _http: HttpClient,
    private _store: Store)
  { 
    this._store.select(getLoggedInUser).subscribe((data)=>{
      this.user = data;
    })
  }

  //Get the saved Time/Expense AG Grid Config
  public getSavedEntryGridConfig(config: string): Observable<any> {
    if (config === Constants.NAV_TIME_ENTRY_ACTUALS) {
      return new Observable(observer => {
        observer.next(GridConfig.time_grid_config);
        observer.complete();
      });
    } else if (config === Constants.NAV_EXPENSE_ENTRY) {
      return new Observable(observer => {
        observer.next(GridConfig.expense_grid_config);
        observer.complete();
      });
    }
  
    // Handle other cases or return an appropriate default value if needed
    return new Observable(observer => {
      observer.error('Invalid config provided');
    });
  }
}
