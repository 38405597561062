import moment from "moment";

export class ReportBlueprint {
    private static _reportBlueprint = {
        companySummary: {
            reportDisplayName: "Company Summary",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"dateFrom dateTo company_id"',
                        '"dateFrom dateTo company_id"',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom" "dateTo" "company_id"',
                        '"dateFrom" "dateTo" "company_id"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "dateFrom",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setStartMonth"
                        },
                        {
                            filterCode: "ED",
                            filterName: "dateTo",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setEndMonth"
                        },
                        {
                            filterCode: "CO",
                            filterName: "company_id",
                            filterDisplayName: "Company",
                            filterValues: "getCompany",
                            isDateField: false,
                            defaultValue: "this.defaultCompanyName",
                            isTransformationRequired: true,
                            transformFunction: "setCompanyName"
                        }
                    ]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "Planning System/Report 1 Revenue Summary 6 Months",
                        parameters: {
                            dateFrom: "SD",
                            dateTo: "ED",
                            company_id: "CO",
                            plan_version_id: "setPlanVersion",
                            culture: "setCulture"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        projectDetailReport: {
            reportDisplayName: "Project Detail Report",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"clients company_id dateFrom dateTo"',
                        '"clients company_id dateFrom dateTo"',
                        '"clients company_id dateFrom dateTo"',
                        '"clients company_id dateFrom dateTo"',
                        '"clients company_id dateFrom dateTo"',
                        '"clients" "company_id" "dateFrom" "dateTo"',
                        '"clients" "company_id" "dateFrom" "dateTo"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "dateFrom",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setStartMonth"
                        },
                        {
                            filterCode: "ED",
                            filterName: "dateTo",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setEndMonth"
                        },
                        {
                            filterCode: "CO",
                            filterName: "company_id",
                            filterDisplayName: "Company",
                            filterValues: "getCompany",
                            isDateField: false,
                            defaultValue: "this.defaultCompanyName",
                            isTransformationRequired: true,
                            transformFunction: "setCompanyName"
                        },
                        {
                            filterCode: "CL",
                            filterName: "clients",
                            filterDisplayName: "Client",
                            isDateField: false,
                            filterValues: "getClients",
                            defaultValue: "this.defaultClientName",
                            isTransformationRequired: true,
                            transformFunction: "setClientName"
                        }
                    ]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "Planning System/Report 2 Client By Consultant Revenue by Week",
                        parameters: {
                            dateFrom: "SD",
                            dateTo: "ED",
                            company_id: "CO",
                            plan_version_id: "setPlanVersion",
                            clients: "CL",
                            culture: "setCulture"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        consultantAvailability: {
            reportDisplayName: "Consultant Availability Report",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"dateFrom dateTo company_id"',
                        '"dateFrom dateTo company_id"',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom dateTo" "company_id ."',
                        '"dateFrom" "dateTo" "company_id"',
                        '"dateFrom" "dateTo" "company_id"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "dateFrom",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setStartMonth"
                        },
                        {
                            filterCode: "ED",
                            filterName: "dateTo",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setEndMonth"
                        },
                        {
                            filterCode: "CO",
                            filterName: "company_id",
                            filterDisplayName: "Company",
                            filterValues: "getCompany",
                            isDateField: false,
                            defaultValue: "this.defaultCompanyName",
                            isTransformationRequired: true,
                            transformFunction: "setCompanyName"
                        }
                    ]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "Planning System/Report 3 Consultant Availability by Day Rate With Drill Details&rc:LinkTarget=_self",
                        parameters: {
                            dateFrom: "SD",
                            dateTo: "ED",
                            company_id: "CO",
                            plan_version_id: "setPlanVersion",
                            culture: "setCulture"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        monthlyReport: {
            reportDisplayName: "Monthly Report",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"dateFrom dateTo consultant"',
                        '"dateFrom dateTo consultant"',
                        '"dateFrom dateTo" "consultant ."',
                        '"dateFrom dateTo" "consultant ."',
                        '"dateFrom dateTo" "consultant ."',
                        '"dateFrom" "dateTo" "consultant"', 
                        '"dateFrom" "dateTo" "consultant"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "dateFrom",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: false,
                            transformFunction: ""
                        },
                        {
                            filterCode: "ED",
                            filterName: "dateTo",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: false,
                            transformFunction: ""
                        },
                        {
                            filterCode: "CN",
                            filterName: "consultant",
                            filterDisplayName: "Employee",
                            isDateField: false,
                            filterValues: "getConsultantNames",
                            defaultValue: "this.currentUser",
                            isTransformationRequired: false,
                            transformFunction: ""
                        }
                    ]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "Planning System/Report 4 Expense Items",
                        parameters: {
                            dateFrom: "SD",
                            dateTo: "ED",
                            consultant: "CN",
                            culture: "setCulture"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        consultantDetailReport: {
            reportDisplayName: "Consultant Detail Report",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"employee company_id dateFrom dateTo"',
                        '"employee company_id dateFrom dateTo"',
                        '"employee company_id dateFrom dateTo"',
                        '"employee company_id dateFrom dateTo"',
                        '"employee company_id dateFrom dateTo"',
                        '"employee" "company_id" "dateFrom" "dateTo"',
                        '"employee" "company_id" "dateFrom" "dateTo"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "dateFrom",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setStartMonth"
                        },
                        {
                            filterCode: "ED",
                            filterName: "dateTo",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: true,
                            transformFunction: "setEndMonth"
                        },
                        {
                            filterCode: "CO",
                            filterName: "company_id",
                            filterDisplayName: "Company",
                            filterValues: "getCompany",
                            isDateField: false,
                            defaultValue: "this.defaultCompanyName",
                            isTransformationRequired: true,
                            transformFunction: "setCompanyName"
                        },
                        {
                            filterCode: "CN",
                            filterName: "employee",
                            filterDisplayName: "Employee",
                            isDateField: false,
                            filterValues: "getConsultantNames",
                            defaultValue: "this.defaultUser",
                            isTransformationRequired: true,
                            transformFunction: "setUserName"
                        }

                    ]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "Planning System/Report 2 Consultant By Client Revenue by Week",
                        parameters: {
                            dateFrom: "SD",
                            dateTo: "ED",
                            company_id: "CO",
                            plan_version_id: "setPlanVersion",
                            employee: "CN",
                            culture: "setCulture"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        consultantAvailabilityChart: {
            reportDisplayName: "Consultant Availability Chart",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"Start_Date End_Date"',
                        '"Start_Date End_Date"',
                        '"Start_Date End_Date"',
                        '"Start_Date End_Date"',
                        '"Start_Date End_Date"',
                        '"Start_Date" "End_Date"',
                        '"Start_Date" "End_Date"'
                    ],
                    filterDetails: [
                        {
                            filterCode: "SD",
                            filterName: "Start_Date",
                            filterDisplayName: "Period Start",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().startOf('month'),
                            isTransformationRequired: false,
                            transformFunction: ""
                        },
                        {
                            filterCode: "ED",
                            filterName: "End_Date",
                            filterDisplayName: "Period End",
                            isDateField: true,
                            filterValues: "dates",
                            defaultValue: moment().endOf('month'),
                            isTransformationRequired: false,
                            transformFunction: ""
                        }
                    ]

                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reporturl: "US Planning Meeting Reports/Availability with Hours and End Date&rc:LinkTarget=_self",
                        parameters: {
                            Start_Date: "SD",
                            End_Date: "ED"
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
        leaveDetails: {
            reportDisplayName: "Leave Details",
            pageTemplateData: [
                {
                    sectionName: "Header",
                    className: [],
                    childAreaTemplate: [
                        '"CompanyId"',
                        '"CompanyId"',
                        '"CompanyId"',
                        '"CompanyId"',
                        '"CompanyId"',
                        '"CompanyId"',
                        '"CompanyId"',
                    ],
                    filterDetails: [{
                        filterCode: "CO",
                        filterName: "CompanyId",
                        filterDisplayName: "Company",
                        filterValues: "getCompany",
                        isDateField: false,
                        defaultValue: "this.loggedInUserCountry",
                        isTransformationRequired: true,
                        transformFunction: "setCompanyName"
                    }]
                },
                {
                    sectionName: "ReportArea",
                    className: [],
                    reportMetaData: {
                        reportServer: "",
                        reporturl: "LMS/Report 6 Leave Details&rc:LinkTarget=_self",
                        parameters: {
                            CompanyId: "CO",
                        }
                    },
                    reportInteractionRestriction: []
                }
            ]
        },
    }


    public static getReportPageData(page: string) {
        return this._reportBlueprint[page]
    }

    public static getPageTemplateHeaderData(page: string) {
        let reportPageData = this.getReportPageData(page);
        return reportPageData.pageTemplateData[0];

    }

    public static getListOfFilters(page: string) {
        let reportPageData = this.getReportPageData(page);
        return reportPageData.pageTemplateData[0].filterDetails;
    }

    public static getReportMetaData(page: string) {
        let reportPageData = this.getReportPageData(page);
        return reportPageData.pageTemplateData[1].reportMetaData;
    }

    public static getPageTemplateSection(page: string, sectionName: string) {
        let reportPageData = this.getReportPageData(page);
        return reportPageData.pageTemplateData.find(section => section.sectionName == sectionName);
    }
}