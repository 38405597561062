<div class="maintenance">
    <div
        class="flex flex-col justify-center items-center h-screen"
    >
        <!-- Loading the Home page -->
        <div class="flex flex-col justify-center items-center maintenance-form">
            <div class="pb-7">
                <img [src]="gifImagePath" class="w-32" />
            </div>
            <div class="title">{{ bigMessage }}</div>

            <div class="subtitle">
                {{ smallMessage }}
            </div>
        </div>
    </div>
</div>
