import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SnackbarService } from '../../../common/services/snackbar.service';
import { Constants } from '../../../common/utilities/constants.config';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { FlagEntryModel } from '../../../common/models/flag-entry.model';
import { FlagEntryService } from '../../../common/services/flag-entry.service';
import { UserModel } from '../../../common/models/user.model';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { Store } from '@ngrx/store';
import { getFlagReminderMsg } from 'app/core/store/flagReminderMsg/flagReminderMsg.selectors';
import { DateUtility } from '../../../common/utilities/date.utility';
import { WriteSilenceService } from '../../../common/services/write-silence.service';
import { error } from 'console';
import { Router } from '@angular/router';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';

@Component({
  selector: 'app-flag-container',
  templateUrl: './flag-container.component.html',
  styleUrls: ['./flag-container.component.scss'],
})
export class FlagContainerComponent implements OnInit {
  @Input() card: any;
  @Input() matOptionsValue: any;
  @Input() checkFlagDeadline: any;
  @ViewChild('weekFlag', {static:true}) weekFlag!: TemplateRef<any>;
  @ViewChild('monthFlag', {static:true}) monthFlag!: TemplateRef<any>;
  @ViewChild('notifications', {static:true}) notifications!: TemplateRef<any>;
  isWeekMode: boolean;
  weekStartDate: any;
  weekEndDate: any;
  monthStartDate: any;
  monthEndDate: any;
  changeSummaryData = false;
  getTimeStartDate: any;
  getTimeEndDate: any;
  options: any = [];
  selected: any;
  flagEntryParams: FlagEntryModel = new FlagEntryModel();
  currentUser: ConsultantModel;
  loggedInUser: UserModel;
  flagReminderMsg: string | null;
  tempDisableBtn: boolean = false;

  isWriteSilence: boolean;

  /**
   * Constructor
   */
  constructor(
    private _snackBarService: SnackbarService,
    private _flagEntryService: FlagEntryService,
    private _store: Store,
    private _writeSilenceService: WriteSilenceService,
    private _router: Router
  ) {
        this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this._store.select(getLoggedInUser).subscribe((data) => {
      this.loggedInUser = data;
    });
    this._flagEntryService
      .getIsWeekMode()
      .subscribe((data) => (this.isWeekMode = data));
    this._flagEntryService.getSummaryDateRange().subscribe((data) => {
      (this.weekStartDate = data.weekStartDate),
        (this.weekEndDate = data.weekEndDate),
        (this.monthStartDate = data.monthStartDate),
        (this.monthEndDate = data.monthEndDate);
    });
    this._store.select(getFlagReminderMsg).subscribe((data) => {
      this.flagReminderMsg = data;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */

  ngOnInit() {
    this._writeSilenceService.writeSilence.subscribe((data) => {
      this.isWriteSilence = data;
    });
    switch (this.card['id']) {
      case 'weekFlag':
        for (let week of this.matOptionsValue['weeks']) {
          this.options.push(week['dateRange']);
        }
        this.selected = this.matOptionsValue['selectedWeek']['dateRange'];
        break;
      case 'monthFlag':
        for (let month of this.matOptionsValue['months']) {
          this.options.push(month['monthRange']);
        }
        this.selected = this.matOptionsValue['selectedMonth']['monthRange'];
        break;
    }
  }

  // Configuration for the Mode and the View By Buttons
  onSelectChangeHandler(): void {
    const selectedvaluetostring = this.selected.toString();
    const dateFormat = new Date(selectedvaluetostring);
    if (this.card['id'] == 'weekFlag') {
      const week_partition = selectedvaluetostring.split(' - ');
      this.getTimeStartDate = week_partition[0];
      this.getTimeEndDate = week_partition[1];
      this._flagEntryService.setSummaryDateRange({
        weekStartDate: this.getTimeStartDate,
        weekEndDate: this.getTimeEndDate,
        monthStartDate: this.monthStartDate,
        monthEndDate: this.monthEndDate,
      });
      if (this.isWeekMode) {
        this.changeSummaryData = true;
      }
    } else if (this.card['id'] == 'monthFlag') {
      this.getTimeStartDate = DateUtility.formatDatefromDate(
        new Date(dateFormat.getFullYear(), dateFormat.getMonth(), 1)
      );
      this.getTimeEndDate = DateUtility.formatDatefromDate(
        new Date(dateFormat.getFullYear(), dateFormat.getMonth() + 1, 0)
      );
      this._flagEntryService.setSummaryDateRange({
        weekStartDate: this.weekStartDate,
        weekEndDate: this.weekEndDate,
        monthStartDate: this.getTimeStartDate,
        monthEndDate: this.getTimeEndDate,
      });
      if (!this.isWeekMode) {
        this.changeSummaryData = true;
      }
    }
    if (this.changeSummaryData) {
      this._snackBarService.showSnackBar(
        Constants.SNACKBAR_MSG_FLAG_SUMMARY_GRID_REFRESH,
        null,
        'info'
      );
      this._flagEntryService.getFlagTimeEntries(
        this.getTimeStartDate,
        this.getTimeEndDate,
        this.currentUser.Employee
      );
      this.changeSummaryData = false;
    }
  }

  // When Flag is clicked
  flagClickHandler(flagMetric: string): void {
    switch (this.card['id']) {
      case 'weekFlag':
        this.flagEntryParams.flagPeriod = 'Week';
        this.flagEntryParams.entryDate = this.weekEndDate;
        this.flagEntryParams.startDate = this.weekStartDate;
        break;
      case 'monthFlag':
        this.flagEntryParams.flagPeriod = 'Month';
        this.flagEntryParams.entryDate = this.monthEndDate;
        this.flagEntryParams.startDate = this.monthStartDate;
        break;
    }
    const flagBtn = document.getElementById(this.flagEntryParams.flagPeriod + ' - ' + flagMetric);
    // flagBtn.setAttribute('disabled', 'true');
    this.flagEntryParams.flagType = flagMetric;
    this.flagEntryParams.employee = this.currentUser.Employee;
    this.flagEntryParams.employeeMail = this.currentUser.Email;
    this.flagEntryParams.loginName = this.loggedInUser.username;
    this.flagEntryParams.CurrCode = 'GBP';
    this.tempDisableBtn = true;
    this._snackBarService.showSnackBar(
      Constants.SNACKBAR_MSG_WAITING_FOR_API_RESPONSE,
      null,
      'info'
    );
    this._flagEntryService.saveFlagEntry(this.flagEntryParams).subscribe(
      (response) => {
        if (response) {
          this._flagEntryService.getFlagEntry().subscribe((res) => {
            this.tempDisableBtn = false;
            this.checkFlagDeadline = res;
            // flagBtn.setAttribute('disabled', 'false');
          });
          this._snackBarService.showSnackBar(
            response['Message'],
            null,
            response['Type']
          );
          if (this.card['id'] == 'monthFlag') {
            this._flagEntryService.monthFlagHitAnnounce();
          }
        }
      },
      (error) => {
        this.tempDisableBtn = false;
        // flagBtn.setAttribute('disabled', 'false');
        let errorMessage = error.statusText;
        if (error.error['Message']) {
          errorMessage += ' - ' + error.error['Message'];
        } else if (error.error['detail']) {
          errorMessage += ' - ' + error.error['detail'];
        }
        this._snackBarService.showSnackBar(errorMessage, 'close', 'failure');
      }
    );
  }

  // Disable Flag Button when Week or Month chosen where flag can't be hit
  flagDisabledHandler(flagMetric: string): boolean {
    const formattedDateweekend = new Date(this.weekEndDate).toLocaleDateString(
      'en-CA'
    );
    const year = new Date(this.monthEndDate).getFullYear();
    const month = new Date(this.monthEndDate).getMonth() + 1;
    const formattedDatemonthend = `${year}|${month}`;

    switch (true) {
      case this.card['id'] === 'weekFlag' && flagMetric === 'Time':
        return (
          (this.checkFlagDeadline && this.checkFlagDeadline['periodWeekFlagMap']
            ? this.checkFlagDeadline['periodWeekFlagMap'].get(
                formattedDateweekend
              )['isTSFlagPresent']
            : false) || this.isWriteSilence
        );
      case this.card['id'] === 'weekFlag' && flagMetric === 'Expense':
        return (
          (this.checkFlagDeadline && this.checkFlagDeadline['periodWeekFlagMap']
            ? this.checkFlagDeadline['periodWeekFlagMap'].get(
                formattedDateweekend
              )['isEXFlagPresent']
            : false) || this.isWriteSilence
        );
      case this.card['id'] === 'monthFlag' && flagMetric === 'Time':
        return (
          (this.checkFlagDeadline &&
          this.checkFlagDeadline['periodMonthFlagMap']
            ? this.checkFlagDeadline['periodMonthFlagMap'].get(
                formattedDatemonthend
              )['isTSFlagPresent']
            : false) || this.isWriteSilence
        );
      case this.card['id'] === 'monthFlag' && flagMetric === 'Expense':
        return (
          (this.checkFlagDeadline &&
          this.checkFlagDeadline['periodMonthFlagMap']
            ? this.checkFlagDeadline['periodMonthFlagMap'].get(
                formattedDatemonthend
              )['isEXFlagPresent']
            : false) || this.isWriteSilence
        );
    }
  }
}
