import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ProjectDetailsComponent } from "./project-details.component";
import { FormModule } from "../form-baselayout/form.module";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from '@angular/material/button';
import { AgGridModule } from 'ag-grid-angular';

import 'ag-grid-enterprise';
import { ThemeAllocationDailogComponent } from './project-details-dialog/theme-allocation-dailog/theme-allocation-dailog.component';
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { DirectivesModule } from "../common/directives/directives.module";


@NgModule({
    declarations: [ProjectDetailsComponent, ThemeAllocationDailogComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        FormModule,
        MatTabsModule,
        AgGridModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        DirectivesModule
    ],
    exports: [ProjectDetailsComponent],
})
export class ProjectDetailsModule {

}