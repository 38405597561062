import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  //Set Local Storage Value
  public static setSessionStorageValueBasedOnKey(keyName:string, value:string){
    try{
        sessionStorage.setItem(keyName,value);
    }
    catch(error){
      console.error(error);
    }
    
  }

  //Get Local Storage Value
  public static getSessionStorageValueBasedOnKeys(keyName:string){
    try{
      const value = sessionStorage.getItem(keyName);
      if (value) {
        return value;
      }
    }
    catch(error){
      console.error(error);
    }
    return null;
  }

  //Remove Local Storage Value
  public static removeSessionStorageValueBasedOnKeys(keyName:string){
    try{
      return sessionStorage.removeItem(keyName);
    }
    catch(error){
      console.error(error);
      return null;
    }
  }
}
