import { Injectable } from '@angular/core';
import { ApiheaderService } from './apiheader.service';
import { HttpClient } from '@angular/common/http';
import { URLPaths } from '../utilities/urlPaths.config';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConsultantProjectRateParameterModel, ConsultantRateModel } from '../models/consultant-rate.model';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultantRateService {

  private consRateByProjMap: Map<string, ConsultantRateModel[]> = new Map<string, ConsultantRateModel[]>();

  constructor(
      private _http: HttpClient,
      private _errorHandlerService: ErrorHandlerService
  ) {
  }

  //Return the consultant rate for a project
  getConsultantByProject(params: ConsultantProjectRateParameterModel, forceReload: boolean = false): Observable<ConsultantRateModel[]> {
      const key = this.getMapKey(params);
      if (forceReload || !this.consRateByProjMap.has(key)) {
          return this._http.get(URLPaths.URL_CONSULTANT_RATE_BY_PROJECT,
              {params: params as any, headers: ApiheaderService.getHeaders()})
              .pipe(
                  map(res => {
                      const consultantRates = res as ConsultantRateModel[];
                      const plannedProjectRates = consultantRates.filter(value => {
                          return value.Is_Default_Rate === 0;
                      });
                      for (const value of consultantRates) {
                          if (!value.Is_Default_Rate) {
                              value.Display_Rate = value.Converted_Currency_Symbol + ' ' + value.Converted_Rate;
                          } else {
                              value.Display_Rate = '(base:)' + value.Converted_Currency_Symbol + ' ' + value.Converted_Rate;
                          }
                      }

                      if (plannedProjectRates.length > 0) {
                          this.consRateByProjMap.set(key, plannedProjectRates);
                      }else {
                          this.consRateByProjMap.set(key, consultantRates);
                      }

                      return this.consRateByProjMap.get(key);
                  }),
                  catchError(this._errorHandlerService.handleError.bind(this))
              );
      } else {
          return of(this.consRateByProjMap.get(key));
      }
    }

    private getMapKey(req: ConsultantProjectRateParameterModel): string {
        const separator = '-';
        return req.client + separator + req.project + separator + req.employee + separator + req.entrydate;
    }
}
