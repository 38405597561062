
import { values } from "lodash";
import moment from "moment";

export class ProjectDetailsGridConfigModel {
    consultantProjectRatesColumns = [
        {
            headerName: "Consultant",
            field: "Consultant",
            editable: false,
            // cellStyle: {"background-color": "#dadada"},

            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#F6F5F2" }
                }
            },
            checkboxSelection: true,
            headerCheckboxSelection: true
        },
        {
            headerName: "Currency",
            field: "Currency",
            editable: false,
            //cellStyle: {"background-color": "#dadada"},
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#F6F5F2" }
                }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Rate",
            field: "Rate",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Month",
            field: "Month",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Leverage %",
            field: "Leverage",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
        },
        {
            headerName: "Grid Name",
            field: "GridName",
            editable: false,
            valueGetter: (params: any) => {
                return 'CPR'
            },
            hide: true
        },

    ];
    projectTasksColumns = [
        {
            headerName: "Task Id",
            field: "Task_id",
            editable: false,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            hide: true
        },
        {
            headerName: "Task Reference",
            field: "Task_Reference",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: params => {
                if (params.data.Default == "YES") {
                    return false;
                }
                else {
                    return true;
                }
            },
            headerCheckboxSelection: true
        },
        {
            headerName: "Description",
            field: "Description",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Fixed Cost Value",
            field: "Fixed_Cost_Value",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Fixed Cost Start",
            field: "Fixed_Cost_Start",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            valueFormatter: function (params) {
                if (params.value == null) {
                    return params.value;
                }
                return moment(params.value).format('DD MMM YYYY');
            },
            cellEditor: "GridDatePickerComponent"
        },
        {
            headerName: "Fixed Cost End",
            field: "Fixed_Cost_End",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            valueFormatter: function (params) {
                if (params.value == null) {
                    return params.value;
                }
                return moment(params.value).format('DD MMM YYYY');
            },
            cellEditor: "GridDatePickerComponent"
        },
        {
            headerName: "Manager",
            field: "Manager",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: "this.consultantList",
                filterList: true,
                searchType: "match",
                allowTyping: true,
                valueListMaxHeight: 240,
            }
        },
        {
            headerName: "Default",
            field: "Default",
            editable: true,
            cellStyle: params => {
                if (params.data.Default == "YES") {
                    if(params.node.isSelected()){ 
                        return { background: 'lightblue' }
                    }
                    else if (params.node.data.dirty && params.node.data.dirty == true) {
                        return { background: '#ffc107' }
                        // return {"background-color": "#dadada", "pointer-events": "none", };
                    } else {
                        return { "background-color": "#F6F5F2", "pointer-events": "none", };

                    }
                }
                else {
                    if(params.node.isSelected()){ 
                        return { background: 'lightblue' }
                    }
                    else if (params.node.data.dirty && params.node.data.dirty == true) {
                        return { background: '#ffc107' }
                        // return {"background-color": "#dadada", "pointer-events": "none", };
                    } else {
                        return { background: "#ffffff" }
                    }
                }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: "this.defaultActiveBooleanValues",
                filterList: true,
                searchType: "match",
                allowTyping: true,
                valueListMaxHeight: 240,
            },

        },
        {
            headerName: "Active",
            field: "Active",
            editable: true,
            cellStyle: params => {
                if (params.data.Default == "YES") {
                    if(params.node.isSelected()){ 
                        return { background: 'lightblue' }
                    }
                    else if (params.node.data.dirty && params.node.data.dirty == true) {
                        return { background: '#ffc107' }
                        // return {"background-color": "#dadada", "pointer-events": "none", };
                    } else {
                        return { "background-color": "#F6F5F2", "pointer-events": "none", };

                    }
                }
                else {
                    if(params.node.isSelected()){ 
                        return { background: 'lightblue' }
                    }
                    else if (params.node.data.dirty && params.node.data.dirty == true) {
                        return { background: '#ffc107' }
                        // return {"background-color": "#dadada", "pointer-events": "none", };
                    } else {
                        return { background: "#ffffff" }
                    }
                }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: "this.defaultActiveBooleanValues",
                filterList: true,
                searchType: "match",
                allowTyping: true,
                valueListMaxHeight: 240,
            }
        },

        {
            headerName: "Grid Name",
            field: "GridName",
            editable: false,
            valueGetter: (params: any) => {
                return 'PT'
            },
            hide: true
        },

    ];
    themeAllocationColumns = [
        {
            headerName: "Theme",
            field: "Theme",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: "this.themeList",
                filterList: true,
                searchType: "match",
                allowTyping: true,
                valueListMaxHeight: 240,
            }
        },
        {
            headerName: "Allocation Percent",
            field: "Allocation_Percent",
            editable: true,
            cellStyle: (params: any) => {
                if(params.node.isSelected()){ 
                    return { background: 'lightblue' }
                }
                else if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                // else {
                //     return { background: "#ffffff" }
                // }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false
        },
        {
            headerName: "Grid Name",
            field: "GridName",
            editable: false,
            valueGetter: (params: any) => {
                return 'TA'
            },
            hide: true
        },

    ];
    prepaymentDetailsColumns = [
        {
            headerName: "ID",
            field: "ID",
            editable: false,
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#ffffff" }
                }
            },
            checkboxSelection: false,
            headerCheckboxSelection: false,
            hide: true
        },
        {
            headerName: "Purchase Order Number",
            field: "Purchase_Order_Number",
            editable: false,
            // cellStyle: {"background-color": "#dadada"}
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#F6F5F2" }
                }
            }
        },
        {
            headerName: "Prepayment Amount",
            field: "Prepayment_Amount",
            editable: true,
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#ffffff" }
                }
            }
        },
        {
            headerName: "Reference Number",
            field: "Reference_Number",
            editable: true,
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#ffffff" }
                }
            }
        },
        {
            headerName: "Prepayment Date",
            field: "Prepayment_Date",
            editable: true,
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#ffffff" }
                }
            },
            valueFormatter: function (params) {
                if (params.value == null) {
                    return params.value;
                }
                return moment(params.value).format('DD MMM YYYY');
            },
            cellEditor: "GridDatePickerComponent"
        },
        {
            headerName: "Description",
            field: "Description",
            editable: true,
            cellStyle: (params: any) => {
                if (params.node.data.dirty && params.node.data.dirty == true) {
                    return { background: '#ffc107' }
                }
                else {
                    return { background: "#ffffff" }
                }
            },
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                rows: 15,
                cols: 50
            },

        },
        {
            headerName: "Grid Name",
            field: "GridName",
            editable: false,
            valueGetter: (params: any) => {
                return 'PD'
            },
            hide: true
        },

    ];


}

