import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ExpensePaymentMethodModel } from '../models/expense-payment-method.model';
import { map } from 'rxjs/operators';
import { commonFunctions } from '../classes/common.class';
import { ApiheaderService } from './apiheader.service';
import { URLPaths } from '../utilities/urlPaths.config';

@Injectable({
    providedIn: 'root'
})
export class ExpensePaymentMethodService {
    expensePaymentMethod: ExpensePaymentMethodModel[];

    constructor(private http: HttpClient) { }
    //Return the Expense Payment Methods
    getExpensePaymentMethod(): Observable<ExpensePaymentMethodModel[]> {
        if (commonFunctions.isNullOrUndefined(this.expensePaymentMethod)) {
            return this.http.get<ExpensePaymentMethodModel[]>(
                URLPaths.URL_EXPENSE_PAYMENT_METHOD, { headers: ApiheaderService.getHeaders() }).pipe(
                    map(res => {
                        this.expensePaymentMethod = res;
                        return res;
                    }));
        }
        return of(this.expensePaymentMethod);

    }

    ngOnInit() {

    }
}
