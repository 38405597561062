import { Injectable } from '@angular/core';
import { ExpenseTypeDescriptionModel, ExpenseTypeModel } from '../models/expense-type.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';

@Injectable({
    providedIn: 'root'
})
export class ExpenseTypeService {
    private ExpenseTypeMap: Map<string, ExpenseTypeModel[]> = new Map<string, ExpenseTypeModel[]>();
    private ExpenseTypeDescMap: Map<string, ExpenseTypeDescriptionModel[]> = new Map<string, ExpenseTypeDescriptionModel[]>();
    constructor(private http: HttpClient,
        private errorHandlerService: ErrorHandlerService
    ) {

    }

    //Get the Expense Types
    getExpenseTypes(client, project: string, forceReload: boolean = false): Observable<ExpenseTypeModel[]> {
        const key = this.getMapKey(client, project);
        if (forceReload || !this.ExpenseTypeMap.has(key)) {
            const params = { 'client': client, 'project': project };
            return this.http.get<ExpenseTypeModel[]>(URLPaths.URL_EXPENSE_TYPE,
                { params: params, headers: ApiheaderService.getHeaders() })
                .pipe(
                    map(res => {
                        this.ExpenseTypeMap.set(key, res);
                        return this.ExpenseTypeMap.get(key);
                    }),
                    catchError(this.errorHandlerService.handleError.bind(this))
                );
        } else {
            return of(this.ExpenseTypeMap.get(key));
        }

    }

    //Get the Expense Type Description
    getExpenseTypeDesc(expenseType, forceReload: boolean = false): Observable<ExpenseTypeDescriptionModel[]>{
        const key = expenseType;
        if (forceReload || !this.ExpenseTypeDescMap.has(key)) {
            const params = {'expenseType' : expenseType}
            return this.http.get<ExpenseTypeDescriptionModel[]>(URLPaths.URL_EXPENSE_TYPE_DESCRIPTION,
                {params: params, headers: ApiheaderService.getHeaders()})
                .pipe(
                    map(res => {
                        this.ExpenseTypeDescMap.set(key, res);
                        return this.ExpenseTypeDescMap.get(key);
                    }),
                    catchError(this.errorHandlerService.handleError.bind(this))
                );
        } else {
            return of(this.ExpenseTypeDescMap.get(key));
        }

    }

    private getMapKey(client, project: string): string {
        return client + '-' + project;
    }
}
