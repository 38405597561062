import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { IconButtonComponent } from './mips-lib/icon-button/icon-button.component';
import { ButtonComponent } from './mips-lib/button/button.component';
import { HeadingComponent } from './mips-lib/heading/heading.component';
import { SubHeadingComponent } from './mips-lib/sub-heading/sub-heading.component';
import { ParagraphComponent } from './mips-lib/paragraph/paragraph.component';
import { EntryModule } from 'app/core/mips/actuals/entry/entry.module';
import { TimeDicerModule } from 'app/core/mips/actuals/time-dicer/time-dicer.module';


@NgModule({
    declarations: [
        SettingsComponent,
        IconButtonComponent,
        ButtonComponent,
        HeadingComponent,
        SubHeadingComponent,
        ParagraphComponent,
      ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        FuseDrawerModule,
        EntryModule,
        TimeDicerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SettingsComponent,
    ]
})
export class SharedModule
{
}
