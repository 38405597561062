import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProjectTaskModel } from '../models/project-task.model';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectTaskService {

  private clientProjMap: Map<string, ProjectTaskModel[]> = new Map<string, ProjectTaskModel[]>();

  constructor(
    private _http: HttpClient,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  private _getMapKey(client, project: string): string {
    return client + '-' + project;
  }

  //Get Project Tasks based on Projects
  getProjectTasks(client, project: string, forceReload: boolean = false): Observable<ProjectTaskModel[]> {
    const key = this._getMapKey(client, project);
    if (forceReload || !this.clientProjMap.has(key)) {
        const params = {'client': client, 'project': project};
        return this._http.get<ProjectTaskModel[]>(URLPaths.URL_PROJECT_TASK,
            {params: params, headers: ApiheaderService.getHeaders()})
            .pipe(
                map(res => {
                    for (const value of res){
                        value.Display_String = value.Task_name + '-' + value.Task_description;
                    }
                    this.clientProjMap.set(key, res );
                    return this.clientProjMap.get(key);
                }),
                catchError(this._errorHandlerService.handleError.bind(this))
            );
    } else {
        return of(this.clientProjMap.get(key));
    }
}
}
