import { commonFunctions } from "../classes/common.class";

export class DateUtility {

    //Static date format to be displayed in the mat-datepicker
    static CALENDER_DATE_FORMAT = {
        parse: {
            dateInput: 'DD MMM YYYY',
        },
        display: {
            dateInput: 'DD MMM YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    };

    static monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    static monthLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
        'November', 'December'];


    //Returns the month name based on the month number from the predefined array
    public static getMonthName(monthNumber: number, type: 'short' | 'full'): string {
        if (type === 'short') {
            return this.monthShort[monthNumber];
        } else {
            return this.monthLong[monthNumber];

        }
    }

    //Format date from date string
    public static createDateTypeFromString(dateString: string): any {
        if (commonFunctions.isNullOrUndefined(dateString)) {
            return null;
        }
        const dateToFormat = dateString.split(' ');
        const day = dateToFormat[0];
        const month = this.monthShort.indexOf(dateToFormat[1]) + 1;
        const year = dateToFormat[2];
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return date;

    }

    //Return the day name based on the day number
    public static getDayName(dayNumber: number, type: 'short' | 'full'): string {
        const weekday = new Array(7);
        if (type === 'short') {
            weekday[0] = 'Sun';
            weekday[1] = 'Mon';
            weekday[2] = 'Tue';
            weekday[3] = 'Wed';
            weekday[4] = 'Thu';
            weekday[5] = 'Fri';
            weekday[6] = 'Sat';
        } else {
            weekday[0] = 'Sunday';
            weekday[1] = 'Monday';
            weekday[2] = 'Tuesday';
            weekday[3] = 'Wednesday';
            weekday[4] = 'Thursday';
            weekday[5] = 'Friday';
            weekday[6] = 'Saturday';
        }
        return weekday[dayNumber];
    }

    //Create date from datestring
    public static createDateTypefromDate(dateString: string): any {
        if (commonFunctions.isNullOrUndefined(dateString)) {
            return null;
        }
        const dateToFormat = dateString.split('-');
        const day = dateToFormat[2];
        const month = dateToFormat[1];
        const year = dateToFormat[0];
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return date;
    }

    //Create a new Date
    public static createDateType(): any {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    //Format a date from a different date format
    public static formatStringfromDate(dateToFormat: Date): any {
        if (commonFunctions.isNullOrUndefined(dateToFormat)) {
            return null;
        }
        const day = (dateToFormat.getDate() < 10 ? '0' : '') + dateToFormat.getDate();
        const month = (Number(dateToFormat.getMonth()) + 1 < 10 ? '0' : '') + (Number(dateToFormat.getMonth()) + 1);
        const year = dateToFormat.getFullYear();
        const formattedDateTime = DateUtility.createDateTypefromDate(year + '-' + month + '-' + day);
        formattedDateTime.setHours(0, 0, 0, 0);
        return year + '-' + month + '-' + day + ' ' + ((formattedDateTime.getHours() < 10 ? '0' : '') + formattedDateTime.getHours()) +
            ':' + ((formattedDateTime.getMinutes() < 10 ? '0' : '') + formattedDateTime.getMinutes()) + ':' + ((formattedDateTime.getSeconds() < 10 ? '0' : '') + formattedDateTime.getSeconds());
    }

    //Format a date from date string
    public static formatDatefromString(dateString: string): any {
        if (commonFunctions.isNullOrUndefined(dateString)) {
            return null;
        }
        const dateToFormat = dateString.split('-');
        // const day = dateString.substr(8, 2);
        // const month = dateString.substr(5, 2);
        // const year = dateString.substr(0, 4);
        const day = dateToFormat[2];
        const month = dateToFormat[1];
        const year = dateToFormat[0];
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        return (date.getDate() < 10 ? '0' : '') + date.getDate() + ' ' + DateUtility.getMonthName(date.getMonth(), 'short') + ' ' + date.getFullYear();
    }

    //Format a date to a different date format
    public static formatDatefromDate(date: Date): any {
        if (commonFunctions.isNullOrUndefined(date)) {
            return null;
        }
        return (date.getDate() < 10 ? '0' : '') + date.getDate() + ' ' + DateUtility.getMonthName(date.getMonth(), 'short') + ' ' + date.getFullYear();
    }

    //Format a datestring based on its day name
    public static formatDateWithDayNamefromString(dateString: string): any {
        if (commonFunctions.isNullOrUndefined(dateString)) {
            return null;
        }
        const date = this.createDateTypefromDate(dateString);
        return DateUtility.formatDatefromString(dateString) + ', ' + DateUtility.getDayName(date.getDay(), 'full');
    }

    //Format a date based on its day name
    public static formatDateWithDayNamefromDate(date: Date): any {
        if (commonFunctions.isNullOrUndefined(date)) {
            return null;
        }
        return DateUtility.formatDatefromDate(date) + ', ' + DateUtility.getDayName(date.getDay(), 'full');
    }

    //Return the start and end date of the week in date format
    public static getCurrentWeekDates(): any {
        const today = this.createDateType();
        const day = today.getDay();
        const date = today.getDate() - (day === 0 ? 7 : day);
        const StartDate = this.createDateType();
        const EndDate = this.createDateType();
        StartDate.setHours(0, 0, 0, 0);
        EndDate.setHours(0, 0, 0, 0);
        StartDate.setDate(date + 1);
        EndDate.setDate(date + 5);
        const formattedStartDate = this.formatDatefromDate(StartDate);
        const formattedEndDate = this.formatDatefromDate(EndDate);
        return {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };

    }

    //Return the start and end date of the week in date format
    public static getCurrentWeekDateinDateFormat(): any {
        const today = this.createDateType();
        const day = today.getDay();
        const date = today.getDate() - (day === 0 ? 7 : day);
        const StartDate = this.createDateType();
        const EndDate = this.createDateType();
        StartDate.setHours(0, 0, 0, 0);
        EndDate.setHours(0, 0, 0, 0);
        StartDate.setDate(date + 1);
        EndDate.setDate(date + 5);
        return {
            startDate: StartDate,
            endDate: EndDate
        };

    }

    //Return the start and end date of the month
    public static getMonthStartEndDate(): any {
        const today = DateUtility.createDateType()
        const first = new Date(today.getFullYear(), today.getMonth(), 1);
        const last = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const StartDate = DateUtility.formatDatefromDate(first);
        const EndDate = DateUtility.formatDatefromDate(last);
        return {
            startDate: StartDate,
            endDate: EndDate
        };

    }
}
