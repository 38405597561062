import { Component } from '@angular/core';

@Component({
  selector: 'app-time-entry-for-planning',
  templateUrl: './time-entry-for-planning.component.html',
  styleUrls: ['./time-entry-for-planning.component.scss']
})
export class TimeEntryForPlanningComponent {

}
