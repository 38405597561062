import {DateUtility} from '../utilities/date.utility';

export class TimeEntryModel {
    ID: number;
    Time_ID: number = null;
    TDate: string =  DateUtility.formatStringfromDate(DateUtility.createDateType());
    Employee: string = '';
    Client: string = '';
    ClientName: string = '';
    Project: string = '';
    ProjectName: string = '';
    Task_Description: string = '';
    Hours: number = 0;
    Days: number = 0; // NEW
    Rate: number = 0;
    Task_Name: string = '';
    Task_Code: string = '';
    Task_id: number = 0;
    Invoice_Date: string =  DateUtility.formatStringfromDate(DateUtility.createDateType());
    Charge: string = '';
    Currency_Code: string = '';
    Currency_Symbol: string = '';
    Entry_Owner: string = '';
    Entry_Owner_LoginName: string = '';
    Invoice_id: number = 0;
    Plan_Version_id: number = null;
    State_id: number = null;
    State_Code: number = null;
    State_Name: string = null;
    State_Tax: number = null;
    Currency_symbol_short: string = '';
    Employee_Loginname: string = null;
    Company_id: number = 0;
    Consultant_Company_id: number = null;
    PlanSheet: number = null;
    status = 'clean';
    Version: number | string = 'ACTUAL';
    Mode: string = null;
    Task_NameCode: string = null;
    Contact: string = '';
    Employee_FullName: string = '';
    Cost: number = 0;
    ApplyTo: string = '';
    Year: number;
    Month: string;
    Week_Commencing: string = '';
    Company_Name: string = '';
    Consultant_Company_Name: string = '';
}
