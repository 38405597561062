import { constant } from "lodash";
import { Constants, ConstantFormStates } from "../common/utilities/constants.config";
import { GlobalConstants } from "../common/utilities/global-constants";
import { ButtonComponent } from "app/shared/mips-lib/button/button.component";
import { constants } from "buffer";

type Location = {
    country: string,
    state: string,
}

export class ProjectDetailsBlueprint {
    public static readonly projectContactDetailsLocalStorageNamePrefix: string = 'projects-contacts_';
    private static readonly countriesWithDisabledNoVatField = GlobalConstants.countriesWithDisabledNoVatField;
    public static readonly countryToCurrencyMap = {};
    public static readonly countryToTaxTypeMap = {};
    public static readonly locationToTaxPercentageMap: Map<string, number> = new Map<string, number>();

    private static getContactsWithDisabledNoVatField(client: string) {
        const projectContactDetails = JSON.parse(localStorage.getItem(ProjectDetailsBlueprint.projectContactDetailsLocalStorageNamePrefix + client))
        let contactsWithDisabledNoVatField = []
        
        for (const contact of projectContactDetails[client].contact_details) {
            if (ProjectDetailsBlueprint.countriesWithDisabledNoVatField.includes(contact.country)) {
                contactsWithDisabledNoVatField.push(contact.contact);
            }
        }
        
        return contactsWithDisabledNoVatField
    }

    public static setLocationToTaxPercentageMap(location: Location, taxPercentage: number) {
        ProjectDetailsBlueprint.locationToTaxPercentageMap.set(JSON.stringify(location), taxPercentage)
    }

    public static getLocationToTaxPercentageMap(location: Location) {
        return ProjectDetailsBlueprint.locationToTaxPercentageMap.get(JSON.stringify(location))
    }

    // not used anymore
    public static _projectDetailsViewModeBlueprint = {
        headerDetails: {
            sectionDisplayName: "",
            sectionName: "headerDetails",
            formTemplateData : [
                {
                    templateArea: [
                       '"CL PR AVAIL-CONT"',
                       '"CL PR" "AVAIL-CONT ."',
                       '"CL PR" "AVAIL-CONT ."',
                       '"CL" "PR" "AVAIL-CONT"'
                    ],
                    fieldDetails: [
                        {
                            fieldCode: "CL",
                            fieldName: "client",
                            fieldDisplayName: "Client",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a client',
                            fieldValuesFunction: "getClients",
                            fieldValuesFunctionParams: [],
                            fieldValues: "",
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR",
                            fieldName: "project",
                            fieldDisplayName: "Project",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a project',
                            fieldValuesFunction: "getProjects",
                            fieldValuesFunctionParams: ['CL', Constants.FORM_EDIT_MODE],
                            fieldValues: "",
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "AVAIL-CONT",
                            fieldName: "availableContacts",
                            fieldDisplayName: "Contact",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a contact',
                            fieldValuesFunction: "getContacts",
                            fieldValuesFunctionParams: ['CL'],
                            fieldValues: "",
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        projectDetails : {   
            sectionDisplayName: "Project Details",
            sectionName: "projectDetails",
            formTemplateData : [
                {
                    templateArea: [
                        '"PR-CODE SC" "PR-DESC PR-DESC" "PR-TYPE INT" "OC PR-MNG" "BC BC" "PD ."',
                        '"PR-CODE SC" "PR-DESC PR-DESC" "PR-TYPE INT" "OC PR-MNG" "BC BC" "PD ."',
                        '"PR-CODE SC" "PR-DESC PR-DESC" "PR-TYPE INT" "OC PR-MNG" "BC BC" "PD ."',
                        '"PR-CODE" "SC" "PR-DESC" "PR-DESC" "PR-TYPE" "INT" "OC" "PR-MNG" "BC" "PD"',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "PR-CODE",
                            fieldName: "projectCode",
                            fieldDisplayName: "Project Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "PR-DESC",
                            fieldName: "projectDescription",
                            fieldDisplayName: "Project Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "PR-TYPE",
                            fieldName: "projectType",
                            fieldDisplayName: "Project Type",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],

                        },
                        {
                            fieldCode: "SC",
                            fieldName: "shortCode",
                            fieldDisplayName: "Short Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "INT",
                            fieldName: "internal",
                            fieldDisplayName: "Internal",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "OC",
                            fieldName: "oldCode",
                            fieldDisplayName: "Old Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PR-MNG",
                            fieldName: "projectManager",
                            fieldDisplayName: "Manager",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "BC",
                            fieldName: "billingCompany",
                            fieldDisplayName: "Billing Company",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PD",
                            fieldName: "paymentDays",
                            fieldDisplayName: "Payment Days",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.WHOLE_NO_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                    ]
                }
            ]
        },
        contactDetails : {
            sectionDisplayName: "Contact Details",
            sectionName: "contactDetails",
            formTemplateData : [
                {
                    templateArea: [
                        '"CONT TELE" "ADD ADD" "RET-MIL ." "CN ST"',
                        '"CONT TELE" "ADD ADD" "RET-MIL ." "CN ST"',
                        '"CONT TELE" "ADD ADD" "RET-MIL ." "CN ST"',
                        '"CONT" "TELE" "ADD" "RET-MIL" "CN" "ST"',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "CONT",
                            fieldName: "contact",
                            fieldDisplayName: "Contact",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        },
                        {
                            fieldCode: "ADD",
                            fieldName: "address",
                            fieldDisplayName: "Address",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        },
                        {
                            fieldCode: "TELE",
                            fieldName: "telephone",
                            fieldDisplayName: "Telephone",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TELEPHONE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        },
                        {
                            fieldCode: "RET-MIL",
                            fieldName: "returnMileage",
                            fieldDisplayName: "Return Mileage",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        },
                        {
                            fieldCode: "CN",
                            fieldName: "country",
                            fieldDisplayName: "Country",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        },
                        {
                            fieldCode: "ST",
                            fieldName: "state",
                            fieldDisplayName: "State",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR', 'AVAIL-CONT'],
                        }
                    ]
                }
            ]
        },
        invoiceDetails : {   
            sectionDisplayName: "Invoice Details",
            formTemplateData : [
                {
                    templateArea: [
                        '"IN-DESC IN-DESC . ." "PO-NO-VW MIL-RA SHB TAX" "PR-DISC NO-VAT PPD CNPJ" "CL-VAT-NO PHR TOS CHG" "PPP IN-FOR IN-CONT IN-CONT" "FFD FFD IN-CONT-ADD IN-CONT-ADD"',
                        '"IN-DESC IN-DESC" "PO-NO-VW ." "MIL-RA SHB" "TAX PR-DISC" "NO-VAT PPD" "CNPJ CL-VAT-NO" "PHR TOS" "CHG PPP" "IN-FOR ." "IN-CONT IN-CONT" "FFD IN-CONT-ADD"',
                        '"IN-DESC IN-DESC" "PO-NO-VW ." "MIL-RA SHB" "TAX PR-DISC" "NO-VAT PPD" "CNPJ CL-VAT-NO" "PHR TOS" "CHG PPP" "IN-FOR ." "IN-CONT IN-CONT" "FFD IN-CONT-ADD"',
                        '"IN-DESC" "PO-NO-VW" "MIL-RA" "SHB" "TAX" "PR-DISC" "NO-VAT" "PPD" "CNPJ" "CL-VAT-NO" "PHR" "TOS" "CHG" "PPP" "IN-FOR" "IN-CONT" "FFD" "IN-CONT-ADD"',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "IN-DESC",
                            fieldName: "invoiceDescription",
                            fieldDisplayName: "Invoice Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "MIL-RA",
                            fieldName: "mileageRate",
                            fieldDisplayName: "", 
                            fillFieldNameFunction: 
                                function (country: any) { return (country === '' || !country ? 'Mileage Rate' : 'Mileage Rate: (' + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                            fillFieldNameFunctionParams: ['Unisted States of America'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "TAX",
                            fieldName: "salesTax",
                            fieldDisplayName: "",
                            fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "VAT Rate" : ProjectDetailsBlueprint.countryToTaxTypeMap[country]) + ': (%)' },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '', 
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "NO-VAT",
                            fieldName: "noVat",
                            fieldDisplayName: "No VAT",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "CNPJ",
                            fieldName: "cnpj",
                            fieldDisplayName: "CNPJ",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PHR",   
                            fieldName: "projectHourlyRate",
                            fieldDisplayName: "Project Hourly Rate",  
                            fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "Project Hourly Rate: " : "Project Hourly Rate: (" + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "CHG",   
                            fieldName: "charge",
                            fieldDisplayName: "Charge",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "IN-FOR",   
                            fieldName: "invoiceFormat",
                            fieldDisplayName: "Invoice Format",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "FFD",   
                            fieldName: "freeFormatDescription",
                            fieldDisplayName: "Free Format Description",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        // {
                        //     fieldCode: "PO-NO-VW",   
                        //     fieldName: "purchaseOrderNoViewMode",
                        //     fieldDisplayName: "Purchase Order Number", 
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.DROPDOWN_FIELD_NAME, 
                        //     inputType: Constants.DROPDOWN_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: '',
                        //     fieldPlaceholder: 'Purchase Order Numbers',
                        //     fieldValuesFunction: 'getPurchaseOrderDetails',
                        //     fieldValuesFunctionParams: ['CL', 'PR'],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,
                        //     required: true,
                        //     disabledFor: null,
                        //     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                        //     parentFields: ['PR']
                        // },
                        {
                            fieldCode: "PO-NO-VW",   
                            fieldName: "purchaseOrderNoViewMode",
                            fieldDisplayName: "Purchase Order Number", 
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME, 
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: 'Purchase Order Numbers',
                            fieldValuesFunction: 'getPurchaseOrderDetails',
                            fieldValuesFunctionParams: ['CL', 'PR'],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "SHB",   
                            fieldName: "showHourlyBreakdown",
                            fieldDisplayName: "Show Hourly Breakdown",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PR-DISC",   
                            fieldName: "projectDiscount",
                            fieldDisplayName: "Project Discount (%)",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,    
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PPD",     
                            fieldName: "promptPaymentDiscount",
                            fieldDisplayName: "Prompt Payment Discount (%)",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "CL-VAT-NO",     
                            fieldName: "clientVatNo",
                            fieldDisplayName: "Client VAT No",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "TOS", 
                            fieldName: "typeOfService",
                            fieldDisplayName: "Type of Service",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PPP",   
                            fieldName: "promptPaymentPeriodWithType",
                            fieldDisplayName: "Prompt Payment Period",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "IN-CONT",   
                            fieldName: "invoiceContact",
                            fieldDisplayName: "Invoice Contact",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "IN-CONT-ADD",   
                            fieldName: "invoiceContactAddress",
                            fieldDisplayName: "Invoice Contact Address",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: ['PR'],
                        },
                    ]
                }
            ]
        },
        props: {
            formName: 'projectDetailsViewMode',
            rootField: 'CL',
            selectionFields: {
                'CL': ['PR', 'AVAIL-CONT'],
                'PR': ['PO-NO-VW'],
            },
            gridAreaForm: [
                '"headerDetails headerDetails" "projectDetails contactDetails" "invoiceDetails invoiceDetails"',
                '"headerDetails" "projectDetails" "contactDetails" "invoiceDetails"',
                '"headerDetails" "projectDetails" "contactDetails" "invoiceDetails"',
                '"headerDetails" "projectDetails" "contactDetails" "invoiceDetails"',
            ],
            buttons: [],
            buttonClickHandlers: [],
        },
    }

    public static _projectDetailsEditModeBlueprint = {
        headerDetails: {
            sectionDisplayName: "",
            sectionName: "headerDetails",
            formTemplateData : [
                {
                    templateArea: [
                    '"CL PR "',
                    '"CL PR" ',
                    '"CL PR" ',
                    '"CL" "PR" '
                    ],
                    fieldDetails: [
                        {
                            fieldCode: "CL",
                            fieldName: "client",
                            fieldDisplayName: "Client",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a client',
                            fieldValuesFunction: "getClients",
                            fieldValuesFunctionParams: [],
                            fieldValues: "",
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR",
                            fieldName: "project",
                            fieldDisplayName: "Project",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a project',
                            fieldValuesFunction: "getProjects",
                            fieldValuesFunctionParams: ['CL'],
                            fieldValues: "",
                            functionToFillOtherFields: 'fillProjectDetails',
                            fillOtherFieldsFunctionParams: ['CL', 'PR'],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: ['CL'],
                            invoiceEnable: {'ENABLED_ALWAYS' : ["IN-DESC", "IN-FOR", "TAX", "SHB", "PR-DISC", "PHR", "PO-NO-VW", "PPD", "PPP", "PPPT", "CHG", "MIL-RA", "IN-CONT", "IN-CONT-ADD"]}
                        },
                    ]
                }
            ]
        },
        projectDetails : {   
            sectionDisplayName: "Project Details",
            sectionName: "projectDetails",
            formTemplateData : [
                {
                    templateArea: [
                        '"PR-CODE PR-TYPE" "PR-DESC PR-DESC" "PR-MNG SC" "OC INT" "BC PD" ',
                        '"PR-CODE PR-TYPE" "PR-DESC PR-DESC" "PR-MNG SC"  "OC INT" "BC PD" ',
                        '"PR-CODE PR-TYPE" "PR-DESC PR-DESC" "PR-MNG SC"  "OC INT" "BC PD" ',
                        '"PR-CODE PR-TYPE" "PR-DESC PR-DESC" "PR-MNG SC"  "OC INT" "BC PD" ',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "PR-CODE",
                            fieldName: "projectCode",
                            fieldDisplayName: "Project Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: ConstantFormStates.disabledAlways,
                            enabledFor: null,
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "PR-DESC",
                            fieldName: "projectDescription",
                            fieldDisplayName: "Project Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: ['PR'],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, // Product Description is Editable
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "PR-TYPE",
                            fieldName: "projectType",
                            fieldDisplayName: "Project Type",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a project type',
                            fieldValuesFunction: 'getProjectTypes',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                            
                        },
                        {
                            fieldCode: "SC",
                            fieldName: "shortCode",
                            fieldDisplayName: "Short Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "INT",
                            fieldName: "internal",
                            fieldDisplayName: "Internal",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: false,
                            disabledFor: null,
                           enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR'],

                        },
                        {
                            fieldCode: "OC",
                            fieldName: "oldCode",
                            fieldDisplayName: "Old Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: false,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR'],

                        },
                        {
                            fieldCode: "PR-MNG",
                            fieldName: "projectManager",
                            fieldDisplayName: "Manager",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Manager',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: [['PR']]
                        },
                        {
                            fieldCode: "BC",
                            fieldName: "billingCompany",
                            fieldDisplayName: "Billing Company",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Company',
                            fieldValuesFunction: 'getCompanies',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            // parentFields: [['PR']],
                            parentFields: ['PR'],
                            invoiceEnable: {
                                "Thorogood Brazil Ltda" : ["CNPJ", "TOS"]
                            }
                        },
                        {
                            fieldCode: "PD",
                            fieldName: "paymentDays",
                            fieldDisplayName: "Payment Days",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.WHOLE_NO_FIELD_NAME,
                            fieldDefaultValue: null,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR'],
                        },
                    ]
                }
            ]
        },
        contactDetails : {
            sectionDisplayName: "Contact Details",
            sectionName: "contactDetails",
            formTemplateData : [
                {
                    // templateArea: [
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE" "SEL-CONT" "CONT-NAME" "AVAIL-CONT" "ADD" "TELE" "RET-MIL" "CN" "ST" ',
                    // ],
                    templateArea: [
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE" "SEL-CONT" "CONT-NAME" "AVAIL-CONT" "ADD" "TELE" "CN" "ST" ',
                    ],
                    fieldDetails : [
                        {   
                            fieldCode: "CONT-MODE",
                            fieldName: "contactMode",
                            fieldDisplayName: "",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.RADIO_FIELD_NAME,
                            inputType: Constants.RADIO_FIELD_NAME,
                            fieldDefaultValue: "ADD-NEW",
                            fieldPlaceholder: '',
                            fieldValuesFunction: "",
                            fieldValuesFunctionParams: [],
                            fieldValues: ['Add Contact', 'Select from existing contacts'],
                            fieldKey: ['ADD-NEW','SEL-CONT'],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: ['CL', 'AVAIL-CONT'],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR'],
                        },
                        {
                            fieldCode: "CONT-NAME",
                            fieldName: "contact",
                            //fieldName: "contactName",
                            fieldDisplayName: "Contact Name",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.INPUT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: 'Contact Name',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: null,
                            disabledFor:{  
                                         "SEL-CONT" : {
                                           filterValues: ['Select from existing contacts']
                                       }
                                    },
                            enabledFor: { 
                                             "ADD-NEW" : {
                                                 filterValues: ['Add Contact']
                                             },
                                      },
                            parentFields: ['AVAIL-CONT', 'PR'],
                        },
                        {
                            fieldCode: "AVAIL-CONT",
                            fieldName: "availableContacts",
                            fieldDisplayName: "Contact",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a contact',
                            fieldValuesFunction: "getContacts",
                            fieldValuesFunctionParams: ['CL'],
                            fieldValues: "",
                            functionToFillOtherFields: 'fillContactDetails',
                            fillOtherFieldsFunctionParams: ['CL', 'AVAIL-CONT'],
                            isEditable: true,
                            required: true,
                            disabledFor: {
                                          "ADD-NEW" : {
                                            filterValues: ['Add Contact']
                                        }
                                    },
                            enabledFor: {  
                                          "SEL-CONT" : {
                                            filterValues: ['Select from existing contacts']
                                        }
                                    },
                            parentFields: [],
                        },
                        {
                            fieldCode: "ADD",
                            fieldName: "address",
                            fieldDisplayName: "Address",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: null,
                            disabledFor:{  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            // disabledFor: null,
                            enabledFor: { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                            // enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['AVAIL-CONT', 'PR'],
                        },
                        {
                            fieldCode: "TELE",
                            fieldName: "telephone",
                            fieldDisplayName: "Telephone",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TELEPHONE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: null,
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            enabledFor: { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                            parentFields: ['AVAIL-CONT', 'PR'],
                        },
                        // {
                        //     fieldCode: "RET-MIL",
                        //     fieldName: "returnMileage",
                        //     fieldDisplayName: "Return Mileage",
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: '',
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,
                        //     required: false,
                        //     disabledFor: {  
                        //         "SEL-CONT" : {
                        //             filterValues: ['Select from existing contacts']
                        //         }
                        //     },
                        //     enabledFor:  { 
                        //         'ADD-NEW' : {
                        //             filterValues: ['Add Contact']
                        //         }
                        //     },
                        //     parentFields: ['AVAIL-CONT', 'PR'],
                        // },
                        {
                            fieldCode: "CN",
                            fieldName: "country",
                            fieldDisplayName: "Country",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Country',
                            fieldValuesFunction: "getCountries",
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields:'',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            enabledFor:  { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                            parentFields: ['AVAIL-CONT'],
                            invoiceEnable: {
                                "United States of America" : ["NO-VAT"],
                                // "United Kingdom" : ["NO-VAT"],
                                // "India": ["NO-VAT"],
                                // "Singapore": ["NO-VAT"],
                                // "Brazil": ["NO-VAT"],
                            }
                        },
                        {
                           fieldCode: "ST",
                            fieldName: "state",
                            fieldDisplayName: "State",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'State',
                            fieldValuesFunction: 'getStates',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: false,
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                },
                            },
                            enabledFor: { 
                                "CN" : {
                                   filterValues: GlobalConstants.countriesWithDisabledNoVatField,
                                },
                            },
                            parentFields: ['CN']
                        },
                    ]
                }
            ]
        },
        // invoiceDetails : {   
        //     sectionDisplayName: "Invoice Details",
        //     formTemplateData : [
        //         {
        //             templateArea: [
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC" "PO-NO-VW PO-NO-VW" "MIL-RA TAX" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD"  "PPD CL-VAT-NO" "PPPT PPP" "TOS TOS"  "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"'
        //             ],
        //             fieldDetails : [
        //                 {
        //                     fieldCode: "IN-DESC",
        //                     fieldName: "invoiceDescription",
        //                     fieldDisplayName: "Invoice Description",
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "MIL-RA",
        //                     fieldName: "mileageRate",
        //                     fieldDisplayName: "", 
        //                     fillFieldNameFunction: 
        //                         function (country: any) { return (country === '' || !country ? 'Mileage Rate' : 'Mileage Rate: (' + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "TAX",
        //                     fieldName: "salesTax",
        //                     fieldDisplayName: "",
        //                     fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "VAT Rate" : ProjectDetailsBlueprint.countryToTaxTypeMap[country]) + ': (%)' },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '', 
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {   
        //                     fieldCode: "NO-VAT",
        //                     fieldName: "noVat",
        //                     fieldDisplayName: "No VAT",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: false,
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true, 
        //                     required: false,
        //                     disabledFor: null,  
        //                     // disabledFor: { 'AVAIL-CONT': {
        //                     //     functionToGetFilterValues: ProjectDetailsBlueprint.getContactsWithDisabledNoVatField,
        //                     //     params: ['CL']
        //                     // } },
        //                     enabledFor: { "CN": {
        //                         filterValues: GlobalConstants.countriesWithEnabledNoVatField,
        //                     } },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "CNPJ",
        //                     fieldName: "cnpj",
        //                     fieldDisplayName: "CNPJ",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,   
        //                     required: true,
        //                     disabledFor:null,
        //                     enabledFor:{ "BC": {
        //                         filterValues: GlobalConstants.billingCompaniesWithCnpj,
        //                     } } ,
        //                     parentFields: [],
        //                 },
        //                 {
        //                     fieldCode: "PHR",   
        //                     fieldName: "projectHourlyRate",
        //                     fieldDisplayName: "Project Hourly Rate",  
        //                     fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "Project Hourly Rate: " : "Project Hourly Rate: (" + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true, 
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 { 
        //                     fieldCode: "CHG",   
        //                     fieldName: "charge",
        //                     fieldDisplayName: "Charge",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "IN-FOR",   
        //                     fieldName: "invoiceFormat",
        //                     fieldDisplayName: "Invoice Format",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Select a Invoice Format',
        //                     fieldValuesFunction: 'getInvoiceFormats',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']

        //                 },
        //                 {
        //                     fieldCode: "FFD",   
        //                     fieldName: "freeFormatDescription",
        //                     fieldDisplayName: "Free Format Description",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { "IN-FOR": {
        //                         filterValues: ["Free Format"]
        //                     } },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "PO-NO-VW",   
        //                     fieldName: "purchaseOrderNoViewMode",
        //                     fieldDisplayName: "Purchase Order Number", 
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME, 
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Purchase Order Numbers',
        //                     fieldValuesFunction: 'getPurchaseOrderDetails',
        //                     fieldValuesFunctionParams: ['CL', 'PR'],
        //                     functionToFillOtherFields: 'fillPrepaymentPO',
        //                     fillOtherFieldsFunctionParams: ['PO-NO-VW'],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "SHB",   
        //                     fieldName: "showHourlyBreakdown",
        //                     fieldDisplayName: "Show Hourly Breakdown",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,   
        //                     required: false,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "PR-DISC",   
        //                     fieldName: "projectDiscount",
        //                     fieldDisplayName: "Project Discount (%)",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,    
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "PPD",     
        //                     fieldName: "promptPaymentDiscount",
        //                     fieldDisplayName: "Prompt Payment Discount (%)",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,  
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "CL-VAT-NO",     
        //                     fieldName: "clientVatNo",
        //                     fieldDisplayName: "Client VAT No",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: false,
        //                     disabledFor: null,
        //                     enabledFor: { "NO-VAT": {
        //                         filterValues: [true]
        //                     } },
        //                     parentFields: ['PR'],

        //                 },
        //                 {
        //                     fieldCode: "TOS", 
        //                     fieldName: "typeOfService",
        //                     fieldDisplayName: "Type of Service",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Select a Type of Service',
        //                     fieldValuesFunction: 'getTypesOfService',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,   
        //                     required: true,
        //                     disabledFor:null,
        //                     enabledFor: { "BC": {
        //                         filterValues: GlobalConstants.billingCompaniesWithCnpj,
        //                     } } ,
        //                     parentFields: ['PR'],
        //                 },
        //                 {
        //                     fieldCode: "PPP",   
        //                     fieldName: "promptPaymentPeriod",
        //                     fieldDisplayName: "Prompt Payment Period",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.WHOLE_NO_FIELD_NAME,
        //                     fieldDefaultValue: "",
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "PPPT",   
        //                     fieldName: "promptPaymentPeriodType",
        //                     fieldDisplayName: "",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Select a Period Type',
        //                     fieldValuesFunction: 'getPromptPaymentPeriodTypes',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor: null,               
        //                     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "IN-CONT",   
        //                     fieldName: "invoiceContact",
        //                     fieldDisplayName: "Invoice Contact",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true, 
        //                     required: true,
        //                     disabledFor:{  
        //                         "SEL-CONT" : {
        //                           filterValues: ['Select from existing contacts']
        //                       }
        //                    },
        //                     enabledFor: { 
        //                             "ADD-NEW" : {
        //                                 filterValues: ['Add Contact']
        //                             },
        //                     },
        //                     parentFields: ['AVAIL-CONT', 'PR'],
        //                     // disabledFor: null,               
        //                     // enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     // parentFields: ['PR']
        //                 },
        //                 {
        //                     fieldCode: "IN-CONT-ADD",   
        //                     fieldName: "invoiceContactAddress",
        //                     fieldDisplayName: "Invoice Contact Address",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: true,
        //                     required: true,
        //                     disabledFor:{  
        //                         "SEL-CONT" : {
        //                           filterValues: ['Select from existing contacts']
        //                       }
        //                    },
        //                     enabledFor: { 
        //                             "ADD-NEW" : {
        //                                 filterValues: ['Add Contact']
        //                             },
        //                      },
        //                     parentFields: ['AVAIL-CONT', 'PR'],
        //                     // disabledFor: null,               
        //                     // enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
        //                     // parentFields: ['PR']
        //                 },
        //             ]
        //         }
        //     ]
            
        // },
        props: {
            formName: 'projectDetailsEditMode',
            rootField: 'CL',
            selectionFields: {
                'CL': ['PR'],
                'PR': ['PR-MNG','BC','PR-TYPE']
            },
            invoiceSelectionFields: {
                'PR': ['PO-NO-VW', 'PPPT', 'IN-FOR']
            },
            gridAreaForm: [
                '"headerDetails headerDetails" "projectDetails contactDetails" "buttons buttons"',
                '"headerDetails" "projectDetails" "contactDetails"',
                '"headerDetails" "projectDetails" "contactDetails"',
                '"headerDetails" "projectDetails" "contactDetails"',
            ],
            buttons: [
                { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateProject', isValidationRequired: false, buttonType: Constants.PROJECT_DETAILS_EDIT_MODE, page: 'edit' },
                { buttonName: Constants.CLEAR_BUTTON_NAME, buttonClickHandler: 'clearProjectDetails', isValidationRequired: false, buttonType: Constants.PROJECT_DETAILS_EDIT_MODE, page: 'edit' },
            ],
            
        },
    }

    public static _projectDetailsEditModeInvoiceDetails = {
        invoiceDetails : {   
            sectionDisplayName: "Invoice Details",
            formTemplateData : [
                {
                    // templateArea: [
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-VW PO-NO-VW PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC" "PO-NO-VW PO-NO-VW" "MIL-RA TAX" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD"  "PPD CL-VAT-NO" "PPPT PPP" "TOS TOS"  "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"'
                    // ],

                    templateArea: [
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC" "PO-NO-VW PO-NO-VW" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD" "TOS TOS" "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"'
                      ],
                    fieldDetails : [
                        {
                            fieldCode: "IN-DESC",
                            fieldName: "invoiceDescription",
                            fieldDisplayName: "Invoice Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        // {
                        //     fieldCode: "MIL-RA",
                        //     fieldName: "mileageRate",
                        //     fieldDisplayName: "", 
                        //     fillFieldNameFunction: 
                        //         function (country: any) { return (country === '' || !country ? 'Mileage Rate' : 'Mileage Rate: (' + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                        //     fillFieldNameFunctionParams: ['CN'],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: '',
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,
                        //     required: true,
                        //     disabledFor: null,
                        //     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                        //     parentFields: ['PR']
                        // },
                        {
                            fieldCode: "TAX",
                            fieldName: "salesTax",
                            fieldDisplayName: "",
                            fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "VAT Rate" : ProjectDetailsBlueprint.countryToTaxTypeMap[country]) + ': (%)' },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '', 
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        {   
                            fieldCode: "NO-VAT",
                            fieldName: "noVat",
                            fieldDisplayName: "No VAT",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: false,
                            disabledFor: null,  
                            // disabledFor: { 'AVAIL-CONT': {
                            //     functionToGetFilterValues: ProjectDetailsBlueprint.getContactsWithDisabledNoVatField,
                            //     params: ['CL']
                            // } },
                            enabledFor: { "CN": {
                                filterValues: GlobalConstants.countriesWithEnabledNoVatField,
                            } },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "CNPJ",
                            fieldName: "cnpj",
                            fieldDisplayName: "CNPJ",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,   
                            required: true,
                            disabledFor:null,
                            enabledFor:{ "BC": {
                                filterValues: GlobalConstants.billingCompaniesWithCnpj,
                            } } ,
                            parentFields: [],
                        },
                        {
                            fieldCode: "PHR",   
                            fieldName: "projectHourlyRate",
                            fieldDisplayName: "Project Hourly Rate",  
                            fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "Project Hourly Rate: " : "Project Hourly Rate: (" + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        { 
                            fieldCode: "CHG",   
                            fieldName: "charge",
                            fieldDisplayName: "Charge",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "IN-FOR",   
                            fieldName: "invoiceFormat",
                            fieldDisplayName: "Invoice Format",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Invoice Format',
                            fieldValuesFunction: 'getInvoiceFormats',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']

                        },
                        {
                            fieldCode: "FFD",   
                            fieldName: "freeFormatDescription",
                            fieldDisplayName: "Free Format Description",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { "IN-FOR": {
                                filterValues: ["Free Format"]
                            } },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "PO-NO-VW",   
                            fieldName: "purchaseOrderNoViewMode",
                            fieldDisplayName: "Purchase Order Number", 
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME, 
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Purchase Order Numbers',
                            fieldValuesFunction: 'getPurchaseOrderDetails',
                            fieldValuesFunctionParams: ['CL', 'PR'],
                            functionToFillOtherFields: 'fillPrepaymentPO',
                            fillOtherFieldsFunctionParams: ['PO-NO-VW'],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            // fieldValues: [],
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "SHB",   
                            fieldName: "showHourlyBreakdown",
                            fieldDisplayName: "Show Hourly Breakdown",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,   
                            required: false,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        {
                            fieldCode: "PR-DISC",   
                            fieldName: "projectDiscount",
                            fieldDisplayName: "Project Discount (%)",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,    
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            parentFields: ['PR']
                        },
                        // {
                        //     fieldCode: "PPD",     
                        //     fieldName: "promptPaymentDiscount",
                        //     fieldDisplayName: "Prompt Payment Discount (%)",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: '',
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,  
                        //     required: true,
                        //     disabledFor: null,
                        //     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                        //     parentFields: ['PR']
                        // },
                        {
                            fieldCode: "CL-VAT-NO",     
                            fieldName: "clientVatNo",
                            fieldDisplayName: "Client VAT No",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: false,
                            disabledFor: null,
                            enabledFor: { "NO-VAT": {
                                filterValues: [true]
                            } },
                            parentFields: ['PR'],

                        },
                        {
                            fieldCode: "TOS", 
                            fieldName: "typeOfService",
                            fieldDisplayName: "Type of Service",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Type of Service',
                            fieldValuesFunction: 'getTypesOfService',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor:null,
                            // fieldValues: [],
                            enabledFor: { "BC": {
                                filterValues: GlobalConstants.billingCompaniesWithCnpj,
                            } } ,
                            parentFields: ['PR'],
                        },
                        // {
                        //     fieldCode: "PPP",   
                        //     fieldName: "promptPaymentPeriod",
                        //     fieldDisplayName: "Prompt Payment Period",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.WHOLE_NO_FIELD_NAME,
                        //     fieldDefaultValue: "",
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,
                        //     required: true,
                        //     disabledFor: null,
                        //     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                        //     parentFields: ['PR']
                        // },
                        // {
                        //     fieldCode: "PPPT",   
                        //     fieldName: "promptPaymentPeriodType",
                        //     fieldDisplayName: "",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.DROPDOWN_FIELD_NAME,
                        //     inputType: Constants.DROPDOWN_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: null,
                        //     fieldPlaceholder: 'Select a Period Type',
                        //     fieldValuesFunction: 'getPromptPaymentPeriodTypes',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: true,
                        //     required: true,
                        //     disabledFor: null,
                        //     // fieldValues: [],            
                        //     enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                        //     parentFields: ['PR']
                        // },
                        {
                            fieldCode: "IN-CONT",   
                            fieldName: "invoiceContact",
                            fieldDisplayName: "Invoice Contact",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: true,
                            disabledFor:{  
                                "SEL-CONT" : {
                                  filterValues: ['Select from existing contacts']
                              }
                           },
                            enabledFor: { 
                                    "ADD-NEW" : {
                                        filterValues: ['Add Contact']
                                    },
                            },
                            parentFields: ['AVAIL-CONT', 'PR'],
                            // disabledFor: null,               
                            // enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            // parentFields: ['PR']
                        },
                        {
                            fieldCode: "IN-CONT-ADD",   
                            fieldName: "invoiceContactAddress",
                            fieldDisplayName: "Invoice Contact Address",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor:{  
                                "SEL-CONT" : {
                                  filterValues: ['Select from existing contacts']
                              }
                           },
                            enabledFor: { 
                                    "ADD-NEW" : {
                                        filterValues: ['Add Contact']
                                    },
                             },
                            parentFields: ['AVAIL-CONT', 'PR'],
                            // disabledFor: null,               
                            // enabledFor: { 'PR': ConstantFormStates.enabledAlways, },
                            // parentFields: ['PR']
                        },
                    ]
                }
            ]
            
        },
        props : {
            formName: 'projectDetailsEditModeInvoiceDetails',
            selectionFields: {},

            gridAreaForm: [
                '"invoiceDetails" "buttons"'
            ],
            
            buttons: [{ buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'updateProjectInvoice', isValidationRequired: true, buttonType: Constants.INVOICE_DETAILS_EDIT_MODE }, 
                { buttonName: Constants.CLEAR_BUTTON_NAME, buttonClickHandler: 'clearProjectDetails', isValidationRequired: false, buttonType: Constants.INVOICE_DETAILS_EDIT_MODE }],   // add this prop for the below forms too
        }
    }
    
    public static _projectDetailsEditModeConsultantProjectRates = {
        consultantProjectRates : {
            sectionDisplayName: "Consultant Project Rates",
            formTemplateData : [
                {
                    templateArea : [
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON" "CON" "HR" "LEV"',
                    ],

                     fieldDetails : [
                        {
                            fieldCode: "MON",
                            fieldName: "month",
                            fieldDisplayName: "Month",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a month',
                            fieldValuesFunction: 'getMonths',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "CON",
                            fieldName: "consultant",
                            fieldDisplayName: "Consultant",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a consultant',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "HR",
                            fieldName: "hourlyRate",
                            fieldDisplayName: "Hourly Rate",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "LEV",
                            fieldName: "leverage",
                            fieldDisplayName: "Leverage",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        }, 
                     ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsEditModeConsultantProjectRates',
            selectionFields: {},
            gridAreaForm: [
                '"consultantProjectRates" "buttons"',
                '"consultantProjectRates"',
                '"consultantProjectRates"',
                '"consultantProjectRates"',
            ],
            buttons: [
            { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addConsultantProjectRates', isValidationRequired: true, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'edit' },
            { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateConsultantProjectRates', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'edit' },
            { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteConsultantProjectRates', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'edit' },
            { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, page: 'edit' }
        ],   
        }
    }

    public static _projectDetailsEditModeProjectTasks = {
        projectTasks : {
            sectionDisplayName: "Project Tasks",
            formTemplateData : [
                {
                    templateArea : [
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF" "PR-TASK-DESC" "FCS" "FCE" "FCV" "PR-TASK-MNG" "ACT" "IS-DEF"',
                    ],

                     fieldDetails : [
                        {
                            fieldCode: "TASK-REF",
                            fieldName: "taskRef",
                            fieldDisplayName: "Task Ref",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "PR-TASK-DESC",
                            fieldName: "projectTaskDescription",
                            fieldDisplayName: "Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCS",
                            fieldName: "fixedCostStart",
                            fieldDisplayName: "Fixed Cost Start",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCE",
                            fieldName: "fixedCostEnd",
                            fieldDisplayName: "Fixed Cost End",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCV",
                            fieldName: "fixedCostValue",
                            fieldDisplayName: "Fixed Cost Value",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-TASK-MNG",
                            fieldName: "projectTaskManager",
                            fieldDisplayName: "Manager",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a manager',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "ACT",
                            fieldName: "active",
                            fieldDisplayName: "Active",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "IS-DEF",
                            fieldName: "isDefault",
                            fieldDisplayName: "Is Default",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        }
                     ]

                }
            ]
        },
        props : {
            formName: 'projectDetailsEditModeProjectTasks',
            selectionFields: {},
            gridAreaForm: [
                '"projectTasks" "buttons"',
                '"projectTasks"',
                '"projectTasks"',
                '"projectTasks"',
            ],
            buttons: [
            { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addProjectTask', isValidationRequired: true, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'edit' },
            { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateProjectTask', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'edit' },
            { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteProjectTask', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'edit' },
            { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, page: 'edit' }],
        }
    }

    public static _projectDetailsEditModeThemeAllocation = {
        themeAllocation: {
            sectionDisplayName: "Theme Allocation",
            formTemplateData: [
                {
                    templateArea: [
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                    ],

  
                     fieldDetails: [
                        {
                            fieldCode: "THM",
                            fieldName: "theme",
                            fieldDisplayName: "Theme",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null, 
                            fieldPlaceholder: 'Select a Theme',
                            fieldValuesFunction: 'getThemeList',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "ALC",   
                            fieldName: "allocation",
                            fieldDisplayName: "Allocation %",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "", 
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,  
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                     ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsEditModeThemeAllocation',
            selectionFields: {},
            gridAreaForm: [
                '"themeAllocation" "buttons"',
                '"themeAllocation"',
                '"themeAllocation"',
                '"themeAllocation"',
            ],
            buttons: [
                { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addThemeAllocation', isValidationRequired: true, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'edit' },
                // { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateThemeAllocation', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'edit' },
                // { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteThemeAllocation', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'edit' },
                { buttonName: Constants.VIEW_BUTTON_NAME, buttonClickHandler: '', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'edit'},
                { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, page: 'edit' }]
        }
    }

    public static _projectDetailsEditModePrepaymentDetails = {
        prepaymentDetails: {
            sectionDisplayName: "Prepayment Details",
            formTemplateData: [
                {
                    templateArea: [
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD" "PP-AMT" "REF-NO" "PP-DT" "PP-DESC"',
                    ],
                    fieldDetails: [
                        {
                            fieldCode: "PO-NO-PPD",   
                            fieldName: "purchaseOrderNoViewMode",
                            fieldDisplayName: "Purchase Order Number", 
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: Constants.TEXT_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: 'getPurchaseOrderNo',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor:  ConstantFormStates.disabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-AMT",   
                            fieldName: "prepaymentAmt",
                            fieldDisplayName: "Prepayment Amount",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                            
                        },
                        {
                            fieldCode: "REF-NO",   
                            fieldName: "referenceNo",
                            fieldDisplayName: "Reference Number",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: Constants.TEXT_FIELD_NAME,
                            fieldDefaultValue: "",  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-DT",   
                            fieldName: "prepaymentDate",
                            fieldDisplayName: "Prepayment Date",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-DESC",   
                            fieldName: "prepaymentDescription",
                            fieldDisplayName: "Description",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsEditModePrepaymentDetails',
            selectionFields: {},
            gridAreaForm: [
                '"prepaymentDetails" "buttons"',
                '"prepaymentDetails"',
                '"prepaymentDetails"',
                '"prepaymentDetails"',
            ],
            buttons: [
            { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addPrepaymentDetails', isValidationRequired: true, buttonType: Constants.PREPAYMENT_DETAILS_FORM, buttonPage: 'edit' }, 
            // { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updatePrepaymentDetails', isValidationRequired: false, buttonType: Constants.PREPAYMENT_DETAILS_FORM, buttonPage: 'edit' },
            { buttonName: Constants.VIEW_BUTTON_NAME, buttonClickHandler: '', isValidationRequired: false, buttonType: Constants.PREPAYMENT_DETAILS_FORM, buttonPage: 'edit'},
            ]
        }
    }
    public static _projectDetailsAddModePrimaryFormBlueprint = {
        projectDetails : {   
            sectionDisplayName: "Project Details",
            sectionName: "projectDetails",
            formTemplateData : [
                {
                    templateArea: [
                        '"CL PR-MNG" "PR-CODE PR-TYPE" "PR-DESC PR-DESC"  "OC INT" "BC PD" ',
                        '"CL PR-MNG" "PR-CODE PR-TYPE" "PR-DESC PR-DESC"   "OC INT" "BC PD" ',
                        '"CL PR-MNG" "PR-CODE PR-TYPE" "PR-DESC PR-DESC"   "OC INT" "BC PD" ',
                        '"CL" "PR-CODE PR-TYPE" "PR-DESC PR-DESC"  "OC INT" "BC PD" ',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "CL",
                            fieldName: "client",
                            fieldDisplayName: "Client",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a client',
                            fieldValuesFunction: "getClients",
                            fieldValuesFunctionParams: [],
                            fieldValues: "",
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-CODE",
                            fieldName: "projectCode",
                            fieldDisplayName: "Project Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Add Project Code',
                            fieldValuesFunction: 'getProjectCodes',
                            fieldValuesFunctionParams: ['CL'],
                            functionToFillOtherFields: 'fillProjectDetails',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-DESC",
                            fieldName: "projectDescription",
                            fieldDisplayName: "Project Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-TYPE",
                            fieldName: "projectType",
                            fieldDisplayName: "Project Type",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a project type',
                            fieldValuesFunction: 'getProjectTypes',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "INT",
                            fieldName: "internal",
                            fieldDisplayName: "Internal",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false, 
                            fieldPlaceholder: '',
                            fieldValuesFunction: 'updateInternalFieldState',
                            fieldValuesFunctionParams: ['CL'], 
                            functionToFillOtherFields: '', 
                            isEditable: false, 
                            required: false,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "OC",
                            fieldName: "oldCode",
                            fieldDisplayName: "Old Code",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-MNG",
                            fieldName: "projectManager",
                            fieldDisplayName: "Manager",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Manager',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "BC",
                            fieldName: "billingCompany",
                            fieldDisplayName: "Billing Company",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Company',
                            fieldValuesFunction: 'getCompanies',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                        {
                            fieldCode: "PD",
                            fieldName: "paymentDays",
                            fieldDisplayName: "Payment Days",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.WHOLE_NO_FIELD_NAME,
                            fieldDefaultValue: 30,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        contactDetails : {
            sectionDisplayName: "Contact Details",
            sectionName: "contactDetails",
            formTemplateData : [
                {
                    // templateArea: [
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE RET-MIL" "CN ST" ',
                    //     '"CONT-MODE" "SEL-CONT" "CONT-NAME" "AVAIL-CONT" "ADD" "TELE" "RET-MIL" "CN" "ST" ',
                    // ],
                    templateArea: [
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE CONT-MODE" "AVAIL-CONT CONT-NAME" "ADD ADD" "TELE TELE" "CN ST" ',
                        '"CONT-MODE" "SEL-CONT" "CONT-NAME" "AVAIL-CONT" "ADD" "TELE" "CN" "ST" ',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "CONT-MODE",
                            fieldName: "contactMode",
                            fieldDisplayName: "",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.RADIO_FIELD_NAME,
                            inputType: Constants.RADIO_FIELD_NAME,
                            fieldDefaultValue: null,
                            fieldPlaceholder: '',
                            fieldValuesFunction: "",
                            fieldValuesFunctionParams: [],
                            fieldValues: ['Add Contact', 'Select from existing contacts'],
                            fieldKey: ['ADD-NEW','SEL-CONT'],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: ['CL', 'AVAIL-CONT'],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
                            parentFields: [],
                        },
                        {
                            fieldCode: "CONT-NAME",
                            fieldName: "contact",
                            //fieldName: "contactName",
                            fieldDisplayName: "Contact Name",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.INPUT_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: 'Contact Name',
                            fieldValuesFunction: "",
                            fieldValuesFunctionParams: "",
                            fieldValues: "",
                            functionToFillOtherFields: "",
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: null,
                            disabledFor: {  
                                "SEL-CONT" : {
                                  filterValues: ['Select from existing contacts']
                              }
                            },
                            enabledFor: {
                                    "ADD-NEW" : {
                                        filterValues: ['Add Contact']
                                    },
                             },
                            parentFields: ['AVAIL-CONT'],
                        },
                        {
                            fieldCode: "AVAIL-CONT",
                            fieldName: "availableContacts",
                            fieldDisplayName: "Contact",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a contact',
                            fieldValuesFunction: "getContacts",
                            fieldValuesFunctionParams: ['CL'],
                            fieldValues: "",
                            functionToFillOtherFields: 'fillContactDetails',
                            fillOtherFieldsFunctionParams: ['CL', 'AVAIL-CONT'],
                            isEditable: true,
                            required: true,
                            disabledFor: {
                                          "ADD-NEW" : {
                                            filterValues: ['Add Contact']
                                        }
                                    },
                            enabledFor: {  
                                          "SEL-CONT" : {
                                            filterValues: ['Select from existing contacts']
                                        }
                                    },
                            parentFields: [],
                        },
                        {
                            fieldCode: "ADD",
                            fieldName: "address",
                            fieldDisplayName: "Address",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: null,    
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            enabledFor: { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                            parentFields: ['AVAIL-CONT'],
                        },
                        {
                            fieldCode: "TELE",
                            fieldName: "telephone",
                            fieldDisplayName: "Telephone",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TELEPHONE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: null,
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            enabledFor: { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                            parentFields: ['AVAIL-CONT']
                        },
                        // {
                        //     fieldCode: "RET-MIL",
                        //     fieldName: "returnMileage",
                        //     fieldDisplayName: "Return Mileage",
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: '',
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: false,
                        //     disabledFor: {  
                        //         "SEL-CONT" : {
                        //             filterValues: ['Select from existing contacts']
                        //         }
                        //     },
                        //     enabledFor: { 
                        //         'ADD-NEW' : {
                        //             filterValues: ['Add Contact']
                        //         }
                        //     },
                        //     parentFields: ['AVAIL-CONT']
                        // },
                        {
                            fieldCode: "CN",
                            fieldName: "country",
                            fieldDisplayName: "Country",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: "Country",
                            fieldValuesFunction: "getCountries",
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: {  
                                "SEL-CONT" : {
                                    filterValues: ['Select from existing contacts']
                                }
                            },
                            enabledFor: { 
                                'ADD-NEW' : {
                                    filterValues: ['Add Contact']
                                }
                            },
                             parentFields: ['AVAIL-CONT']
                        },
                        {
                            fieldCode: "ST",
                            fieldName: "state",
                            fieldDisplayName: "State",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'State',
                            fieldValuesFunction: 'getStates',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: { "CN" : {
                                filterValues: GlobalConstants.countriesWithDisabledNoVatField,
                            }},
                            parentFields: [],
                        }
                    ]
                }
            ]
        },
        // invoiceDetails : {   
        //     sectionDisplayName: "Invoice Details",
        //     formTemplateData : [
        //         {
        //             templateArea: [
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
        //                 '"IN-DESC IN-DESC" "PO-NO-ADD PO-NO-ADD" "MIL-RA TAX" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD"  "PPD CL-VAT-NO" "PPPT PPP" "TOS TOS"  "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"',
        //             ],
        //             fieldDetails : [
        //                 {
        //                     fieldCode: "IN-DESC",
        //                     fieldName: "invoiceDescription",
        //                     fieldDisplayName: "Invoice Description",
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,  
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "MIL-RA",
        //                     fieldName: "mileageRate",
        //                     fieldDisplayName: "Mileage Rate", 
        //                     fillFieldNameFunction: function (country: any) { 
        //                         return (country === '' || !country ? 'Mileage Rate' : 'Mileage Rate: (' + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: "0.00",   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "TAX",
        //                     fieldName: "salesTax",
        //                     fieldDisplayName: "Tax Rate (%)",  
        //                     fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "Tax Rate" : ProjectDetailsBlueprint.countryToTaxTypeMap[country]) + ': (%)' },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '',
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: [],
        //                 },
        //                 {
        //                     fieldCode: "NO-VAT",
        //                     fieldName: "noVat",
        //                     fieldDisplayName: "No VAT",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: false,   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: { 'AVAIL-CONT': {
        //                         functionToGetFilterValues: ProjectDetailsBlueprint.getContactsWithDisabledNoVatField,
        //                         params: ['CL']
        //                     } },
        //                     // enabledFor: { 'AVAIL-CONT': ConstantFormStates.enabledAlways, },
        //                     enabledFor: { "CN": {
        //                         filterValues: GlobalConstants.countriesWithEnabledNoVatField,
        //                     } },
        //                     parentFields: [],
        //                 },
        //                 {
        //                     fieldCode: "CNPJ",
        //                     fieldName: "cnpj",
        //                     fieldDisplayName: "CNPJ",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,   
        //                     required: true,
        //                     disabledFor: null,             
        //                     enabledFor: { "BC": {
        //                         filterValues: GlobalConstants.billingCompaniesWithCnpj,
        //                     } },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "PHR",   
        //                     fieldName: "projectHourlyRate",
        //                     fieldDisplayName: "Project Hourly Rate",  
        //                     fillFieldNameFunction: 
        //                         function (country: any) { return (country === '' || !country ? "Project Hourly Rate: " : "Project Hourly Rate: (" + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
        //                     fillFieldNameFunctionParams: ['CN'],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: "0.00",  
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false, 
        //                     required: true,
        //                     disabledFor: null,                      
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "CHG",   
        //                     fieldName: "charge",
        //                     fieldDisplayName: "Charge",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: false,   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: null,                         
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "IN-FOR",   
        //                     fieldName: "invoiceFormat",
        //                     fieldDisplayName: "Invoice Format",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Select an Invoice Format',
        //                     fieldValuesFunction: 'getInvoiceFormats',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "FFD",   
        //                     fieldName: "freeFormatDescription",
        //                     fieldDisplayName: "Free Format Description",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,
        //                     enabledFor: { "IN-FOR": {
        //                         filterValues: ["Free Format"]
        //                     } },
        //                     parentFields: []
        //                 },
        //                 {    
        //                     fieldCode: "PO-NO-ADD",   
        //                     fieldName: "purchaseOrderNoAddMode",
        //                     fieldDisplayName: "Purchase Order No", 
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME, 
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "Not Applicable",      
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [''],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: null,
        //                     enabledFor: ConstantFormStates.disabledAlways,
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "SHB",   
        //                     fieldName: "showHourlyBreakdown",
        //                     fieldDisplayName: "Show Hourly Breakdown",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.CHECKBOX_FIELD_NAME,
        //                     inputType: Constants.CHECKBOX_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: false,   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,   
        //                     required: false,
        //                     disabledFor: null,                     
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "PR-DISC",   
        //                     fieldName: "projectDiscount",
        //                     fieldDisplayName: "Project Discount (%)",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '0.00',  
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,    
        //                     required: true,
        //                     disabledFor: null,                       
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "PPD",     
        //                     fieldName: "promptPaymentDiscount",
        //                     fieldDisplayName: "Prompt Payment Discount (%)",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.NUMBER_FIELD_NAME,
        //                     fieldDefaultValue: '0.00',      
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,                 
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "CL-VAT-NO",     
        //                     fieldName: "clientVatNo",
        //                     fieldDisplayName: "Client VAT No",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",      
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: false,
        //                     disabledFor: null,                     
        //                     enabledFor: { "NO-VAT": {
        //                         filterValues: [true]
        //                     } },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "TOS", 
        //                     fieldName: "typeOfService",
        //                     fieldDisplayName: "Type of Service",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: null,
        //                     fieldPlaceholder: 'Select a Type of Service',
        //                     fieldValuesFunction: 'getTypesOfService',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,   
        //                     required: true,
        //                     disabledFor: null,                 
        //                     enabledFor: { "BC": {
        //                         filterValues: GlobalConstants.billingCompaniesWithCnpj,
        //                     } },
        //                     parentFields: []
        //                 },
        //                 {
        //                     fieldCode: "PPPT",   
        //                     fieldName: "promptPaymentPeriodType",
        //                     fieldDisplayName: "",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.DROPDOWN_FIELD_NAME,
        //                     inputType: Constants.DROPDOWN_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "days",
        //                     fieldPlaceholder: 'Select a Period Type',
        //                     fieldValuesFunction: 'getPromptPaymentPeriodTypes',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,               
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: ['CL']
        //                 },
        //                 {
        //                     fieldCode: "PPP",   
        //                     fieldName: "promptPaymentPeriod",
        //                     fieldDisplayName: "Prompt Payment Period",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.NUMBER_FIELD_NAME,
        //                     numberType: Constants.WHOLE_NO_FIELD_NAME,
        //                     fieldDefaultValue: null,   
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor: null,              
        //                     enabledFor: { 'CL': ConstantFormStates.enabledAlways, },
        //                     parentFields: []
        //                 },
        //                 {           
        //                     fieldCode: "IN-CONT",   
        //                     fieldName: "invoiceContact",
        //                     fieldDisplayName: "Invoice Contact",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.INPUT_FIELD_NAME,
        //                     inputType: Constants.TEXT_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",  
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false, 
        //                     required: true,
        //                     disabledFor:{  
        //                         "SEL-CONT" : {
        //                           filterValues: ['Select from existing contacts']
        //                       }
        //                     },
        //                     enabledFor: { 
        //                             "ADD-NEW" : {
        //                                 filterValues: ['Add Contact']
        //                             },
        //                     },
        //                     parentFields: ['AVAIL-CONT'],
        //                 },
        //                 {
        //                     fieldCode: "IN-CONT-ADD",  
        //                     fieldName: "invoiceContactAddress",
        //                     fieldDisplayName: "Invoice Contact Address",  
        //                     fillFieldNameFunction: '',
        //                     fillFieldNameFunctionParams: [],
        //                     fieldType: Constants.TEXTAREA_FIELD_NAME,
        //                     inputType: Constants.TEXTAREA_FIELD_NAME,
        //                     numberType: '',
        //                     fieldDefaultValue: "",  
        //                     fieldPlaceholder: '',
        //                     fieldValuesFunction: '',
        //                     fieldValuesFunctionParams: [],
        //                     functionToFillOtherFields: '',
        //                     fillOtherFieldsFunctionParams: [],
        //                     isEditable: false,
        //                     required: true,
        //                     disabledFor:{  
        //                         "SEL-CONT" : {
        //                           filterValues: ['Select from existing contacts']
        //                       }
        //                     },
        //                     enabledFor: { 
        //                             "ADD-NEW" : {
        //                                 filterValues: ['Add Contact']
        //                             },
        //                     },
        //                     parentFields: ['AVAIL-CONT'],
        //                 },
        //             ]
        //         }
        //     ]
        // },
        props: {
            formName: 'projectDetailsAddMode',
            selectionFields: {
                'CL': ['AVAIL-CONT'],
                'AVAIL-CONT': ['CN']
            },
            // gridAreaForm : [
            //     '"projectDetails contactDetails" "invoiceDetails invoiceDetails" "buttons buttons"',
            //     '"projectDetails contactDetails" "invoiceDetails invoiceDetails"',
            //     '"projectDetails" "contactDetails" "invoiceDetails"',
            //     '"projectDetails" "contactDetails" "invoiceDetails"',
            // ],
            gridAreaForm : [
                '"projectDetails contactDetails" "buttons buttons"',
                '"projectDetails contactDetails"',
                '"projectDetails" "contactDetails"',
                '"projectDetails" "contactDetails"',
            ],
            buttons: [
                { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'callAddOrUpdate', isValidationRequired: true, buttonType: Constants.PROJECT_DETAILS_ADD_MODE }, 
                { buttonName: Constants.CLEAR_BUTTON_NAME, buttonClickHandler: 'clearProjectDetails', isValidationRequired: false, buttonType: Constants.PROJECT_DETAILS_ADD_MODE}
            ],
        },
    }

    public static _projectDetailsAddModeConsultantProjectRates = {
        consultantProjectRates : {
            sectionDisplayName: "Consultant Project Rates",
            formTemplateData : [
                {
                    templateArea : [
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON CON HR LEV"',
                        '"MON" "CON" "HR" "LEV"',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "MON",
                            fieldName: "month",
                            fieldDisplayName: "Month",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a month',
                            fieldValuesFunction: 'getMonths',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "CON",
                            fieldName: "consultant",
                            fieldDisplayName: "Consultant",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a consultant',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "HR",
                            fieldName: "hourlyRate",
                            fieldDisplayName: "Hourly Rate",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "LEV",
                            fieldName: "leverage",
                            fieldDisplayName: "Leverage",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsAddModeConsultantProjectRates',
            selectionFields: {},
            gridAreaForm: [
                '"consultantProjectRates" "buttons"'
            ],
            
            buttons: [{ buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addConsultantProjectRates', isValidationRequired: true, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'add' },
            { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateConsultantProjectRates', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'add' },
            { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteConsultantProjectRates', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'add' },
            { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.CONSUTANT_PROJECT_RATES_FORM, buttonPage: 'add' }],   // add this prop for the below forms too
        }
    }

    public static _projectDetailsAddModeInvoiceDetails = {
        invoiceDetails : {
            sectionDisplayName: "Invoice Details",
            formTemplateData : [
                {
                    // templateArea : [
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "TAX TAX SHB SHB PR-DISC PR-DISC PHR PHR" "PO-NO-ADD PO-NO-ADD PPD PPD PPP PPP PPPT PPPT" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS" "MIL-RA MIL-RA IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD"',
                    //     '"IN-DESC IN-DESC" "PO-NO-ADD PO-NO-ADD" "MIL-RA TAX" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD"  "PPD CL-VAT-NO" "PPPT PPP" "TOS TOS"  "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"',
                    // ],
                    // templateArea: [
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                    //     '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-VW PO-NO-VW IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                    //     '"IN-DESC IN-DESC" "PO-NO-VW PO-NO-VW" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD" "TOS TOS" "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"'
                    //   ],
                    templateArea: [
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-ADD PO-NO-ADD IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-ADD PO-NO-ADD IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC IN-DESC IN-DESC IN-FOR IN-FOR FFD FFD" "PHR PHR SHB SHB PR-DISC PR-DISC TAX TAX" "PO-NO-ADD PO-NO-ADD IN-CONT IN-CONT IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD IN-CONT-ADD" "CHG NO-VAT CL-VAT-NO CL-VAT-NO CNPJ CNPJ TOS TOS"',
                        '"IN-DESC IN-DESC" "PO-NO-VW PO-NO-VW" "NO-VAT CNPJ" "PHR CHG" "SHB PR-DISC" "IN-FOR IN-FOR" "FFD FFD" "TOS TOS" "IN-CONT IN-CONT" "IN-CONT-ADD IN-CONT-ADD"'
                      ],
                    fieldDetails : [
                        {
                            fieldCode: "IN-DESC",
                            fieldName: "invoiceDescription",
                            fieldDisplayName: "Invoice Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,  
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        // {
                        //     fieldCode: "MIL-RA",
                        //     fieldName: "mileageRate",
                        //     fieldDisplayName: "Mileage Rate", 
                        //     fillFieldNameFunction: function (country: any) { 
                        //         return (country === '' || !country ? 'Mileage Rate' : 'Mileage Rate: (' + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                        //     fillFieldNameFunctionParams: ['CN'],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: "0.00",   
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: true,
                        //     disabledFor: null,
                        //     enabledFor: ConstantFormStates.enabledAlways,
                        //     parentFields: []
                        // },
                        {
                            fieldCode: "TAX",
                            fieldName: "salesTax",
                            fieldDisplayName: "Tax Rate (%)",  
                            fillFieldNameFunction: function (country: any) { return (country === '' || !country ? "Tax Rate" : ProjectDetailsBlueprint.countryToTaxTypeMap[country]) + ': (%)' },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {   
                            fieldCode: "NO-VAT",
                            fieldName: "noVat",
                            fieldDisplayName: "No VAT",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: false,
                            disabledFor: null,  
                            // disabledFor: { 'AVAIL-CONT': {
                            //     functionToGetFilterValues: ProjectDetailsBlueprint.getContactsWithDisabledNoVatField,
                            //     params: ['CL']
                            // } },
                            enabledFor: { "CN": {
                                filterValues: GlobalConstants.countriesWithEnabledNoVatField,
                            } },
                            parentFields: ['PR']
                        },
                        
                        {
                            fieldCode: "CNPJ",
                            fieldName: "cnpj",
                            fieldDisplayName: "CNPJ",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,   
                            required: true,
                            disabledFor:null,
                            enabledFor:{ "BC": {
                                filterValues: GlobalConstants.billingCompaniesWithCnpj,
                            } } ,
                            parentFields: [],
                        },
                        {
                            fieldCode: "PHR",   
                            fieldName: "projectHourlyRate",
                            fieldDisplayName: "Project Hourly Rate",  
                            fillFieldNameFunction: 
                                function (country: any) { return (country === '' || !country ? "Project Hourly Rate: " : "Project Hourly Rate: (" + ProjectDetailsBlueprint.countryToCurrencyMap[country] + ')') },
                            fillFieldNameFunctionParams: ['CN'],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "0.00",  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,                      
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "CHG",   
                            fieldName: "charge",
                            fieldDisplayName: "Charge",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,                         
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "IN-FOR",   
                            fieldName: "invoiceFormat",
                            fieldDisplayName: "Invoice Format",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select an Invoice Format',
                            fieldValuesFunction: 'getInvoiceFormats',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor:  ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "FFD",   
                            fieldName: "freeFormatDescription",
                            fieldDisplayName: "Free Format Description",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: { "IN-FOR": {
                                filterValues: ["Free Format"]
                            } },
                            parentFields: []
                        },
                        {    
                            fieldCode: "PO-NO-ADD",   
                            fieldName: "purchaseOrderNoAddMode",
                            fieldDisplayName: "Purchase Order No", 
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME, 
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "Not Applicable",      
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [''],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.disabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "SHB",   
                            fieldName: "showHourlyBreakdown",
                            fieldDisplayName: "Show Hourly Breakdown",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: false,
                            disabledFor: null,                     
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-DISC",   
                            fieldName: "projectDiscount",
                            fieldDisplayName: "Project Discount (%)",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: '0.00',  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,    
                            required: true,
                            disabledFor: null,                       
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        // {
                        //     fieldCode: "PPD",     
                        //     fieldName: "promptPaymentDiscount",
                        //     fieldDisplayName: "Prompt Payment Discount (%)",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.NUMBER_FIELD_NAME,
                        //     fieldDefaultValue: '0.00',      
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: true,
                        //     disabledFor: null,                 
                        //     enabledFor: ConstantFormStates.enabledAlways,
                        //     parentFields: []
                        // },
                        {
                            fieldCode: "CL-VAT-NO",     
                            fieldName: "clientVatNo",
                            fieldDisplayName: "Client VAT No",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",      
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,                     
                            enabledFor: { "NO-VAT": {
                                filterValues: [true]
                            } },
                            parentFields: []
                        },
                        // {
                        //     fieldCode: "TOS", 
                        //     fieldName: "typeOfService",
                        //     fieldDisplayName: "Type of Service",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.DROPDOWN_FIELD_NAME,
                        //     inputType: Constants.DROPDOWN_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: null,
                        //     fieldPlaceholder: 'Select a Type of Service',
                        //     fieldValuesFunction: 'getTypesOfService',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,   
                        //     required: true,
                        //     disabledFor: null,                 
                        //     enabledFor: { "BC": {
                        //         filterValues: GlobalConstants.billingCompaniesWithCnpj,
                        //     } },
                        //     parentFields: []
                        // },
                        {
                            fieldCode: "TOS", 
                            fieldName: "typeOfService",
                            fieldDisplayName: "Type of Service",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a Type of Service',
                            fieldValuesFunction: 'getTypesOfService',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor:null,
                            // fieldValues: [],
                            // enabledFor: { "BC": {
                            //     filterValues: GlobalConstants.billingCompaniesWithCnpj,
                            // } } ,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        // {
                        //     fieldCode: "PPPT",   
                        //     fieldName: "promptPaymentPeriodType",
                        //     fieldDisplayName: "",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.DROPDOWN_FIELD_NAME,
                        //     inputType: Constants.DROPDOWN_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: "days",
                        //     fieldPlaceholder: 'Select a Period Type',
                        //     fieldValuesFunction: 'getPromptPaymentPeriodTypes',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: true,
                        //     disabledFor: null,               
                        //     enabledFor: ConstantFormStates.enabledAlways,
                        //     parentFields: ['CL']
                        // },
                        // {
                        //     fieldCode: "PPP",   
                        //     fieldName: "promptPaymentPeriod",
                        //     fieldDisplayName: "Prompt Payment Period",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.NUMBER_FIELD_NAME,
                        //     numberType: Constants.WHOLE_NO_FIELD_NAME,
                        //     fieldDefaultValue: null,   
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: true,
                        //     disabledFor: null,              
                        //     enabledFor: ConstantFormStates.enabledAlways,
                        //     parentFields: []
                        // },
                        {
                            fieldCode: "IN-CONT",   
                            fieldName: "invoiceContact",
                            fieldDisplayName: "Invoice Contact",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true, 
                            required: true,
                        //     disabledFor:{  
                        //         "SEL-CONT" : {
                        //           filterValues: ['Select from existing contacts']
                        //       }
                        //    },
                        //     enabledFor: { 
                        //             "ADD-NEW" : {
                        //                 filterValues: ['Add Contact']
                        //             },
                        //     },
                        //     parentFields: [],
                            disabledFor: null,               
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "IN-CONT-ADD",   
                            fieldName: "invoiceContactAddress",
                            fieldDisplayName: "Invoice Contact Address",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: true,
                            required: true,
                        //     disabledFor:{  
                        //         "SEL-CONT" : {
                        //           filterValues: ['Select from existing contacts']
                        //       }
                        //    },
                        //     enabledFor: { 
                        //             "ADD-NEW" : {
                        //                 filterValues: ['Add Contact']
                        //             },
                        //      },
                        //     parentFields: [],
                            disabledFor: null,               
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        // {           
                        //     fieldCode: "IN-CONT",   
                        //     fieldName: "invoiceContact",
                        //     fieldDisplayName: "Invoice Contact",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.INPUT_FIELD_NAME,
                        //     inputType: Constants.TEXT_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: "",  
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false, 
                        //     required: true,
                        //     disabledFor:{  
                        //         "SEL-CONT" : {
                        //           filterValues: ['Select from existing contacts']
                        //       }
                        //     },
                        //     enabledFor: { 
                        //             "ADD-NEW" : {
                        //                 filterValues: ['Add Contact']
                        //             },
                        //     },
                        //     parentFields: [],
                        // },
                        // {
                        //     fieldCode: "IN-CONT-ADD",  
                        //     fieldName: "invoiceContactAddress",
                        //     fieldDisplayName: "Invoice Contact Address",  
                        //     fillFieldNameFunction: '',
                        //     fillFieldNameFunctionParams: [],
                        //     fieldType: Constants.TEXTAREA_FIELD_NAME,
                        //     inputType: Constants.TEXTAREA_FIELD_NAME,
                        //     numberType: '',
                        //     fieldDefaultValue: "",  
                        //     fieldPlaceholder: '',
                        //     fieldValuesFunction: '',
                        //     fieldValuesFunctionParams: [],
                        //     functionToFillOtherFields: '',
                        //     fillOtherFieldsFunctionParams: [],
                        //     isEditable: false,
                        //     required: true,
                        //     disabledFor:{  
                        //         "SEL-CONT" : {
                        //           filterValues: ['Select from existing contacts']
                        //       }
                        //     },
                        //     enabledFor: { 
                        //             "ADD-NEW" : {
                        //                 filterValues: ['Add Contact']
                        //             },
                        //     },
                        //     parentFields: [],
                        // },
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsAddModeInvoiceDetails',
            selectionFields: {},
            gridAreaForm: [
                '"invoiceDetails" "buttons"'
            ],
            
            buttons: [{ buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'updateProjectInvoice', isValidationRequired: true, buttonType: Constants.INVOICE_DETAILS_ADD_MODE }, 
                { buttonName: Constants.CLEAR_BUTTON_NAME, buttonClickHandler: 'clearProjectDetails', isValidationRequired: false, buttonType: Constants.INVOICE_DETAILS_ADD_MODE}],   // add this prop for the below forms too
        }
    }

    public static _projectDetailsAddModeProjectTasks = {
        projectTasks : {
            sectionDisplayName: "Project Tasks",
            formTemplateData : [
                {
                    templateArea : [
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF PR-TASK-DESC PR-TASK-MNG FCV" "FCS FCE ACT IS-DEF"',
                        '"TASK-REF" "PR-TASK-DESC" "FCS" "FCE" "FCV" "PR-TASK-MNG" "ACT" "IS-DEF"',
                    ],
                    fieldDetails : [
                        {
                            fieldCode: "TASK-REF",
                            fieldName: "taskRef",
                            fieldDisplayName: "Task Ref",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "PR-TASK-DESC",
                            fieldName: "projectTaskDescription",
                            fieldDisplayName: "Description",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCS",
                            fieldName: "fixedCostStart",
                            fieldDisplayName: "Fixed Cost Start",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: '',
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCE",
                            fieldName: "fixedCostEnd",
                            fieldDisplayName: "Fixed Cost End",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: false,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: [],
                        },
                        {
                            fieldCode: "FCV",
                            fieldName: "fixedCostValue",
                            fieldDisplayName: "Fixed Cost Value",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PR-TASK-MNG",
                            fieldName: "projectTaskManager",
                            fieldDisplayName: "Manager",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null,
                            fieldPlaceholder: 'Select a manager',
                            fieldValuesFunction: 'getEmployees',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "ACT",
                            fieldName: "active",
                            fieldDisplayName: "Active",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "IS-DEF",
                            fieldName: "isDefault",
                            fieldDisplayName: "Is Default",
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.CHECKBOX_FIELD_NAME,
                            inputType: Constants.CHECKBOX_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: false,
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        }
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsAddModeProjectTasks',
            selectionFields: {},
            gridAreaForm: [
                '"projectTasks"  "buttons"',
                '"projectTasks"',
                '"projectTasks"',
                '"projectTasks"',
            ],
            buttons: [{ buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addProjectTask', isValidationRequired: true, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'add' }, 
            { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateProjectTask', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'add' },
            { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteProjectTask', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'add' },
            { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.PROJECT_TASKS_FORM, buttonPage: 'add' }], 
        }
    }

    public static _projectDetailsAddModeThemeAllocation = {
        themeAllocation: {
            sectionDisplayName: "Theme Allocation",
            formTemplateData: [
                {
                    templateArea: [
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                        '"THM ALC"',
                    ],
                    fieldDetails: [
                        {
                            fieldCode: "THM",
                            fieldName: "theme",
                            fieldDisplayName: "Theme",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DROPDOWN_FIELD_NAME,
                            inputType: Constants.DROPDOWN_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: null, 
                            fieldPlaceholder: 'Select a Theme',
                            fieldValuesFunction: 'getThemeList',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "ALC",   
                            fieldName: "allocation",
                            fieldDisplayName: "Allocation %",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "", 
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,  
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsAddModeThemeAllocation',
            selectionFields: {},
            gridAreaForm: [
                '"themeAllocation"  "buttons"',
                '"themeAllocation"',
                '"themeAllocation"',
                '"themeAllocation"',
            ],
            buttons: [{ buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addThemeAllocation', isValidationRequired: true, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add' }, 
                { buttonName: Constants.VIEW_BUTTON_NAME, buttonClickHandler: '', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add'},
            // { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updateThemeAllocation', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add' },
            // { buttonName: Constants.DELETE_BUTTON_NAME, buttonClickHandler: 'deleteThemeAllocation', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add' },
            { buttonName: Constants.NEXT_BUTTON_NAME, buttonClickHandler: 'nextProjectDetails', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add' },
            ]
        }
    }

    public static _projectDetailsAddModePrepaymentDetails = {
        prepaymentDetails: {
            sectionDisplayName: "Prepayment Details",
            formTemplateData: [
                {
                    templateArea: [
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD REF-NO PP-AMT" "PP-DESC PP-DESC PP-DT"',
                        '"PO-NO-PPD" "PP-AMT" "REF-NO" "PP-DT" "PP-DESC"',
                    ],
                    fieldDetails: [
                        {
                            fieldCode: "PO-NO-PPD",
                            fieldName: "prepaymentPurchaseOrderNo",
                            fieldDisplayName: "Purchase Order No",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,   
                            required: true,
                            disabledFor: ConstantFormStates.disabledAlways,
                            enabledFor: null,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-AMT",   
                            fieldName: "prepaymentAmt",
                            fieldDisplayName: "Prepayment Amount",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.NUMBER_FIELD_NAME,
                            numberType: Constants.NUMBER_FIELD_NAME,
                            fieldDefaultValue: "",   
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false,
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                            
                        },
                        {
                            fieldCode: "REF-NO",   
                            fieldName: "referenceNo",
                            fieldDisplayName: "Reference Number",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.INPUT_FIELD_NAME,
                            inputType: Constants.TEXT_FIELD_NAME,
                            numberType: Constants.TEXT_FIELD_NAME,
                            fieldDefaultValue: "",  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-DT",   
                            fieldName: "prepaymentDate",
                            fieldDisplayName: "Prepayment Date",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.DATE_FIELD_NAME,
                            inputType: Constants.DATE_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                        {
                            fieldCode: "PP-DESC",   
                            fieldName: "prepaymentDescription",
                            fieldDisplayName: "Description",  
                            fillFieldNameFunction: '',
                            fillFieldNameFunctionParams: [],
                            fieldType: Constants.TEXTAREA_FIELD_NAME,
                            inputType: Constants.TEXTAREA_FIELD_NAME,
                            numberType: '',
                            fieldDefaultValue: "",  
                            fieldPlaceholder: '',
                            fieldValuesFunction: '',
                            fieldValuesFunctionParams: [],
                            functionToFillOtherFields: '',
                            fillOtherFieldsFunctionParams: [],
                            isEditable: false, 
                            required: true,
                            disabledFor: null,
                            enabledFor: ConstantFormStates.enabledAlways,
                            parentFields: []
                        },
                    ]
                }
            ]
        },
        props : {
            formName: 'projectDetailsAddModePrepaymentDetails',
            selectionFields: {},
            gridAreaForm: [
                '"prepaymentDetails"  "buttons"',
                '"prepaymentDetails"',
                '"prepaymentDetails"',
                '"prepaymentDetails"',
            ],
            buttons: [
            { buttonName: Constants.SUBMIT_BUTTON_NAME, buttonClickHandler: 'addPrepaymentDetails', isValidationRequired: true, buttonType: Constants.PREPAYMENT_DETAILS_FORM, buttonPage: 'add' }, 
            // { buttonName: Constants.SAVE_BUTTON_NAME, buttonClickHandler: 'updatePrepaymentDetails', isValidationRequired: false, buttonType: Constants.PREPAYMENT_DETAILS_FORM, buttonPage: 'add' },
            { buttonName: Constants.VIEW_BUTTON_NAME, buttonClickHandler: '', isValidationRequired: false, buttonType: Constants.THEME_ALLOCATION_FORM, buttonPage: 'add'},
        ]
        }
    }

    public static getProjectBlueprintData(mode) {
        switch (mode) {
        case 'view':
            return {
            projectDetailsBlueprint: this._projectDetailsViewModeBlueprint,
            projectDetailsFieldDetails: [
                ...this._projectDetailsViewModeBlueprint.headerDetails
                .formTemplateData[0].fieldDetails,
                ...this._projectDetailsViewModeBlueprint.projectDetails
                .formTemplateData[0].fieldDetails,
                ...this._projectDetailsViewModeBlueprint.contactDetails
                .formTemplateData[0].fieldDetails,
                ...this._projectDetailsViewModeBlueprint.invoiceDetails
                .formTemplateData[0].fieldDetails,
            ],
            };
        
        //Darshan 23-04-2024 Edit Case Added
        case 'edit':

            return {

                projectDetailsBlueprint: this._projectDetailsEditModeBlueprint,
                themeAllocationBlueprint: this._projectDetailsEditModeThemeAllocation,
                prepaymentDetailsBlueprint: this._projectDetailsEditModePrepaymentDetails,
                consultantProjectRatesBlueprint: this._projectDetailsEditModeConsultantProjectRates,
                projectTasksBlueprint: this._projectDetailsEditModeProjectTasks,
                invoiceDetailsBlueprint: this._projectDetailsEditModeInvoiceDetails,
 

                projectDetailsFieldDetails: [
                    ...this._projectDetailsEditModeBlueprint.headerDetails
                    .formTemplateData[0].fieldDetails,
                    ...this._projectDetailsEditModeBlueprint.projectDetails
                    .formTemplateData[0].fieldDetails,
                    ...this._projectDetailsEditModeBlueprint.contactDetails
                    .formTemplateData[0].fieldDetails
                ],
                themeAllocationFieldDetails: [
                    ...this._projectDetailsEditModeThemeAllocation
                    .themeAllocation.formTemplateData[0].fieldDetails,
                ],
                prepaymentDetailsFieldDetails: [
                    ...this._projectDetailsEditModePrepaymentDetails.prepaymentDetails
                    .formTemplateData[0].fieldDetails,
                ],
                consultantProjectRatesFieldDetails: [
                    ...this._projectDetailsEditModeConsultantProjectRates
                    .consultantProjectRates.formTemplateData[0].fieldDetails,
                ],
                projectTasksFieldDetails: [
                    ...this._projectDetailsEditModeProjectTasks.projectTasks
                    .formTemplateData[0].fieldDetails,
                ],
                invoiceDetails: [
                    ...this._projectDetailsEditModeInvoiceDetails
                    .invoiceDetails.formTemplateData[0].fieldDetails,
                ],

            };

        case 'add':
            return {
                projectDetailsBlueprint:
                    this._projectDetailsAddModePrimaryFormBlueprint,
                themeAllocationBlueprint:
                    this._projectDetailsAddModeThemeAllocation,
                prepaymentDetailsBlueprint:
                    this._projectDetailsAddModePrepaymentDetails,
                consultantProjectRatesBlueprint:
                    this._projectDetailsAddModeConsultantProjectRates,
                invoiceDetailsBlueprint: this._projectDetailsAddModeInvoiceDetails,
                projectTasksBlueprint: this._projectDetailsAddModeProjectTasks,

                projectDetailsFieldDetails: [
                    ...this._projectDetailsAddModePrimaryFormBlueprint.projectDetails
                    .formTemplateData[0].fieldDetails,
                    ...this._projectDetailsAddModePrimaryFormBlueprint.contactDetails
                    .formTemplateData[0].fieldDetails
                ],

                themeAllocationFieldDetails: [
                    ...this._projectDetailsAddModeThemeAllocation
                    .themeAllocation.formTemplateData[0].fieldDetails,
                ],
                prepaymentDetailsFieldDetails: [
                    ...this._projectDetailsAddModePrepaymentDetails.prepaymentDetails
                    .formTemplateData[0].fieldDetails,
                ],
                consultantProjectRatesFieldDetails: [
                    ...this._projectDetailsAddModeConsultantProjectRates
                    .consultantProjectRates.formTemplateData[0].fieldDetails,
                ],
                projectTasksFieldDetails: [
                    ...this._projectDetailsAddModeProjectTasks.projectTasks
                    .formTemplateData[0].fieldDetails,
                ],
                invoiceDetails: [
                    ...this._projectDetailsAddModeInvoiceDetails
                    .invoiceDetails.formTemplateData[0].fieldDetails,
                ],
            };
        }
    }
}
