import { Component } from '@angular/core';
import { Constants } from 'app/core/mips/common/utilities/constants.config';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  homeRoute = URLPaths.ROUTE_LANDING_PAGE;
  bigMessage = Constants.PAGES_404_BIG_TEXT;
  smallMessage = Constants.PAGES_404_SMALL_TEXT;
}
