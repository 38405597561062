import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { ChartDataFactory, SeriesModel } from '../../../common/models/chart-data.model';
import { TimeEntryModel } from '../../../common/models/TimeEntry.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { getTimeDateRange } from 'app/core/store/timeDateRangeSelection/timeDateRangeSelection.selectors';

@Component({
  selector: 'app-plan-actual-comparision-dialog',
  templateUrl: './plan-actual-comparision-dialog.component.html',
  styleUrls: ['./plan-actual-comparision-dialog.component.scss']
})
export class PlanActualComparisionDialogComponent implements AfterViewInit {
  chartData: SeriesModel[];
  plannedAndActualsCombined: TimeEntryModel[] = null;
  dateRange: any;
  weekStart: any = '';
  weekEnd: any = '';
  relativewidth = '500px';
  legendposition: string;
  relativeView: number[];
  @ViewChild('chartArea')
  chartArea: ElementRef;
  nativeElement: any;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C']
  };

  constructor(public dialogRef: MatDialogRef<PlanActualComparisionDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public dialogData: any, private _store: Store) {
    this.dateRange = dialogData.splice(-1, 1);
    //Get the current Calender Week range selection
    this._store.select(getTimeDateRange).subscribe((data) => {
      if (data) {
        this.weekStart = data["startDate"];
        this.weekEnd = data["endDate"];
      }
    })
    this.plannedAndActualsCombined = dialogData;
  }

  ngOnInit(): void {
    this.plannedAndActualsCombined = this.dialogData;
    let count = 0;
    //Clear ChartDataFactory Data
    ChartDataFactory.initialize();
    for (const value of this.plannedAndActualsCombined)
      if (!(value.Client === 'AD' && (value.Project === 'TS' || value.Project === 'EX'))) {
        count++;
        ChartDataFactory.addOrUpdateTimeEntry(value);
      }
    this.chartData = ChartDataFactory.getChartDataArray();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    //Resize Chart size when window size is changes
    this.setViewSize(this.nativeElement);
  }

  ngAfterViewInit(): void {
    //Get bar chart DOM Reference
    this.nativeElement = this.chartArea.nativeElement;
    this.setViewSize(this.nativeElement);
  }

  setViewSize(element) {
    if (element) {
      //Set relative view of the chart
      this.relativeView = [Math.max(Math.floor(this.chartData.length / 0.5) * 75, element.offsetWidth), 400];
    }
  }
}
