import { Route } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { URLPaths } from './core/mips/common/utilities/urlPaths.config';
import { MipsRouteGuard } from './core/mips/common/utilities/mips.routeguard';

export const appRoutes: Route[] = [
    
    // Landing routes
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'mips',
        loadChildren: () => import('./core/mips/mips.module').then(m => m.MipsModule),
        canActivate: [MipsRouteGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: '',
        redirectTo: URLPaths.ROUTE_LANDING_PAGE,
        pathMatch: 'full'
    },
    {
        path: '**',
        pathMatch: 'full',
        component: PageNotFoundComponent
    }
];
