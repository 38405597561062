import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DateUtility } from '../utilities/date.utility';
import { ConsultantModel } from '../models/consultant.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { catchError, map } from 'rxjs/operators';
import { commonFunctions } from '../classes/common.class';
import { ErrorHandlerService } from './error-handler.service';
import { FlagEntryModel } from '../models/flag-entry.model';
import { TimeEntryService } from './time-entry.service';
import { SnackbarService } from './snackbar.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { clearFlagReminderMsg, setFlagReminderMsg } from 'app/core/store/flagReminderMsg/flagReminderMsg.actions';
import { getFlagReminderMsg } from 'app/core/store/flagReminderMsg/flagReminderMsg.selectors';
import { TimeEntryModel } from '../models/TimeEntry.model';

@Injectable({
    providedIn: 'root'
})

export class FlagEntryService {
    weeks = [];
    months = []
    currentUser: ConsultantModel;
    periodMonthFlagMap = new Map<string, object>();
    periodWeekFlagMap = new Map<string, object>();
    planVsActualComparisonMsg: string | null;
    private _announceMonthFlagHit = new Subject<any>();
    announceMonthFlagHit$ = this._announceMonthFlagHit.asObservable();
    private _isWeekMode = new BehaviorSubject<boolean>(true);
    private _isWeekMode$ = this._isWeekMode.asObservable();
    private _summaryDateRange = new BehaviorSubject<any>({
        weekStartDate: DateUtility.getCurrentWeekDates()['startDate'],
        weekEndDate: DateUtility.getCurrentWeekDates()['endDate'],
        monthStartDate: DateUtility.formatDatefromDate(new Date((DateUtility.createDateType()).getFullYear(), (DateUtility.createDateType()).getMonth(), 1)),
        monthEndDate: DateUtility.formatDatefromDate(new Date((DateUtility.createDateType()).getFullYear(), (DateUtility.createDateType()).getMonth() + 1, 0))
    });
    private _summaryDateRange$ = this._summaryDateRange.asObservable();
    flagSummaryData: BehaviorSubject<TimeEntryModel[]> = new BehaviorSubject<TimeEntryModel[]>([]);

    constructor(
        private _http: HttpClient,
        private _errorHandlerService: ErrorHandlerService,
        private _timeEntryService: TimeEntryService,
        private _snackBarService: SnackbarService,
        private _store: Store
    ) {
        this._store.select(getCurrentUser).subscribe((data) => {
            this.currentUser = data;
        })
    }

    //Emit When Month Flag is Hit
    monthFlagHitAnnounce(): void {
        this._announceMonthFlagHit.next(true);
    }

    //Return whether the Flag page summary is in week mode or not
    getIsWeekMode(): Observable<any> {
        return this._isWeekMode$;
    }

    //Set the Flag page summary in week mode
    setIsWeekMode(): boolean {
        this._isWeekMode.next(!this._isWeekMode.value);
        return this._isWeekMode.value;
    }

    //Return the Summary Date Range
    getSummaryDateRange(): Observable<any> {
        return this._summaryDateRange$;
    }

    //Set the Summary Date Range
    setSummaryDateRange(dateRange) {
        this._summaryDateRange.next(dateRange);
    }

    //Return Flag Entry Data
    getFlagEntry(): Observable<any> {
        this.periodMonthFlagMap.clear();
        this.periodWeekFlagMap.clear();
        const period = this.checkFlagDeadline();
        let params = new HttpParams();
        let formattedPeriod = '';
        if (period !== '') {
            formattedPeriod = period.split(',').map(value => {
                return ('\'' + value + '\'');
            }).join(',');
        } else {
            formattedPeriod = '';
        }
        params = params.append('period', formattedPeriod);
        params = params.append('loginname', this.currentUser.LoginName);
        return this._http.get<any>(URLPaths.URL_FLAG_ENTRY, { headers: ApiheaderService.getHeaders(), params: params }).pipe(
            map(res => {
                // Setting values for week and month hashmaps based on time entries
                this.buildFlagHashmaps(res);
                return {
                    periodWeekFlagMap: this.periodWeekFlagMap,
                    periodMonthFlagMap: this.periodMonthFlagMap
                };

            }),
            catchError(this._errorHandlerService.handleError.bind(this))
        );
    }

    //Check the flag deadline
    checkFlagDeadline(): any {
        this._store.dispatch(clearFlagReminderMsg());
        const startMonthNumber = DateUtility.createDateType().getMonth() - 12;
        const monthOption = DateUtility.createDateType();
        monthOption.setMonth(startMonthNumber, 1);

        let searchFlagPeriod = '';
        let currentUserDateofJoining = DateUtility.createDateTypefromDate(this.currentUser.DateOfJoining.substr(0, 10));
        currentUserDateofJoining.setDate(1);


        for (let count = 1; count <= 25; count++) {
            if (currentUserDateofJoining <= monthOption) {
                this.periodMonthFlagMap.set(monthOption.getFullYear() + '|' + (monthOption.getMonth() + 1), { isTSFlagPresent: false, isEXFlagPresent: false, notifications: [] });
                searchFlagPeriod = searchFlagPeriod + ',' + monthOption.getFullYear() + '|' + (monthOption.getMonth() + 1);
            }
            monthOption.setMonth(monthOption.getMonth() + 1);

        }


        // Set default values for week hashMap
        currentUserDateofJoining = DateUtility.createDateTypefromDate(this.currentUser.DateOfJoining.substr(0, 10));

        const startWeekNumber = DateUtility.createDateType().getDate() - DateUtility.createDateType().getDay() + 5 - 357;
        const weekOption = DateUtility.createDateType();
        weekOption.setDate(startWeekNumber);

        for (let count = 1; count <= 105; count++) {
            if (currentUserDateofJoining <= weekOption) {
                this.periodWeekFlagMap.set(DateUtility.formatStringfromDate(weekOption).substr(0, 10), { isTSFlagPresent: false, isEXFlagPresent: false, notifications: [] });
            }
            weekOption.setDate(weekOption.getDate() + 7);
        }

        return searchFlagPeriod;
    }

    buildFlagHashmaps(flagDetails): void {

        let isCurrMonthFlagPresent = true;
        let month_deadline_date: any = null;
        let week_deadline_date: any = null;
        let monthNotificationMsg = '';

        for (const timeCompleteEntry of flagDetails) {
            if (timeCompleteEntry['IsWeekFlag'] === '0' && !commonFunctions.isNullOrUndefined(this.periodMonthFlagMap.get(timeCompleteEntry['YearMonth']))) {
                if (timeCompleteEntry['Project'] === 'TS') {
                    this.periodMonthFlagMap.get(timeCompleteEntry['YearMonth'])['isTSFlagPresent'] = true;
                } else {
                    this.periodMonthFlagMap.get(timeCompleteEntry['YearMonth'])['isEXFlagPresent'] = true;
                }
            }

            if (!commonFunctions.isNullOrUndefined(this.periodWeekFlagMap.get(timeCompleteEntry['PeriodDate'].substr(0, 10)))) {
                if (timeCompleteEntry['Project'] === 'TS') {
                    this.periodWeekFlagMap.get(timeCompleteEntry['PeriodDate'].substr(0, 10))['isTSFlagPresent'] = true;
                } else {
                    this.periodWeekFlagMap.get(timeCompleteEntry['PeriodDate'].substr(0, 10))['isEXFlagPresent'] = true;

                }
            }
        }
        this.periodWeekFlagMap.forEach((value: object, key: string) => {
            let notificationMsg = '';
            if ((DateUtility.createDateTypefromDate(key) <= DateUtility.createDateType()) && (!value['isTSFlagPresent'] || !value['isEXFlagPresent'])) {
                if (!commonFunctions.isNullOrUndefined(week_deadline_date)) {
                    week_deadline_date = week_deadline_date + ', ' + DateUtility.formatDatefromString(key);
                } else {
                    week_deadline_date = DateUtility.formatDatefromString(key);
                }
                if (!value['isTSFlagPresent'] && !value['isEXFlagPresent']) {
                    notificationMsg = 'Hit your time and expense complete flag for week ' + DateUtility.formatDatefromString(key);
                } else if (!value['isTSFlagPresent'] && value['isEXFlagPresent']) {
                    notificationMsg = 'Hit your time complete flag for week ' + DateUtility.formatDatefromString(key);
                } else if (value['isTSFlagPresent'] && !value['isEXFlagPresent']) {
                    notificationMsg = 'Hit your expense complete flag for week ' + DateUtility.formatDatefromString(key);
                }

                if (!commonFunctions.isNullOrUndefined(this.periodWeekFlagMap.get(key))) {
                    this.periodWeekFlagMap.get(key)['notifications'].push(notificationMsg);
                }

            }
        });
        if (!commonFunctions.isNullOrUndefined(week_deadline_date)) {
            let msg = 'Hit your week flag(s) for ' + week_deadline_date + ';  ';
            this._store.dispatch(setFlagReminderMsg({ msg }));
        }


        this.periodMonthFlagMap.forEach((value: object, key: string) => {
            if ((Number(key.split('|')[0]) * 100 + Number(key.split('|')[1])) < (DateUtility.createDateType().getFullYear() * 100 + (DateUtility.createDateType().getMonth() + 1))
                && (!value['isTSFlagPresent'] || !value['isEXFlagPresent'])) {

                if (!commonFunctions.isNullOrUndefined(month_deadline_date)) {
                    month_deadline_date = month_deadline_date + ', ' + DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full');
                } else {
                    month_deadline_date = DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full');
                }
                if (!value['isTSFlagPresent'] && !value['isEXFlagPresent']) {

                    monthNotificationMsg = 'Hit your time and expense complete flag for ' +
                        DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                } else if (!value['isTSFlagPresent'] && value['isEXFlagPresent']) {
                    monthNotificationMsg = 'Hit your time complete flag for ' + DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                } else if (value['isTSFlagPresent'] && !value['isEXFlagPresent']) {
                    monthNotificationMsg = 'Hit your expense complete flag for ' + DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                }
                if (!commonFunctions.isNullOrUndefined(this.periodMonthFlagMap.get(key))) {
                    this.periodMonthFlagMap.get(key)['notifications'].push(monthNotificationMsg);
                }


            }
            if ((Number(key.split('|')[0]) * 100 + Number(key.split('|')[1]) ===
                (DateUtility.createDateType().getFullYear() * 100 + (DateUtility.createDateType().getMonth() + 1))) &&
                (!value['isTSFlagPresent'] || !value['isEXFlagPresent'])) {
                isCurrMonthFlagPresent = false;

            }

        });
        if (!commonFunctions.isNullOrUndefined(month_deadline_date)) {
            let msg: string;
            this._store.select(getFlagReminderMsg).subscribe((data) => {
                msg = data;
            });
            msg = msg + ' month flag(s) for ' + month_deadline_date + ', ';
            this._store.dispatch(setFlagReminderMsg({ msg }));
        }

        this.getMonthFlagDeadlineForConsultant(this.currentUser.Employee,
            DateUtility.getMonthName(DateUtility.createDateType().getMonth(), 'full'), DateUtility.createDateType().getFullYear()).subscribe(response => {
                if (response.length > 0) {
                    month_deadline_date = DateUtility.createDateTypefromDate(response[0]['Deadline_Date']);
                    for (const row of response) {
                        const rowDate = DateUtility.createDateTypefromDate(row['Deadline_Date']);
                        if (rowDate < month_deadline_date) {
                            month_deadline_date = rowDate;
                        }
                    }
                    if (!isCurrMonthFlagPresent && (DateUtility.createDateType() >= month_deadline_date)) {
                        const key = DateUtility.createDateType().getFullYear() + '|' + (DateUtility.createDateType().getMonth() + 1);
                        const searchFlaginCurrentMonth = this.periodMonthFlagMap.get(key);
                        if (!searchFlaginCurrentMonth['isTSFlagPresent'] && !searchFlaginCurrentMonth['isEXFlagPresent']) {
                            monthNotificationMsg = 'Hit your time and expense complete flag for ' +
                                DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                        } else if (!searchFlaginCurrentMonth['isTSFlagPresent'] && searchFlaginCurrentMonth['isEXFlagPresent']) {
                            monthNotificationMsg = 'Hit your time complete flag for ' +
                                DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                        } else if (searchFlaginCurrentMonth['isTSFlagPresent'] && !searchFlaginCurrentMonth['isEXFlagPresent']) {
                            monthNotificationMsg = 'Hit your expense complete flag for ' +
                                DateUtility.getMonthName(Number(key.split('|')[1]) - 1, 'full') + ' ' + key.split('|')[0];
                        }

                        this.periodMonthFlagMap.get(DateUtility.createDateType().getFullYear() + '|' +
                            (DateUtility.createDateType().getMonth() + 1))['notifications'].push(monthNotificationMsg);
                        let msg: string;
                        // this._store.select(getFlagReminderMsg).subscribe((data) => {
                        //     msg = data;
                        // });
                        msg = 'Hit your month flag before ' + DateUtility.formatDatefromDate(month_deadline_date);
                        this._store.dispatch(setFlagReminderMsg({ msg }));
                    }
                }

            });
    }

    //Return the Month Flag Deadline for a consultant
    getMonthFlagDeadlineForConsultant(employee, month, year): Observable<any> {
        let params = new HttpParams();
        params = params.append('employee', employee);
        params = params.append('month', month);
        params = params.append('year', year);
        return this._http.get<any>(URLPaths.URL_MONTH_FLAG_DEADLINE, { headers: ApiheaderService.getHeaders(), params: params }).pipe(
            catchError(this._errorHandlerService.handleError.bind(this)));
    }

    //Return the Weeks in a year
    getWeeksInYear() {
        let weekStart: any;
        let weekEnd: any;
        let formattedStart: any;
        let formattedEnd: any;
        const today = DateUtility.createDateType();
        const firstDate = today.getDate() - today.getDay() + 1 - 357;
        weekStart = DateUtility.createDateType();
        weekStart.setDate(firstDate);
        weekEnd = DateUtility.createDateType();
        weekEnd.setDate(firstDate + 4);
        const currentUserDateofJoining = DateUtility.createDateTypefromDate(this.currentUser.DateOfJoining.substr(0, 10));
        currentUserDateofJoining.setDate(currentUserDateofJoining.getDate() - currentUserDateofJoining.getDay() + 5);
        for (let count = 1; count <= 105; count++) {
            if (currentUserDateofJoining <= weekEnd) {
                formattedStart = DateUtility.formatDatefromDate(weekStart);
                formattedEnd = DateUtility.formatDatefromDate(weekEnd);
                this.weeks.push({
                    dateRange: formattedStart + ' - ' + formattedEnd, start: formattedStart, end: formattedEnd,
                    weekstart: DateUtility.formatStringfromDate(weekStart).substring(0, 10), weekend: DateUtility.formatStringfromDate(weekEnd).substring(0, 10)
                });
            }
            weekStart.setDate(weekStart.getDate() + 7);
            weekEnd.setDate(weekEnd.getDate() + 7);
        }
        return this.weeks;
    }

    //Return the Selected Week
    getSelectedWeek(weekStartDate) {
        const selectedWeekIndex = this.weeks.findIndex(i => i.start === weekStartDate);
        return this.weeks[selectedWeekIndex];
    }

    //Return the number of months in a year
    getMonthsInYear() {
        let monthOption: any;
        let formattedMonth: any;
        const thisMonthNumber = (DateUtility.createDateType()).getMonth() - 12;
        monthOption = DateUtility.createDateType();
        monthOption.setMonth(thisMonthNumber, 1);
        let monthLastDate = new Date(monthOption.getFullYear(), monthOption.getMonth() + 1, 0);
        let monthStartDate = new Date(monthOption.getFullYear(), monthOption.getMonth(), 1);
        const currentUserDateofJoining = DateUtility.createDateTypefromDate(this.currentUser.DateOfJoining.substr(0, 10));
        currentUserDateofJoining.setDate(1);
        for (let count = 1; count <= 25; count++) {
            if (currentUserDateofJoining <= monthOption) {
                formattedMonth = DateUtility.getMonthName(monthOption.getMonth(), 'short') + ' ' + monthOption.getFullYear();
                this.months.push({
                    monthRange: formattedMonth, monthStart: DateUtility.formatDatefromDate(monthStartDate),
                    monthEnd: DateUtility.formatDatefromDate(monthLastDate),
                    monthFormatted: monthOption.getFullYear() + '|' + (monthOption.getMonth() + 1)
                });
            }
            monthOption.setMonth(monthOption.getMonth() + 1);
            monthLastDate = new Date(monthOption.getFullYear(), monthOption.getMonth() + 1, 0);
            monthStartDate = new Date(monthOption.getFullYear(), monthOption.getMonth(), 1);
        }
        return this.months;
    }

    //Return the Selected Month
    getSelectedMonth(monthName, day) {
        const selectedMonth = this.months.filter(value => value.monthRange === (monthName + ' ' + day.getFullYear()))[0];
        return selectedMonth;
    }

    //Save the Flag Hit Entry
    saveFlagEntry(flagEntryData: FlagEntryModel): Observable<any> {
        return this._http.post(URLPaths.URL_FLAG_ENTRY, { 'flagEntry': [flagEntryData] }, { headers: ApiheaderService.getHeaders() }).pipe(
            // catchError(this._errorHandlerService.handleError.bind(this))
        )
    }

    //Return Flag Time Entries
    getFlagTimeEntries(getTimeStartDate, getTimeEndDate, Employee) {
        let plannedHours = 0;
        let actualHours = 0;
        this._timeEntryService.getTimeEntries(getTimeStartDate, getTimeEndDate, Employee).subscribe(values => {
            this.flagSummaryData.next(values);
            this._snackBarService.dismissSnackBar();
            for (const entry of values) {
                if (entry.Version === 'ACTUAL') {
                    actualHours = actualHours + parseFloat(entry.Hours.toString());
                } else {
                    plannedHours = plannedHours + parseFloat(entry.Hours.toString());
                }
            }
            if (actualHours < plannedHours) {
                this.planVsActualComparisonMsg = 'Your actuals is less than the plan! :( ';
                // this._store.dispatch(setPlanVsActualComparisonMsg({ msg: 'Your actuals is less than the plan! :( ' }));
            } else if (actualHours > plannedHours) {
                this.planVsActualComparisonMsg = 'Your actuals is greater than the plan! :D ';
                // this._store.dispatch(setPlanVsActualComparisonMsg({ msg: 'Your actuals is greater than the plan! :D ' }));
            }
        });
    }

}
