import {TimeEntryModel} from './TimeEntry.model';

export class SeriesChildModel {
    public name: string;
    public value: number;

}

export class SeriesModel {
    public name: string;
    public series: SeriesChildModel[];


    constructor() {
        this.name = '';
        this.series = [{name: 'ACTUAL', value: 0}, {name: 'PLAN', value: 0}];
    }

}

export class ChartDataFactory {
    private static data: Map<string, SeriesModel> = new Map<string, SeriesModel>();

    public static addOrUpdateTimeEntry(timeEntry: TimeEntryModel) {

        let index: number;
        const key = ChartDataFactory.getKey(timeEntry.Client, timeEntry.Project);
        if (timeEntry.Version === 'ACTUAL') {
            index = 0;
        } else {
            index = 1;
        }

        if (ChartDataFactory.data && ChartDataFactory.data.has(key)) {
            const currSeries = ChartDataFactory.data.get(key);
            const hours = Number(Number(currSeries.series[index].value).toFixed(2)) + Number(Number(timeEntry.Hours).toFixed(2));
            currSeries.series[index].value = hours;
            ChartDataFactory.data.set(key, currSeries);
        } else {
            const series = new SeriesModel();
            series.name = timeEntry.Client.concat(' - ' , timeEntry.Project);
            series.series[index].value = Number(Number(timeEntry.Hours).toFixed(2));
            ChartDataFactory.data.set(key, series);

        }
    }

    public static getChartDataArray(): SeriesModel[] {
        return Array.from(ChartDataFactory.data.values());
    }

    static initialize() {
        ChartDataFactory.data.clear();
    }

    private static getKey(client: string, project: string): string {
        return client + '-' + project;
    }
}
