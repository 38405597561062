<div class="flex flex-col gap-2.5 h-full">
  
  <!-- Time Dicer header -->
  <div class="hidden tb:block">
    <app-time-dicer-header></app-time-dicer-header>
  </div>

  <!-- Time Dicer AG Grid -->
  <mat-card class="h-full">
    <mat-card-content class="flex flex-row h-full p-0">
      <ag-grid-angular
        style="max-width: 2256px; width: 100%;"
        class="ag-theme-balham"
        (gridReady)="onGridReady($event)"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        [pivotMode]="true"
        [sideBar]="true"
        [animateRows]="true"
        [defaultColDef]="defaultColDef"
      >
      </ag-grid-angular>
    </mat-card-content>
  </mat-card>
</div>