import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ProjectModel } from '../models/project.model';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private employeeProjMap: Map<string, ProjectModel[]> = new Map<string, ProjectModel[]>();
  private projects: ProjectModel[] = null;
  private sortedProjectList: any = null;

  constructor(
    private _http: HttpClient,
    private _errorHandlerService: ErrorHandlerService
  ) { }

  //Get all the projects
  getProjects(forceReload: boolean = false, employee: string, startDate: string, endDate: string): Observable<ProjectModel[]> {
    let params = new HttpParams();
    params = params.append('employee', employee);
    params = params.append('startdate', startDate);
    params = params.append('enddate', endDate);
    if (forceReload || !this.employeeProjMap.has(employee)) {
        return this._http.get(URLPaths.URL_PROJECT, {headers: ApiheaderService.getHeaders(), params: params})
            .pipe(
                map(res => {
                    this.projects = res as ProjectModel[];
                    this.sortedProjectList = this.projects.sort((a, b) => {
                        if (Number(a.Frequency) > Number(b.Frequency)) {
                            return -1;
                        } else if (Number(a.Frequency) < Number(b.Frequency)) {
                            return 1;
                        }
                        else {
                            if (a.Charge.toLowerCase() === 'yes' && b.Charge.toLowerCase() === 'no') {
                                return -1;
                            } else if (a.Charge.toLowerCase() === 'no' && b.Charge.toLowerCase() === 'yes') {
                                return 1;
                            } else {
                                if (a.Description.toLowerCase() < b.Description.toLowerCase()) {
                                    return -1;
                                } else if (a.Description.toLowerCase() > b.Description.toLowerCase()) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    });
                    for (const proj of this.sortedProjectList) {
                        proj.Display_String = proj.Description + ' (' + proj.Project + ') - ' + proj.ClientName + ' (' + proj.Client + ')';
                        if (Number(proj.IsPlannedorFrequent) === 1) {
                            proj.IsPlannedorFrequent = 'Planned/ Frequently Used Projects';
                            continue;
                        }
                        proj.IsPlannedorFrequent = 'Other Projects';
                    }
                    this.employeeProjMap.set(employee, this.sortedProjectList as ProjectModel[]);
                    return this.employeeProjMap.get(employee);
                    // return this.sortedProjectList;
                }),
                catchError(this._errorHandlerService.handleError.bind(this))
            );
    } else {
        return of(this.employeeProjMap.get(employee));
    }
  }
}
