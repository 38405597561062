import { DateRangeModel } from 'app/core/mips/common/models/date-range.model';
import { DateUtility } from '../../mips/common/utilities/date.utility';


export interface DateRange {
    timeDicerDateRangeSelection: DateRangeModel | null;
  }
  
  // const a = {
  //   startDate: ;
  //   endDate: ;
  // };


  export const initialTimeMonthState: DateRange = {
    timeDicerDateRangeSelection: DateUtility.getMonthStartEndDate(),
   };