import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { URLSanitizerPipe } from './urlsanitizer.pipe';

@NgModule({
    declarations: [URLSanitizerPipe],
    imports: [
        CommonModule
    ],
    exports: [URLSanitizerPipe]
})
export class PipesModule {
}
