import { Component } from '@angular/core';
import { Constants } from '../../common/utilities/constants.config';

@Component({
  selector: 'app-read-only-mode',
  templateUrl: './read-only-mode.component.html',
  styleUrls: ['./read-only-mode.component.scss']
})
export class ReadOnlyModeComponent {
  readOnlyModeText = Constants.MSG_READ_ONLY_MODE;
}
