import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { getCurrentUser } from "app/core/store/currentUser/user.selectors";
import { ClientModel } from "../common/models/client-model";
import { URLPaths } from "../common/utilities/urlPaths.config";
import { ApiheaderService } from "../common/services/apiheader.service";
import { catchError, map, Observable, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SnackbarService } from "../common/services/snackbar.service";

@Injectable({
  providedIn: 'root'
})

export class AddClientService {

  loginName: string;
  employeeName: string;

  constructor(private _store: Store, private http: HttpClient, private _snackBarService: SnackbarService) {
    this._store.select(getCurrentUser).subscribe(data => {
      this.loginName = data.LoginName;
      this.employeeName = data.Employee;
    })
  }

  private clients = [
    { clientName: 'Client 1', clientCode: 'C001', internal: true, active: true },
    { clientName: 'Client 2', clientCode: 'C002', internal: false, active: false },
  ];

  getClients() {
    console.log(this.getClientData());
    return this.clients;
  }

  async saveClients(clients: any[]): Promise<void> {
    let requestbody = [];

    for (let index in clients) {
      const client: any = {
        clientCode: clients[index].Client,
        clientName: clients[index].ClientName,
        internal: clients[index].Internal,
        active: clients[index].Active,
      }
      requestbody.push(client);
    }
    console.log(requestbody)
    return this.http.put(URLPaths.URL_GET_CLENTS_FOR_ADD_CLIENT, { 'update_clients': requestbody }, { headers: ApiheaderService.getHeaders() })
      .pipe(
        tap((response: any) => {
          this.showSuccessOrFailureSnackbar(response);
        }),
        catchError((error) => {
          this.showSuccessOrFailureSnackbar(error.error);
          return of(error);
        })
      )
      .toPromise() // Convert Observable to Promise
      .then(() => console.log('Clients saved successfully'))
      .catch(error => console.error('Error saving clients:', error));
    // console.log(clients)
    // this.clients = clients;
    // console.log('Clients saved:', this.clients);
  }

  showSuccessOrFailureSnackbar(responseFromBackend) {
    if (responseFromBackend != undefined) {
      if (responseFromBackend['Type'] == "failure") {
        this._snackBarService.showSnackBar(
          responseFromBackend['Message'],
          'close',
          'failure'
        );
      } else {
        this._snackBarService.showSnackBar(
          responseFromBackend['Message'],
          '',
          'success'
        );
      }
    }
  }

  addClient(client: any): Observable<any> {
    client.active = true;
    this.clients.push(client)
    const clientDetails: ClientModel = {
      client: client.clientName,
      code: client.clientCode,
      isInternal: client.internal,
      loginName: this.loginName,
      active: true
    }

    const clientDetailsRequestObj = {
      clientName: clientDetails.client,
      clientCode: clientDetails.code,
      internal: clientDetails.isInternal,
      loginName: clientDetails.loginName,
      active: true
    }

    return this.http.post(URLPaths.URL_GET_CLENTS_FOR_ADD_CLIENT, { 'create_client': clientDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
      .pipe(
        tap((response: any) => {
          this.showSuccessOrFailureSnackbar(response);
        }),
        catchError((error) => {
          this.showSuccessOrFailureSnackbar(error.error);
          return of(error);
        })
      );
  }

  getClientData(): Observable<any> {
    return this.http.get(URLPaths.URL_GET_CLENTS_FOR_ADD_CLIENT, { headers: ApiheaderService.getHeaders() });
  }
}