export class TimeDicerConfigModel {
    id: number;
    NameOfConfig: string = '';
    User: number = null;
    ConfigDescription: string = '';
    ConsultantsSelected: string = null;
    ProjectsSelected: string = null;
    Configuration: string = '';
    IsPivotModeOn: boolean;
    IsDefaultConfig: boolean;
    status: string;
}
