import { SafeUrl } from "@angular/platform-browser";

class MipsUserProfileModel {
    passwordLastResetDateTime: string;
    employee: string;
}

export class UserModel {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_superuser: boolean;
    last_login: string;
    userProfile: MipsUserProfileModel;
    userProfilePicture? : SafeUrl;
}
