import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from './snackbar.service';
import { ErrorHandlerService } from './error-handler.service';
import { TimeDicerConfigModel } from '../models/time-dicer-config.model';
import { URLPaths } from '../utilities/urlPaths.config';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiheaderService } from './apiheader.service';
import { GridApi } from 'ag-grid-community';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { commonFunctions } from '../classes/common.class';
import { TimeDicerBookmarkConfigPanelComponent } from '../../actuals/time-dicer/time-dicer-bookmark-config-panel/time-dicer-bookmark-config-panel.component';
import { Constants } from '../utilities/constants.config';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantModel } from '../models/consultant.model';
import { UserModel } from '../models/user.model';
import { getLoggedInUser } from 'app/core/store/loggedInUser/loggedInUser.selectors';
import { values } from 'lodash';
import { TimeEntryService } from './time-entry.service';
import { getTimeDicerDateRange } from 'app/core/store/timeDicerDateRangeSelection/timeDicerDateRangeSelection.selector';


@Injectable({
  providedIn: 'root'
})
export class TimeDicerConfigService {
  agGridAPI: GridApi;
  weekStart: string = '';
  weekEnd: string = '';
  agGridColumnAPI: any;
  currentUser: ConsultantModel;
  loggedinUser: UserModel;
  private _manageConfigButtonClickedSource = new Subject<string>();
  private _loadConfigDetailsInOverlaySource = new Subject<string>();
  manageConfigButtonClicked$ = this._manageConfigButtonClickedSource.asObservable();
  loadConfigButtonClicked$ = this._loadConfigDetailsInOverlaySource.asObservable();
  timeDicerRowData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  currentConfig: TimeDicerConfigModel = new TimeDicerConfigModel();
  dialogRef: any;
  defaultConfig: TimeDicerConfigModel;
  loadConfigIdFromUrl: number;
  prevConfigId: number;
  timeDicerConfigList: TimeDicerConfigModel[] = [];
  clientProjectNames: any;
  consultantNames: any;
  rowData: any;
  selectedConsultants: any = null;
  selectedProjects: any = null;

  //Emit when Manage button is clicked
  announceManageButtonConfigClicked(selectedConsultants, selectedProjects): void {
    const selectedConsultantsProjects = {'selectedProjects': selectedProjects, 'selectedConsultants': selectedConsultants};
    this._manageConfigButtonClickedSource.next(JSON.stringify(selectedConsultantsProjects));
  }

  //Emit Time Dicer Config value when load is clicked
  announceLoadConfigButtonConfigClicked(configuration): void {
    this._loadConfigDetailsInOverlaySource.next(configuration);
  }

  constructor(
    private http: HttpClient,
    private _snackBarService: SnackbarService,
    private errorHandlerService: ErrorHandlerService,
    private _dialog: MatDialog,
    private _store: Store,
    private _router: Router,
    private _timeEntryService: TimeEntryService
  ) {

      // Retrieve current User Details from the Store
      this._store.select(getCurrentUser).subscribe((data) => {
        this.currentUser = data;
      });
      
      // Retrieve logged in User Details from the Store
      this._store.select(getLoggedInUser).subscribe((data) => {
        this.loggedinUser = data;
      });

      this._store.select(getTimeDicerDateRange).subscribe((data)=>{
        if(data){
          this.weekStart = data['startDate'];
          this.weekEnd = data['endDate']; 
        }
        
      })
      this.rowData = [];

      // Get selected consultants and projects
      this.loadConfigButtonClicked$.subscribe((res) => {
        this.selectedConsultants = [];
        this.selectedProjects = [];
        if (!commonFunctions.isNullOrUndefined(res['ConsultantsSelected'])) {
          this.selectedConsultants = JSON.parse(res['ConsultantsSelected']);
        }
        if (!commonFunctions.isNullOrUndefined(res['ProjectsSelected'])) {
          this.selectedProjects = JSON.parse(res['ProjectsSelected']);
        }
      });

  }

  //Set AG Grid API
  setGridApi(agGridAPI: GridApi) {
    this.agGridAPI = agGridAPI;
  }

  //Get AG Grid API
  getGridApi() {
    return this.agGridAPI;
  }

  //Set AG Grid Column API
  setGridColumnApi(agGridColumnAPI: any) {
    this.agGridColumnAPI = agGridColumnAPI;
  }

  //Get AG Grid Column API
  getGridColumnApi() {
    return this.agGridColumnAPI;
  }

  //Set Time Entry Actuals to store
  setTimeDicerRowData(rowData) {
    this.timeDicerRowData.next(rowData);
  }

  //Save the Time Dicer Config
  public saveTimeDicerConfig(timeDicerConfig: TimeDicerConfigModel): Observable<any> {
    return this.http.post(URLPaths.URL_TIME_DICER_CONFIG, timeDicerConfig, {headers: ApiheaderService.getHeaders()}).pipe(
        catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  //Update the Time Dicer Config
  public updateTimeDicerConfig(timeDicerConfig: TimeDicerConfigModel): Observable<any> {
    return this.http.put(URLPaths.URL_TIME_DICER_CONFIG + timeDicerConfig.id + '/', timeDicerConfig, {headers: ApiheaderService.getHeaders()}).pipe(
        catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  //Get the saved Time Dicer Config
  public getSavedTimeDicerConfig(): Observable<any> {
    return this.http.get(URLPaths.URL_TIME_DICER_CONFIG, {headers: ApiheaderService.getHeaders()}).pipe(
        catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  //Delete the Time Dicer Config
  public deleteSavedTimeDicerConfig(id = 0): Observable<any> {
    return this.http.delete(URLPaths.URL_TIME_DICER_CONFIG + id + '/', {headers: ApiheaderService.getHeaders()}).pipe(
        catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  // Bookmark Dialog Box Configuration,
  // Apply required configurations based on whether Load, Delete or Save Button is Clicked
  manageConfig(selectedProjects, selectedConsultants): void {
    const dialogConfig = new MatDialogConfig();

    if (!commonFunctions.isNullOrUndefined(this.currentConfig)) {
      dialogConfig.data = this.currentConfig;
    } else {
      dialogConfig.data = new TimeDicerConfigModel();
    }
    dialogConfig.disableClose = true;
    dialogConfig.maxWidth = 'clamp(340px, 92vw, 900px)';
    dialogConfig.width = '100%';
    dialogConfig.height = 'auto';
    this.dialogRef = this._dialog.open(
      TimeDicerBookmarkConfigPanelComponent,
      dialogConfig
    );
    this.dialogRef.afterClosed().subscribe((result) => {
      if (
        !commonFunctions.isNullOrUndefined(result) &&
        typeof result != typeof true
      ) {
        if (result.status === 'load') {
          this.loadConfigButtonClicked(result);
        } else if (result.status === 'delete') {
          this.deleteConfigButtonClicked(result);
        } else {
          this.saveConfigButtonClicked(result, selectedProjects, selectedConsultants);
        }
      }
    });
  }

  // Get time entries
  getTimeEntries(): void {
    this.agGridAPI = this.getGridApi();
    this.agGridAPI.setRowData(null);
    this._snackBarService.showSnackBar(
      Constants.SNACKBAR_MSG_WAITING_FOR_API_RESPONSE,
      null,
      'info'
    );
    this._timeEntryService
      .getTimeEntries(
        this.weekStart,
        this.weekEnd,
        this.consultantNames,
        this.clientProjectNames
      )
      .subscribe((values) => {
        this._snackBarService.dismissSnackBar();
        this.rowData = values;
        this.setTimeDicerRowData(this.rowData);
      });
  }

   // Apply the Time Dicer Configuration for selected Consultants and Projects
   applyChanges(selectedConsultants, selectedProjects): void {
    if (
      (commonFunctions.isNullOrUndefined(selectedConsultants) ||
        selectedConsultants.length === 0) &&
      (commonFunctions.isNullOrUndefined(selectedProjects) ||
        selectedProjects.length === 0)
    ) {
      this._snackBarService.showSnackBar(
        Constants.SNACKBAR_MSG_TIME_DICER_BOTH_EMPTY,
        'close',
        'failure'
      );
    } else {
      if (!commonFunctions.isNullOrUndefined(selectedProjects)) {
        this.clientProjectNames = selectedProjects
          .map((values) => {
            return "'" + values.Client + '|' + values.Project + "'";
          })
          .join(',');
      } else {
        this.clientProjectNames = '';
      }
      if (!commonFunctions.isNullOrUndefined(selectedConsultants)) {
        this.consultantNames = selectedConsultants
          .map((consultant) => consultant.Employee)
          .join(',');
      } else {
        this.consultantNames = '';
      }
    //   Get time entries for selected configuration
      this.getTimeEntries();
    }
  }

  // Load selected configuration
  loadConfigButtonClicked(result): void {
    this.setConfigurations(result);
    this.currentConfig = result;
    this.announceLoadConfigButtonConfigClicked(result);
    this.applyChanges(this.selectedConsultants, this.selectedProjects);
  }

  // Apply configurations on the Time Dicer AG Grid
  setConfigurations(config): void {
    this.agGridColumnAPI.resetColumnState();
    this.agGridColumnAPI.applyColumnState({
      state: JSON.parse(config.Configuration),
      applyOrder: true,
    });
    this.agGridColumnAPI.setPivotMode(config.IsPivotModeOn);
  }

  // Delete selected Configuration
  deleteConfigButtonClicked(result): void {
    this.deleteSavedTimeDicerConfig(result.id)
      .subscribe((res) => {
        this._snackBarService.showSnackBar(
          Constants.SNACKBAR_MSG_TIME_DICER_CONFIG_DELETED,
          'close',
          'success'
        );

        if (result.IsDefaultConfig) {
          this.defaultConfig = null;
        }
        if (result.id === this.currentConfig.id) {
          this.currentConfig = null;
          if (!commonFunctions.isNullOrUndefined(this.defaultConfig)) {
          } else {
            this._router.navigate([URLPaths.ROUTE_TIME_DICER_ACTUALS]);
          }
        }

        this.getTimeDicerConfig();
      });
  }

  //  Get Time Dicer Configuration
  getTimeDicerConfig(): void {
    this.getSavedTimeDicerConfig()
      .subscribe((values) => {
        for (const value of values) {
          // Changed from current user to logged in user
          if (value['User'] === this.loggedinUser.id && value.IsDefaultConfig) {
            this.defaultConfig = value;
          }

          if (value.id === this.loadConfigIdFromUrl) {
            this.prevConfigId = this.loadConfigIdFromUrl;
            this.currentConfig = value;
          }
        }
        this.timeDicerConfigList = values;
        if (commonFunctions.isNullOrUndefined(this.currentConfig)) {
          if (!commonFunctions.isNullOrUndefined(this.defaultConfig)) {
            this.currentConfig = this.defaultConfig;
          } else {
            this._router.navigate([URLPaths.ROUTE_TIME_DICER_ACTUALS]);
          }
        }
        if (!commonFunctions.isNullOrUndefined(this.currentConfig)) {
          this.announceLoadConfigButtonConfigClicked(
            this.currentConfig
          );
          this.setConfigurations(this.currentConfig);
        }
      });
  }

  // Save configuration as a Bookmark
  saveConfigButtonClicked(config, selectedProjects, selectedConsultants): void {
    config.Configuration = JSON.stringify(
      this.agGridColumnAPI.getColumnState()
    );
    config.IsPivotModeOn = this.agGridColumnAPI.isPivotMode();
    config.ConsultantsSelected = JSON.stringify(selectedConsultants);
    config.ProjectsSelected = JSON.stringify(selectedProjects);
    this._snackBarService.showSnackBar(
      Constants.SNACKBAR_MSG_SAVING_CHANGES,
      null,
      'info'
    );

    if (
      config.IsDefaultConfig &&
      !commonFunctions.isNullOrUndefined(this.defaultConfig) &&
      this.defaultConfig.id !== config.id
    ) {
      this.defaultConfig.IsDefaultConfig = false;
      this.updateTimeDicerConfig(this.defaultConfig)
        .subscribe((res) => {});
    }
    if (config.status === 'update') {
      this.updateTimeDicerConfig(config).subscribe(
        (res) => {
          if (res.IsDefaultConfig) {
            this.defaultConfig = res;
          }
          this._snackBarService.showSnackBar(
            Constants.SNACKBAR_MSG_TIME_DICER_CONFIG_UPDATED,
            '',
            'success'
          );
        },
        (error) => {
          this._snackBarService.showSnackBar(error, null, 'failure');
        }
      );
    } else {
      this.saveTimeDicerConfig(config)
        .subscribe((res) => {
          this.currentConfig = res;
          this.announceLoadConfigButtonConfigClicked(
            this.currentConfig
          );
          this._snackBarService.showSnackBar(
            Constants.SNACKBAR_MSG_TIME_DICER_CONFIG_SAVED,
            '',
            'success'
          );
        });
    }
  }

}
