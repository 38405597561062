import { DateRangeModel } from 'app/core/mips/common/models/date-range.model';
import { DateUtility } from 'app/core/mips/common/utilities/date.utility';


export interface DateRange {
    expenseDateRangeSelection: DateRangeModel | null;
}
  
export const initialExpenseWeekState: DateRange = {
  expenseDateRangeSelection: {
    startDate: DateUtility.getCurrentWeekDates().startDate,
    endDate: DateUtility.getCurrentWeekDates().endDate
  }
};