import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddClientService } from './add-client.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions } from 'ag-grid-community';
import { map } from 'rxjs';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  clientForm: FormGroup;
  columnDefs: any[];
  rowData: any[];
  defaultColDef: any;
  isSaveButtonEnabled: boolean = false;
  isSubmitButtonEnabled: boolean = false;
  gridOptions: GridOptions = {};
  gridApi: GridApi;

  @ViewChild('agGrid') agGrid: AgGridAngular;
  gridColumnApi: any;

  constructor(private fb: FormBuilder, private clientDataService: AddClientService) { 
  }

  ngOnInit(): void {
    this.clientForm = this.fb.group({
      clientCode: ['', [Validators.required, Validators.maxLength(4)]],
      clientName: ['', Validators.required],
      internal: [false]
    });

    this.fillAgGrid();

    this.columnDefs = this.columnDefsForAgGrid();

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true
    };
  }

  fillAgGrid(){
    this.clientDataService.getClientData().pipe(
      map(clients =>
        clients.map((client: any) => ({
          ...client,
          oldActive: client.Active // Set oldActive to the current value of active
        }))
      )
    ).subscribe(
      transformedClients => {
        this.rowData = transformedClients;
        if (this.agGrid.api) {
          this.agGrid.api.setRowData(this.rowData);
          this.agGrid.api.refreshCells({ force: true });
        }
      }
    );
  }

  columnDefsForAgGrid(){
    return [
      { 
        headerName: 'Client Name',
        field: 'ClientName', 
        editable: false,
        filter: 'agTextColumnFilter',
        cellStyle: (params: any) => {
          if (params.data.oldActive !== params.data.Active) {
            console.log(params.data)
            return { background: '#ffc107' };
          }
          return { background: "#F6F5F2" }
        },
      },
      { 
        headerName: 'Client Code', 
        field: 'Client', 
        editable: false,
        cellStyle: (params: any) => {
          if (params.data.oldActive !== params.data.Active) {
            return { background: '#ffc107' };
          }
          return { background: "#F6F5F2" }
        },
      },
      { 
        headerName: 'Internal', 
        field: 'Internal', 
        editable: false,
        cellRenderer: (params: any) => params.value ? 'YES' : 'NO',
        cellStyle: (params: any) => {
          if (params.data.oldActive !== params.data.Active) {
            return { background: '#ffc107' };
          }
          return { background: "#F6F5F2" }
        },
      },
      {
        headerName: 'Activate',
        field: 'Active',
        cellStyle: (params: any) => {
          if (params.data.oldActive !== params.data.Active) {
            return { background: '#ffc107' };
          }
          else{
            return {background: '#ffffff'}
          }
        },
        cellRenderer: (params: any) => {
          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.checked = params.value;
          checkbox.addEventListener('change', (event) => {
            params.node.setDataValue(params.colDef.field, checkbox.checked);
            this.onCellValueChanged(params);
          });
          return checkbox;
        },
      }
    ];
  }

  checkDisability(){
    this.isSubmitButtonEnabled = this.clientForm.valid;
    return !this.isSubmitButtonEnabled;
  }

  onSubmit(): void {
    if (this.clientForm.valid) {
      const formData = this.clientForm.value;
      this.clientDataService.addClient(formData).subscribe(
        response => {
          // After adding the client, reload the grid data
          this.fillAgGrid();
          console.log('Client added successfully:', response);
        },
        error => {
          console.error('Error adding client:', error);
        }
      );
      // this.rowData = [...this.clientDataService.getClients()]; // Refresh the grid data
    } else {
      console.log('Form is invalid');
    }
  }

  onClear(): void {
    this.clientForm.reset({ clientCode: '', clientName: '', internal: false });
  }

  async onSave(): Promise<void> {
    const dirtyClients = this.rowData.filter(client => client.oldActive !== client.Active);
    await this.clientDataService.saveClients(dirtyClients);
    // After saving, you may want to reset the dirty flag and disable the save button
    dirtyClients.forEach(client => client.oldActive = client.Active);
    this.isSaveButtonEnabled = false;
    // Optionally, refresh the grid to update cell styles
    this.gridApi.refreshCells({ force: true });
  }

  onCellValueChanged(params: any): void {
    this.isSaveButtonEnabled = params.data.oldActive !== params.data.Active;
    // params.data.dirty = true;
    console.log(params.data)
    params.api.refreshCells({ force: true });
  }

  AddOuterGridStyle() {
    let cssStyles = {
     'display': 'grid',
    //  'grid-template-areas': this.formBlueprint.props.gridAreaForm[this.index],
    }
    return cssStyles;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSearch(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.agGrid.api.setQuickFilter(searchTerm);
  }
}
