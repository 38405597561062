import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateUtility } from '../../../common/utilities/date.utility';
import { ImmigrationDetailsService } from '../../../common/services/immigration-details.service';
import { ProjectsService } from '../../../common/services/projects.service';
import { ProjectTaskService } from '../../../common/services/project-task.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { ConsultantModel } from '../../../common/models/consultant.model';
import { ProjectTaskModel } from '../../../common/models/project-task.model';
import { ConsultantProjectRateParameterModel, ConsultantRateModel } from '../../../common/models/consultant-rate.model';
import { commonFunctions } from '../../../common/classes/common.class';
import { ConsultantRateService } from '../../../common/services/consultant-rate.service';
import { SnackbarService } from '../../../common/services/snackbar.service';
import { Constants } from '../../../common/utilities/constants.config';
import { CurrencyModel } from '../../../common/models/currency.model';
import { ExpensePaymentMethodModel } from '../../../common/models/expense-payment-method.model';
import { ExpenseTypeModel, ExpenseTypeDescriptionModel } from '../../../common/models/expense-type.model';
import { ExpenseTypeService } from '../../../common/services/expense-type.service';
import { ExpensePaymentMethodService } from '../../../common/services/expense-payment-method.service';
import { CurrencyService } from '../../../common/services/currency.service';
import { getImmigrationDetails } from 'app/core/store/immigrationDetails/immigrationDetails.selector';


@Component({
  selector: 'app-entry-dialog',
  templateUrl: './entry-dialog.component.html',
  styleUrls: ['./entry-dialog.component.scss']
})
export class EntryDialogComponent {
  entryForm: FormGroup;
  currentUser: ConsultantModel;
  projectList: any[] = [];
  selectedProject: any;
  projectTaskList: ProjectTaskModel[] = [];
  selectedProjectTask: any;
  consultantProjectRate: ConsultantRateModel[] = [];
  selectedConsultantRate: ConsultantRateModel;
  consulantProjectClientRate: ConsultantRateModel;
  expenseTypeList: ExpenseTypeModel[] = [];
  selectedExpenseType: ExpenseTypeModel;
  expenseTypeDescList: ExpenseTypeDescriptionModel[] = [];
  selectedExpenseDescType: ExpenseTypeDescriptionModel;
  paymentMethodList: ExpensePaymentMethodModel[] = [];
  selectedPaymentMethod: ExpensePaymentMethodModel;
  currencyList: CurrencyModel[] = [];
  selectedCurrency: CurrencyModel;
  visible = true;
  isChargeable = false;
  isEntryCreateMode = true;
  hourChoice = 'Hour';
  consultantWorkHour = null;
  invoiceOnSameDate = true;
  consultantImmigrationDetails: any;
  consultantWorkCountry = null;
  projectCurrency: string;

  constructor(
    private dialogRef: MatDialogRef<EntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _formBuilder: FormBuilder,
    private consultantImmigrationDetailsService: ImmigrationDetailsService,
    private projectService: ProjectsService,
    private projectTaskService: ProjectTaskService,
    private consultantRateService: ConsultantRateService,
    private snackBarService: SnackbarService,
    private _store: Store,
    private expenseTypeService: ExpenseTypeService,
    private expensePaymentMethodService: ExpensePaymentMethodService,
    private currencyService: CurrencyService
  ) {
    this._store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    })

    this._store.select(getImmigrationDetails).subscribe(immigrationDetails => {
      this.consultantImmigrationDetails = immigrationDetails;
      this.consultantWorkCountry = this.consultantImmigrationDetails['consultantWorkCountry'];
      this.consultantWorkHour = this.consultantImmigrationDetails['consultantWorkHour'];
    });

    if (this.dialogData.Mode === 'edit') {
      this.isEntryCreateMode = false;
      if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
        this.isChargeable = this.dialogData.Charge.toLowerCase() === 'yes';
      }
    }

    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.entryForm = this._formBuilder.group({
        Time_ID: [this.dialogData.Time_ID],
        Project: [this.dialogData.Project, Validators.required],
        ProjectName: [this.dialogData.ProjectName],
        ClientName: [this.dialogData.ClientName],
        Client: [this.dialogData.Client],
        Cost: [this.dialogData.Cost],
        Days: [this.dialogData.Days],
        Task_Description: [this.dialogData.Task_Description, Validators.required],
        Hours: [this.dialogData.Hours, Validators.required],
        Task_Code: [this.dialogData.Task_Name],
        Task_Name: [this.dialogData.Task_Description, Validators.required],
        Rate: [this.dialogData.Rate, Validators.required],
        TDate: [this.dialogData.TDate, Validators.required],
        Invoice_Date: [this.dialogData.Invoice_Date, Validators.required],
        Invoice_id: [this.dialogData.Invoice_id],
        Charge: [this.isChargeable, Validators.required],
        Task_id: [this.dialogData.Task_id],
        Currency_Code: [this.dialogData.Currency_Code],
        Currency_symbol_short: [this.dialogData.Currency_symbol_short],
        Employee: [this.dialogData.Employee],
        Copy: [true],
        Contact: [this.dialogData.Contact],
        Version: [this.dialogData.Version],
        ApplyTo: [this.dialogData.ApplyTo],
        Employee_FullName: [this.dialogData.Employee_FullName],
        Year: [this.dialogData.Year],
        Month: [this.dialogData.Month],
        Week_Commencing: [this.dialogData.Week_Commencing],
        Company_Name: [this.dialogData.Company_Name],
        Consultant_Company_Name: [this.dialogData.Consultant_Company_Name]
      });
      if (this.dialogData.TDate !== this.dialogData.Invoice_Date) {
        this.invoiceOnSameDate = false;
      }
    }
    else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      this.entryForm = this._formBuilder.group(
        {
          ExpenseID: [this.dialogData.ExpenseID],
          AmountSpent: [this.dialogData.AmountSpent, EntryDialogComponent.AmountRangeValidator],
          AmountConstant: [this.dialogData.Amount_Constant],
          AmountNative: [this.dialogData.Amount_Native],
          ClientName: [this.dialogData.ClientName],
          Client: [this.dialogData.Client],
          Company_id: [this.dialogData.Company_id],
          Currency_To: [this.dialogData.Currency_To],
          Currency_symbol: [this.dialogData.Currency_symbol],
          Currency_symbol_short: [this.dialogData.Currency_symbol_short, Validators.required],
          Currency_symbol_short_to: [this.dialogData.Currency_symbol_short_to],
          Currency_From: [this.dialogData.Currency_From],
          Description: [this.dialogData.Description, Validators.required],
          Details: [this.dialogData.Details, Validators.required],
          Employee: [this.dialogData.Employee],
          ExpenseType: [this.dialogData.ExpenseType, Validators.required],
          TDate: [this.dialogData.TDate, Validators.required],
          Invoice_date: [this.dialogData.Invoice_date],
          Invoice_id: [this.dialogData.Invoice_id],
          LoginName: [this.dialogData.LoginName],
          Mileage: [this.dialogData.Mileage],
          MileageRate: [this.dialogData.MileageRate],
          Mode: [this.dialogData.Mode],
          PaymentMethod: [this.dialogData.PaymentMethod, Validators.required],
          Period_Month: [''],
          Project: [this.dialogData.Project, Validators.required],
          Project_Description: [this.dialogData.ProjectName],
          Project_Sales_Tax: null,
          State_code: [this.dialogData.State_code],
          State_Name: [this.dialogData.State_Name],
          State_id: [this.dialogData.State_id],
          Task_Name: [this.dialogData.Task_Name, Validators.required],
          Task_Description: [this.dialogData.Task_Description],
          Task_id: [this.dialogData.Task_id],
          Version_id: [this.dialogData.Version_id],
          status: [this.dialogData.status]
        }
      );
      if (this.dialogData.TDate !== this.dialogData.Invoice_date) {
        this.invoiceOnSameDate = false;
      }
      this.getExpenseType(this.dialogData.Client, this.dialogData.Project);
      this.getPaymentMethod();
      this.getCurrency(this.dialogData.Client, this.dialogData.Project);
    }
    this.getProjects();
    this.getProjectTask(this.dialogData.Client, this.dialogData.Project);
  }

  static AmountRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && ((isNaN(control.value) || control.value === 0 || commonFunctions.isNullOrUndefined(control.value)))) {
      return { 'AmountRange': true };
    }

    return null;
  }

  setInputFieldStatus(hourChoice: string): void {
    this.hourChoice = hourChoice;
    if (this.hourChoice === 'Half') {
      this.entryForm.controls['Hours'].disable();
      this.entryForm.controls['Hours'].setValue(Number(this.consultantWorkHour) / 2);
    }
    else if (this.hourChoice === 'Full') {
      this.entryForm.controls['Hours'].disable();
      this.entryForm.controls['Hours'].setValue(this.consultantWorkHour);
    }
    else {
      this.entryForm.controls['Hours'].enable();
    }
  }

  ngOnInit() {

    // this.getProjects();
    this.getWorkCountry();

  }

  getWorkCountry(): void {
      // this.entryForm.controls['Hours'].setValue(this.hourChoice === 'Hour' ? this.entryForm.value.Hours : (this.hourChoice === 'Half' ? Number(this.consultantWorkHour) / 2 : Number(this.consultantWorkHour)));
      if (this.hourChoice === 'Half') {
        this.entryForm.controls['Hours'].setValue(Number(this.consultantWorkHour) / 2);
      } else if (this.hourChoice === 'Full') {
        this.entryForm.controls['Hours'].setValue(this.consultantWorkHour);
      }
  }

  getProjects(): void {
    // this.projectService.getProjects(false,  this.currentUser['Employee']).subscribe(projectList => {
    this.projectService.getProjects(false, this.currentUser['Employee'], this.dialogData.startDate, this.dialogData.endDate).subscribe(projectList => {
      
      this.projectList = projectList;
      if (!this.isEntryCreateMode) {
        this.selectedProject = this.projectList.filter(project => {
          return project.Project.toLowerCase() === this.dialogData.Project.toLowerCase() && project.Client.toLowerCase() === this.dialogData.Client.toLowerCase();
        })[0];
        this.getConsultantProjectRate(this.dialogData.Client, this.dialogData.Project);
      }
    });

  }

  getProjectTask(client, project): void {
    this.projectTaskList = [];
    if (!commonFunctions.isNullOrUndefined(client) && !commonFunctions.isNullOrUndefined(project)) {
      this.projectTaskService.getProjectTasks(client, project).subscribe(projectTasks => {
        this.projectTaskList = projectTasks;
        
        this.selectedProjectTask = this.projectTaskList.filter(task => {
          return task.Task_id === this.dialogData.Task_id;
        })[0];
        if (commonFunctions.isNullOrUndefined(this.selectedProjectTask) && this.projectTaskList.length === 1) {
          this.selectedProjectTask = this.projectTaskList[0];
        }
      });
    }
  }

  changeInvoiceDate(): void {
    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.entryForm.value.Invoice_Date = DateUtility.formatStringfromDate(this.entryForm.value.Invoice_Date.toDate());
    }
    else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      this.entryForm.value.Invoice_date = DateUtility.formatStringfromDate(this.entryForm.value.Invoice_date.toDate());
    }
  }

  changeTDate(): void {

    if (typeof this.entryForm.value.TDate === 'object') {
      this.entryForm.value.TDate = DateUtility.formatStringfromDate(this.entryForm.value.TDate.toDate());
    }
    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.selectedConsultantRate = null;
      if (!commonFunctions.isNullOrUndefined(this.selectedProject)) {
        this.getConsultantProjectRate(this.selectedProject.Client, this.selectedProject.Project);
      }
    }
    this.getWorkCountry();
  }

  getConsultantProjectRate(client, project): void {
    const consultantClientProjectRate = new ConsultantProjectRateParameterModel();
    
    if (typeof this.entryForm.value.TDate === 'object') {
      this.entryForm.value.TDate = DateUtility.formatStringfromDate(this.entryForm.value.TDate.toDate());
    }
    const entryDate = DateUtility.createDateTypefromDate(this.entryForm.value.TDate.substr(0, 10));
    consultantClientProjectRate.employee = this.currentUser.Employee;
    consultantClientProjectRate.client = client;
    consultantClientProjectRate.project = project;
    consultantClientProjectRate.entrydate = String(entryDate.getFullYear()) + String((Number(entryDate.getMonth()) + 1 < 10 ? '0' : '') + (Number(entryDate.getMonth()) + 1));
    this.consultantRateService.getConsultantByProject(consultantClientProjectRate).subscribe(consultantRate => {
      this.consultantProjectRate = consultantRate;
      this.selectedConsultantRate = this.consultantProjectRate.filter(rate => {
        return rate.Client === this.selectedProject.Client && rate.Project === this.selectedProject.Project;
      })[0];
      if (commonFunctions.isNullOrUndefined(this.selectedConsultantRate)) {
        this.selectedConsultantRate = this.consultantProjectRate.filter(rates => rates.Is_Default_Rate === 1)[0];
      }
    });
  }

  onChangeSelectedProject(params): void {
    if (!commonFunctions.isNullOrUndefined(params)) {
      this.selectedProjectTask = null;
      this.selectedProject = this.projectList.filter(project => {
        return project.Project === params.Project && project.Client === params.Client;
      })[0];

      this.getProjectTask(params.Client, params.Project);

      if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
        this.selectedConsultantRate = null;
        this.getConsultantProjectRate(params.Client, params.Project);
        if (this.selectedProject.Charge.toLowerCase() === 'yes') {
          this.isChargeable = true;
        } else {
          this.isChargeable = false;
        }
      } else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
        this.selectedExpenseType = null;
        this.selectedExpenseDescType = null;
        this.selectedPaymentMethod = null;
        this.selectedCurrency = null;
        this.entryForm.controls['Mileage'].setValue(this.selectedProject.DefaultMileage);
        this.getExpenseType(params.Client, params.Project);
        this.getPaymentMethod();
        this.getCurrency(params.Client, params.Project);
      }
    }
  }

  onChangeSelectedType(params): void {

    if (!commonFunctions.isNullOrUndefined(params)) {
      this.selectedExpenseDescType = null;
      this.getExpenseTypeDesc(params.Type);
      // this.getExpenseTypeDesc(params.value.Type);
    }
  }

  setConsultantHours(): void {
    if (this.entryForm.controls['Hours'].value === Number(this.consultantWorkHour) / 2) {
      this.entryForm.value.Hours = Number(this.consultantWorkHour) / 2;

    }
    else if (Number(this.entryForm.controls['Hours'].value) === Number(this.consultantWorkHour)) {
      this.entryForm.value.Hours = Number(this.consultantWorkHour);

    }
    

  }

  getExpenseType(client, project): void {
    this.expenseTypeList = [];
    if (!commonFunctions.isNullOrUndefined(client) && !commonFunctions.isNullOrUndefined(project) && client !== '') {
      this.expenseTypeService.getExpenseTypes(client, project).subscribe(expensetypes => {
        this.expenseTypeList = expensetypes;
        this.selectedExpenseType = this.expenseTypeList.filter(type => {
          return type.Type === this.dialogData.ExpenseType;
        })[0];
        if (!commonFunctions.isNullOrUndefined(this.selectedExpenseType)) {
          this.getExpenseTypeDesc(this.selectedExpenseType.Type);
        }
        if (commonFunctions.isNullOrUndefined(this.selectedExpenseType) && this.expenseTypeList.length === 1) {
          this.selectedExpenseType = this.expenseTypeList[0];
        }
        // this.getExpenseTypeDesc(this.selectedExpenseType);
      });
    }
  }

  getExpenseTypeDesc(expenseType): void {
    this.expenseTypeDescList = [];
    if (!commonFunctions.isNullOrUndefined(expenseType)) {
      this.expenseTypeService.getExpenseTypeDesc(expenseType).subscribe(expenseTypeDesc => {
        this.expenseTypeDescList = expenseTypeDesc;
        this.selectedExpenseDescType = this.expenseTypeDescList.filter(typedesc => {
          return typedesc.description === this.dialogData.Description;
        })[0];
        if (commonFunctions.isNullOrUndefined(this.selectedExpenseDescType) && this.expenseTypeDescList.length === 1) {
          this.selectedExpenseDescType = this.expenseTypeDescList[0];
        }
      });
    }

  }

  getPaymentMethod(): void {
    this.paymentMethodList = [];
    this.expensePaymentMethodService.getExpensePaymentMethod().subscribe(paymentMethod => {
      this.paymentMethodList = paymentMethod;
      this.selectedPaymentMethod = this.paymentMethodList.filter(method => {
        return method.value.toLowerCase() === this.dialogData.PaymentMethod.toLowerCase();
      }
      )[0];
    });
  }

  getCurrency(client, project): void {
    this.currencyList = [];
    const consultantClientProjectRate = new ConsultantProjectRateParameterModel();
    if (typeof this.entryForm.value.TDate === 'object') {
      this.entryForm.value.TDate = DateUtility.formatStringfromDate(this.entryForm.value.TDate.toDate());
    }
    const entryDate = DateUtility.createDateTypefromDate(this.entryForm.value.TDate.substr(0, 10));
    consultantClientProjectRate.employee = this.currentUser.Employee;
    consultantClientProjectRate.client = client;
    consultantClientProjectRate.project = project;
    // consultantClientProjectRate.entrydate = String(entryDate.getFullYear()) + String(entryDate.getMonth() + 1);
    consultantClientProjectRate.entrydate = String(entryDate.getFullYear()) + String((Number(entryDate.getMonth()) + 1 < 10 ? '0' : '') + (Number(entryDate.getMonth()) + 1));
    this.currencyService.getCurrencyDetails().subscribe(currency => {
      this.currencyList = currency;
      this.consultantRateService.getConsultantByProject(consultantClientProjectRate).subscribe(consultantRate => {

        if (!commonFunctions.isNullOrUndefined(consultantRate[0])) {
          this.consulantProjectClientRate = consultantRate[0];
          this.selectedCurrency = this.currencyList.filter(selectedcurrency => {
            return selectedcurrency.currency_symbol_short === this.consulantProjectClientRate.Converted_Currency_Symbol;
          })[0];
          this.projectCurrency = this.selectedCurrency.currency_symbol_short;
        }
        if (!commonFunctions.isNullOrUndefined(this.dialogData.Currency_symbol_short) && this.dialogData.Currency_symbol_short !== '') {
          this.selectedCurrency = this.currencyList.filter(selectedcurrency => {
            return selectedcurrency.currency_symbol_short === this.dialogData.Currency_symbol_short;
          })[0];
        }
      });
    });
  }

  save(): void {
    this.entryForm.value.Copy = false;
    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.setConsultantHours();
      if (this.entryForm.value.Hours > 0 && this.entryForm.value.Hours <= 24) {
        this.setFormGroupValue();
        this.dialogRef.close(this.entryForm.value);
      }
      else {
        this.snackBarService.showSnackBar(Constants.SNACKBAR_MSG_TIME_ENTRY_VALID_HOURS, 'close', 'failure');
      }
    } else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      if (!commonFunctions.isNullOrUndefined(this.selectedExpenseDescType) && this.selectedExpenseDescType.description !== 'Mileage') {
        this.setFormGroupValue();
        this.entryForm.value.Mileage = 0;
        this.dialogRef.close(this.entryForm.value);
      }
      else {
        if (this.entryForm.value.Mileage > 0) {
          this.setFormGroupValue();
          this.dialogRef.close(this.entryForm.value);
        }
        else {
          this.snackBarService.showSnackBar(Constants.SNACKBAR_MSG_EXPENSE_ENTRY_VALID_MILEAGE, 'close', 'failure');
        }
      }
    }

  }

  cloneData(): void {
    this.entryForm.value.Copy = true;
    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.entryForm.value.Time_ID = 0;
      this.setConsultantHours();
      this.setFormGroupValue();
      this.dialogRef.close(this.entryForm.value);
    } else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      this.entryForm.value.ExpenseID = 0;
      if (!commonFunctions.isNullOrUndefined(this.selectedExpenseDescType) && this.selectedExpenseDescType.description !== 'Mileage') {
        this.setFormGroupValue();
        this.dialogRef.close(this.entryForm.value);
      }
      else {
        if (this.entryForm.value.Mileage > 0) {
          this.setFormGroupValue();
          this.dialogRef.close(this.entryForm.value);
        }
        else {
          this.snackBarService.showSnackBar('Please enter valid mileage', 'close', 'failure');
        }
      }
    }
  }

  setFormGroupValue(): void {
    this.entryForm.value.Task_Name = this.selectedProjectTask.Task_description;
    this.entryForm.value.Task_id = this.selectedProjectTask.Task_id;
    this.entryForm.value.Client = this.selectedProject.Client;
    this.entryForm.value.ClientName = this.selectedProject.ClientName;
    this.entryForm.value.ProjectName = this.selectedProject.Description;
    this.entryForm.value.Project = this.selectedProject.Project;
    if (typeof this.entryForm.value.TDate === 'object') {
      this.entryForm.value.TDate = DateUtility.formatStringfromDate(this.entryForm.value.TDate.toDate());
    }
    if (this.dialogData.entryPageType == Constants.NAV_TIME_ENTRY_ACTUALS) {
      this.entryForm.value.Task_Code = this.selectedProjectTask.Task_name;
      this.entryForm.value.Rate = this.selectedConsultantRate.Converted_Rate;
      this.entryForm.value.Currency_Code = this.selectedConsultantRate.Converted_Currency_Code;
      this.entryForm.value.Currency_symbol_short = this.selectedConsultantRate.Converted_Currency_Symbol;
      if (this.isChargeable) {
        this.entryForm.value.Charge = 'YES';
      } else {
        this.entryForm.value.Charge = 'NO';
      }
      if (typeof this.entryForm.value.Invoice_Date === 'object') {
        this.entryForm.value.Invoice_Date = DateUtility.formatStringfromDate(this.entryForm.value.Invoice_Date.toDate());
      }

      if (this.invoiceOnSameDate) {
        this.entryForm.value.Invoice_Date = this.entryForm.value.TDate;
      }
    }
    else if (this.dialogData.entryPageType == Constants.NAV_EXPENSE_ENTRY) {
      this.entryForm.value.Task_Description = this.selectedProjectTask.Task_name;
      this.entryForm.value.Project_Description = this.selectedProject.Description;
      this.entryForm.value.Currency_From = this.selectedCurrency.currency_code;
      this.entryForm.value.Currency_symbol = this.selectedCurrency.currency_symbol;
      this.entryForm.value.Currency_symbol_short = this.selectedCurrency.currency_symbol_short;
      this.entryForm.value.PaymentMethod = this.selectedPaymentMethod.value;
      this.entryForm.value.ExpenseType = this.selectedExpenseType.Type;
      this.entryForm.value.Description = this.selectedExpenseDescType.description;
      if (this.entryForm.value.Mileage > 0) {
        this.entryForm.value.Mileage = parseFloat(this.entryForm.value.Mileage).toFixed(6);
      }
      if (this.entryForm.value.AmountSpent !== 0) {
        this.entryForm.value.AmountSpent = parseFloat(this.entryForm.value.AmountSpent).toFixed(4);
      }
      this.entryForm.value.MileageRate = this.selectedProject.MileageRate;
      if (typeof this.entryForm.value.Invoice_date === 'object') {
        this.entryForm.value.Invoice_date = DateUtility.formatStringfromDate(this.entryForm.value.Invoice_date.toDate());
      }

      if (this.invoiceOnSameDate) {
        this.entryForm.value.Invoice_date = this.entryForm.value.TDate;
      }
    }
  }

  searchFunctionDropdown(term: string, item): any {
    const clientName = item['ClientName'].toLowerCase();
    const description = item['Description'].toLowerCase();
    const client = item['Client'].toLowerCase();
    const project = item['Project'].toLowerCase();
    const searchTerm = term.toLowerCase().split(' ');
    let found = 0;

    for (const key of searchTerm) {
      if (description.includes(key) || clientName.includes(key) || project.includes(key)
        || client.includes(key.toLowerCase())) {
        found = 1;
      } else {
        found = 0;
        break;
      }
    }
    return (found);
  }

  searchDropDownProjectTask(term: string, item): any {
    const taskName = item['Task_name'].toLowerCase();
    const taskDescription = item['Task_description'].toLowerCase();
    const searchterm = term.toLowerCase().split(' ');
    let found = 0;
    for (const values of searchterm) {
      if (taskName.includes(values) || taskDescription.includes(values)) {
        found = 1;
      }
      else {
        found = 0;
      }
    }
    return (found);
  }

  searchDropDownType(term: string, item): any {
    const type = item['Type'].toLowerCase();
    if (type.includes(term.toLowerCase())) {
      return 1;
    }
    else {
      return 0;
    }
  }

  searchDropDownTypeDesc(term: string, item): any {
    const description = item['description'].toLowerCase();
    if (description.includes(term.toLowerCase())) {
      return 1;
    }
    else {
      return 0;
    }
  }

}
