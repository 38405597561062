import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';
import { UserModel } from 'app/core/mips/common/models/user.model';
import { LoginModel } from 'app/core/mips/common/models/login.model';
import { ApiheaderService } from 'app/core/mips/common/services/apiheader.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Constructor
   */
  constructor(
    private _http: HttpClient,
  ) { }

  login(username: string, password: string): Observable<string> {
    return this._http.post<string>(URLPaths.URL_LOGIN, { 'username': username, 'password': password });
  }
  login_optimized(username: string, password: string): Observable<LoginModel> {
    return this._http.post<LoginModel>(URLPaths.URL_LOGIN_OPTIMIZED, { 'username': username, 'password': password });
  }

  logout(): Observable<string> {
    return this._http.get<string>(URLPaths.URL_LOGOUT, { headers: ApiheaderService.getHeaders() });
  }

  getUser(): Observable<UserModel> {
    return this._http.get<UserModel>(URLPaths.URL_USER, { headers: ApiheaderService.getHeaders() });
  }

  validateToken(): Observable<any> {
    return this._http.get(URLPaths.URL_VALIDATE_TOKEN, { headers: ApiheaderService.getHeaders() })
  }

}
