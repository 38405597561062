import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDynamicStyles]'
})
export class DynamicStylesDirective implements OnInit {
  @Input('appDynamicStyles') stylesConfig: { [key: string]: { zoomout: string, zoomin: string } };

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.adjustStyles();
    window.addEventListener('resize', this.adjustStyles.bind(this)); // Adjust on resize
  }

  private adjustStyles(): void {
    const dpr = window.devicePixelRatio;

    for (const [property, config] of Object.entries(this.stylesConfig)) {
      if (dpr >= 1.5) {
        this.renderer.setStyle(this.el.nativeElement, property, config.zoomin);
      } else {
        this.renderer.setStyle(this.el.nativeElement, property, config.zoomout);
      }
    }
  }
}