import { Component } from '@angular/core';
import { Constants } from '../../common/utilities/constants.config';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-time-entry',
  templateUrl: './time-entry.component.html',
  styleUrls: ['./time-entry.component.scss']
})
export class TimeEntryComponent {
  entryPageType: any;
  agGridAPIFromTableToHeader: GridApi;
  ngOnInit(): void {
    //Set Entry Page Type to Time Entry Page
    this.entryPageType = Constants.NAV_TIME_ENTRY_ACTUALS;
  }
}
