import { Component, OnInit } from '@angular/core';
import { DateUtility } from '../../../mips/common/utilities/date.utility';
import { commonFunctions } from '../../../mips/common/classes/common.class';
import { GridApi, GridOptions, ColumnApi } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { TimeDicerConfigService } from '../../common/services/time-dicer-config.service';

@Component({
  selector: 'app-time-dicer',
  templateUrl: './time-dicer.component.html',
  styleUrls: ['./time-dicer.component.scss'],
})
export class TimeDicerComponent implements OnInit {
  gridOptions: GridOptions = {};
  agGridAPI: GridApi;
  agGridColumnAPI: ColumnApi;
  defaultColDef: any;
  rowData: any = [];

  /**
   * Constructor
   */

  constructor(
    private _datePipe: DatePipe,
    private _timeDicerConfigService: TimeDicerConfigService,
  ) {
    this._timeDicerConfigService.timeDicerRowData.subscribe((data) => {
      this.rowData = data;
    })

    // Column Selections for the Time Dicer
    this.gridOptions.columnDefs = [
      {
        headerName: 'Employee',
        field: 'Employee',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Employee_FullName',
        field: 'Employee_FullName',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Client',
        field: 'Client',
        enablePivot: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return params.data.Client + ' - ' + params.data.ClientName;
          }
          return null;
        },
      },
      {
        headerName: 'Contact',
        field: 'Contact',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Project',
        field: 'Project',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return params.data.Project + ' - ' + params.data.ProjectName;
          }
          return null;
        },
      },
      {
        headerName: 'Task Name',
        field: 'Task_Code',
        enablePivot: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return params.data.Task_Code + ' - ' + params.data.Task_Name;
          }
          return null;
        },
      },
      {
        headerName: 'Task Description',
        editable: true,
        width: 400,
        field: 'Task_Description',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Hours',
        field: 'Hours',
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          if (!params.node.group) {
            return parseFloat(params.data.Hours);
          }
          return null;
        },
        valueFormatter: (params) => {
          if (!params.node.group) {
            return Number(params.value).toFixed(2);
          } else {
            if (commonFunctions.isNullOrUndefined(params.value)) {
              return null;
            } else {
              return Number(params.value).toFixed(2);
            }
          }
        },
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
      },
      {
        headerName: 'Entry Date',
        field: 'TDate',
        filter: 'agDateColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return DateUtility.createDateTypefromDate(
              params.data.TDate.substr(0, 10)
            );
          }
          return null;
        },
        valueFormatter: (params) => {
          return DateUtility.formatDateWithDayNamefromString(
            this._datePipe.transform(params.value, 'yyyy-MM-dd')
          );
        },
        pivotComparator(value1, value2): number {
          value1 = DateUtility.formatDatefromString(
            _datePipe.transform(value1, 'yyyy-MM-dd')
          );
          value1 = DateUtility.createDateTypeFromString(value1);
          value2 = DateUtility.formatDatefromString(
            _datePipe.transform(value2, 'yyyy-MM-dd')
          );
          value2 = DateUtility.createDateTypeFromString(value2);
          if (value1 > value2) {
            return 1;
          } else {
            return -1;
          }
        },
      },
      {
        headerName: 'Rate',
        field: 'Rate',
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (params.node.group) {
            return null;
          }
          return parseFloat(params.data.Rate);
        },
        valueFormatter: (params) => {
          if (params.node.group) {
            return null;
          }
          return (
            params.data.Currency_Symbol + ' ' + Number(params.value).toFixed(3)
          );
        },
      },
      {
        headerName: 'Cost',
        field: 'Cost',
        enableValue: true,
        enablePivot: true,
        enableRowGroup: true,
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          if (!params.node.group) {
            return parseFloat(params.data.Cost);
          }
          return null;
        },
        valueFormatter: (params) => {
          if (!params.node.group) {
            return (
              params.data.Currency_symbol_short +
              ' ' +
              Number(params.value).toFixed(3)
            );
          } else {
            if (commonFunctions.isNullOrUndefined(params.value)) {
              return null;
            } else {
              return Number(params.value).toFixed(3);
            }
          }
        },
      },
      {
        headerName: 'Currency Code',
        field: 'Currency_Code',
        enablePivot: true,
        enableRowGroup: true,
        hide: true,
      },
      {
        headerName: 'Invoice Date',
        field: 'Invoice_Date',
        filter: 'agDateColumnFilter',
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return DateUtility.createDateTypefromDate(
              params.data.Invoice_Date.substr(0, 10)
            );
          }
          return null;
        },
        valueFormatter: (params) => {
          return DateUtility.formatDateWithDayNamefromString(
            this._datePipe.transform(params.value, 'yyyy-MM-dd')
          );
        },
      },
      {
        headerName: 'Charge',
        field: 'Charge',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Entry Owner',
        field: 'Entry_Owner',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Entry Owner LoginName',
        field: 'Entry_Owner_LoginName',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Apply To',
        field: 'ApplyTo',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Employee LoginName',
        field: 'Employee_Loginname',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Version',
        field: 'Version',
        enablePivot: true,
        enableRowGroup: true,
        // hide: true
      },
      {
        headerName: 'State Name',
        field: 'State_Name',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Year',
        field: 'Year',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Year-Month',
        field: 'Month',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Week Start',
        colId: 'weekStart',
        field: 'Week_Commencing',
        filter: 'agDateColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (!params.node.group) {
            return DateUtility.createDateTypefromDate(
              params.data.Week_Commencing.substr(0, 10)
            );
          } else {
            return null;
          }
        },
        valueFormatter: (params) => {
          return DateUtility.formatDateWithDayNamefromString(
            this._datePipe.transform(params.value, 'yyyy-MM-dd')
          );
        },
        
        pivotComparator(value1, value2): number {
          value1 = DateUtility.formatDatefromString(
            _datePipe.transform(value1, 'yyyy-MM-dd')
          );
          value1 = DateUtility.createDateTypeFromString(value1);
          value2 = DateUtility.formatDatefromString(
            _datePipe.transform(value2, 'yyyy-MM-dd')
          );
          value2 = DateUtility.createDateTypeFromString(value2);
          if (value1 > value2) {
            return 1;
          } else {
            return -1;
          }
        },
      },
      {
        headerName: 'Company Name',
        field: 'Company_Name',
        enablePivot: true,
        enableRowGroup: true,
      },
      {
        headerName: 'Consultant Company Name',
        field: 'Consultant_Company_Name',
        enablePivot: true,
        enableRowGroup: true,
      },
    ];
    this.gridOptions.groupDefaultExpanded = 1;
    this.gridOptions.suppressAggFuncInHeader = true;
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
    }; 
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {}


  // Sets the params with the grid api properties  
  onGridReady(params): void {
    this.agGridAPI = params.api;
    this.agGridColumnAPI = params.columnApi;
    this._timeDicerConfigService.setGridApi(this.agGridAPI);
    this._timeDicerConfigService.setGridColumnApi(this.agGridColumnAPI);
  }
}
