import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { commonFunctions } from '../classes/common.class';
import { Constants } from '../utilities/constants.config';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  matSnackBarRef: any;

  constructor(private snackBar: MatSnackBar) { }

  //Shows snackbar on the bottom right of the page
  showSnackBar(message: string, action: string, snackBarType: 'success' | 'warning' | 'failure' | 'info', noTimeout?: boolean) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = Constants.SNACKBAR_VERTICALPOSITION;
    config.horizontalPosition = Constants.SNACKBAR_HORIZONTALPOSITION;
    // config.duration = Constants.SNACKBAR_TIME;
    if(!noTimeout){
        config.duration = Constants.SNACKBAR_TIME;
    }

        if (snackBarType === 'success') {
            config.panelClass = ['style-success'];
        }
        if (snackBarType === 'warning') {
            config.panelClass = ['style-warning'];
        }
        if (snackBarType === 'failure') {
            config.panelClass = ['style-failure'];
        }
        if (snackBarType === 'info') {
            config.panelClass = ['style-info'];
        }
        this.matSnackBarRef = this.snackBar.open(message, action, config);
  }

  dismissSnackBar(): void {
    if (!commonFunctions.isNullOrUndefined(this.matSnackBarRef)) {
        this.matSnackBarRef.dismiss();
    }

}

}
