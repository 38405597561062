import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridApi } from 'ag-grid-community';
import { catchError, map } from 'rxjs/operators';
import { TimeEntryModel } from '../models/TimeEntry.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { URLPaths } from '../utilities/urlPaths.config';
import { ApiheaderService } from './apiheader.service';
import { commonFunctions } from '../classes/common.class';
import { ErrorHandlerService } from './error-handler.service';
import { DateUtility } from '../utilities/date.utility';

@Injectable({
    providedIn: 'root'
})
export class TimeEntryService {

    agGridAPI: GridApi;
    dateEntryCountHashMap: any;
    agGridColumnAPI: any;
    entryCountHashMap: BehaviorSubject<Map<string, number>> = new BehaviorSubject<Map<string, number>>(null);
    entryActualsAndChanged: BehaviorSubject<TimeEntryModel[]> = new BehaviorSubject<TimeEntryModel[]>([]);
    entryIsUnsavedChangesPresent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    entryisRowSelectedForDeletion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    entryPlanned: BehaviorSubject<TimeEntryModel[]> = new BehaviorSubject<TimeEntryModel[]>([]);
    rowDragMode: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(true);
    columnDefs = [];
    autoGroupColumnDefs = {};
    entryTDate = DateUtility.formatStringfromDate(DateUtility.createDateType());

    constructor(
        private _http: HttpClient,
        private _titleCasePipe: TitleCasePipe,
        private _errorHandlerService: ErrorHandlerService,
    ) {
        this.entryCountHashMap.subscribe((data) => {
            this.dateEntryCountHashMap = data;
        })
        //Time Entry AG Grid Column Defs
        this.columnDefs = [
            {
                headerName: '',
                field: 'Task_Description',
                width: 100,
                valueGetter: (params) => {
                    return '';
                },
                checkboxSelection: params => {
                    if ((params.node.group) || (params.node.data && (params.node.data.status !== 'dummy' && params.node.data.Invoice_id === 0))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                rowDrag: params => !params.node.group && params.node.data.status !== 'dummy' && params.node.data.status !== 'bad' && params.node.data.status !== 'multiplerate',
                pinned: 'left'
            },
            {
                headerName: 'ID',
                field: 'Time_ID',
                hide: true,
                valueGetter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return parseInt(params.data.Time_ID, 10);
                },
            },
            {
                headerName: 'Client',
                field: 'Client',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return params.data.Client + ' - ' + params.data.ClientName;

                    }
                    return null;
                }
            },
            {
                headerName: 'Project',
                field: 'Project',
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return params.data.Project + ' - ' + params.data.ProjectName;
                    }
                    return null;
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Task Name', field: 'Task_Name',
                valueGetter: (params) => {
                    return params.node.data ? (params.node.data.Task_Code + ' - ' + params.node.data.Task_Name) : '';
                },
                enablePivot: true, enableRowGroup: true, enableValue: true
            },
            {
                headerName: 'Hours',
                field: 'Hours',
                filter: 'agNumberColumnFilter',
                aggFunc: 'sum',
                editable: params => {
                    if (!commonFunctions.isNullOrUndefined(params.node.data) && params.node.data.status !== 'dummy' &&
                        !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0) &&
                        (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                        return true;
                    } else {
                        return false;
                    }
                },
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return parseFloat(params.data.Hours);
                    }
                    return null;
                },
                valueParser: (params) => {
                    const returnValue = +Number(params.newValue).toFixed(2);
                    if (!isNaN(returnValue)) {
                        if (returnValue > 0 && returnValue <= 24) {
                            return returnValue;
                        }
                    }
                    return params.oldValue;
                },
                valueSetter: (params) => {
                    if (params.oldValue !== params.newValue) {
                        params.data.Hours = params.newValue;
                        params.data.status = 'update';
                        this.setTimeEntryIsUnsavedChangesPresent(true);
                        return true;
                    } else {
                        return false;
                    }
                },
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Entry Date',
                field: 'TDate',
                filter: 'agDateColumnFilter',
                rowGroup: true,
                hide: true,
                valueGetter: (params) => {
                    if (params.node.group) {
                        return (params.node.aggData);
                    }
                    return (params.data.TDate);
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return DateUtility.formatDateWithDayNamefromString(params.value.substr(0, 10));
                    }
                    return DateUtility.formatDatefromString(params.value.substr(0, 10));
                },
                showRowGroup: true
            },
            {
                headerName: 'Rate',
                field: 'Rate',
                filter: 'agNumberColumnFilter',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
                valueGetter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return parseFloat(params.data.Rate).toFixed(3);
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return params.data.Currency_symbol_short + ' ' + params.value;
                }
            },
            {
                headerName: 'Cost',
                field: 'Cost',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
                filter: 'agNumberColumnFilter',
                valueGetter: (params) => {
                    if (!params.node.group) {
                        return parseFloat(params.data.Cost).toFixed(3);
                    }
                    return null;
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return params.data.Currency_symbol_short + ' ' + params.value;
                }
            },
            {
                headerName: 'Currency Code', field: 'Currency_Code',
                enablePivot: true, enableRowGroup: true, enableValue: true
                , hide: true
            },
            {
                headerName: 'Invoice Date',
                field: 'Invoice_Date',
                filter: 'agDateColumnFilter',
                valueGetter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return (params.data.Invoice_Date);
                },
                valueFormatter: (params) => {
                    if (params.node.group) {
                        return null;
                    }
                    return DateUtility.formatDatefromString(params.value.toString().substr(0, 10));
                }
            },
            {
                headerName: 'Charge',
                field: 'Charge',
                filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (!commonFunctions.isNullOrUndefined(params.data) && params.data.Charge) {
                        return params.data.Charge.toUpperCase();
                    }
                },
            },
            {
                headerName: 'Employee',
                field: 'Employee',
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
                aggFunc: 'first'
            },
            {
                headerName: 'Employee_FullName',
                field: 'Employee_FullName',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Entry Owner LoginName',
                field: 'Entry_Owner_LoginName',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'Contact',
                field: 'Contact',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true
            },
            {
                headerName: 'State Name',
                field: 'State_Name',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Year',
                field: 'Year',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Year-Month',
                field: 'Month',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Week Start',
                field: 'Week_Commencing',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Company Name',
                field: 'Company_Name',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            },
            {
                headerName: 'Consultant Company Name',
                field: 'Consultant_Company_Name',
                hide: true,
                enablePivot: true,
                enableRowGroup: true,
                enableValue: true,
            }
        ];
        //Time Entry AG Grid Column Defs
        this.autoGroupColumnDefs = {
            headerName: 'Task Description',
            width: 400,
            field: 'Task_Description',
            editable: params => {
                if (params.node.data.status !== 'dummy' &&
                    params.node.data.status !== 'bad' && params.node.data.status !== 'multiplerate' // )
                    && !commonFunctions.isNullOrUndefined(params.node.data.Invoice_id) && (params.node.data.Invoice_id === 0)
                    && (!(params.node.data.Client === 'AD' && (params.node.data.Project === 'TS' || params.node.data.Project === 'EX')))) {
                    return true;
                } else {
                    return false;
                }
            },
            valueSetter: (params) => {
                if (params.oldValue !== params.newValue) {
                    params.data.Task_Description = params.newValue;
                    params.data.status = 'update';
                    this.setTimeEntryIsUnsavedChangesPresent(true);
                    return true;
                } else {
                    return false;
                }
            },
        };
    }

    //Set Entry TDate
    setEntryTDate(TDate) {
        this.entryTDate = TDate;
    }

    //Get Entry TDate
    getEntryTDate(){
        return this.entryTDate;
    }

    //Set AG Grid API
    setGridApi(agGridAPI: GridApi) {
        this.agGridAPI = agGridAPI;
    }

    //Get AG Grid API
    getGridApi() {
        return this.agGridAPI;
    }

    //Set AG Grid Column API
    setGridColumnApi(agGridColumnAPI: any) {
        this.agGridColumnAPI = agGridColumnAPI;
    }

    //Get AG Grid Column API
    getGridColumnApi() {
        return this.agGridColumnAPI;
    }

    //Set Time Entry Actuals to store
    setTimeEntryActualsAndChanged(actualsAndChangedTimeEntries) {
        this.entryActualsAndChanged.next(actualsAndChangedTimeEntries);
    }

    //Set Time Entry Count Hashmap to store
    setDateTimeEntryCountHashMap(dateEntryCountHashMap) {
        this.entryCountHashMap.next(dateEntryCountHashMap);
    }

    //Set boolean whether if any unsaved changes are present in time page
    setTimeEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
        this.entryIsUnsavedChangesPresent.next(isUnsavedChangesPresent);
    }

    //Get all the Time Entries 
    getTimeEntries(startDate, endDate, employee = '', clientProjects = ''): Observable<TimeEntryModel[]> {
        let params = new HttpParams();
        let formattedEmployee = '';
        if (employee !== '') {
            formattedEmployee = employee.split(',').map(value => {
                return ('\'' + value + '\'');
            }).join(',');
        } else {
            formattedEmployee = '';
        }

        params = params.append('startdate', startDate);
        params = params.append('enddate', endDate);
        params = params.append('employee', formattedEmployee);
        params = params.append('clientProjects', clientProjects);

        return this._http.get<TimeEntryModel[]>(URLPaths.URL_TIME_ENTRY, { headers: ApiheaderService.getHeaders(), params: params })
            .pipe(
                map(values => {
                    for (const value of values) {
                        value.Employee = this._titleCasePipe.transform(value.Employee);
                        value.Client = commonFunctions.convertCase(value.Client, 1);
                        value.Project = commonFunctions.convertCase(value.Project, 1);
                        value.Task_Code = commonFunctions.convertCase(value.Task_Code, 1);
                        value.Charge = commonFunctions.convertCase(value.Charge, 1);
                        value.Currency_Code = commonFunctions.convertCase(value.Currency_Code, 1);
                        value.Entry_Owner = this._titleCasePipe.transform(value.Entry_Owner);
                        value.Entry_Owner_LoginName = commonFunctions.convertCase(value.Entry_Owner_LoginName, 0);
                        value.Employee_Loginname = commonFunctions.convertCase(value.Employee_Loginname, 0);
                        value.State_Name = this._titleCasePipe.transform(value.State_Name);

                        if (parseInt(<string>value.Version, 10) === 1) {
                            value.Version = 'ACTUAL';
                        } else {
                            value.Version = 'PLAN';
                        }
                    }
                    return values as TimeEntryModel[];
                }),
                catchError(this._errorHandlerService.handleError.bind(this))
            );
    }

    //Update the actuals entry in AG Grid
    updateActualsAndChangedEntries(agGridAPI: GridApi): void {
        const rowData: TimeEntryModel[] = [];
        this.entryActualsAndChanged.next([]);
        agGridAPI.forEachNode(node => {
            if (!commonFunctions.isNullOrUndefined(node.data) && node.data.status !== 'dummy') {
                if (commonFunctions.isNullOrUndefined(node.data.Time_ID)) {
                    node.data.Time_ID = 0;
                }
                rowData.push(node.data);
            }
        });
        this.setTimeEntryActualsAndChanged(rowData);
    }

    //Add Records in the AG Grid
    addRecordsToGrid(agGridAPI: GridApi, values): void {
        if (values.status !== 'bad' && values.status !== 'multiplerate') {
            values.status = 'create';
        }
        this.setTimeEntryIsUnsavedChangesPresent(true);
        if (this.dateEntryCountHashMap.get(values.TDate.substr(0, 10)) === -1) {
            this.dateEntryCountHashMap.set(values.TDate.substr(0, 10), 1);
            agGridAPI.forEachNode(node => {
                if (!commonFunctions.isNullOrUndefined(node.data) && node.data.TDate === values.TDate && node.data.status === 'dummy') {
                    agGridAPI.applyTransaction({ remove: [node.data] });
                }
            });
        } else {
            this.dateEntryCountHashMap.set(values.TDate.substr(0, 10),
                this.dateEntryCountHashMap.get(values.TDate.substr(0, 10)) + 1);
        }
        this.setDateTimeEntryCountHashMap(this.dateEntryCountHashMap);
        agGridAPI.applyTransaction({ add: [values] });
    }

    //Save Time Entry Data
    saveEntries(timeEntryData: TimeEntryModel[]): Observable<any> {
        for (let timeEntry of timeEntryData) {
            if (timeEntry.Version === 'ACTUAL') {
                (timeEntry.Version) = 1;
            } else if (timeEntry.Version === 'PLAN') {
                (timeEntry.Version) = 2;
            }
        }
        timeEntryData = timeEntryData.filter(values => {
            return values.Version === 1 && values.status !== 'clean' && values.status !== 'remove';
        });
        return this._http.post(URLPaths.URL_TIME_ENTRY, { 'save_time_entries': timeEntryData }, { headers: ApiheaderService.getHeaders() })
            .pipe(
                catchError(this._errorHandlerService.handleError.bind(this)));
    }

    //Delete Time Entries
    deleteEntries(timeEntryData: TimeEntryModel[]): Observable<any> {
        let params = new HttpParams();
        for (const timeEntry of timeEntryData) {
            params = params.append('deleteTimeIdArray', String(timeEntry.Time_ID));
        }
        return this._http.delete(URLPaths.URL_TIME_ENTRY + '1/', { headers: ApiheaderService.getHeaders(), params: params }).pipe(
            catchError(this._errorHandlerService.handleError.bind(this)));
    }

}
