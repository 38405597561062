import { Component } from '@angular/core';
import { TimeEntryForPlanningService } from '../time-entry-for-planning.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DateRangeModel } from 'app/core/mips/common/models/date-range.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-time-entry-header',
  templateUrl: './time-entry-header.component.html',
  styleUrls: ['./time-entry-header.component.scss']
})
export class TimeEntryHeaderComponent {

  entryForm: FormGroup;
  getDateRange: any;
  setDateRange: any;
  weekStart: string = '';
  weekEnd: string = '';
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  clientList = [
    { id: 1, name: 'Option 1A' },
    { id: 2, name: 'Option 1B' },
    { id: 3, name: 'Option 1C' }
  ];
  projectList = [
    { id: 1, name: 'Option 2A' },
    { id: 2, name: 'Option 2B' },
    { id: 3, name: 'Option 2C' }
  ];
  employeeList = [
    { id: 1, name: 'Option 3A' },
    { id: 2, name: 'Option 3B' },
    { id: 3, name: 'Option 3C' }
  ];

  constructor(private service: TimeEntryForPlanningService,
              private fb: FormBuilder,
              private _store: Store,
              private _datePipe: DatePipe,
  ) 
  {

  }

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      clientDropDown: [],
      projectDropDown: [],
      employeeDropDown: [],
      firstCheckbox: [false],
      secondCheckbox: [false],
      dateRange: this.fb.group({
        startDate: [],
        endDate: []
      })
    });

    this.calculateWeekRange();
  }

  calculateWeekRange(): void {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)); // Monday
    const endOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 5));   // Friday

    this.weekStart = this.formatDate(startOfWeek);
    this.weekEnd = this.formatDate(endOfWeek);
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' }); // Converts month to short format like 'Jan', 'Feb', etc.
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

}
