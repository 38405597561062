export class ConsultantRateModel {
    Client: string;
    Project: string;
    Rate: string;
    Currency_symbol_short: string;
    Currency_Code: string;
    Currency_To: string;
    Converted_Currency_Symbol: string;
    Converted_Currency_Code: string;
    Converted_Rate: string;
    Is_Converted_Rate: number;
    Is_Default_Rate: number;
    Display_Rate: string;
}

export class ConsultantProjectRateParameterModel {
    employee: string;
    client: string;     // client code
    project: string;       // project code
    entrydate: string;      // format: YYYYMM
    hourlyRate?: number;
    leveragePercentage?: number;
    currencyCode?: string;
}
