import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class FormLayoutService  {
  public projectEntryIsUnsavedChangesPresent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setprojectEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
    this.projectEntryIsUnsavedChangesPresent.next(isUnsavedChangesPresent);
  }
  
  
}
