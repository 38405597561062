import { Route } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const pageRoutes: Route[] = [
    // Landing routes
    {
        path: 'landing',
        component: LandingComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '404',
        component: PageNotFoundComponent
    }
];