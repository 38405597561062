import { Component } from '@angular/core';
import { FlagEntryService } from '../../common/services/flag-entry.service';
import { DateUtility } from '../../common/utilities/date.utility';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
})
export class FlagComponent {
  currentWeekStart: any;
  today = DateUtility.createDateType();
  checkFlagDeadline = null;
  matOptionsValue = {
    weeks: [],
    months: [],
    selectedWeek: '',
    selectedMonth: '',
  };
  // Cards shown in the flag page
  cards = [
    {
      id: 'monthFlag',
      title: 'Month Flag',
      displayOrder: '2',
      data: []
    },
    {
      id: 'weekFlag',
      title: 'Week Flag',
      displayOrder: '1',
      data: []
    },
    {
      id: 'notifications',
      title: 'Notifications',
      displayOrder: '3',
      data: []
    }
  ];


  constructor(
    private _flagEntryService: FlagEntryService,
    private _store: Store) {
    this._flagEntryService.getSummaryDateRange().subscribe((data) => {
      this.currentWeekStart = data.weekStartDate;
    });
    this._store.select(getCurrentUser).subscribe((data) => {
      this._flagEntryService.getFlagEntry().subscribe(
        res => {
          this.checkFlagDeadline = res;
        });
    });

    this.matOptionsValue = {
      weeks: this._flagEntryService.getWeeksInYear(),
      selectedWeek: this._flagEntryService.getSelectedWeek(this.currentWeekStart),
      months: this._flagEntryService.getMonthsInYear(),
      selectedMonth: this._flagEntryService.getSelectedMonth(DateUtility.getMonthName(this.today.getMonth(), 'short'), this.today)
    }
  }
}
