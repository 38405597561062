<!-- Time Entry Page-->
<div class="flex flex-col gap-2.5 h-full">
    <!-- Time Entry Page Header -->
    <div class="hidden tb:block">
        <app-entry-header [entryPageType]="entryPageType"></app-entry-header>
    </div>
    <!-- Time Entry Page Table -->
    <div class="h-full">
        <app-entry-table [entryPageType]="entryPageType"></app-entry-table>
    </div>
</div>
