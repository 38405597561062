import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddClientComponent } from './add-client.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '../common/directives/directives.module';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  declarations: [AddClientComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AgGridModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatInputModule,
    DirectivesModule,
    MatTabsModule
  ],
  exports: [AddClientComponent]
})
export class AddClientModule { }
